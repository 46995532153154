import React, { ComponentType, useEffect, useRef } from 'react';

export default function withAutoFocus<T>(Component: ComponentType<T>) {
  const WithAutoFocusComponent = (hocProps: T) => {
    const ref = useRef<HTMLElement | null>(null);

    useEffect(() => {
      setTimeout(() => ref?.current?.focus(), 2);
    }, []);

    return <Component ref={ref} {...hocProps} />;
  };

  return WithAutoFocusComponent;
}

import _accept from 'attr-accept';

/**
 * They added support for ESM in v3;
 * https://github.com/react-dropzone/attr-accept/pull/75
 *
 * Until that is released we can use either v3.0.0-beta.1, or this workaround;
 * https://github.com/react-dropzone/react-dropzone/commit/2471dd9
 */
type Accept = typeof _accept;
const accept = typeof _accept === 'function' ? _accept : (_accept as { default: Accept }).default;

// This covers all standard MIME type categories as defined by IANA (Internet Assigned Numbers Authority).
// Any valid MIME type should fall under one of these categories.
const STANDARD_MIME_CATEGORIES = [
  'application/*',
  'audio/*',
  'font/*',
  'image/*',
  'message/*',
  'model/*',
  'multipart/*',
  'text/*',
  'video/*',
  'chemical/*', // IANA registered
  'x-conference/*', // IANA registered
];

export const isValidMimeType = (mimeType: string): boolean => {
  // Check if it follows the format type/subtype
  if (!/^[a-z0-9\-.+]+\/[a-z0-9\-.+]+$/i.test(mimeType)) {
    return false;
  }

  // Check if it falls under one of the standard MIME type categories
  return accept({ type: mimeType }, STANDARD_MIME_CATEGORIES);
};

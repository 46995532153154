import styled from 'styled-components';

export const REQUIRED_INDICATOR_SIZE = 16;
export const REQUIRED_INDICATOR_PADDING = 5;

export const Container = styled.div<{
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}>`
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : 'auto')};
  right: ${({ right }) => (right ? `${right}px` : 'auto')};
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : 'auto')};
  left: ${({ left }) => (left ? `${left}px` : 'auto')};
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${REQUIRED_INDICATOR_SIZE}px;
  height: ${REQUIRED_INDICATOR_SIZE}px;
  min-width: ${REQUIRED_INDICATOR_SIZE}px;
  min-height: ${REQUIRED_INDICATOR_SIZE}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.textGrayscale6};
  color: ${({ theme }) => theme.color.text};
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  cursor: pointer;

  > span {
    position: relative;
    top: 2px;
  }

  &.hidden {
    visibility: hidden;
  }
`;

import { BlockType, INSERT_MENU_EMBED_BLOCKS } from '@tallyforms/lib';
import { breakpoint, withDirection, withTextLinkStyle } from '@tallyforms/ui';
import styled from 'styled-components';

import { FormStyles } from '@/types/form-design';

export const Container = styled.div`
  a:not(.unstyled) {
    ${({ theme }) => withTextLinkStyle(theme)}
  }

  span.mention {
    &:empty {
      border-bottom: 2px solid ${({ theme }) => theme.color.textGrayscale4};

      :before {
        content: '                    ';
      }
    }
  }
`;

export const ColumnList = styled.div`
  display: flex;

  @media (max-width: ${breakpoint.sm}) {
    flex-direction: column;
  }
`;

export const Column = styled.div<{
  ratio: number;
  flex?: number;
  first: boolean;
  last: boolean;
}>`
  padding: 0 ${({ theme }) => theme.spacing.xs};

  ${({ flex, ratio }) =>
    typeof flex === 'undefined' &&
    `
    width: ${ratio}%;
  `}

  ${({ flex }) =>
    typeof flex !== 'undefined' &&
    `
    flex: 1;
  `}

  ${({ first, theme }) => first && withDirection('padding-left', '0', theme.direction)}

  ${({ last, theme }) => last && withDirection('padding-right', '0', theme.direction)}

  div[class*=' tally-block-input'] > div > div,
  .tally-block-payment > div,
  .tally-block-dropdown > div > div,
  .tally-block-multiple-choice-option > div > div,
  .tally-block-checkbox > div > div,
  .tally-block-multi-select > div > div,
  .tally-other-option-input {
    min-width: 100%;
  }

  .tally-block-multiple-choice-option img,
  .tally-block-checkbox img {
    max-width: 100%;
  }

  @media (max-width: ${breakpoint.sm}) {
    width: 100%;
    padding: 0;
  }
`;

export const BlockContainer = styled.div<{ blockType: BlockType; formStyles?: FormStyles }>`
  padding: 1px 0;

  ${({ blockType }) =>
    INSERT_MENU_EMBED_BLOCKS.includes(blockType) === false &&
    `
    white-space: pre-wrap;
    word-break: break-word;
  `}

  ${({ blockType, formStyles }) =>
    blockType === BlockType.FormTitle &&
    formStyles?.hideTitle &&
    `
    display: none;
  `}

  &:empty {
    padding: 0;
  }
`;

import { Country } from '../types';

export const EU_COUNTRIES = [
  Country.Belgium,
  Country.Bulgaria,
  Country.CzechRepublic,
  Country.Denmark,
  Country.Germany,
  Country.Estonia,
  Country.Ireland,
  'EL', // not ISO 3166-1 alpha-2 compliant, but it's Greece
  Country.Spain,
  Country.France,
  Country.Croatia,
  Country.Italy,
  Country.Cyprus,
  Country.Latvia,
  Country.Lithuania,
  Country.Luxembourg,
  Country.Hungary,
  Country.Malta,
  Country.Netherlands,
  Country.Austria,
  Country.Poland,
  Country.Portugal,
  Country.Romania,
  Country.Slovenia,
  Country.Slovakia,
  Country.Finland,
  Country.Sweden,
];

import { LucideIcon } from 'lucide-react';
import { ReactNode } from 'react';

export type State = ContextualCommandItemPayload[];

export type ContextualCommandItemPayload = {
  icon: LucideIcon | ReactNode;
  label: string;
  onClick: () => void | Promise<void>;
  isDisabled?: boolean;
  disabledText?: string;
};

export interface Action {
  type: ActionType;
  data?: any;
}

export enum ActionType {
  SetCommands = 'Commands.Set',
}

export type CommandMenuItem = {
  id: string;
  type: CommandMenuItemType;
  label: string;
  payload: any;
};

export enum CommandMenuItemType {
  Form = 'FORM',
  Workspace = 'WORKSPACE',
  Help = 'HELP',
  Navigation = 'NAVIGATION',
  Contextual = 'CONTEXTUAL',
}

export const SEARCH_TYPES = [
  CommandMenuItemType.Form,
  CommandMenuItemType.Workspace,
  CommandMenuItemType.Help,
];

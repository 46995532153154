import { breakpoint, withDirection } from '@tallyforms/ui';
import styled from 'styled-components';

export const Content = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 0;

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Container = styled.button<{
  variant?: 'primary' | 'outline' | 'ghost' | 'ghost-secondary' | 'destructive';
  color?: 'accent' | 'black' | 'white' | 'main' | any;
  size?: 'regular' | 'big' | 'small';
  fullWidth?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  iconOnly?: boolean;
  isIconOnlyOnMobile?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 100%;
  height: 28px;
  padding: 0 8px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  outline: none;
  white-space: nowrap;
  transition: background 200ms ease-in 0s;
  transition: color 100ms ease-in 0s;
  text-align: ${({ theme }) => (theme.direction === 'rtl' ? 'right' : 'left')};
  line-height: ${({ size }) => (size === 'big' ? '18px' : '16px')};

  &:focus {
    box-shadow: ${({ theme }) => theme.color.inputBoxShadowFocus};
  }

  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;

    &:first-child {
      ${({ theme }) => withDirection('margin-right', '8px', theme.direction)}
    }

    &:last-child {
      ${({ theme }) => withDirection('margin-left', '8px', theme.direction)}
    }

    &:first-child:last-child {
      margin: 0;
    }
  }

  ${({ iconOnly }) =>
    iconOnly &&
    `
    padding: 0 6px;
  `}

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
  `}

  ${({ theme, variant }) =>
    (!variant || variant === 'primary') &&
    `
    color: ${theme.color.buttonText};
    background: ${theme.color.buttonBackground};
    box-shadow: ${theme.color.inputBoxShadow};

    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
        box-shadow: ${theme.color.inputBoxShadowHover};
      }
    }
  `}

  ${({ theme, variant, color }) =>
    (!variant || variant === 'primary') &&
    color === 'accent' &&
    `
    background: ${theme.color.accentButton};
    box-shadow: ${theme.color.accentButtonBoxShadow};

    @media (hover: hover) {
      &:hover {
        opacity: 1;
        background: ${theme.color.accentButtonHover};
        box-shadow: ${theme.color.accentButtonBoxShadowHover};
      }
    }
  `}

  ${({ theme, variant, color }) =>
    (!variant || variant === 'primary') &&
    color === 'main' &&
    `
    background: ${theme.color.main};

    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
        box-shadow: ${theme.color.inputBoxShadowHover};
      }
    }
  `}

  ${({ theme, variant, color }) =>
    (!variant || variant === 'primary') &&
    color === 'white' &&
    `
    color: ${theme.color.darkerGray};
    background: ${theme.color.white};
  `}

  ${({ theme, variant }) =>
    variant === 'outline' &&
    `
    color: ${theme.color.textGrayscale2};
    background-color: transparent;
    border: 1px solid ${theme.color.textGrayscale5};

    @media (hover: hover) {
      &:hover {
        color: ${theme.color.text};
        background-color: ${theme.color.actionBackgroundHover};
        border: 1px solid ${theme.color.textGrayscale4};
      }
    }
  `}

  ${({ theme, variant }) =>
    variant === 'ghost' &&
    `
    color: ${theme.color.textGrayscale2};
    background-color: transparent;

    @media (hover: hover) {
      &:hover {
        color: ${theme.color.text};
        background-color: ${theme.color.actionBackgroundHover};
      }
    }
  `}

  ${({ theme, variant, color }) =>
    variant === 'ghost' &&
    color === 'accent' &&
    `
    color: ${theme.color.accentButton};

    @media (hover: hover) {
      &:hover {
        color: ${theme.color.accentButtonHover};
      }
    }
  `}

  ${({ theme, variant, color }) =>
    variant === 'ghost' &&
    color === 'red' &&
    `
    color: ${theme.color.red};

    @media (hover: hover) {
      &:hover {
        color: ${theme.color.redDark1};
        background-color: ${theme.color.redLight2};
      }
    }
  `}

  ${({ theme, variant, color }) =>
    variant === 'ghost' &&
    color === 'text' &&
    `
    color: ${theme.color.text};

    @media (hover: hover) {
      &:hover {
        color: ${theme.color.black};
      }
    }
  `}

   ${({ variant, color }) =>
    variant === 'ghost' &&
    color &&
    ['accent', 'black', 'white', 'red'].includes(color) === false &&
    `
    color: ${color};

    @media (hover: hover) {
      &:hover {
        color: ${color};
      }
    }
  `}

  ${({ theme, variant }) =>
    variant === 'ghost-secondary' &&
    `
    color: ${theme.color.textGrayscale3};
    background-color: transparent;
    font-weight: ${theme.font.weight.medium};

    @media (hover: hover) {
      &:hover {
        color: ${theme.color.text};
        background-color: ${theme.color.actionBackgroundHover};
      }
    }
  `}

  ${({ theme, variant, color }) =>
    variant === 'ghost-secondary' &&
    color === 'accent' &&
    `
    color: ${theme.color.accentButton};

    @media (hover: hover) {
      &:hover {
        color: ${theme.color.accentButtonHover};
      }
    }
  `}

  ${({ theme, variant }) =>
    variant === 'destructive' &&
    `
    color: ${theme.color.white};
    background-color: ${theme.color.error};

    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }
  `}


  ${({ theme, size }) =>
    size === 'big' &&
    `
    height: 36px;
    padding: 0 14px;
    font-size: 16px;
    font-weight: ${theme.font.weight.semiBold};
  `}

  ${({ size, iconOnly }) =>
    size === 'big' &&
    iconOnly &&
    `
    padding: 0 10px;
  `}

  ${({ theme, size }) =>
    size === 'small' &&
    `
    height: 20px;
    padding: 0 6px;

    svg {
      &:first-child {
        ${withDirection('margin-right', '4px', theme.direction)}
      }

      &:last-child {
        ${withDirection('margin-left', '4px', theme.direction)}
      }
    }
  `}

  ${({ size, iconOnly }) =>
    size === 'small' &&
    iconOnly &&
    `
    padding: 0 2px;
  `}

  ${({ isIconOnlyOnMobile }) =>
    isIconOnlyOnMobile &&
    `
    padding: 0 10px;
    font-weight: bold;

    @media (max-width: ${breakpoint.md}) {
      padding: 0 6px;

      ${Content} span {
        display: none;
      }

      svg {
        margin: 0 !important;
      }
    }
  `}

  ${({ isLoading }) =>
    isLoading &&
    `
    ${Content} {
      opacity: 0;
    }
  `}

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;

    @media (hover: hover) {
      &:hover {
        opacity: 0.5;
      }
    }
  `}
`;

export const LoadingContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const LinkWrapper = styled.div<{ fullWidth?: boolean }>`
  display: inline-flex;

  a {
    display: inline-flex;
    text-decoration: none;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;

    a {
      width: 100%;
    }
  `}
`;

import { Container } from './styled';

interface Props {
  html: string;
}

const FormTitleBlock = ({ html }: Props) => {
  return <Container dangerouslySetInnerHTML={{ __html: html }} />;
};

export default FormTitleBlock;

import {
  BlockType,
  convertPriceValueToAmount,
  FormErrors,
  hasBusinessAccess,
  hasProAccess,
  isField,
  Language,
  PaymentBlock as PaymentBlockType,
  RespondBlock,
  SafeSchemaBlock,
  SubscriptionPlan,
  UNSELECTABLE_BLOCKS,
} from '@tallyforms/lib';
import dynamic from 'next/dynamic';

import RequiredIndicator from '@/components/form/required-indicator';
import DividerBlock from '@/components/form-respond/block/divider';
import FormTitleBlock from '@/components/form-respond/block/form-title';
import HeadingBlock from '@/components/form-respond/block/heading';
import ImageBlock from '@/components/form-respond/block/image';
import InputEmailBlock from '@/components/form-respond/block/input/email';
import InputLinkBlock from '@/components/form-respond/block/input/link';
import InputNumberBlock from '@/components/form-respond/block/input/number';
import InputTextBlock from '@/components/form-respond/block/input/text';
import LabelBlock from '@/components/form-respond/block/label';
import RespondentCountryBlock from '@/components/form-respond/block/respondent-country';
import TextBlock from '@/components/form-respond/block/text';
import TextareaBlock from '@/components/form-respond/block/textarea';
import TitleBlock from '@/components/form-respond/block/title';
import { Question } from '@/types/form-builder';
import { FormStyles } from '@/types/form-design';
import { isRequiredIndicatorAtBlock } from '@/utils/block';
import { getAnswerForField } from '@/utils/form-respond';

// Dynamic block components
const InputDateBlock = dynamic(() => import('@/components/form-respond/block/input/date'));
const InputTimeBlock = dynamic(() => import('@/components/form-respond/block/input/time'));
const InputPhoneNumberBlock = dynamic(
  () => import('@/components/form-respond/block/input/phone-number'),
);
const MultipleChoiceOptionBlock = dynamic(
  () => import('@/components/form-respond/block/multiple-choice-option'),
);
const CheckboxBlock = dynamic(() => import('@/components/form-respond/block/checkbox'));
const DropdownBlock = dynamic(() => import('@/components/form-respond/block/dropdown'));
const RankingOptionBlock = dynamic(() => import('@/components/form-respond/block/ranking-option'));
const MultiSelectBlock = dynamic(() => import('@/components/form-respond/block/multi-select'));
const LinearScaleBlock = dynamic(() => import('@/components/form-respond/block/linear-scale'));
const RatingBlock = dynamic(() => import('@/components/form-respond/block/rating'));
const FileUploadBlock = dynamic(() => import('@/components/form-respond/block/file-upload'));
const PaymentBlock = dynamic(() => import('@/components/form-respond/block/payment'));
const EmbedBlock = dynamic(() => import('@/components/form-respond/block/embed'));
const SignatureBlock = dynamic(() => import('@/components/form-respond/block/signature'));
const MatrixCellBlock = dynamic(() => import('@/components/form-respond/block/matrix-cell'));
const CaptchaBlock = dynamic(() => import('@/components/form-respond/block/captcha'));
const WalletConnectBlock = dynamic(() => import('@/components/form-respond/block/wallet-connect'));

interface Props {
  block: RespondBlock;
  blocks: SafeSchemaBlock[];
  pageBlocks: RespondBlock[];
  question?: Question;
  formData: any;
  errors: FormErrors;
  mode: 'respond' | 'preview';
  subscriptionPlan: SubscriptionPlan;
  hasPartialSubmissions: boolean;
  language: Language;
  formId?: string;
  workspaceId?: string;
  styles?: FormStyles;
  respondentUuid?: string;
  sessionUuid?: string;
  onChange: (id: string, value: any) => void;
  onSaveResponse?: (response: any) => Promise<void>;
  onSolveCaptcha?: (response: any) => void;
}

const FormBlock = ({
  block,
  blocks,
  pageBlocks,
  question,
  formData,
  errors,
  mode,
  subscriptionPlan,
  hasPartialSubmissions,
  language,
  formId,
  workspaceId,
  onChange,
  onSaveResponse,
  onSolveCaptcha,
  respondentUuid,
  sessionUuid,
}: Props) => {
  const { uuid, type, groupUuid, groupType, payload } = block;

  const renderRequiredIndicator = (requiredIndicatorProps: any = {}) => {
    if (!isRequiredIndicatorAtBlock(uuid, question)) {
      return null;
    }

    return <RequiredIndicator uniqueKey={uuid} {...requiredIndicatorProps} />;
  };

  const handleAnswerChange = (value: any) => {
    onChange(groupUuid, value);

    if (hasProAccess(subscriptionPlan) && hasPartialSubmissions && onSaveResponse) {
      // Collect partial submission
      let partialValue = value;

      // value of undefined for multiple choice, rating, linear scale means the respondent unselected the option
      // Set the value to NULL, which will instruct the API to remove the previously saved response
      if (typeof value === 'undefined' && UNSELECTABLE_BLOCKS.includes(groupType)) {
        partialValue = null;
      }

      onSaveResponse({ [groupUuid]: partialValue });
    }
  };

  switch (type) {
    case BlockType.FormTitle: {
      return <FormTitleBlock key={uuid} {...payload} />;
    }

    case BlockType.Text:
      return <TextBlock key={uuid} {...payload} />;

    case BlockType.Label:
      return (
        <LabelBlock
          key={uuid}
          uuid={uuid}
          {...payload}
          question={question}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );

    case BlockType.Title:
      return (
        <TitleBlock
          key={uuid}
          uuid={uuid}
          {...payload}
          question={question}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );

    case BlockType.Heading1:
    case BlockType.Heading2:
    case BlockType.Heading3:
      return (
        <HeadingBlock
          key={uuid}
          uuid={uuid}
          groupType={groupType}
          {...payload}
          question={question}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );

    case BlockType.Image:
      return <ImageBlock key={uuid} {...payload} />;

    case BlockType.Divider:
      return <DividerBlock key={uuid} {...payload} />;

    case BlockType.Embed:
    case BlockType.EmbedVideo:
    case BlockType.EmbedAudio:
      return <EmbedBlock key={uuid} uuid={uuid} payload={payload} />;

    case BlockType.InputText:
      return (
        <InputTextBlock
          key={uuid}
          value={formData[groupUuid]}
          error={errors[groupUuid]}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
          {...payload}
        />
      );

    case BlockType.InputNumber:
      return (
        <InputNumberBlock
          key={uuid}
          {...payload}
          value={formData[groupUuid]}
          error={errors[groupUuid]}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );

    case BlockType.InputEmail:
      return (
        <InputEmailBlock
          key={uuid}
          formId={formId}
          respondentUuid={respondentUuid}
          sessionUuid={sessionUuid}
          value={formData[groupUuid]}
          error={errors[groupUuid]}
          question={question}
          hasBusinessAccess={hasBusinessAccess(subscriptionPlan)}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
          mode={mode}
          {...payload}
        />
      );

    case BlockType.InputLink:
      return (
        <InputLinkBlock
          key={uuid}
          value={formData[groupUuid]}
          error={errors[groupUuid]}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
          {...payload}
        />
      );

    case BlockType.InputPhoneNumber:
      return (
        <InputPhoneNumberBlock
          key={uuid}
          value={formData[groupUuid]}
          error={errors[groupUuid]}
          question={question}
          language={language}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
          {...payload}
        />
      );

    case BlockType.InputDate:
      return (
        <InputDateBlock
          key={uuid}
          value={formData[groupUuid]}
          language={language}
          error={errors[groupUuid]}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
          {...payload}
        />
      );

    case BlockType.InputTime:
      return (
        <InputTimeBlock
          key={uuid}
          value={formData[groupUuid]}
          error={errors[groupUuid]}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
          {...payload}
        />
      );

    case BlockType.Textarea:
      return (
        <TextareaBlock
          key={uuid}
          value={formData[groupUuid]}
          error={errors[groupUuid]}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
          {...payload}
        />
      );

    case BlockType.FileUpload:
      return (
        <FileUploadBlock
          key={uuid}
          files={formData[groupUuid] ?? []}
          mode={mode}
          hasProAccess={hasProAccess(subscriptionPlan)}
          formId={formId}
          error={errors[groupUuid]}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
          respondentUuid={respondentUuid}
          sessionUuid={sessionUuid}
          {...payload}
        />
      );

    case BlockType.LinearScale:
      return (
        <LinearScaleBlock
          key={uuid}
          uuid={uuid}
          value={formData[groupUuid]}
          error={errors[groupUuid]}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
          {...payload}
        />
      );

    case BlockType.Rating:
      return (
        <RatingBlock
          key={uuid}
          uuid={uuid}
          value={formData[groupUuid]}
          question={question}
          {...payload}
          error={errors[groupUuid]}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );

    case BlockType.MultipleChoiceOption:
      return (
        <MultipleChoiceOptionBlock
          key={uuid}
          uuid={uuid}
          values={formData[groupUuid] ?? []}
          {...payload}
          error={errors[groupUuid]}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );

    case BlockType.Checkbox: {
      return (
        <CheckboxBlock
          key={uuid}
          uuid={uuid}
          values={formData[groupUuid] ?? []}
          {...payload}
          error={errors[groupUuid]}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );
    }

    case BlockType.Dropdown:
      return (
        <DropdownBlock
          key={uuid}
          values={formData[groupUuid] ?? []}
          {...payload}
          error={errors[groupUuid]}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );

    case BlockType.RankingOption:
      return (
        <RankingOptionBlock
          key={uuid}
          uuid={uuid}
          values={formData[groupUuid] ?? []}
          options={pageBlocks.filter((x) => x.groupUuid === groupUuid).map((x) => x.uuid)}
          {...payload}
          error={errors[groupUuid]}
          question={question}
          optionBlocks={pageBlocks.filter((x) => x.groupUuid === groupUuid)}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );

    case BlockType.MultiSelect:
      return (
        <MultiSelectBlock
          key={uuid}
          values={formData[groupUuid] ?? []}
          {...payload}
          layout={payload.layout}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
          error={errors[groupUuid]}
        />
      );

    case BlockType.Payment: {
      const { amount, currency } = payload as PaymentBlockType['payload'];

      // Either use the custom amount or the pre-populated one
      let amountProp = 0;

      if (isField(amount)) {
        const answer = getAnswerForField(formData, amount, blocks);
        amountProp = answer ? convertPriceValueToAmount(answer, currency) : 0;
      } else if (amount) {
        amountProp = amount;
      }

      return (
        <PaymentBlock
          key={uuid}
          mode={mode}
          uuid={uuid}
          groupUuid={groupUuid}
          formId={formId}
          workspaceId={workspaceId}
          result={formData[groupUuid]}
          {...payload}
          amount={amountProp}
          language={language}
          error={errors[groupUuid]}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );
    }

    case BlockType.Signature:
      return (
        <SignatureBlock
          key={uuid}
          uuid={uuid}
          value={formData[groupUuid]}
          {...payload}
          error={errors[groupUuid]}
          question={question}
          onChange={handleAnswerChange}
          renderRequiredIndicator={renderRequiredIndicator}
        />
      );

    case BlockType.Matrix:
    case BlockType.MatrixRow:
    case BlockType.MatrixColumn:
      return (
        <MatrixCellBlock
          key={uuid}
          html=""
          {...payload}
          renderRequiredIndicator={renderRequiredIndicator}
          type={type}
        />
      );

    case BlockType.Captcha:
      return (
        <CaptchaBlock
          key={uuid}
          language={language}
          error={errors[groupUuid]}
          onSolved={(data) => onSolveCaptcha && onSolveCaptcha({ [groupUuid]: data })}
        />
      );

    case BlockType.WalletConnect:
      return (
        <WalletConnectBlock
          key={uuid}
          error={errors[groupUuid]}
          onChange={handleAnswerChange}
          value={formData[groupUuid]}
          renderRequiredIndicator={renderRequiredIndicator}
          {...payload}
        />
      );

    case BlockType.RespondentCountry:
      return <RespondentCountryBlock key={uuid} onChange={handleAnswerChange} {...payload} />;
  }

  return null;
};

export default FormBlock;

import {
  Block,
  BlockType,
  NOT_ELIGIBLE_BLOCKS_TO_MOVE_IN_COLUMN,
  QUESTION_TITLE_BLOCKS,
  QUESTIONS_AUTO_JUMP_TO_NEXT_PAGE,
  RespondBlock,
} from '@tallyforms/lib';
import range from 'lodash/range';

import { ActionType, Question } from '@/types/form-builder';
import { getBlockIndex } from '@/utils/form-builder/dom';

export const isRequiredIndicatorAtBlock = (blockUuid: string, question?: Question): boolean => {
  if (!question || !question.isRequired) {
    return false;
  }

  return (
    question?.isRequired &&
    (question.titleBlockUuid === blockUuid ||
      (!question.titleBlockUuid && question.isRequiredBlockUuid === blockUuid))
  );
};

export const getBlocksBeforeSpecificBlock = (
  blocks: (Block | RespondBlock)[],
  blockUuid: string,
  alwaysIncludeBlockTypes: BlockType[] = [],
): (Block | RespondBlock)[] => {
  const filteredBlocks: (Block | RespondBlock)[] = [];
  let reachedBlockUuid = false;

  for (const block of blocks) {
    if (!reachedBlockUuid && block.uuid !== blockUuid) {
      filteredBlocks.push(block);
      continue;
    } else {
      reachedBlockUuid = true;
    }

    if (reachedBlockUuid && alwaysIncludeBlockTypes.includes(block.type)) {
      filteredBlocks.push(block);
      continue;
    }
  }

  return filteredBlocks;
};

export const isSingleChoiceBlock = (question: Question, blocks: RespondBlock[]): boolean => {
  if (!QUESTIONS_AUTO_JUMP_TO_NEXT_PAGE.includes(question.type)) {
    return false;
  }

  const block = blocks.find((block) => block.groupUuid === question.blockGroupUuid);

  // If there is no block we can assume it is a single choice block
  if (!block) {
    return true;
  }

  if (block.type === BlockType.MultipleChoiceOption || block.type === BlockType.Dropdown) {
    return !block.payload.allowMultiple;
  }

  // Otherwise we assume it is a single choice block
  return true;
};

export const hasConnectedBlocks = (block: Block, options?: { includeQuestions?: boolean }) =>
  isFoldedQuestionTitle(block) ||
  BlockType.Matrix === block.type ||
  (options?.includeQuestions && QUESTION_TITLE_BLOCKS.includes(block.type));

export const getConnectedBlockIndexes = (
  block: Block,
  question: Question,
  options?: { includeQuestions?: boolean },
): number[] => {
  let blockIndexes: number[] = [];

  if (hasConnectedBlocks(block, options)) {
    blockIndexes = range(
      getBlockIndex(question.startBlockUuid) + 1,
      getBlockIndex(question.endBlockUuid) + 1,
    );
  }

  return blockIndexes;
};

export const isFoldedQuestionTitle = (block: Block) =>
  block.payload.isFolded && QUESTION_TITLE_BLOCKS.includes(block.type);

export const isBlockNotEligibleToMoveInColumn = (blockType: BlockType, moveAction: ActionType) =>
  NOT_ELIGIBLE_BLOCKS_TO_MOVE_IN_COLUMN.includes(blockType) &&
  [ActionType.CreateColumnList, ActionType.CreateColumn, ActionType.AddToColumn].includes(
    moveAction,
  );

import {
  BlockType,
  ConditionalLogicActionType,
  ConditionalLogicComparison,
  ConditionalLogicConditional,
  ConditionalLogicLogicalOperator,
  ConditionalLogicPayload,
  ConditionalLogicType,
  FormData,
  getPageBreakBlock,
  getPageNumberByPageBlock,
  GROUP_OPTIONS_BLOCKS,
  isField,
  MATRIX_BLOCKS,
  NOT_ELIGIBLE_BLOCKS_TO_HIDE,
  RespondBlock,
  SafeSchemaBlock,
} from '@tallyforms/lib';
import escapeRegExp from 'lodash/escapeRegExp';
import isEqual from 'lodash/isEqual';

import { captureException } from '@/services/sentry';
import { isUuid } from '@/services/uuid';
import { getFormDataForField } from '@/utils/form-respond';

export const isConditionMet = (
  { type, payload }: ConditionalLogicConditional,
  formData: { [key: string]: any },
  blocks: (SafeSchemaBlock | RespondBlock)[],
): boolean => {
  if (type === ConditionalLogicType.Group && payload?.conditionals && payload.logicalOperator) {
    return areConditionsMet(payload.conditionals, payload.logicalOperator, formData, blocks);
  } else if (type === ConditionalLogicType.Single && payload?.field && payload?.comparison) {
    const { field, comparison } = payload;
    let { value } = payload;

    // Get the answer
    const answer = getFormDataForField(formData, field);

    // Check if the value is a field, then get the field's answer
    if (isField(value)) {
      value = getFormDataForField(formData, value);
    }

    try {
      switch (comparison) {
        case ConditionalLogicComparison.Is: {
          if (field.questionType === BlockType.Matrix) {
            if (!Array.isArray(value)) {
              value = [value];
            }

            return typeof answer !== 'undefined' && isEqual(answer, value);
          }

          // The comparison value is an array, but only has one value (because of previously using "is any of" or "is every of" comparison)
          if (Array.isArray(value) && value.length === 1) {
            value = value[0];
          }

          if (Array.isArray(answer)) {
            return answer.length === 1 && isEqualWithGroupOptionSupport(answer[0], value, blocks);
          }

          return (
            typeof answer !== 'undefined' && isEqualWithGroupOptionSupport(answer, value, blocks)
          );
        }

        case ConditionalLogicComparison.IsNot: {
          if (field.questionType === BlockType.Matrix) {
            if (!Array.isArray(value)) {
              value = [value];
            }

            return typeof answer !== 'undefined' && !isEqual(answer, value);
          }

          // The comparison value is an array, but only has one value (because of previously using "is any of" or "is every of" comparison)
          if (Array.isArray(value) && value.length === 1) {
            value = value[0];
          }

          if (Array.isArray(answer)) {
            return (
              answer.length !== 1 ||
              isEqualWithGroupOptionSupport(answer[0], value, blocks) === false
            );
          }

          return (
            typeof answer !== 'undefined' &&
            isEqualWithGroupOptionSupport(answer, value, blocks) === false
          );
        }

        case ConditionalLogicComparison.IsAnyOf: {
          if (!Array.isArray(value) || !Array.isArray(answer)) {
            return isEqualWithGroupOptionSupport(answer, value, blocks);
          }

          return value.some((x) => answer.some((y) => isEqualWithGroupOptionSupport(y, x, blocks)));
        }

        case ConditionalLogicComparison.IsEveryOf: {
          if (!Array.isArray(value) || !Array.isArray(answer)) {
            return isEqualWithGroupOptionSupport(answer, value, blocks);
          }

          return value.every((x) =>
            answer.some((y) => isEqualWithGroupOptionSupport(y, x, blocks)),
          );
        }

        case ConditionalLogicComparison.Contains:
          if (Array.isArray(answer)) {
            return (
              typeof value !== 'undefined' &&
              answer.some((x) => isEqualWithGroupOptionSupport(x, value, blocks))
            );
          }

          return (
            typeof answer !== 'undefined' &&
            typeof value !== 'undefined' &&
            new RegExp(escapeRegExp(value), 'i').test(answer)
          );

        case ConditionalLogicComparison.DoesNotContain:
          if (Array.isArray(answer)) {
            return (
              typeof value !== 'undefined' &&
              answer.some((x) => isEqualWithGroupOptionSupport(x, value, blocks)) === false
            );
          }

          return (
            typeof answer !== 'undefined' &&
            typeof value !== 'undefined' &&
            new RegExp(escapeRegExp(value), 'i').test(answer) === false
          );

        case ConditionalLogicComparison.StartsWith:
          return (
            typeof answer !== 'undefined' &&
            typeof value !== 'undefined' &&
            new RegExp(`^${escapeRegExp(value)}`, 'i').test(answer)
          );

        case ConditionalLogicComparison.DoesNotStartWith:
          return (
            typeof answer !== 'undefined' &&
            typeof value !== 'undefined' &&
            new RegExp(`^${escapeRegExp(value)}`, 'i').test(answer) === false
          );

        case ConditionalLogicComparison.EndsWith:
          return (
            typeof answer !== 'undefined' &&
            typeof value !== 'undefined' &&
            new RegExp(`${escapeRegExp(value)}$`, 'i').test(answer)
          );

        case ConditionalLogicComparison.DoesNotEndWith:
          return (
            typeof answer !== 'undefined' &&
            typeof value !== 'undefined' &&
            new RegExp(`${escapeRegExp(value)}$`, 'i').test(answer) === false
          );

        case ConditionalLogicComparison.IsEmpty:
          if (Array.isArray(answer)) {
            return answer.length === 0;
          }

          return typeof answer === 'undefined' || answer === '';

        case ConditionalLogicComparison.IsNotEmpty:
          if (Array.isArray(answer)) {
            return answer.length > 0;
          }

          return typeof answer !== 'undefined' && answer !== '';

        case ConditionalLogicComparison.Equal:
          return Number(answer) === Number(value);

        case ConditionalLogicComparison.NotEqual:
          return Number(answer) !== Number(value);

        case ConditionalLogicComparison.GreaterThan:
          return Number(answer) > Number(value);

        case ConditionalLogicComparison.LessThan:
          return Number(answer) < Number(value);

        case ConditionalLogicComparison.GreaterOrEqualThan:
          return Number(answer) >= Number(value);

        case ConditionalLogicComparison.LessOrEqualThan:
          return Number(answer) <= Number(value);

        case ConditionalLogicComparison.IsBefore:
          if (/^[0-9]{2}:[0-9]{2}$/.test(answer)) {
            return Date.parse(`01/01/2000 ${answer}`) < Date.parse(`01/01/2000 ${value}`);
          }

          return Date.parse(answer) < Date.parse(value);

        case ConditionalLogicComparison.IsAfter:
          if (/^[0-9]{2}:[0-9]{2}$/.test(answer)) {
            return Date.parse(`01/01/2000 ${answer}`) > Date.parse(`01/01/2000 ${value}`);
          }

          return Date.parse(answer) > Date.parse(value);
      }
    } catch (e) {
      // Capture error and return false
      captureException(e);
    }
  }

  return false;
};

export const areConditionsMet = (
  conditionals: ConditionalLogicConditional[],
  logicalOperator: ConditionalLogicLogicalOperator,
  formData: { [key: string]: any },
  blocks: (SafeSchemaBlock | RespondBlock)[],
): boolean => {
  const metConditions = conditionals.map((x) => isConditionMet(x, formData, blocks));

  return (
    (logicalOperator === ConditionalLogicLogicalOperator.And && metConditions.every((x) => x)) ||
    (logicalOperator === ConditionalLogicLogicalOperator.Or && metConditions.some((x) => x))
  );
};

export const applyConditionsToPageBlocks = (
  pageBlocks: RespondBlock[],
  blocks: SafeSchemaBlock[],
  formData: { [key: string]: any },
  onChangeFormData?: (values: FormData) => void,
): RespondBlock[] => {
  // Get conditionals
  const conditionalLogicBlocks = blocks.filter((x) => x.type === BlockType.ConditionalLogic);

  // Get hidden blocks
  let hideBlocks = blocks.filter((x) => x.payload.isHidden).map((x) => x.uuid);

  // Nothing to do here
  if (conditionalLogicBlocks.length === 0 && hideBlocks.length === 0) {
    return pageBlocks;
  }

  let modifiedPageBlocks = [...pageBlocks];

  // Apply conditions
  for (const block of conditionalLogicBlocks) {
    const { logicalOperator, conditionals, actions } = block.payload as ConditionalLogicPayload;

    if (areConditionsMet(conditionals, logicalOperator, formData, blocks)) {
      for (const action of actions) {
        // Show blocks action
        if (action.type === ConditionalLogicActionType.ShowBlocks && action.payload?.showBlocks) {
          hideBlocks = hideBlocks.filter((x) => action.payload?.showBlocks?.includes(x) === false);
        }

        // Hide blocks action
        if (
          action.type === ConditionalLogicActionType.HideBlocks &&
          Array.isArray(action.payload?.hideBlocks)
        ) {
          hideBlocks = [...hideBlocks, ...action.payload.hideBlocks];
        }

        // Require answer
        if (
          action.type === ConditionalLogicActionType.RequireAnswer &&
          action.payload?.requireAnswer
        ) {
          modifiedPageBlocks = modifiedPageBlocks.map((block) => {
            if (action.payload?.requireAnswer?.includes(block.groupUuid)) {
              return {
                ...block,
                payload: {
                  ...block.payload,
                  isRequired: true,
                },
              };
            }

            return block;
          });
        }
      }
    }
  }

  // Filter out hidden blocks if any
  if (hideBlocks.length > 0) {
    modifiedPageBlocks = modifiedPageBlocks.filter((x) => hideBlocks.includes(x.uuid) === false);

    // Check what type of blocks we are hiding
    const hidingOptionsForGroup: string[] = [];
    blocks.forEach(({ uuid, type, groupUuid, groupType, payload }) => {
      if (hideBlocks.includes(uuid) === false) {
        return;
      }

      // Ignore the block if it is not eligible for hiding
      if (NOT_ELIGIBLE_BLOCKS_TO_HIDE.includes(type)) {
        return;
      }

      // Check if we are hiding group option blocks
      if (GROUP_OPTIONS_BLOCKS.includes(groupType)) {
        hidingOptionsForGroup.push(groupUuid);
      }

      // Are we hiding a question which already has an answer (but not default answer)?
      if (
        typeof formData[groupUuid] !== 'undefined' &&
        !(payload.hasDefaultAnswer && typeof payload.defaultAnswer !== 'undefined') &&
        !MATRIX_BLOCKS.includes(type)
      ) {
        if (
          type === BlockType.Checkbox ||
          type === BlockType.RankingOption ||
          type === BlockType.MultiSelectOption
        ) {
          if (Array.isArray(formData[groupUuid]) && formData[groupUuid].includes(uuid)) {
            const updatedData = [...formData[groupUuid]];
            updatedData.splice(updatedData.indexOf(uuid), 1);
            onChangeFormData?.({ [groupUuid]: updatedData });
          }
        } else if ([BlockType.DropdownOption, BlockType.MultipleChoiceOption].includes(type)) {
          if (Array.isArray(formData[groupUuid]) && formData[groupUuid].includes(uuid)) {
            const updatedData = [...formData[groupUuid]];
            updatedData.splice(updatedData.indexOf(uuid), 1);
            onChangeFormData?.({ [groupUuid]: updatedData });
          } else if (formData[groupUuid] === uuid) {
            onChangeFormData?.({ [groupUuid]: undefined });
          }
        } else if (type !== BlockType.Payment) {
          onChangeFormData?.({ [groupUuid]: undefined });
        }
      }

      // If we changed a matrix row that is hidden
      if (
        type === BlockType.MatrixRow &&
        typeof formData[groupUuid] !== 'undefined' &&
        formData[groupUuid][uuid]
      ) {
        onChangeFormData?.({
          [groupUuid]: {
            ...formData[groupUuid],
            [uuid]: undefined,
          },
        });
      }

      // If we selected a matrix column that is hidden
      if (type === BlockType.MatrixColumn && typeof formData[groupUuid] !== 'undefined') {
        const rows = Object.keys(formData[groupUuid]);

        if (rows.length > 0) {
          const updatedData = { ...formData[groupUuid] };
          let hasColumn = false;

          rows.forEach((row) => {
            if (updatedData[row] && updatedData[row].includes(uuid)) {
              updatedData[row].splice(updatedData[row].indexOf(uuid), 1);
              hasColumn = true;
            }
          });

          if (hasColumn) {
            onChangeFormData?.({ [groupUuid]: updatedData });
          }
        }
      }

      // If we are hiding a matrix row or column, check if we need to hide the MATRIX block as well if all rows and columns are hidden
      if (type === BlockType.MatrixRow || type === BlockType.MatrixColumn) {
        const areAllRowColumnBlocksHidden = modifiedPageBlocks
          .filter((x) => x.groupUuid === groupUuid && x.type !== BlockType.Matrix)
          .every((x) => hideBlocks.includes(x.uuid));
        if (areAllRowColumnBlocksHidden) {
          modifiedPageBlocks = modifiedPageBlocks.filter((x) => x.groupUuid !== groupUuid);
        }
      }
    });

    // Adjustments to groups
    if (hidingOptionsForGroup.length > 0) {
      const processedGroups = new Map<string, { firstBlockUuid: string; lastBlockUuid: string }>();
      modifiedPageBlocks = modifiedPageBlocks
        .map((block) => {
          // Filter out dropdown or multi select options
          if (
            hidingOptionsForGroup.includes(block.groupUuid) &&
            (block.type === BlockType.Dropdown || block.type === BlockType.MultiSelect)
          ) {
            return {
              ...block,
              payload: {
                ...block.payload,
                options: block.payload.options.filter(
                  ({ value }: { value: string }) => hideBlocks.includes(value) === false,
                ),
              },
            };
            // Multiple choice, checkboxes and ranking: we need to adjust the isFirst and isLast payload values if options are hidden
          } else if (
            hidingOptionsForGroup.includes(block.groupUuid) &&
            [BlockType.MultipleChoiceOption, BlockType.Checkbox, BlockType.RankingOption].includes(
              block.type,
            )
          ) {
            if (!processedGroups.get(block.groupUuid)) {
              processedGroups.set(block.groupUuid, {
                firstBlockUuid: modifiedPageBlocks.find((x) => x.groupUuid === block.groupUuid)!
                  .uuid,
                lastBlockUuid: [...modifiedPageBlocks]
                  .reverse()
                  .find((x) => x.groupUuid === block.groupUuid)!.uuid,
              });
            }

            const { firstBlockUuid, lastBlockUuid } = processedGroups.get(block.groupUuid)!;

            return {
              ...block,
              payload: {
                ...block.payload,
                isFirst: firstBlockUuid === block.uuid,
                isLast: lastBlockUuid === block.uuid,
              },
            };
          }

          return block;
        })
        // Filter out the whole block if no options are remaining
        .filter(
          (x) =>
            !(
              (x.type === BlockType.Dropdown || x.type === BlockType.MultiSelect) &&
              x.payload.options.length === 0
            ),
        );
    }
  }

  return modifiedPageBlocks;
};

export const getNextPage = (
  currentPage: number,
  pageBlocks: RespondBlock[],
  numberOfPages: number,
  blocks: RespondBlock[],
  formData: { [key: string]: any },
): number | null => {
  let nextPage: number | null = currentPage + 1;

  const conditionalLogicBlocks = pageBlocks.filter((x) => x.type === BlockType.ConditionalLogic);

  // No conditional logic, go to the next sequential page
  if (conditionalLogicBlocks.length === 0) {
    return nextPage && nextPage <= numberOfPages ? nextPage : null;
  }

  // Go over conditional logic blocks and search for Jump To Page actions
  for (const block of conditionalLogicBlocks) {
    const { logicalOperator, conditionals, actions } =
      // TODO: fix type casting
      block.payload as unknown as ConditionalLogicPayload;

    if (areConditionsMet(conditionals, logicalOperator, formData, blocks)) {
      for (const action of actions) {
        if (
          action.type === ConditionalLogicActionType.JumpToPage &&
          typeof action.payload?.jumpToPage !== 'undefined'
        ) {
          if (action.payload?.jumpToPage?.toString() === '0') {
            // Jump to page 0 means go to the Default Thank you page (the end)
            nextPage = null;
          } else if (typeof action.payload?.jumpToPage === 'number') {
            // Legacy support for old jumpToPage values
            nextPage = action.payload.jumpToPage;
          } else {
            // Jump to page is a string, so we need to find the page with the matching uuid
            nextPage = getPageNumberByPageBlock(action.payload.jumpToPage, blocks);
          }
        }
      }
    }
  }

  return nextPage && nextPage <= numberOfPages ? nextPage : null;
};

export const getPageFlow = (
  blocks: RespondBlock[],
  pages: RespondBlock[][],
  formData: { [key: string]: any },
): number[] => {
  const progressPages: number[] = [1];
  const numberOfPages = pages.length;

  let nextPage: number | null = 1;

  while (nextPage !== null) {
    const currentPage: number = nextPage;
    const pageBlocks = pages[currentPage - 1];
    nextPage = getNextPage(currentPage, pageBlocks, numberOfPages, blocks, formData);

    if (currentPage === nextPage) {
      nextPage = null;
      continue;
    }

    if (nextPage === null) {
      continue;
    }

    // In case of a loop, we need to break it
    if (progressPages.includes(nextPage)) {
      nextPage = null;
      continue;
    }

    // If we are on a custom thank you page, we need to stop the flow
    const pageBreakBlock = getPageBreakBlock(nextPage, blocks);
    const isCustomThankYouPage = !!pageBreakBlock?.payload?.isThankYouPage;
    if (isCustomThankYouPage) {
      nextPage = null;
      continue;
    }

    progressPages.push(nextPage);
  }

  return progressPages;
};

const isEqualWithGroupOptionSupport = (
  answer: string,
  value: string,
  blocks: (SafeSchemaBlock | RespondBlock)[],
) => {
  let result = answer === value;

  // They aren't equal, but maybe they are group options
  if (result === false) {
    // Possibly this is "Other" option
    if (
      typeof answer === 'string' &&
      typeof value === 'string' &&
      isUuid(answer) === false &&
      isUuid(value) === true
    ) {
      const block = blocks.find((x) => x.uuid === value);
      if (block?.payload?.isOtherOption) {
        result = true;
      }
    }
  }

  return result;
};

import { Container, RadioInput } from './styled';

interface Props {
  value: string;
  text?: string;
  isChecked: boolean;
  isRequired?: boolean;
  isContainerClickable?: boolean;
  minimalDesign?: boolean;
  name?: string;
  ariaLabel?: string;
  ariaDescribedBy?: string;
  onToggle?: (value: string) => void;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
}

const Radio = ({
  value,
  text,
  isChecked,
  isRequired,
  isContainerClickable = false,
  minimalDesign = false,
  name,
  ariaLabel,
  ariaDescribedBy,
  onToggle,
  renderRequiredIndicator,
}: Props) => {
  return (
    <Container
      hasText={!!text}
      clickable={isContainerClickable}
      onClick={() => {
        if (isContainerClickable) {
          onToggle?.(value);
        }
      }}>
      <RadioInput isChecked={isChecked} minimalDesign={minimalDesign}>
        <input
          id={`radio_${value}`}
          type="radio"
          name={name}
          checked={isChecked}
          required={isRequired}
          aria-label={ariaLabel}
          aria-describedby={ariaDescribedBy}
          onChange={() => onToggle && onToggle(value)}
        />
      </RadioInput>
      {text && <label htmlFor={`radio_${value}`}>{text}</label>}
      {renderRequiredIndicator?.()}
    </Container>
  );
};

export default Radio;

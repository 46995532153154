import { convertAmountToPriceValue } from './convert-amount-to-price-value';
import { convertPriceValueToAmount } from './convert-price-value-to-amount';

export const amountToRoundedDecimals = (amount: number, currency: string): number => {
  // This rounds the decimals
  const price = convertAmountToPriceValue(amount, currency);

  // Convert back to amount
  return convertPriceValueToAmount(price, currency);
};

import { Fragment, ReactNode } from 'react';

import { FormStyles } from '@/types/form-design';
import { MetaData } from '@/types/html';
import { Integration } from '@/types/integration';

import LayoutHead from '../_head';
import { Container, Content } from './styled';

interface Props {
  metaData: MetaData;
  integrations: Integration[];
  styles: FormStyles;
  children?: ReactNode;
}

const RespondLayout = ({ metaData, integrations, styles, children }: Props) => {
  return (
    <Fragment>
      <Container className="tally-app" styles={styles}>
        <LayoutHead metaData={metaData} integrations={integrations} />

        <Content styles={styles}>{children}</Content>
      </Container>
    </Fragment>
  );
};

export default RespondLayout;

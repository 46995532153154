export const range = (start: number, end: number, step = 1): number[] => {
  const arr = [];
  for (let i = start; i < end; i += step) {
    arr.push(i);
  }
  return arr;
};

export const move = (arr: any[], fromIndex: number, toIndex: number): any[] => {
  if (fromIndex === toIndex) {
    return arr;
  }

  const item = arr[fromIndex];

  // Remove the item from the array
  arr.splice(fromIndex, 1);

  // Adjust the index if the item was removed from the left
  if (fromIndex < toIndex) {
    toIndex--;
  }

  // Insert the item at the new index
  arr.splice(toIndex, 0, item);

  return arr;
};

export enum Language {
  Afrikaans = 'af',
  Arabic = 'ar',
  Bengali = 'bn',
  Bulgarian = 'bg',
  Catalan = 'ca',
  ChineseSimplified = 'zh-CN',
  ChineseTraditional = 'zh-TW',
  Croatian = 'hr',
  Czech = 'cs',
  Danish = 'da',
  Dutch = 'nl',
  English = 'en',
  Estonian = 'et',
  Euskara = 'eu',
  Farsi = 'fa',
  Filipino = 'fil',
  Finnish = 'fi',
  French = 'fr',
  Georgian = 'ka',
  German = 'de',
  Greek = 'el',
  Hebrew = 'he',
  Hindi = 'hi',
  Hungarian = 'hu',
  Icelandic = 'is',
  Indonesian = 'id',
  Italian = 'it',
  Japanese = 'ja',
  Korean = 'ko',
  Latvian = 'lv',
  Lithuanian = 'lt',
  Norwegian = 'no',
  Malay = 'ms',
  Polish = 'pl',
  Portuguese = 'pt',
  PortugueseBrazil = 'pt-BR',
  Romanian = 'ro',
  Russian = 'ru',
  Serbian = 'sr',
  Sinhala = 'si',
  Slovak = 'sk',
  Slovenian = 'sl',
  Spanish = 'es',
  SpanishMexico = 'es-MX',
  Swedish = 'sv',
  Tamil = 'ta',
  Thai = 'th',
  Turkish = 'tr',
  Ukrainian = 'uk',
  Vietnamese = 'vi',
  Yoruba = 'yo',
}

export interface Font {
  provider: string;
  family: string;
  baseSize?: string;
}

export interface ThemeFont extends Font {
  weight: {
    medium?: string;
    semiBold?: string;
    extraBold?: string;
    black?: string;
  };
}

export const getFont = (customFont: Partial<ThemeFont> = {}): ThemeFont => {
  return {
    provider: customFont?.provider || 'Google',
    family: customFont?.family || 'var(--font-family)',
    baseSize: '16px',
    weight: {
      medium: '500',
      semiBold: '600',
      extraBold: '800',
      black: '900',
    },
  };
};

import { Block } from './block';

export interface FormBuilderBlock<B extends Block = Block> extends Block<B['type'], B['payload']> {
  ref: ((el: HTMLDivElement) => void) | undefined;
  value: string;
}

export const isFormBuilderBlock = (block: Block): block is FormBuilderBlock => {
  return Object.keys(block).includes('value');
};

import { useTranslation } from 'react-i18next';

import { Container } from './styled';

interface Props {
  errorType?: string;
  errorText?: string;
  exception?: any;
}

const Error = ({ errorType, errorText, exception }: Props) => {
  const { t } = useTranslation();

  let text = '';

  if (exception) {
    const { response } = exception;

    if (response?.data?.errorType) {
      errorType = response.data.errorType;
    } else if ((exception && exception.message === 'Network Error') || response?.status === 0) {
      errorType = 'NETWORK_ERROR';
    } else if (!response && exception && exception.message) {
      text = exception.message;
    } else {
      errorType = 'GENERAL';
    }
  }

  if (errorType) {
    text = t(`error.${errorType}`);

    // No such error exists
    if (text === `error.${errorType}`) {
      text = t('error.GENERAL');
    }
  }

  if (errorText) {
    text = errorText;
  }

  return <Container role="alert">{text}</Container>;
};

export default Error;

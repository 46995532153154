import {
  Block,
  BLOCK_TYPE_TO_GROUP_TYPE,
  BlockType,
  ELIGIBLE_INPUT_PLACEHOLDER_BLOCKS,
  EMPTY_QUESTION_TITLES,
  FIELD_BLOCKS,
  GROUP_OPTIONS_BLOCKS,
  GROUP_OPTIONS_BLOCKS_WITHOUT_MATRIX,
  INPUT_FIELD_BLOCKS,
  isFormBuilderBlock,
  NO_TITLE_FIELD_BLOCKS,
  QUESTION_BLOCKS,
  QUESTION_TITLE_BLOCKS,
  RespondBlock,
  stripHtml,
} from '@tallyforms/lib';
import findLastIndex from 'lodash/findLastIndex';

import { Question } from '@/types/form-builder';
import {
  FormLayoutBlock,
  FormLayoutColumn,
  FormLayoutColumnList,
  FormLayoutObject,
  FormLayoutObjectType,
} from '@/types/form-design';
import { shouldRatiosBeEqual } from '@/utils/form-builder/column-layout';

export const transformBlocksToFormLayoutObjects = (
  blocks: (Block | RespondBlock)[],
): FormLayoutObject[] => {
  const objects: FormLayoutObject[] = [];
  const processedMatrixGroupUuids: string[] = [];
  const processedColumnListUuids: string[] = [];
  const processedColumnUuids: string[] = [];

  blocks.forEach((block, index) => {
    // Matrix
    if (
      block.groupType === BlockType.Matrix &&
      blocks.filter((x) => x.groupUuid === block.groupUuid && x.type === BlockType.MatrixRow)
        .length > 0
    ) {
      if (processedMatrixGroupUuids.includes(block.groupUuid)) {
        return;
      }

      processedMatrixGroupUuids.push(block.groupUuid);

      let matrixBlockIndex = index + 1;

      const columns: FormLayoutBlock[] = blocks
        .filter((x) => x.groupUuid === block.groupUuid && x.type === BlockType.MatrixColumn)
        .map((x) => ({
          uuid: x.uuid,
          type: FormLayoutObjectType.Block,
          block: x,
          index: matrixBlockIndex++,
        }));

      const rows: FormLayoutBlock[] = blocks
        .filter((x) => x.groupUuid === block.groupUuid && x.type === BlockType.MatrixRow)
        .map((x) => ({
          uuid: x.uuid,
          type: FormLayoutObjectType.Block,
          block: x,
          index: matrixBlockIndex++,
        }));

      objects.push({
        uuid: block.groupUuid,
        type: FormLayoutObjectType.Matrix,
        table: {
          uuid: block.uuid,
          type: FormLayoutObjectType.Block,
          // The table cell i.e. empty cell in the most left top corner should be empty.
          // However, if the block with type=BlockType.Matrix is hidden, then the table block is the first column block instead.
          // So need to set the html to empty string to cover that case.
          block: { ...block, payload: { ...block.payload, html: '' } },
          index,
        },
        columns,
        rows,
      });
      return;
    }

    // Block not within a column
    if (!block.payload.columnListUuid) {
      objects.push({
        uuid: block.uuid,
        type: FormLayoutObjectType.Block,
        block,
        index,
      });
      return;
    }

    if (processedColumnListUuids.includes(block.payload.columnListUuid)) {
      return;
    }

    processedColumnListUuids.push(block.payload.columnListUuid);

    // Column list
    const columnListBlocks = blocks.filter(
      (x) => x.payload.columnListUuid === block.payload.columnListUuid,
    );

    const columnList: FormLayoutColumnList = {
      uuid: block.payload.columnListUuid,
      type: FormLayoutObjectType.ColumnList,
      columns: [],
    };

    columnListBlocks.forEach((columnListBlock, indexWithinColumnList) => {
      if (processedColumnUuids.includes(columnListBlock.payload.columnUuid)) {
        return;
      }

      processedColumnUuids.push(columnListBlock.payload.columnUuid);

      // Column
      const column: FormLayoutColumn = {
        uuid: columnListBlock.payload.columnUuid,
        type: FormLayoutObjectType.Column,
        ratio: columnListBlock.payload.columnRatio,
        blocks: [],
      };

      column.blocks = columnListBlocks
        .filter((x) => x.payload.columnUuid === column.uuid)
        .map((x, indexWithinColumn) => ({
          uuid: x.uuid,
          type: FormLayoutObjectType.Block,
          block: x,
          index: index + indexWithinColumnList + indexWithinColumn,
        }));

      column.firstBlock = column.blocks[0];
      column.lastBlock = column.blocks[column.blocks.length - 1];

      columnList.columns.push(column);
    });

    // Check if each column's ratio is almost the same
    // If so, we can use flex instead of absolute width %
    if (shouldRatiosBeEqual(columnList.columns.map((x) => x.ratio))) {
      columnList.columns = columnList.columns.map((x) => ({ ...x, flex: 1 }));
    }

    columnList.firstColumn = columnList.columns[0];
    columnList.lastColumn = columnList.columns[columnList.columns.length - 1];

    objects.push(columnList);
  });

  return objects;
};

export const transformBlockToQuestion = (
  block: Block | RespondBlock,
  blocks: (Block | RespondBlock)[],
  groupStartEndIndexesMap?: Map<string, { start: number; end: number }>,
): Question | null => {
  if (QUESTION_BLOCKS.includes(block.type) === false) {
    return null;
  }

  const { uuid, type, groupUuid, groupType, payload } = block;
  const value = isFormBuilderBlock(block) ? block.value : undefined;

  const questionType = BLOCK_TYPE_TO_GROUP_TYPE[type] ?? type;
  let title: string | null = null;
  let titleBlockUuid: string | null = null;
  let placeholder: string | null = null;
  let placeholderBlockUuid: string | null = null;
  let isUntitled = true;
  let isFolded = false;
  let startBlockIndex =
    groupStartEndIndexesMap?.get(groupUuid)?.start ?? blocks.findIndex((x) => x.uuid === uuid);
  let startBlockUuid = blocks[startBlockIndex]?.uuid;

  if (NO_TITLE_FIELD_BLOCKS.includes(type) === false) {
    // Check for placeholder to use as title
    if (ELIGIBLE_INPUT_PLACEHOLDER_BLOCKS.includes(type) && (value || payload.placeholder)) {
      placeholder = stripHtml(value ?? payload.placeholder);
      placeholderBlockUuid = uuid;
    } else if (type === BlockType.Signature && payload.label) {
      placeholder = stripHtml(payload.label);
      placeholderBlockUuid = uuid;
    } else if (type === BlockType.DropdownOption && payload.placeholder) {
      placeholder = stripHtml(payload.placeholder);
      placeholderBlockUuid = uuid;
    }

    // Check if we can find a title in the blocks above
    if (title === null) {
      const result = findTitleAbove(startBlockIndex, groupUuid, blocks);

      title = result.title;
      titleBlockUuid = result.titleBlockUuid;
      isFolded = result.isFolded;

      if (titleBlockUuid !== null) {
        startBlockIndex = result.titleIndex;
        startBlockUuid = titleBlockUuid;
      }
    }

    // No title, but we have placeholder, use that as a title
    if (title === null && placeholder !== null) {
      title = placeholder;
      titleBlockUuid = placeholderBlockUuid;
    }

    // If we set a custom input name, use it as a title
    if (payload.name) {
      title = payload.name;
    }
  }

  if (title !== null) {
    isUntitled = false;
  }

  let isRequiredBlockUuid = uuid;

  // The matrix question needs to show the required indicator on the last column
  if (groupType === BlockType.Matrix) {
    const lastColumnBlock = blocks.find(
      (x) =>
        x.groupUuid === groupUuid && x.type === BlockType.MatrixColumn && x.payload.isLast === true,
    );
    if (lastColumnBlock) {
      isRequiredBlockUuid = lastColumnBlock.uuid;
    }
  }

  // Find the end block, check if the question can be folded and how many blocks it has
  const { endBlockUuid, canBeFolded, numberOfBlocks } = findEndBlock(
    startBlockIndex,
    block,
    blocks,
    groupStartEndIndexesMap,
  );

  return {
    type: questionType,
    blockGroupUuid: groupUuid,
    title: title || EMPTY_QUESTION_TITLES[questionType],
    titleBlockUuid,
    isRequired: payload.isRequired,
    isRequiredBlockUuid,
    isRequiredGroupBlockUuid: groupUuid,
    isUntitled,
    isFolded,
    canBeFolded,
    startBlockUuid,
    endBlockUuid,
    numberOfBlocks,
  };
};

export const transformBlocksToQuestionPerBlockGroupMap = (
  blocks: (Block | RespondBlock)[],
): Map<string, Question> => {
  const processedGroups: string[] = [];
  const questionsMap = new Map<string, Question>();

  // We loop over the blocks once to get the start and end indexes of each group
  // We can use this map to get the start and end indexes of each group in O(1) time, rather than O(n) each time
  const groupStartEndIndexesMap = transformBlocksToGroupStartEndIndexesMap(blocks);

  // Go over question blocks
  blocks.forEach((block) => {
    if (QUESTION_BLOCKS.includes(block.type) === false) {
      return;
    }

    if (processedGroups.includes(block.groupUuid)) {
      return;
    }

    questionsMap.set(
      block.groupUuid,
      transformBlockToQuestion(block, blocks, groupStartEndIndexesMap)!,
    );

    processedGroups.push(block.groupUuid);
  });

  return questionsMap;
};

export const transformBlocksToQuestionsMap = (
  blocks: (Block | RespondBlock)[],
): Map<string, Question> => {
  const processedGroupsQuestionsMap = new Map<string, Question>();
  const questionsMap = new Map<string, Question>();

  // We loop over the blocks once to get the start and end indexes of each group
  // We can use this map to get the start and end indexes of each group in O(1) time, rather than O(n) each time
  const groupStartEndIndexesMap = transformBlocksToGroupStartEndIndexesMap(blocks);

  // Go over question blocks
  blocks.forEach((block) => {
    if (QUESTION_BLOCKS.includes(block.type) === false) {
      return;
    }

    // Was the group already processed?
    const processedGroupQuestion = processedGroupsQuestionsMap.get(block.groupUuid);
    if (processedGroupQuestion) {
      questionsMap.set(block.uuid, processedGroupQuestion);
      return;
    }

    // New question
    const question = transformBlockToQuestion(block, blocks, groupStartEndIndexesMap)!;
    if (question.titleBlockUuid) {
      questionsMap.set(question.titleBlockUuid, question);
    }

    questionsMap.set(block.uuid, question);
    processedGroupsQuestionsMap.set(block.groupUuid, question);
  });

  return questionsMap;
};

const findTitleAbove = (
  index: number,
  groupUuid: string,
  blocks: (Block | RespondBlock)[],
): {
  title: string | null;
  titleBlockUuid: string | null;
  titleIndex: number;
  isFolded: boolean;
} => {
  index--;

  // Block doesn't exit
  if (!blocks[index]) {
    return {
      title: null,
      titleBlockUuid: null,
      isFolded: false,
      titleIndex: -1,
    };
  }

  const block = blocks[index];
  const value = isFormBuilderBlock(block) ? block.value : undefined;

  // Input block or page break from another group reached => no title found
  if (
    block.groupUuid !== groupUuid &&
    (FIELD_BLOCKS.includes(block.type) ||
      GROUP_OPTIONS_BLOCKS.includes(block.groupType) ||
      block.type === BlockType.PageBreak ||
      block.type === BlockType.Divider)
  ) {
    return {
      title: null,
      titleBlockUuid: null,
      isFolded: false,
      titleIndex: -1,
    };
  }

  // Title found
  // Check both value and payload.html props as this is used in both edit and respond modes
  if (QUESTION_TITLE_BLOCKS.includes(block.type)) {
    return {
      title: stripHtml(value ?? block.payload.html ?? ''),
      titleBlockUuid: block.uuid,
      isFolded: block.payload.isFolded ?? false,
      titleIndex: index,
    };
  }

  // Go to the block above
  return findTitleAbove(index, groupUuid, blocks);
};

export const findEndBlock = (
  startBlockIndex: number,
  groupBlock: Block | RespondBlock,
  blocks: (Block | RespondBlock)[],
  groupStartEndIndexesMap?: Map<string, { start: number; end: number }>,
): {
  endBlockUuid: string;
  canBeFolded: boolean;
  numberOfBlocks: number;
} => {
  const endBlockIndex =
    groupStartEndIndexesMap?.get(groupBlock.groupUuid)?.end ??
    findLastIndex(blocks, (x) => x.groupUuid === groupBlock.groupUuid);
  const endBlockUuid = blocks[endBlockIndex]?.uuid ?? groupBlock.uuid;
  const numberOfBlocks = endBlockIndex - startBlockIndex + 1;

  let canBeFolded = GROUP_OPTIONS_BLOCKS_WITHOUT_MATRIX.includes(groupBlock.groupType);
  if (!canBeFolded) {
    return { endBlockUuid, canBeFolded, numberOfBlocks };
  }

  // We need to go down till we reach another question group or the end of the blocks
  for (let i = startBlockIndex + 1; i <= endBlockIndex; i++) {
    const block = blocks[i];

    // Input block from another group reached or a page break => can't be folded
    if (
      block &&
      block.groupUuid !== groupBlock.groupUuid &&
      (FIELD_BLOCKS.includes(block.type) || BlockType.PageBreak === block.type)
    ) {
      canBeFolded = false;
      break;
    }
  }

  return { endBlockUuid, canBeFolded, numberOfBlocks };
};

export const findQuestionBlockGroupUuidBelowTitle = (
  index: number,
  blocks: (Block | RespondBlock)[],
): string | undefined => {
  index++;

  // Block doesn't exit
  if (!blocks[index]) {
    return undefined;
  }

  const block = blocks[index];

  // Another question title or page break reached => no question block group found
  if (
    QUESTION_TITLE_BLOCKS.includes(block.type) ||
    block.type === BlockType.PageBreak ||
    block.type === BlockType.Divider
  ) {
    return undefined;
  }

  // Question block group found
  if (INPUT_FIELD_BLOCKS.includes(block.type)) {
    return block.groupUuid;
  }

  // Go to the block below
  return findQuestionBlockGroupUuidBelowTitle(index, blocks);
};

export const transformBlocksToGroupStartEndIndexesMap = (
  blocks: (Block | RespondBlock)[],
): Map<string, { start: number; end: number }> => {
  const groupStartEndIndexesMap = new Map<string, { start: number; end: number }>();

  blocks.forEach(({ groupUuid }, index) => {
    const group = groupStartEndIndexesMap.get(groupUuid);
    if (group) {
      // Existing group, update the end index
      groupStartEndIndexesMap.set(groupUuid, {
        start: group.start,
        end: index,
      });
    } else {
      // New group
      groupStartEndIndexesMap.set(groupUuid, { start: index, end: index });
    }
  });

  return groupStartEndIndexesMap;
};

import styled from 'styled-components';

export const Container = styled.div`
  padding: 17px 0;
`;

export const Divider = styled.hr`
  height: 1px;
  background-color: ${({ theme }) => theme.color.border};
  border: 0;
`;

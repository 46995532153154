import { Block, BlockType, ValidationRuleConstraint } from '@tallyforms/lib';
import { breakpoint } from '@tallyforms/ui';
import throttle from 'lodash/throttle';
import { ReactNode, useEffect, useRef, useState } from 'react';

import ValidationError from '@/components/form/validation-error';
import MoveDropzone from '@/components/form-builder/blocks/move-dropzone';
import {
  MatrixTable as Table,
  MatrixTableWrapper,
  TableStickyHeader,
  TableWrapper,
} from '@/components/table/styled';
import { ActionType } from '@/types/form-builder';
import { IS_INTERSECTION_OBSERVER_SUPPORTED, isMac } from '@/utils/device';
import { getWindowCenter } from '@/utils/window';

const LG_BREAKPOINT = parseInt(breakpoint.lg, 10);
const MD_BREAKPOINT = parseInt(breakpoint.md, 10);
const SM_BREAKPOINT = parseInt(breakpoint.sm, 10);
const XS_BREAKPOINT = parseInt(breakpoint.xs, 10);

interface Props {
  lastBlock?: Block;
  headers: ReactNode[];
  body: ReactNode[];
  respond?: boolean;
  error?: ValidationRuleConstraint;
  errorId?: string;
}

const MatrixTable = ({ lastBlock, headers, body, error, errorId, respond }: Props) => {
  const [windowCenter, setWindowCenter] = useState(0);
  const tableHeaderRef = useRef<HTMLTableSectionElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const [isWindows, setIsWindows] = useState(false);

  // Calculate header top position to stay sticky
  const calculateHeaderTopPos = (): void => {
    if (!IS_INTERSECTION_OBSERVER_SUPPORTED) {
      return;
    }

    if (!tableHeaderRef.current) {
      return;
    }

    const header = tableHeaderRef.current;
    const headerTopPos = header?.getBoundingClientRect().top || 0;
    const containerTopPos = !respond
      ? document.querySelector('.page-navigation')?.getBoundingClientRect()?.height || 0
      : 0;

    if (headerTopPos === 0) {
      return;
    }

    const table = tableRef.current;
    const inScreen = table?.classList.contains('on-screen');
    if (!inScreen) {
      return;
    }

    const tableTopPos = table?.getBoundingClientRect().top || 0;

    if (tableTopPos >= containerTopPos) {
      header.style.removeProperty('top');
      header.style.removeProperty('position');
      header.style.removeProperty('z-index');
      header.classList.remove('isSticky');
      return;
    }

    const currentTopPos = header.style.top;
    const oldPos = currentTopPos ? parseInt(currentTopPos, 10) : 0;

    let newPos = 0;

    if (headerTopPos > 0) {
      newPos = oldPos - Math.abs(headerTopPos);
    }

    if (headerTopPos < 0) {
      newPos = Math.abs(headerTopPos) + oldPos;
    }

    if (oldPos === 0 && containerTopPos === 0) {
      newPos = Math.abs(headerTopPos);
    }

    header.style.top = `${newPos + containerTopPos}px`;
    header.style.position = 'sticky';
    header.style.zIndex = '3';
    header.classList.add('isSticky');

    const maxTop = table
      ? table.getBoundingClientRect().height - header.getBoundingClientRect().height
      : 0;

    if (newPos + containerTopPos > maxTop) {
      header.style.top = `${maxTop}px`;
    }
  };

  const onWindowWidthChange = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth < LG_BREAKPOINT && windowWidth > MD_BREAKPOINT) {
      setWindowCenter(getWindowCenter(700) + 100);
    } else if (windowWidth < MD_BREAKPOINT && windowWidth > SM_BREAKPOINT) {
      setWindowCenter(getWindowCenter(500) + 100);
    } else if (windowWidth < SM_BREAKPOINT && windowWidth > XS_BREAKPOINT) {
      setWindowCenter(getWindowCenter(respond ? 500 : 300) + 25);
    } else if (windowWidth < XS_BREAKPOINT) {
      setWindowCenter(getWindowCenter(respond ? 500 : 240) + 25);
    } else {
      setWindowCenter(getWindowCenter(900) + 100);
    }
  };

  useEffect(() => {
    let observer: IntersectionObserver;
    let headerObserver: IntersectionObserver;
    if (IS_INTERSECTION_OBSERVER_SUPPORTED && tableRef.current && tableHeaderRef.current) {
      observer = new IntersectionObserver(
        ([e]) => {
          e.target.classList.toggle('on-screen', e.intersectionRatio > 0.01);
        },
        { threshold: [0.0, 0.01] },
      );

      observer.observe(tableRef.current);

      // Detect when the left headers are sticky too
      const leftHeaderElements = tableRef.current
        .getElementsByTagName('tbody')
        ?.item(0)
        ?.getElementsByTagName('th');

      const tableFirstHeader = tableHeaderRef.current.firstElementChild?.firstElementChild;

      if (leftHeaderElements && leftHeaderElements.length > 0 && tableFirstHeader) {
        headerObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((e) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1));
          },
          { threshold: [1] },
        );

        headerObserver.observe(tableFirstHeader);

        for (let i = 0; i < leftHeaderElements.length; i++) {
          const element = leftHeaderElements.item(i);
          if (element) {
            headerObserver.observe(element);
          }
        }
      }
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
      if (headerObserver) {
        headerObserver.disconnect();
      }
    };
  }, [tableRef.current, tableHeaderRef.current]);

  useEffect(() => {
    const throttledWindowWidthChange = throttle(onWindowWidthChange, 10);
    window.addEventListener('resize', throttledWindowWidthChange);
    onWindowWidthChange(); // call it once to set the initial value

    setIsWindows(isMac() === false);

    return () => {
      window.removeEventListener('resize', throttledWindowWidthChange);
    };
  }, []);

  useEffect(() => {
    const throttledCalculateHeaderTopPos = throttle(calculateHeaderTopPos, 10);
    window.addEventListener('scroll', throttledCalculateHeaderTopPos);
    // We need to attach the event listener to the preview container as well to capture events
    const preview = document.getElementById('preview');
    if (preview) {
      preview.addEventListener('scroll', throttledCalculateHeaderTopPos);
    }

    return () => {
      window.removeEventListener('scroll', throttledCalculateHeaderTopPos);

      if (preview) {
        preview.removeEventListener('scroll', throttledCalculateHeaderTopPos);
      }
    };
  }, [tableHeaderRef.current, tableRef.current, respond]);

  return (
    <MatrixTableWrapper center={windowCenter} respond={respond} className="tally-matrix">
      <TableWrapper
        isMatrix={true}
        center={windowCenter}
        ref={scrollWrapperRef}
        styleScroll={isWindows}>
        <Table ref={tableRef} className="block-container selectable">
          <TableStickyHeader ref={tableHeaderRef} isMatrix={true}>
            <tr>{headers}</tr>
          </TableStickyHeader>

          <tbody>{body}</tbody>
        </Table>

        {error && <ValidationError id={errorId} error={error} />}

        {!respond && lastBlock && (
          <MoveDropzone
            style="block"
            move="after"
            blockUuid={lastBlock.uuid}
            action={ActionType.DetachFromColumn}
            isInColumn={false}
            blockType={BlockType.Matrix}
            groupUuid={lastBlock.groupUuid}
          />
        )}
      </TableWrapper>
    </MatrixTableWrapper>
  );
};

export default MatrixTable;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.color.error};
`;

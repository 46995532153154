import tinycolor from 'tinycolor2';

import { Theme } from '../theme';
import { defaultColors } from './default';
import { Color } from './types';

export const getCustomThemeColors = (theme = Theme.Light, customColors?: Color) => {
  let background = defaultColors.white;
  let text = '#37352f';
  let accent = '#007aff';
  let buttonBackground = defaultColors.black;
  let buttonText = defaultColors.white;

  switch (theme) {
    case Theme.Red:
    case Theme.Orange:
    case Theme.Yellow:
    case Theme.Green:
    case Theme.Blue:
    case Theme.Purple:
    case Theme.Pink:
      // @ts-ignore
      background = defaultColors[`${theme.toLowerCase()}Light2`];
      // @ts-ignore
      text = defaultColors[`${theme.toLowerCase()}Dark2`];
      // @ts-ignore
      accent = defaultColors[theme.toLowerCase()];
      // @ts-ignore
      buttonBackground = defaultColors[theme.toLowerCase()];
      buttonText = defaultColors.white;

      if (theme === Theme.Yellow) {
        buttonText = defaultColors.yellowDark2;
      }
      break;

    case Theme.Dark:
      background = '#191919';
      text = '#d8d8d8';
      accent = '#7957ff';
      buttonBackground = '#7957ff';
      buttonText = defaultColors.white;
      break;
  }

  // Use custom colors
  background = customColors?.background ?? background;
  text = customColors?.text ?? text;
  accent = customColors?.accent ?? accent;
  buttonBackground = customColors?.buttonBackground ?? buttonBackground;
  buttonText = customColors?.buttonText ?? buttonText;

  const dynamicColors =
    theme === Theme.Custom
      ? generateColors(background, text, accent)
      : getGeneratedColorsForTheme(theme);

  return {
    ...defaultColors,

    background,
    text,
    accent,
    buttonBackground,
    buttonText,

    ...dynamicColors,
  };
};

export const getGeneratedColorsForTheme = (theme: Theme) => {
  switch (theme) {
    case Theme.Light:
      return {
        boxShadow: 'rgba(61, 59, 53, 0.16)',
        boxShadowHalf: 'rgba(61, 59, 53, 0.08)',
        textGrayscale1: '#45433e',
        textGrayscale2: '#777672',
        textGrayscale3: '#898884',
        textGrayscale4: '#bbbab8',
        textGrayscale5: '#dfdfde',
        textGrayscale6: '#f3f3f3',
        textGrayscale7: '#f5f5f5',
        error: '#B20110',
        border: 'rgba(55, 53, 47, 0.09)',
        actionBackgroundHover: 'rgba(55, 53, 47, 0.09)',
        inputPlaceholder: '#bbbab8',
        inputBackground: 'rgba(255, 255, 255, 1)',
        inputBoxShadow:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px',
        inputBoxShadowHover:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 3px 9px 0px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px',
        inputBoxShadowFocus:
          'rgba(58, 152, 255, 0.36) 0px 0px 0px 4px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActive: '#007aff 0px 0px 0px 2px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActiveFocus:
          'rgba(58, 152, 255, 0.36) 0px 0px 0px 4px, #007aff 0px 0px 0px 2px',
        accentHover: '#0066d6',
        menuBackground: 'rgba(255, 255, 255, 0.5)',
        menuBoxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
        menuOverlay: 'rgba(15, 15, 15, 0.6)',
        isBackgroundDark: false,
      };

    case Theme.Red:
      return {
        boxShadow: 'rgba(132, 40, 40, 0.16)',
        boxShadowHalf: 'rgba(132, 40, 40, 0.08)',
        textGrayscale1: '#893030',
        textGrayscale2: '#a96363',
        textGrayscale3: '#b47575',
        textGrayscale4: '#d4a7a7',
        textGrayscale5: '#ebcccc',
        textGrayscale6: '#f7e0e0',
        textGrayscale7: '#f9e2e2',
        error: '#B20110',
        border: 'rgba(128, 34, 34, 0.09)',
        actionBackgroundHover: 'rgba(128, 34, 34, 0.09)',
        inputPlaceholder: '#d4a7a7',
        inputBackground: 'rgba(255, 255, 255, 0.5)',
        inputBoxShadow:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(132, 40, 40, 0.16) 0px 0px 0px 1px, rgba(132, 40, 40, 0.08) 0px 2px 5px 0px',
        inputBoxShadowHover:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(132, 40, 40, 0.16) 0px 0px 0px 1px, rgba(132, 40, 40, 0.08) 0px 3px 9px 0px, rgba(132, 40, 40, 0.08) 0px 2px 5px 0px',
        inputBoxShadowFocus:
          'rgba(255, 111, 111, 0.36) 0px 0px 0px 4px, rgba(132, 40, 40, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActive: '#FF4444 0px 0px 0px 2px, rgba(132, 40, 40, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActiveFocus:
          'rgba(255, 111, 111, 0.36) 0px 0px 0px 4px, #FF4444 0px 0px 0px 2px',
        accentHover: '#ff1b1b',
        menuBackground: 'rgba(255, 255, 255, 0.5)',
        menuBoxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
        menuOverlay: 'rgba(15, 15, 15, 0.6)',
        isBackgroundDark: false,
      };

    case Theme.Orange:
      return {
        boxShadow: 'rgba(132, 63, 7, 0.16)',
        boxShadowHalf: 'rgba(132, 63, 7, 0.08)',
        textGrayscale1: '#894610',
        textGrayscale2: '#a9744a',
        textGrayscale3: '#b4845e',
        textGrayscale4: '#d4b298',
        textGrayscale5: '#ebd4c1',
        textGrayscale6: '#f7e6d8',
        textGrayscale7: '#f9e8db',
        error: '#B20110',
        border: 'rgba(128, 57, 0, 0.09)',
        actionBackgroundHover: 'rgba(128, 57, 0, 0.09)',
        inputPlaceholder: '#d4b298',
        inputBackground: 'rgba(255, 255, 255, 0.5)',
        inputBoxShadow:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(132, 63, 7, 0.16) 0px 0px 0px 1px, rgba(132, 63, 7, 0.08) 0px 2px 5px 0px',
        inputBoxShadowHover:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(132, 63, 7, 0.16) 0px 0px 0px 1px, rgba(132, 63, 7, 0.08) 0px 3px 9px 0px, rgba(132, 63, 7, 0.08) 0px 2px 5px 0px',
        inputBoxShadowFocus:
          'rgba(255, 146, 58, 0.36) 0px 0px 0px 4px, rgba(132, 63, 7, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActive: '#FF7100 0px 0px 0px 2px, rgba(132, 63, 7, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActiveFocus:
          'rgba(255, 146, 58, 0.36) 0px 0px 0px 4px, #FF7100 0px 0px 0px 2px',
        accentHover: '#d65f00',
        menuBackground: 'rgba(255, 255, 255, 0.5)',
        menuBoxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
        menuOverlay: 'rgba(15, 15, 15, 0.6)',
        isBackgroundDark: false,
      };

    case Theme.Yellow:
      return {
        boxShadow: 'rgba(132, 117, 42, 0.16)',
        boxShadowHalf: 'rgba(132, 117, 42, 0.08)',
        textGrayscale1: '#897b32',
        textGrayscale2: '#a99d64',
        textGrayscale3: '#b4aa76',
        textGrayscale4: '#d4cda9',
        textGrayscale5: '#ebe6cd',
        textGrayscale6: '#f7f4e1',
        textGrayscale7: '#f9f5e3',
        error: '#B20110',
        border: 'rgba(128, 113, 36, 0.09)',
        actionBackgroundHover: 'rgba(128, 113, 36, 0.09)',
        inputPlaceholder: '#d4cda9',
        inputBackground: 'rgba(255, 255, 255, 0.5)',
        inputBoxShadow:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(132, 117, 42, 0.16) 0px 0px 0px 1px, rgba(132, 117, 42, 0.08) 0px 2px 5px 0px',
        inputBoxShadowHover:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(132, 117, 42, 0.16) 0px 0px 0px 1px, rgba(132, 117, 42, 0.08) 0px 3px 9px 0px, rgba(132, 117, 42, 0.08) 0px 2px 5px 0px',
        inputBoxShadowFocus:
          'rgba(255, 232, 114, 0.36) 0px 0px 0px 4px, rgba(132, 117, 42, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActive: '#ffe148 0px 0px 0px 2px, rgba(132, 117, 42, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActiveFocus:
          'rgba(255, 232, 114, 0.36) 0px 0px 0px 4px, #ffe148 0px 0px 0px 2px',
        accentHover: '#ffda1f',
        menuBackground: 'rgba(255, 255, 255, 0.5)',
        menuBoxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
        menuOverlay: 'rgba(15, 15, 15, 0.6)',
        isBackgroundDark: false,
      };

    case Theme.Green:
      return {
        boxShadow: 'rgba(23, 105, 32, 0.16)',
        boxShadowHalf: 'rgba(23, 105, 32, 0.08)',
        textGrayscale1: '#1f6f29',
        textGrayscale2: '#55955d',
        textGrayscale3: '#69a270',
        textGrayscale4: '#9fc7a4',
        textGrayscale5: '#c6e2ca',
        textGrayscale6: '#dcf1de',
        textGrayscale7: '#def3e1',
        error: '#B20110',
        border: 'rgba(16, 101, 26, 0.09)',
        actionBackgroundHover: 'rgba(16, 101, 26, 0.09)',
        inputPlaceholder: '#9fc7a4',
        inputBackground: 'rgba(255, 255, 255, 0.5)',
        inputBoxShadow:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(23, 105, 32, 0.16) 0px 0px 0px 1px, rgba(23, 105, 32, 0.08) 0px 2px 5px 0px',
        inputBoxShadowHover:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(23, 105, 32, 0.16) 0px 0px 0px 1px, rgba(23, 105, 32, 0.08) 0px 3px 9px 0px, rgba(23, 105, 32, 0.08) 0px 2px 5px 0px',
        inputBoxShadowFocus:
          'rgba(83, 213, 98, 0.36) 0px 0px 0px 4px, rgba(23, 105, 32, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActive: '#20c933 0px 0px 0px 2px, rgba(23, 105, 32, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActiveFocus:
          'rgba(83, 213, 98, 0.36) 0px 0px 0px 4px, #20c933 0px 0px 0px 2px',
        accentHover: '#1aa62a',
        menuBackground: 'rgba(255, 255, 255, 0.5)',
        menuBoxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
        menuOverlay: 'rgba(15, 15, 15, 0.6)',
        isBackgroundDark: false,
      };

    case Theme.Blue:
      return {
        boxShadow: 'rgba(7, 66, 132, 0.16)',
        boxShadowHalf: 'rgba(7, 66, 132, 0.08)',
        textGrayscale1: '#104a89',
        textGrayscale2: '#4a77a9',
        textGrayscale3: '#5e87b4',
        textGrayscale4: '#98b4d4',
        textGrayscale5: '#c1d5eb',
        textGrayscale6: '#d8e7f7',
        textGrayscale7: '#dbe9f9',
        error: '#B20110',
        border: 'rgba(0, 61, 128, 0.09)',
        actionBackgroundHover: 'rgba(0, 61, 128, 0.09)',
        inputPlaceholder: '#98b4d4',
        inputBackground: 'rgba(255, 255, 255, 0.5)',
        inputBoxShadow:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(7, 66, 132, 0.16) 0px 0px 0px 1px, rgba(7, 66, 132, 0.08) 0px 2px 5px 0px',
        inputBoxShadowHover:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(7, 66, 132, 0.16) 0px 0px 0px 1px, rgba(7, 66, 132, 0.08) 0px 3px 9px 0px, rgba(7, 66, 132, 0.08) 0px 2px 5px 0px',
        inputBoxShadowFocus:
          'rgba(58, 152, 255, 0.36) 0px 0px 0px 4px, rgba(7, 66, 132, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActive: '#007aff 0px 0px 0px 2px, rgba(7, 66, 132, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActiveFocus:
          'rgba(58, 152, 255, 0.36) 0px 0px 0px 4px, #007aff 0px 0px 0px 2px',
        accentHover: '#0066d6',
        menuBackground: 'rgba(255, 255, 255, 0.5)',
        menuBoxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
        menuOverlay: 'rgba(15, 15, 15, 0.6)',
        isBackgroundDark: false,
      };

    case Theme.Purple:
      return {
        boxShadow: 'rgba(75, 41, 132, 0.16)',
        boxShadowHalf: 'rgba(75, 41, 132, 0.08)',
        textGrayscale1: '#523189',
        textGrayscale2: '#7d64a9',
        textGrayscale3: '#8d76b4',
        textGrayscale4: '#b8a8d4',
        textGrayscale5: '#d7cdeb',
        textGrayscale6: '#e9e1f7',
        textGrayscale7: '#eae3f9',
        error: '#B20110',
        border: 'rgba(70, 35, 128, 0.09)',
        actionBackgroundHover: 'rgba(70, 35, 128, 0.09)',
        inputPlaceholder: '#b8a8d4',
        inputBackground: 'rgba(255, 255, 255, 0.5)',
        inputBoxShadow:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(75, 41, 132, 0.16) 0px 0px 0px 1px, rgba(75, 41, 132, 0.08) 0px 2px 5px 0px',
        inputBoxShadowHover:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(75, 41, 132, 0.16) 0px 0px 0px 1px, rgba(75, 41, 132, 0.08) 0px 3px 9px 0px, rgba(75, 41, 132, 0.08) 0px 2px 5px 0px',
        inputBoxShadowFocus:
          'rgba(166, 112, 255, 0.36) 0px 0px 0px 4px, rgba(75, 41, 132, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActive: '#8b46ff 0px 0px 0px 2px, rgba(75, 41, 132, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActiveFocus:
          'rgba(166, 112, 255, 0.36) 0px 0px 0px 4px, #8b46ff 0px 0px 0px 2px',
        accentHover: '#711dff',
        menuBackground: 'rgba(255, 255, 255, 0.5)',
        menuBoxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
        menuOverlay: 'rgba(15, 15, 15, 0.6)',
        isBackgroundDark: false,
      };

    case Theme.Pink:
      return {
        boxShadow: 'rgba(128, 21, 119, 0.16)',
        boxShadowHalf: 'rgba(128, 21, 119, 0.08)',
        textGrayscale1: '#851d7d',
        textGrayscale2: '#a6549f',
        textGrayscale3: '#b167ab',
        textGrayscale4: '#d29ecd',
        textGrayscale5: '#e9c5e6',
        textGrayscale6: '#f6dbf4',
        textGrayscale7: '#f8ddf5',
        error: '#B20110',
        border: 'rgba(124, 14, 115, 0.09)',
        actionBackgroundHover: 'rgba(124, 14, 115, 0.09)',
        inputPlaceholder: '#d29ecd',
        inputBackground: 'rgba(255, 255, 255, 0.5)',
        inputBoxShadow:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(128, 21, 119, 0.16) 0px 0px 0px 1px, rgba(128, 21, 119, 0.08) 0px 2px 5px 0px',
        inputBoxShadowHover:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(128, 21, 119, 0.16) 0px 0px 0px 1px, rgba(128, 21, 119, 0.08) 0px 3px 9px 0px, rgba(128, 21, 119, 0.08) 0px 2px 5px 0px',
        inputBoxShadowFocus:
          'rgba(250, 80, 235, 0.36) 0px 0px 0px 4px, rgba(128, 21, 119, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActive: '#F81CE5 0px 0px 0px 2px, rgba(128, 21, 119, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActiveFocus:
          'rgba(250, 80, 235, 0.36) 0px 0px 0px 4px, #F81CE5 0px 0px 0px 2px',
        accentHover: '#e407d1',
        menuBackground: 'rgba(255, 255, 255, 0.5)',
        menuBoxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
        menuOverlay: 'rgba(15, 15, 15, 0.6)',
        isBackgroundDark: false,
      };

    case Theme.Dark:
      return {
        boxShadow: 'rgba(210, 210, 210, 0.16)',
        boxShadowHalf: 'rgba(210, 210, 210, 0.08)',
        textGrayscale1: '#cbcbcb',
        textGrayscale2: '#9b9b9b',
        textGrayscale3: '#8a8a8a',
        textGrayscale4: '#5a5a5a',
        textGrayscale5: '#383838',
        textGrayscale6: '#242424',
        textGrayscale7: '#232323',
        error: '#c56d74',
        border: 'rgba(255, 255, 255, 0.09)',
        actionBackgroundHover: 'rgba(255, 255, 255, 0.055)',
        inputPlaceholder: '#5a5a5a',
        inputBackground: 'rgba(255, 255, 255, 0.055)',
        inputBoxShadow:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(210, 210, 210, 0.16) 0px 0px 0px 1px, rgba(210, 210, 210, 0.08) 0px 2px 5px 0px',
        inputBoxShadowHover:
          'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(210, 210, 210, 0.16) 0px 0px 0px 1px, rgba(210, 210, 210, 0.08) 0px 3px 9px 0px, rgba(210, 210, 210, 0.08) 0px 2px 5px 0px',
        inputBoxShadowFocus:
          'rgba(152, 125, 255, 0.36) 0px 0px 0px 4px, rgba(210, 210, 210, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActive: '#7957ff 0px 0px 0px 2px, rgba(210, 210, 210, 0.16) 0px 0px 0px 1px',
        inputBoxShadowActiveFocus:
          'rgba(152, 125, 255, 0.36) 0px 0px 0px 4px, #7957ff 0px 0px 0px 2px',
        accentHover: '#582eff',
        menuBackground: '#262626',
        menuBoxShadow: 'rgba(0, 0, 0, 0.18) 0px 3px 12px',
        menuOverlay: 'rgba(15, 15, 15, 0.8)',
        isBackgroundDark: true,
      };
  }

  return {};
};

export const generateColors = (background: string, text: string, accent: string) => {
  const accentFocus = tinycolor.mix(accent, '#FFFFFF', 22.9).setAlpha(0.36).toRgbString();
  const boxShadow = tinycolor.mix(text, background, 3).setAlpha(0.16).toRgbString();
  const boxShadowHalf = tinycolor.mix(text, background, 3).setAlpha(0.08).toRgbString();

  const isBackgroundDark = tinycolor(background).isDark();

  return {
    boxShadow,
    boxShadowHalf,

    textGrayscale1: tinycolor.mix(text, background, 7).toHexString(), // darkerGray
    textGrayscale2: tinycolor.mix(text, background, 32).toHexString(), // darkGray
    textGrayscale3: tinycolor.mix(text, background, 41).toHexString(), // gray
    textGrayscale4: tinycolor.mix(text, background, 66).toHexString(), // lightGray
    textGrayscale5: tinycolor.mix(text, background, 84).toHexString(), // lighterGray
    textGrayscale6: tinycolor.mix(text, background, 94).toHexString(), // grayBackground
    textGrayscale7: tinycolor.mix(text, background, 95).toHexString(), // lightGrayBackground

    error: isBackgroundDark
      ? tinycolor.mix(defaultColors.error, text, 50).toHexString()
      : defaultColors.error,

    border: isBackgroundDark
      ? 'rgba(255, 255, 255, 0.09)'
      : tinycolor(text).setAlpha(0.09).toRgbString(),
    actionBackgroundHover: isBackgroundDark
      ? 'rgba(255, 255, 255, 0.055)'
      : tinycolor(text).setAlpha(0.09).toRgbString(),

    inputPlaceholder: tinycolor.mix(text, background, 66).toHexString(),
    inputBackground: isBackgroundDark ? 'rgba(255, 255, 255, 0.055)' : 'rgba(255, 255, 255, 0.5)',
    inputBoxShadow: `rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, ${boxShadow} 0px 0px 0px 1px, ${boxShadowHalf} 0px 2px 5px 0px`,
    inputBoxShadowHover: `rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, ${boxShadow} 0px 0px 0px 1px, ${boxShadowHalf} 0px 3px 9px 0px, ${boxShadowHalf} 0px 2px 5px 0px`,
    inputBoxShadowFocus: `${accentFocus} 0px 0px 0px 4px, ${boxShadow} 0px 0px 0px 1px`,
    inputBoxShadowActive: `${accent} 0px 0px 0px 2px, ${boxShadow} 0px 0px 0px 1px`,
    inputBoxShadowActiveFocus: `${accentFocus} 0px 0px 0px 4px, ${accent} 0px 0px 0px 2px`,

    accentHover: tinycolor(accent).darken(8).toHexString(),

    menuBackground: isBackgroundDark
      ? tinycolor(background).lighten(5).toHexString()
      : 'rgba(255, 255, 255, 0.5)',
    menuBoxShadow: isBackgroundDark
      ? 'rgba(0, 0, 0, 0.18) 0px 3px 12px'
      : 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    menuOverlay: isBackgroundDark ? `rgba(15, 15, 15, 0.8)` : `rgba(15, 15, 15, 0.6)`,

    // Utils
    isBackgroundDark,
  };
};

export const generateColorPalette = (name: string, color: string) => {
  const colorPalette = {
    [`${name}Dark2`]: tinycolor.mix(color, '#000', 50).toHexString(),
    [`${name}Dark1`]: tinycolor.mix(color, '#000', 25).toHexString(),
    [`${name}`]: color,
    [`${name}RGB`]: tinycolor(color).toRgbString(),
    [`${name}Light1`]: tinycolor.mix(color, '#fff', 50).toHexString(),
    [`${name}Light2`]: tinycolor.mix(color, '#fff', 90).toHexString(),
  };

  console.log(JSON.stringify(colorPalette)); // eslint-disable-line

  return colorPalette;
};

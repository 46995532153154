import i18next from 'i18next';

import { Language } from '../../types';
import { fetchTranslations } from './fetch-translations';

export const initI18n = async (language?: Language | string) => {
  const lng = (language as Language) || Language.English;
  const translation = await fetchTranslations(lng);

  const instance = i18next.createInstance();
  await instance.init({ lng, resources: { [lng]: { translation } } });

  return instance;
};

export type I18n = Awaited<ReturnType<typeof initI18n>>;

import { withDirection } from '@tallyforms/ui';
import styled from 'styled-components';

import { REQUIRED_INDICATOR_PADDING } from '@/components/form/required-indicator/styled';

export const Container = styled.div``;

export const ContentWrapper = styled.div`
  position: relative;
  display: inline-flex;
  padding: ${({ theme }) => theme.spacing.sm} 0 8px;

  &.required-indicator-position-fallback {
    .tally-required-indicator {
      position: relative;
      left: auto;
      right: auto;
      ${({ theme }) => withDirection('margin-left', theme.spacing.xs, theme.direction)}
    }

    label {
      ${({ theme }) => withDirection('padding-right', '0px', theme.direction)}
    }
  }
`;

export const Content = styled.label`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.618;

  ${({ theme }) =>
    `${withDirection('padding-right', `${REQUIRED_INDICATOR_PADDING}px`, theme.direction)}

    &:empty {
      ${withDirection('padding-right', '0px', theme.direction)}
    }
  `}
`;

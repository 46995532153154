import { Block, BlockType, isBlock } from '../../types';

export const getPageNumberByPageBlock = <T extends Block = Block>(
  blockOrBlockUuid: string | T,
  blocks: T[],
) => {
  const blockUuid = isBlock(blockOrBlockUuid) ? blockOrBlockUuid.uuid : blockOrBlockUuid;
  const pageBreakBlocks = blocks.filter(({ type }) => {
    return [BlockType.FormTitle, BlockType.PageBreak].includes(type);
  });

  const index = pageBreakBlocks.findIndex(({ uuid }) => uuid === blockUuid);
  if (index === -1) {
    return null;
  }

  return index + 1;
};

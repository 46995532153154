let loadedRetries = 0;
let couldNotLoad = false;

export const isLoaded = (): boolean => {
  // @ts-ignore
  return !!(window && window.fbq);
};

export const onLoaded = (callback: () => void) => {
  // @ts-ignore
  if (isLoaded()) {
    callback();
  } else {
    loadedRetries++;

    if (loadedRetries < 5) {
      setTimeout(
        () => {
          // @ts-ignore
          onLoaded(callback);
        },
        loadedRetries * 1000 + 100,
      );
    } else {
      couldNotLoad = true;
    }
  }
};

export const dispatchCustomEvent = (action: string, payload: any = {}) => {
  // @ts-ignore
  if (isLoaded()) {
    // @ts-ignore
    fbq('trackCustom', action, payload);
  } else if (!couldNotLoad) {
    onLoaded(() => {
      dispatchCustomEvent(action, payload);
    });
  }
};

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const Content = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  min-width: 100%;
  width: 100%;
`;

import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type LabelBlock = Block<
  BlockType.Label,
  {
    isHidden?: boolean;
    isFolded?: boolean;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
  }
>;

export function isLabelBlock(block: FormBuilderBlock): block is FormBuilderBlock<LabelBlock>;

export function isLabelBlock(block: RespondBlock): block is RespondBlock<LabelBlock>;

export function isLabelBlock(block: Block): block is LabelBlock {
  return block.type === BlockType.Label;
}

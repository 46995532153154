import { CursorPosition } from '@/types/form-builder';
import {
  childNodesToHTML,
  closestEditableSibling,
  getBlockElementByIndex,
  getBlockElementContainingNode,
  getDeepestFirstChildNode,
  getDeepestLastChildNode,
  getFocusedBlockIndex,
  isNodeEditable,
  nodeFromPoint,
  nodeOffsetFromPoint,
  recursiveSanitizeChildNodes,
} from '@/utils/form-builder/dom';

export const setCursor = (node: Node, offset: number) => {
  try {
    const range = document.createRange();
    range.setStart(node, offset);

    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
  } catch (_e) {
    // Do nothing
  }
};

export const getFocusPrecedingCharacter = (offset = 1): string => {
  let precedingChar = '';
  const sel = window.getSelection();

  try {
    if (sel && sel.rangeCount > 0 && sel.focusNode) {
      const range = sel.getRangeAt(0).cloneRange();
      range.collapse(true);
      range.setStart(sel.focusNode, 0);
      const string = range.toString();
      precedingChar = string.charAt(string.length - offset);
    }
  } catch (_e) {
    // Do nothing
  }

  return precedingChar;
};

export const getFocusFollowingCharacter = (offset = 1): string => {
  let followingChar = '';
  const sel = window.getSelection();

  try {
    if (sel && sel.rangeCount > 0 && sel.focusNode) {
      const range = sel.getRangeAt(0).cloneRange();
      range.collapse(true);
      range.setEnd(sel.focusNode, sel.focusOffset + offset);
      const string = range.toString();
      followingChar = string.charAt(offset - 1);
    }
  } catch (_e) {
    // Do nothing
  }

  return followingChar;
};

export const getFocusOffset = (): number => {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount || !sel.focusNode) {
    return 0;
  }

  return sel.focusOffset;
};

export const getFocusNode = (): Node | null => {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount || !sel.focusNode) {
    return null;
  }

  return sel.focusNode;
};

export const getFocusNodeAndOffset = (): [Node | null, number] => {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount || !sel.focusNode) {
    return [null, 0];
  }

  return [sel.focusNode, sel.focusOffset];
};

export const getRangePoint = (range: Range): { x: number; y: number } => {
  try {
    const rects = range.getClientRects();

    // Empty line buggy behavior
    if (
      range.collapsed &&
      range.startContainer &&
      range.startContainer.nodeType === Node.ELEMENT_NODE &&
      (range.startContainer.childNodes.length === 0 || rects.length === 0)
    ) {
      const startContainer = range.startContainer as Element;
      const rtl = getComputedStyle(startContainer).direction === 'rtl';
      const rect = (range.startContainer as Element).getBoundingClientRect();

      return {
        x: rtl ? rect.right : rect.x,
        y: rect.y,
      };
    }

    if (rects.length === 0) {
      return { x: 0, y: 0 };
    }

    const { x, y } = rects[0];
    return { x, y };
  } catch (_e) {
    return { x: 0, y: 0 };
  }
};

export const getCursorPoint = (): { x: number; y: number } => {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount || !sel.focusNode) {
    return { x: 0, y: 0 };
  }

  return getRangePoint(sel.getRangeAt(0));
};

export const isCursorInsideBlock = (): boolean => {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount || !sel.focusNode) {
    return false;
  }

  const blockEl = getBlockElementContainingNode(sel.focusNode);
  if (!blockEl) {
    return false;
  }

  return true;
};

export const isCursorAtStartOfBlock = (): boolean => {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount || !sel.focusNode || sel.anchorOffset !== sel.focusOffset) {
    return false;
  }

  const blockEl = getBlockElementContainingNode(sel.focusNode);
  if (!blockEl) {
    return false;
  }

  // Empty block
  if (sel.focusNode === blockEl && sel.focusOffset === 0) {
    return true;
  }

  const firstChildNode = getDeepestFirstChildNode(blockEl);

  // The focus node is the last child node with max offset
  if (sel.focusNode === firstChildNode && sel.focusOffset === 0) {
    return true;
  }

  // The focus is on an element
  if (
    sel.focusNode.nodeType === Node.ELEMENT_NODE &&
    sel.focusNode.contains(firstChildNode) &&
    sel.focusOffset === 0
  ) {
    return true;
  }

  return false;
};

export const isCursorAtEndOfBlock = (): boolean => {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount || !sel.focusNode || sel.anchorOffset !== sel.focusOffset) {
    return false;
  }

  const blockEl = getBlockElementContainingNode(sel.focusNode);
  if (!blockEl) {
    return false;
  }

  // Empty block
  if (sel.focusNode === blockEl) {
    return true;
  }

  const lastChildNode = getDeepestLastChildNode(blockEl);

  // The focus node is the last child node with max offset
  if (sel.focusNode === lastChildNode && sel.focusOffset === sel.focusNode.textContent?.length) {
    return true;
  }

  // The focus is on an element
  if (
    sel.focusNode.nodeType === Node.ELEMENT_NODE &&
    sel.focusNode.contains(lastChildNode) &&
    sel.focusOffset === sel.focusNode.childNodes.length
  ) {
    return true;
  }

  return false;
};

export const isCursorAtFirstLineOfBlock = (): boolean => {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount || !sel.focusNode) {
    return false;
  }

  // Get block
  const blockEl = getBlockElementContainingNode(sel.focusNode);
  if (!blockEl) {
    return false;
  }

  // Empty block
  if (sel.focusNode === blockEl) {
    return true;
  }

  // Get the first node in the block element
  // No node means an empty block, so the cursor is on the first line
  const node = getDeepestFirstChildNode(blockEl);
  if (!node) {
    return true;
  }

  // Create a range from the node with offset of 0 (the beginning)
  const range = document.createRange();
  range.setStart(node, 0);

  // If the cursor y coordinates are the same as the node y coordinates, we are at the first line
  return getCursorPoint().y === getRangePoint(range).y;
};

export const isCursorAtLastLineOfBlock = (): boolean => {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount || !sel.focusNode) {
    return false;
  }

  // Get block
  const blockEl = getBlockElementContainingNode(sel.focusNode);
  if (!blockEl) {
    return false;
  }

  // Empty block
  if (sel.focusNode === blockEl) {
    return true;
  }

  // Get the last node in the block element
  // No node means an empty block, so the cursor is on the last line
  const node = getDeepestLastChildNode(blockEl);
  if (!node) {
    return true;
  }

  // Create a range from the node with offset of max content length (the end)
  const range = document.createRange();
  range.setStart(node, node.textContent?.length ?? 0);

  // If the cursor y coordinates are the same as the node y coordinates, we are at the last line
  return getCursorPoint().y === getRangePoint(range).y;
};

export const focusBlock = (index: number, cursorPosition = CursorPosition.Start) => {
  const blockEl = getBlockElementByIndex(index);
  if (!blockEl) {
    return;
  }

  if (cursorPosition === CursorPosition.Start) {
    blockEl.focus();
    return;
  }

  if (cursorPosition === CursorPosition.End) {
    // Get the last node in the block
    const node = getDeepestLastChildNode(blockEl);
    if (!node) {
      // If we don't have a last node, this means the block is empty, set the focus on the block itself
      blockEl.focus();
      return;
    }

    setCursor(node, node.textContent?.length ?? 0);
    return;
  }

  if (cursorPosition === CursorPosition.Inherit) {
    // We will inherit the cursor position from the current cursor
    const focusedIndex = getFocusedBlockIndex();

    // Are we moving the cursor up or down?
    // If moving down, try to set the cursor at the first line of the block
    // If moving up, try to set the cursor at the last line of the block
    const isMoveDown = index > focusedIndex;

    // Get the first/last node in the block depending on if we are moving up or down.
    // We need this block to determine the Y coordinates of the line above/below (see further down).
    const firstLastNode = isMoveDown
      ? getDeepestFirstChildNode(blockEl)
      : getDeepestLastChildNode(blockEl);
    if (!firstLastNode) {
      // If we don't have a first/last node, this means the block is empty, set the focus on the block itself
      blockEl.focus();
      return;
    }

    // Find which node of the block to focus using the coordinates below

    // x = from the current cursor
    const { x: cursorX } = getCursorPoint();

    // y = from setting a virtual cursor at the start/end of the block
    const range = document.createRange();
    range.setStart(firstLastNode, isMoveDown ? 0 : (firstLastNode.textContent?.length ?? 0));
    const { y: firstLastNodeY } = getRangePoint(range);

    const node = nodeFromPoint(cursorX, firstLastNodeY);
    if (!node) {
      // If we didn't find a node
      blockEl.focus();
      return;
    }

    // The node we found is outside of the blockEl, set the focus on the block itself
    if (blockEl.contains(node) === false) {
      focusBlock(index, CursorPosition.End);
      return;
    }

    // If the node is not editable, we need to find the closest editable sibling
    if (isNodeEditable(node) === false) {
      const sibling = closestEditableSibling(node);
      if (sibling) {
        setCursor(sibling, 0);
        return;
      }

      // If we didn't find any siblings, focus the block with offset=1
      setCursor(blockEl, 1);
      return;
    }

    // Node is editable, set the selection range to the offset we found
    const offset = nodeOffsetFromPoint(node, cursorX, firstLastNodeY);
    setCursor(node, offset);
    return;
  }
};

export const splitBlock = (): [string, string] => {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount || !sel.focusNode) {
    return ['', ''];
  }

  const blockEl = getBlockElementContainingNode(sel.focusNode);
  if (!blockEl) {
    return ['', ''];
  }

  const rangeBefore = document.createRange();
  rangeBefore.setStart(blockEl, 0);
  rangeBefore.setEnd(sel.focusNode, sel.focusOffset);
  const beforeHTML = childNodesToHTML(
    recursiveSanitizeChildNodes(rangeBefore.cloneContents().childNodes),
  );

  const rangeAfter = document.createRange();
  rangeAfter.selectNodeContents(blockEl);
  rangeAfter.collapse(false);
  rangeAfter.setStart(sel.focusNode, sel.focusOffset);
  const afterHTML = childNodesToHTML(
    recursiveSanitizeChildNodes(rangeAfter.cloneContents().childNodes),
  );

  return [beforeHTML, afterHTML];
};

export const executeFormattingCommand = (cmd: string, value?: string) => {
  // Set mentions to editable so formatting is applied to them too
  document.querySelectorAll('.mention').forEach((x) => x.setAttribute('contenteditable', 'true'));

  // Execute command
  document.execCommand(cmd, false, value);

  // Put mentions back to non-editable after formatting is applied
  document.querySelectorAll('.mention').forEach((x) => x.setAttribute('contenteditable', 'false'));
};

export const getSelectionTextProps = (): {
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  foreColor: string;
  backColor: string;
  link: string | undefined;
} => {
  const props: {
    isBold: boolean;
    isItalic: boolean;
    isUnderline: boolean;
    foreColor: string;
    backColor: string;
    link: string | undefined;
  } = {
    isBold: false,
    isItalic: false,
    isUnderline: false,
    foreColor: '',
    backColor: '',
    link: undefined,
  };

  if (document.queryCommandState) {
    props.isBold = document.queryCommandState('bold');
    props.isItalic = document.queryCommandState('italic');
    props.isUnderline = document.queryCommandState('underline');
  }

  if (document.queryCommandValue) {
    props.foreColor = document.queryCommandValue('foreColor');
    props.backColor = document.queryCommandValue('backColor');
  }

  const sel = window.getSelection();
  if (sel) {
    props.link = sel.anchorNode?.parentElement?.closest('a')?.href;
  }

  return props;
};

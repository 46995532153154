import '@/utils/polyfills';
import 'react-day-picker/lib/style.css';

import { fetchTranslations, Language } from '@tallyforms/lib';
import { createGlobalStyle } from '@tallyforms/ui';
import i18n from 'i18next';
import NextApp, { AppContext, AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { initReactI18next } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import Page from '@/components/_pages/_page';
import { captureException, captureMessage, sentryWithScope } from '@/services/sentry';
import { loadFont } from '@/services/webfonts';
import { loadCustomCss, removeCustomCss } from '@/styles/css';
import { TallyEvent } from '@/utils/tally-event';

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-family',
});

let GlobalStyle: any = undefined;
let theme: any = {};
let onChangeStyles = (_e: any) => {}; // eslint-disable-line

class App extends NextApp<AppProps> {
  constructor(props: AppProps, context: AppContext) {
    super(props, context);

    this.state = { theme };

    this.setup(props.pageProps);
  }

  private setTheme(newTheme: any) {
    theme = newTheme;
    GlobalStyle = createGlobalStyle(newTheme, inter.style.fontFamily);
  }

  private onChangeStyles(e: any) {
    const changedStyles = e.detail as any;

    // We are changing the font, so we need to load it if it's not loaded
    if (changedStyles.customFont) {
      loadFont(changedStyles.customFont.family);
    }

    // We are changing the CSS, so we need to load it if it's not loaded
    if (changedStyles.customCss) {
      loadCustomCss(changedStyles.customCss);
    } else {
      removeCustomCss();
    }

    this.setTheme(changedStyles.theme);

    // Trigger a re-render on changing the styles
    this.setState({ theme: changedStyles.theme });
  }

  private setup(pageProps: any) {
    onChangeStyles = this.onChangeStyles.bind(this);

    // Init theme
    this.setTheme(pageProps.theme);

    // Init translations
    i18n.use(initReactI18next).init({
      resources: {
        default: { translation: pageProps.defaultTranslations },
        [pageProps.language]: { translation: pageProps.translations },
      },
      lng: pageProps.language,
      fallbackLng: [Language.English, 'default'],
      keySeparator: false,
      interpolation: { escapeValue: false },
      parseMissingKeyHandler: (key) => {
        // Report missing translations to Sentry, except for the ones we know about and can ignore
        if (
          [
            'error.FORM_NOT_FOUND',
            'error.INVALID_CAPTCHA',
            'form.error.object.unknown',
            'form.error.VALIDATION',
            'form.error.SHOULD_RANK_ALL_OPTIONS',
            'form.error.AIRTABLE_INVALID_FIELDS_MAPPING',
          ].includes(key) === false
        ) {
          captureMessage(`[${pageProps.language}] Missing translation for key: ${key}`);
        }
        return key;
      },
    });
  }

  componentDidMount() {
    window.addEventListener(TallyEvent.StylesChange, onChangeStyles);
  }

  componentWillUnmount() {
    window.removeEventListener(TallyEvent.StylesChange, onChangeStyles);
  }

  componentDidCatch(error: any, errorInfo: any) {
    sentryWithScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      captureException(error);
    });
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />

        <Page Component={Component} pageProps={pageProps} />
      </ThemeProvider>
    );
  }
}

export default App;

App.getInitialProps = async (appContext: AppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  // if no translations are provided
  if (!appProps.pageProps.translations) {
    // load default translations
    appProps.pageProps.defaultTranslations = await fetchTranslations('default');
  }

  return { ...appProps };
};

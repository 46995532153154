import styled from 'styled-components';

export const Container = styled.div`
  padding: 4px 0;

  img {
    max-width: 100%;
  }
`;

export const ImageContainer = styled.div``;

export const ImageCaption = styled.div`
  padding: ${({ theme }) => theme.spacing.xs} 2px;
  color: ${({ theme }) => theme.color.textGrayscale3};
  font-size: 14px;
`;

import { breakpoint, withDirection, withFontFamily } from '@tallyforms/ui';
import styled from 'styled-components';

import { FormRespondSection } from '@/components/layout/form';
import { FormStyles } from '@/types/form-design';

export const Wrapper = styled.div`
  direction: ${({ theme }) => theme.direction};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BackButtonContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  ${({ theme }) =>
    theme.direction === 'rtl' &&
    `
      & button svg:first-child {
        margin-left: ${theme.spacing.sm};
        margin-right: 0;
      }
    `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing.sm} 0;

  button:nth-child(2),
  .tally-powered {
    ${({ theme }) => withDirection('margin-left', theme.spacing.sm, theme.direction)}
  }

  > div {
    display: flex;
    min-width: 0;
  }
`;

export const Content = styled(FormRespondSection)<{
  styles: FormStyles;
  page: number;
  isDefaultThankYouPage: boolean;
  isCustomThankYouPage: boolean;
  hasProgressBar?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.xxxl};
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  progress {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  ${BackButtonContainer} {
    button {
      background: none;
      padding: 0;
    }
  }

  .tally-context-menu {
    font-family: ${({ theme }) => withFontFamily(theme.font.family)};
  }

  &.tally-ranking-option-dragging {
    .tally-ranking-option-dropzone {
      display: block;
    }
  }

  ${({ theme, styles }) =>
    !styles.embed &&
    !styles.popup &&
    `
    @media (max-width: ${breakpoint.md}) {
      margin-top: ${theme.spacing.xl};
    }
  `}

  ${({ theme, styles }) =>
    styles.withMedia &&
    `
    margin-top: ${theme.spacing.xl};
  `}

  ${({ styles }) =>
    styles.alignLeft &&
    `
    width: 100%;
    max-width: 100% !important;
  `}

  ${({ styles }) =>
    styles.embed &&
    `
    padding: 8px;
    margin-bottom: 0;
  `}

  ${({ styles }) =>
    styles.embed &&
    !styles.withMedia &&
    `
    margin-top: 0;
  `}

  ${({ theme, styles }) =>
    styles.popup &&
    `
    padding: ${theme.spacing.md};
    margin-top: 0;
    margin-bottom: 0;

    .tally-block {
      :first-child {
        h1, h2, h3, label {
          padding-top: 0;
        }

        h3 + .tally-required-indicator {
          top: 5px;
        }

        label + .tally-required-indicator {
          top: 4px;
        }
      }
    }

    ${BackButtonContainer} {
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 3;

      button {
        background-color: ${theme.color.lightGrayBackground};
        width: 24px;
        height: 24px;
        border-radius: 50%;
        padding: 0 6px;

        @media (hover: hover) {
          &:hover {
            background-color: ${theme.color.grayBackground};
          }
        }

        svg {
          margin-right: 0;
          width: 14px;
          height: 14px;
          min-width: 14px;
          min-height: 14px;
        }

        span:nth-child(2) {
          display:none;
        }
      }

      @media (max-width: ${breakpoint.sm}) {
        button {
          width: 20px;
          height: 20px;

          svg {
            width: 12px;
            height: 12px;
            min-width: 12px;
            min-height: 12px;
          }
        }
      }
    }

    ${ButtonsContainer} {
      margin-top: ${theme.spacing.sm};
      margin-bottom: 0;

      @media (max-width: ${breakpoint.sm}) {
        > div:nth-child(1) {
          flex: 1;

          button {
            width: 100%;
          }
        }
      }
    }
  `}

  ${({ theme, styles }) =>
    styles.popup &&
    styles.withMedia &&
    `
    margin-top: ${theme.spacing.lg};


    @media (max-width: ${breakpoint.sm}) {
      margin-top: ${theme.spacing.md};
    }
  `}

  ${({ styles, page }) =>
    styles.popup &&
    styles.withMedia &&
    (page > 1 || styles.hideTitle) &&
    `
    @media (max-width: ${breakpoint.sm}) {
      margin-top: 0;
    }
  `}

  ${({ styles, page, hasProgressBar }) =>
    styles.popup &&
    !styles.withMedia &&
    (page > 1 || hasProgressBar) &&
    `
    padding-top: 35px;

  `}

  ${({ styles, isDefaultThankYouPage }) =>
    styles.popup &&
    !styles.removeBranding &&
    !isDefaultThankYouPage &&
    `
    padding-bottom: 56px;
  `}

  ${({ styles, isCustomThankYouPage }) =>
    styles.embed &&
    isCustomThankYouPage &&
    !styles.removeBranding &&
    `
    padding-bottom: 72px;
  `}
`;

export const Disclaimer = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.textGrayscale1};
  line-height: 1.5;

  a {
    color: ${({ theme }) => theme.color.textGrayscale1};

    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const PoweredByContainer = styled.div`
  position: fixed;
  bottom: 18px;
  right: 18px;
`;

import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

function useFormBuilderCustomizationActions(): MutableRefObject<HTMLDivElement | null> {
  const ref = useRef<HTMLDivElement | null>(null);

  const addHoverClassName = useCallback(() => {
    document.querySelector('.form-builder-customization-actions')?.classList.add('hover');
  }, []);

  const removeHoverClassName = useCallback(() => {
    document.querySelector('.form-builder-customization-actions')?.classList.remove('hover');
  }, [ref]);

  useEffect(() => {
    ref?.current?.addEventListener('mouseover', addHoverClassName);
    ref?.current?.addEventListener('mouseout', removeHoverClassName);

    return () => {
      ref?.current?.removeEventListener('mouseover', addHoverClassName);
      ref?.current?.removeEventListener('mouseout', removeHoverClassName);
    };
  }, [ref]);

  return ref;
}

export default useFormBuilderCustomizationActions;

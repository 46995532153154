import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type TextBlock = Block<
  BlockType.Text,
  {
    isHidden?: boolean;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
  }
>;

export function isTextBlock(block: FormBuilderBlock): block is FormBuilderBlock<TextBlock>;

export function isTextBlock(block: RespondBlock): block is RespondBlock<TextBlock>;

export function isTextBlock(block: Block): block is TextBlock {
  return block.type === BlockType.Text;
}

import {
  BlockType,
  isCheckboxBlock,
  isDropdownBlock,
  isFileUploadBlock,
  isInputDateBlock,
  isInputNumberBlock,
  isInputPhoneNumberBlock,
  isInputTextBlock,
  isMultipleChoiceOptionBlock,
  isMultiSelectBlock,
  isPaymentBlock,
  isTextareaBlock,
  RespondBlock,
  ValidationRule,
  ValidationRuleConstraint,
  ValidationRules,
} from '@tallyforms/lib';

import { transformBlocksToQuestionsMap } from '../block-transformer';

export const getValidationRulesFromBlocks = (blocks: RespondBlock[]) => {
  const questions = transformBlocksToQuestionsMap(blocks);
  const rules: ValidationRules = {};
  for (const block of blocks) {
    // check if rules are already set for this group
    if (rules[block.groupUuid]) {
      continue;
    }

    if (isPaymentBlock(block) && !block.payload.isConfigured) {
      continue;
    }

    const constraints: ValidationRuleConstraint[] = [];
    if (block.payload.isRequired && typeof questions.get(block.uuid) !== 'undefined') {
      switch (block.type) {
        case BlockType.Payment:
          constraints.push([ValidationRule.PaymentRequired]);
          break;

        case BlockType.MultipleChoiceOption:
        case BlockType.Checkbox:
        case BlockType.Dropdown:
        case BlockType.MultiSelect:
        case BlockType.MultiSelectOption:
          constraints.push([ValidationRule.OptionRequired]);
          break;

        case BlockType.Rating:
        case BlockType.LinearScale:
          constraints.push([ValidationRule.ValueRequired]);
          break;

        case BlockType.Signature:
          constraints.push([ValidationRule.SignatureRequired]);
          break;

        case BlockType.FileUpload:
          constraints.push([ValidationRule.FileRequired]);
          break;

        case BlockType.RankingOption:
          constraints.push([ValidationRule.RankingRequired]);
          break;

        case BlockType.Matrix:
          constraints.push([ValidationRule.MatrixRequired]);
          break;

        default:
          constraints.push([ValidationRule.Required]);
          break;
      }
    }

    if (block.type === BlockType.InputLink) {
      constraints.push([ValidationRule.ShouldBeURL]);
    }

    if (block.type === BlockType.InputEmail) {
      constraints.push([ValidationRule.ShouldBeEmail]);

      if (block.payload.requireVerification) {
        constraints.push([ValidationRule.EmailVerificationRequired]);
      }
    }

    if (isInputPhoneNumberBlock(block) && block.payload.internationalFormat) {
      constraints.push([ValidationRule.ShouldBePhoneNumber]);
    }

    if (isInputNumberBlock(block)) {
      if (block.payload.hasMinNumber) {
        constraints.push([ValidationRule.ShouldBeMinNumber, block.payload.minNumber]);
      }

      if (block.payload.hasMaxNumber) {
        constraints.push([ValidationRule.ShouldBeMaxNumber, block.payload.maxNumber]);
      }
    }

    if (isInputDateBlock(block)) {
      if (block.payload.beforeDate) {
        constraints.push([ValidationRule.ShouldBeBeforeDate, block.payload.beforeDate]);
      }

      if (block.payload.afterDate) {
        constraints.push([ValidationRule.ShouldBeAfterDate, block.payload.afterDate]);
      }

      if (block.payload.dateRange?.from && block.payload.dateRange?.to) {
        constraints.push([
          ValidationRule.ShouldBeBetweenDates,
          [block.payload.dateRange.from, block.payload.dateRange.to],
        ]);
      }

      if (block.payload.specificDates) {
        constraints.push([ValidationRule.ShouldBeOneOfSpecificDates, block.payload.specificDates]);
      }
    }

    if (isInputTextBlock(block) || isTextareaBlock(block)) {
      if (block.payload.hasMinCharacters) {
        constraints.push([ValidationRule.ShouldBeMinChars, block.payload.minCharacters]);
      }

      if (block.payload.hasMaxCharacters) {
        constraints.push([ValidationRule.ShouldBeMaxChars, block.payload.maxCharacters]);
      } else {
        // This is a temporary fix for very long text inputs which we cannot store in the database for now
        constraints.push([ValidationRule.ShouldBeMaxChars, 40000]);
      }
    }

    if (
      isCheckboxBlock(block) ||
      isMultipleChoiceOptionBlock(block) ||
      isDropdownBlock(block) ||
      isMultiSelectBlock(block)
    ) {
      if (block.payload.hasMinChoices) {
        constraints.push([ValidationRule.ShouldHaveMinArrayLength, block.payload.minChoices]);
      }

      if (block.payload.hasMaxChoices) {
        constraints.push([ValidationRule.ShouldHaveMaxArrayLength, block.payload.maxChoices]);
      }
    }

    if (block.type === BlockType.RankingOption) {
      constraints.push([
        ValidationRule.ShouldRankAllOptions,
        blocks.filter(({ groupUuid }) => groupUuid === block.groupUuid).length,
      ]);
    }

    if (isFileUploadBlock(block)) {
      if (block.payload.hasMinFiles) {
        constraints.push([ValidationRule.ShouldHaveMinArrayLength, block.payload.minFiles]);
      }

      if (block.payload.hasMaxFiles) {
        constraints.push([ValidationRule.ShouldHaveMaxArrayLength, block.payload.maxFiles]);
      }

      constraints.push([ValidationRule.ShouldFinishUpload]);
    }

    if (block.type === BlockType.Captcha) {
      constraints.push([ValidationRule.ShouldSolveCaptcha]);
    }

    rules[block.groupUuid] = constraints;
  }

  return rules;
};

import styled from 'styled-components';

import { FormStyles } from '@/types/form-design';

export const Container = styled.div<{ styles: FormStyles }>`
  display: flex;
  background: ${({ theme }) => theme.color.background};
  direction: ${({ theme }) => theme.direction};

  ${({ styles }) =>
    styles.transparentBackground &&
    `
    background: transparent;
  `}

  ${({ styles }) =>
    styles.alignLeft &&
    `
    overflow: hidden;
  `}
`;

export const Content = styled.main<{ styles: FormStyles }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 100vh;

  ${({ styles }) =>
    (styles.popup || (styles.embed && styles.dynamicHeight)) &&
    `
    height: auto;
    min-height: auto;
  `}

  ${({ styles }) =>
    (styles.popup || (styles.embed && styles.dynamicHeight)) &&
    styles.lastPageHeight &&
    `
    min-height: ${styles.lastPageHeight}px;
  `}
`;

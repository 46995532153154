import { breakpoint } from '@tallyforms/ui';
import styled from 'styled-components';

import { FormBuilderContainer, FormRespondContainer } from '@/components/layout/form';
import { FormStyles } from '@/types/form-design';

export const BuilderContainer = styled(FormBuilderContainer)<{
  withCover: boolean;
}>`
  direction: ${({ theme }) => theme.direction};
  position: relative;
  z-index: 2;
  cursor: pointer;
  padding: 0 ${({ theme }) => theme.spacing.xxl};

  @media (max-width: ${breakpoint.sm}) {
    padding: 0 25px;
  }

  img {
    display: block;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    object-fit: cover;
    border-radius: 50%;

    @media (hover: hover) {
      &:hover {
        box-shadow:
          rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
          rgba(15, 15, 15, 0.1) 0px 3px 9px,
          rgba(15, 15, 15, 0.01) 0px 9px 14px;
        transform: translateY(-1px);
      }
    }

    ${({ withCover }) =>
      withCover === false &&
      `
       margin-top: 50px;
    `}
  }
`;

export const RespondContainer = styled(FormRespondContainer)<{
  withCover: boolean;
  styles?: FormStyles;
}>`
  position: relative;
  z-index: 2;

  img {
    display: block;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    object-fit: cover;
    border-radius: 50%;

    ${({ withCover }) =>
      withCover === false &&
      `
       margin-top: 50px;
    `}
  }

  ${({ styles }) =>
    styles?.alignLeft &&
    `
    width: 100%;
    max-width: 100% !important;
    margin: 0 !important;
  `}

  ${({ theme, styles }) =>
    styles?.alignLeft &&
    styles?.popup &&
    `
    padding: 0 ${theme.spacing.md};
  `}

  ${({ theme, styles, withCover }) =>
    styles?.alignLeft &&
    styles?.popup &&
    withCover === false &&
    `
    img {
      margin-top: ${theme.spacing.md};
    }
  `}
`;

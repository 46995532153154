import { Position } from '../position';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export interface HiddenField {
  uuid: string;
  name?: string;
}

export type HiddenFieldsBlock = Block<
  BlockType.HiddenFields,
  {
    hiddenFields: HiddenField[];
  }
>;

export function isHiddenFieldsBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<HiddenFieldsBlock>;

export function isHiddenFieldsBlock(block: RespondBlock): block is RespondBlock<HiddenFieldsBlock>;

export function isHiddenFieldsBlock(block: Block): block is HiddenFieldsBlock;

export function isHiddenFieldsBlock(block: Block): block is HiddenFieldsBlock {
  return block.type === BlockType.HiddenFields;
}

export type HiddenFieldsModifyCallback = ({
  payload,
  hiddenField,
  index,
}: {
  payload: HiddenFieldsBlock['payload'];
  hiddenField: HiddenField;
  index: number;
}) => void;

export interface HiddenFieldMenu {
  isOpen: boolean;
  position: Position;
  hiddenField: HiddenField;
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const waitFor = async (
  checkFn: () => boolean,
  checkIntervalMs = 250,
  timeoutMs: number | null = null,
) => {
  let elapsedMs = 0;

  while (!checkFn() && (timeoutMs === null || elapsedMs < timeoutMs)) {
    await sleep(checkIntervalMs);
    elapsedMs += checkIntervalMs;
  }
};

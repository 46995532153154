import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type InputLinkBlock = Block<
  BlockType.InputLink,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    placeholder?: string;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isInputLinkBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<InputLinkBlock>;

export function isInputLinkBlock(block: RespondBlock): block is RespondBlock<InputLinkBlock>;

export function isInputLinkBlock(block: Block): block is InputLinkBlock {
  return block.type === BlockType.InputLink;
}

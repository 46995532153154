import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type DividerBlock = Block<
  BlockType.Divider,
  {
    isHidden?: boolean;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
  }
>;

export function isDividerBlock(block: FormBuilderBlock): block is FormBuilderBlock<DividerBlock>;

export function isDividerBlock(block: RespondBlock): block is RespondBlock<DividerBlock>;

export function isDividerBlock(block: Block): block is DividerBlock {
  return block.type === BlockType.Divider;
}

import { FormStyles } from '@/types/form-design';

import { Container, CoverImg } from './styled';

interface Props {
  cover: string;
  settings?: { objectPositionYPercent: number };
  styles?: FormStyles;
}

const FormRespondCover = ({ cover, settings, styles }: Props) => {
  return (
    <Container className="tally-form-cover">
      <CoverImg
        src={cover}
        alt="Form cover"
        objectPositionYPercent={settings?.objectPositionYPercent ?? 50}
        styles={styles}
      />
    </Container>
  );
};

export default FormRespondCover;

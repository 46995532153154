import {
  Block,
  BlockType,
  CalculatedFieldType,
  Field,
  FieldType,
  formatNumberToFixedDecimals,
  Mention,
  RespondBlock,
  RichText,
  SafeHTMLModifier,
  SafeHTMLSchema,
  SafeSchemaBlock,
  safeUrl,
  stripHtml,
  transformSafeHTMLSchemaToHTML,
} from '@tallyforms/lib';
import he from 'he';
import { v4 as uuid } from 'uuid';

import { convertMatrixUuidToText } from '@/utils/blocks';
import { isSafari } from '@/utils/device';
import { getFields } from '@/utils/form-builder/fields';
import { convertFormDataToText } from '@/utils/form-respond';

/**
 * This function strips out dangerous HTML characters only with their html entitiy
 *
 * @param string
 * @returns string
 */
export const safeHtmlEntitiesEncode = (str: string): string => {
  // Afaik, those are the only characters that get encoded using the DOM textarea method in the browser, so we can keep it consistent with the client side.
  // return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

  // CR wouterds: The following approach encodes the characters as listed above but this will only
  // work on the client side. I think it's safe to switch to above implementation.
  const encode = document.createElement('textarea');
  encode.textContent = str;
  return encode.innerHTML;
};

/**
 * This is equivalent to the server side transformHTMLToSafeHTMLSchema() function but using the DOM instead of cheerio.
 * If we ever need to update this, we should update the server side function as well.
 */
export const transformNodesToSafeHTMLSchema = (nodes: NodeListOf<ChildNode>): SafeHTMLSchema => {
  const schema: SafeHTMLSchema = [];

  if (!nodes || !nodes.forEach || nodes.length === 0) {
    return schema;
  }

  nodes.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE && node.textContent) {
      schema.push([safeHtmlEntitiesEncode(node.textContent)]);
    } else if (
      node.nodeType === Node.ELEMENT_NODE &&
      (node.childNodes.length > 0 || node.nodeName === 'BR')
    ) {
      const modifiers: SafeHTMLModifier[] = [];

      switch (node.nodeName) {
        case 'B':
          modifiers.push(['font-weight', 'bold']);
          break;
        case 'U':
          modifiers.push(['text-decoration', 'underline']);
          break;
        case 'I':
          modifiers.push(['font-style', 'italic']);
          break;
        case 'A':
          {
            const href = (node as any).href;
            if (
              href?.match(/^http(s)?:\/\//) ||
              href?.match(/^mailto:/) ||
              href?.match(/^tel:/) ||
              href?.match(/^sms:/)
            ) {
              modifiers.push(['href', href]);
            }
          }
          break;
        case 'FONT':
          {
            const color = (node as any).color;
            if (color?.match(/#[0-9a-fA-F]{3,6}/)) {
              modifiers.push(['color', color]);
            }
          }
          break;
        case 'DIV':
          modifiers.push(['tag', 'div']);
          break;
        case 'P':
          modifiers.push(['tag', 'p']);
          break;
        case 'BR':
          modifiers.push(['tag', 'br']);
          break;
        case 'SPAN':
          modifiers.push(['tag', 'span']);
      }

      // All node types might have styles which we consider valid modifiers
      const style = (node as HTMLElement).style;

      if (
        style?.color?.match(/#[0-9a-fA-F]{3,6}/) ||
        style?.color?.match(/rgb\([0-9]{1,3}, [0-9]{1,3}, [0-9]{1,3}\)/) ||
        style?.color === 'inherit'
      ) {
        modifiers.push(['color', style.color]);
      }

      if (
        style?.backgroundColor?.match(/#[0-9a-fA-F]{3,6}/) ||
        style?.backgroundColor?.match(/rgb\([0-9]{1,3}, [0-9]{1,3}, [0-9]{1,3}\)/) ||
        style?.backgroundColor === 'inherit'
      ) {
        modifiers.push(['background-color', style.backgroundColor]);
      }

      if (style?.fontWeight) {
        let fontWeight = style?.fontWeight;
        // We don't support bolder and lighter
        if (['bolder', 'lighter'].includes(fontWeight)) {
          fontWeight = 'bold';
        }

        modifiers.push(['font-weight', fontWeight]);
      }

      if (style?.fontStyle) {
        modifiers.push(['font-style', style.fontStyle]);
      }

      if (style?.textDecoration) {
        modifiers.push(['text-decoration', style.textDecoration]);
      }

      if (style?.display) {
        modifiers.push(['display', style.display]);
      }

      // Check for mentions
      if (node.nodeName === 'SPAN' && (node as HTMLElement).className?.indexOf('mention') !== -1) {
        modifiers.push(['mention', (node as HTMLElement).dataset.uuid!]);
      }

      if (modifiers.length > 0) {
        if (
          node.childNodes.length === 1 &&
          node.childNodes[0].nodeType === Node.TEXT_NODE &&
          node.childNodes[0].textContent
        ) {
          schema.push([safeHtmlEntitiesEncode(node.childNodes[0].textContent), modifiers]);
        } else if (node.nodeName === 'BR') {
          schema.push(['', modifiers]);
        } else {
          schema.push([transformNodesToSafeHTMLSchema(node.childNodes), modifiers]);
        }
      }
    }
  });

  return schema;
};

export const removeMentionsOfMissingFieldsFromSafeHTMLSchema = (
  schema: SafeHTMLSchema,
  mentions: Mention[],
  fields: Field[],
): SafeHTMLSchema => {
  // No schema, return
  if (!schema || schema.length === 0) {
    return schema;
  }

  // If there are no mentions, return the schema as is
  if (mentions.length === 0) {
    return schema;
  }

  return schema.map((el) => {
    const [node, modifiers] = el;

    // Go over the modifiers and remove any mentions that point to a missing field
    let removedMentions = false;
    const filteredModifiers = modifiers?.filter(([property, value]) => {
      if (property === 'mention') {
        const mention = mentions.find((x) => x.uuid === value);
        if (!mention) {
          removedMentions = true;
          return false;
        }

        if (typeof fields.find((x) => x.uuid === mention.field.uuid) === 'undefined') {
          removedMentions = true;
          return false;
        }

        return true;
      }

      return true;
    });

    // If we removed any mentions, return an empty string
    if (removedMentions) {
      return ['', filteredModifiers];
    }

    return [
      typeof node === 'string'
        ? node
        : removeMentionsOfMissingFieldsFromSafeHTMLSchema(node, mentions, fields),
      modifiers,
    ];
  });
};

export const sanitizeHTML = (html: string): string => {
  // Create a container and set the HTML
  const container = document.createElement('div');
  container.innerHTML = html;

  // Remove any tags and attributes we don't support
  return transformSafeHTMLSchemaToHTML(transformNodesToSafeHTMLSchema(container.childNodes));
};

export const getRedirectOnCompletionURL = (
  richText: RichText,
  blocks: SafeSchemaBlock[],
  formData: { [key: string]: any },
  metadata: { [key: string]: any },
): string => {
  if (typeof document === 'undefined') {
    return '';
  }

  // Transform to HTML
  let html = transformSafeHTMLSchemaToHTML(richText.safeHTMLSchema);
  // Remove zero width spaces
  html = html.replace(/\u200B/g, '');

  // Create a container and set the HTML
  const container = document.createElement('div');
  container.innerHTML = html;

  // Replace mentions with the form data
  for (const { uuid, field, defaultValue } of richText.mentions) {
    const mentionEls = container.querySelectorAll(`[data-uuid="${uuid}"]`);
    if (mentionEls.length === 0) {
      continue;
    }

    const { uuid: fieldUuid, type, questionType, blockGroupUuid, calculatedFieldType } = field;

    let data: any = '';
    if (type === FieldType.Metadata) {
      data = metadata[fieldUuid];
    } else if (type === FieldType.InputField && questionType && blockGroupUuid) {
      if (questionType === BlockType.InputDate) {
        // We don't need to format the dates
        data = formData[blockGroupUuid] ?? '';
      } else {
        data = convertFormDataToText(questionType, formData[blockGroupUuid], blocks);
      }
    } else if (
      [FieldType.CalculatedField, FieldType.HiddenField].includes(type) &&
      fieldUuid &&
      blockGroupUuid
    ) {
      data = formData[blockGroupUuid]?.[fieldUuid] ? formData[blockGroupUuid][fieldUuid] : '';
    }

    // Format number
    if (
      type === FieldType.CalculatedField &&
      calculatedFieldType === CalculatedFieldType.Number &&
      typeof data === 'number'
    ) {
      data = formatNumberToFixedDecimals(data, 2);
    }

    if (field.questionType === BlockType.Matrix) {
      const answer = formData[field.blockGroupUuid][field.uuid] ?? [];
      data = answer.map((x: string) => convertMatrixUuidToText(x, blocks)).join(',');
    }

    if (
      // If the string is an URL, make sure the data is URL encoded
      (container.textContent!.startsWith('http') || container.textContent!.startsWith('www.')) &&
      // But the data isn't a pathname
      !(typeof data === 'string' && data.indexOf('/') !== -1)
    ) {
      data = encodeURIComponent(data);
    }

    mentionEls.forEach((el) => {
      el.innerHTML = data?.toString() || defaultValue || '';
    });
  }

  // Get the text content version of the div
  return safeUrl(container.textContent!, true);
};

export const transformStringToRichText = (text: string): RichText => {
  return {
    safeHTMLSchema: [[text]],
    mentions: [],
  };
};

export const isEmptyRichText = (richText: any): boolean => {
  if (!richText || !Array.isArray(richText?.safeHTMLSchema)) {
    return true;
  }

  const textContent = stripHtml(transformSafeHTMLSchemaToHTML(richText.safeHTMLSchema));

  return textContent.length === 0;
};

export const getEmailDefaultFromName = (): RichText => {
  // Generate SafeHTMLSchema from HTML
  const container = document.createElement('div');
  container.innerHTML = `Tally Forms`;

  return {
    safeHTMLSchema: transformNodesToSafeHTMLSchema(container.childNodes),
    mentions: [],
  };
};

export const getSelfEmailDefaultSubject = (blocks: (Block | RespondBlock)[]): RichText => {
  // Get the form name field and create a mention
  const field = getFields(blocks, { includeMetadata: true }).find((x) => x.uuid === 'formName')!;
  const { mentionObj, mentionHtml } = createMention('Form name', field);

  // Generate SafeHTMLSchema from HTML
  const container = document.createElement('div');
  container.innerHTML = `New Tally Form Submission for ${mentionHtml}`;

  return {
    safeHTMLSchema: transformNodesToSafeHTMLSchema(container.childNodes),
    mentions: [mentionObj],
  };
};

export const getSelfEmailDefaultBody = (blocks: (Block | RespondBlock)[]): RichText => {
  const mentions: Mention[] = [];

  // Get all the fields
  const fields = getFields(blocks);

  // Generate SafeHTMLSchema from HTML
  let emailBodyHtml = '';

  for (const field of fields) {
    const { mentionObj, mentionHtml } = createMention(field.title, field);
    emailBodyHtml += `<div><b>${he.encode(field.title)}</b></div><div>${mentionHtml}</div><br/>`;

    mentions.push(mentionObj);
  }

  const container = document.createElement('div');
  container.innerHTML = emailBodyHtml;

  return {
    safeHTMLSchema: transformNodesToSafeHTMLSchema(container.childNodes),
    mentions,
  };
};

export const getSlackDefaultMessage = (blocks: (Block | RespondBlock)[]): RichText => {
  const mentions: Mention[] = [];

  // Generate SafeHTMLSchema from HTML
  let html = '';

  // Form name
  const formNameField = getFields(blocks, { includeMetadata: true }).find(
    (x) => x.uuid === 'formName',
  )!;
  const { mentionObj, mentionHtml } = createMention('Form name', formNameField);
  html += `<div>New submission for *${mentionHtml}*.</div><br/>`;
  mentions.push(mentionObj);

  // Go over all fields
  for (const field of getFields(blocks)) {
    const { mentionObj, mentionHtml } = createMention(field.title, field);
    html += `<div>*${he.encode(field.title)}*: ${mentionHtml}</div>`;
    mentions.push(mentionObj);
  }

  const container = document.createElement('div');
  container.innerHTML = html;

  return {
    safeHTMLSchema: transformNodesToSafeHTMLSchema(container.childNodes),
    mentions,
  };
};

export const getUniqueSubmissionKeyRichText = (field: Field): RichText => {
  const { mentionObj, mentionHtml } = createMention(field.title, field);

  const container = document.createElement('div');
  container.innerHTML = mentionHtml;

  return {
    safeHTMLSchema: transformNodesToSafeHTMLSchema(container.childNodes),
    mentions: [mentionObj],
  };
};

const createMention = (
  title: string,
  field: Field,
): {
  mentionObj: Mention;
  mentionHtml: string;
} => {
  const mentionUuid = uuid();

  return {
    mentionObj: { uuid: mentionUuid, field },
    mentionHtml: `<span class="mention" contenteditable="false" data-uuid="${mentionUuid}">@${he.encode(title)}</span>${
      isSafari() ? '\u200B' : ''
    }`,
  };
};

import { AllowedFiles } from '../files';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export interface FileUpload {
  id: string;
  name: string;
  url: string;
  mimeType: string;
  size: number;
}

export type FileUploadBlock = Block<
  BlockType.FileUpload,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasMultipleFiles?: boolean;
    hasMinFiles?: boolean;
    minFiles?: number;
    hasMaxFiles?: boolean;
    maxFiles?: number;
    hasMaxFileSize?: boolean;
    maxFileSize?: number;
    maxFileSizeUnit?: string;
    allowedFiles?: AllowedFiles;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isFileUploadBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<FileUploadBlock>;

export function isFileUploadBlock(block: RespondBlock): block is RespondBlock<FileUploadBlock>;

export function isFileUploadBlock(block: Block): block is FileUploadBlock {
  return block.type === BlockType.FileUpload;
}

import i18next from 'i18next';

import { Language } from '../../types';
import { fetchTranslations } from './fetch-translations';
import { I18n } from './init-i18n';

type Options = {
  i18next?: I18n;
  language: Language | string;
};

export const changeLanguage = async (options: Options) => {
  const instance = options.i18next || i18next;

  if (!instance.hasResourceBundle(options.language, 'translation')) {
    const translations = await fetchTranslations(options.language);
    instance.addResourceBundle(options.language, 'translation', translations, true, true);
  }

  await instance.changeLanguage(options.language);
};

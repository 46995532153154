import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type InputTextBlock = Block<
  BlockType.InputText,
  {
    isHidden?: boolean;
    isRequired?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    placeholder?: string;
    hasMinCharacters?: boolean;
    minCharacters?: number;
    hasMaxCharacters?: boolean;
    maxCharacters?: number;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isInputTextBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<InputTextBlock>;

export function isInputTextBlock(block: RespondBlock): block is RespondBlock<InputTextBlock>;

export function isInputTextBlock(block: Block): block is InputTextBlock {
  return block.type === BlockType.InputText;
}

export const remove = (key: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    window.localStorage?.removeItem?.(key);
  } catch {
    return;
  }
};

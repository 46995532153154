import axios from 'axios';

import config from '@/config';

let fontsList: string[] = [];

export const getWebFontsList = async (): Promise<string[]> => {
  if (fontsList.length > 0) {
    return fontsList;
  }

  // Load fonts list from the server
  const { data } = await axios.get(
    `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=${config.get(
      'google-webfonts.key',
    )}`,
  );

  fontsList = data.items.map((item: any) => item.family);

  return fontsList;
};

export const loadFont = (fontFamily: string) => {
  const hrefToLoad = `https://fonts.googleapis.com/css?family=${fontFamily.replace(
    /\s/g,
    '+',
  )}:400,500,800,900&display=swap`;

  // Check if font is already loaded
  let isAlreadyLoaded = false;
  const headLinks = document.querySelectorAll('link');
  headLinks.forEach((link) => {
    if (link.href === hrefToLoad) {
      isAlreadyLoaded = true;
    }
  });

  if (isAlreadyLoaded) {
    return;
  }

  // Load font
  const link = document.createElement('link');
  link.href = `https://fonts.googleapis.com/css?family=${fontFamily.replace(
    /\s/g,
    '+',
  )}:400,500,800,900&display=swap`;
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};

import { FormData, isEmpty, RespondBlock } from '@tallyforms/lib';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { isUuid } from '@/services/uuid';

let lastChangedGroupUuid: string | undefined;

const usePageAutoJump = (
  formData: FormData,
  pageBlocks: RespondBlock[],
  canAutoJumpToNextPage: boolean,
  pageFilledIn: boolean,
  onContinue: () => void,
): {
  isAutoJumping: boolean;
  setIsAutoJumping: Dispatch<SetStateAction<boolean>>;
  setLastChangedGroupUuid: (groupUuid: string | undefined) => void;
} => {
  const [isAutoJumping, setIsAutoJumping] = useState(false);

  const setLastChangedGroupUuid = (groupUuid: string | undefined) => {
    lastChangedGroupUuid = groupUuid;
  };

  useEffect(() => {
    if (!lastChangedGroupUuid) {
      return;
    }

    const value = formData[lastChangedGroupUuid];

    let isSupportedValue = typeof value !== 'undefined' && isEmpty(value) === false;

    // If the user selected an "Other" option, we should not auto jump to the next page
    if (
      canAutoJumpToNextPage &&
      isSupportedValue &&
      Array.isArray(value) &&
      pageBlocks.find((x) => x.groupUuid === lastChangedGroupUuid)?.payload.hasOtherOption &&
      (isUuid(value[0]) === false ||
        pageBlocks.find((x) => x.uuid === value[0])?.payload.isOtherOption)
    ) {
      isSupportedValue = false;
    }

    if (canAutoJumpToNextPage && isSupportedValue) {
      // Queue auto jump to next page
      setIsAutoJumping(true);
    }

    lastChangedGroupUuid = undefined;
  }, [formData]);

  useEffect(() => {
    // Auto jump to next page
    if (canAutoJumpToNextPage && pageFilledIn && isAutoJumping) {
      setTimeout(() => {
        onContinue();
        setIsAutoJumping(false);
      }, 400);
    }

    // We were auto jumping, but the conditions changed, stop here
    if (!canAutoJumpToNextPage && isAutoJumping) {
      setIsAutoJumping(false);
    }
  }, [canAutoJumpToNextPage, pageFilledIn, isAutoJumping]);

  return { isAutoJumping, setIsAutoJumping, setLastChangedGroupUuid };
};

export default usePageAutoJump;

import { ValidationRuleConstraint } from '@tallyforms/lib';
import dynamic from 'next/dynamic';
import { useMemo } from 'react';

import DefaultInput from '@/components/form/input';
import ValidationError from '@/components/form/validation-error';
import { Question } from '@/types/form-builder';

import { Container, Content } from '../styled';

const InputVerifiedEmail = dynamic(() => import('./verified'));

interface Props {
  formId?: string;
  respondentUuid?: string;
  sessionUuid?: string;
  value?: string;
  placeholder?: string;
  isRequired?: boolean;
  requireVerification?: boolean;
  error?: ValidationRuleConstraint;
  question?: Question;
  hasBusinessAccess?: boolean;
  onChange?: (value: string) => void;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
  mode?: 'respond' | 'preview';
}

const InputEmailBlock = ({
  formId,
  respondentUuid,
  sessionUuid,
  value = '',
  placeholder = '',
  isRequired,
  requireVerification,
  error,
  question,
  hasBusinessAccess,
  onChange,
  renderRequiredIndicator,
  mode,
}: Props) => {
  const errorId = error ? `error_${question?.blockGroupUuid}` : undefined;

  const Input = useMemo(() => {
    if (hasBusinessAccess && requireVerification) {
      return InputVerifiedEmail;
    }

    return DefaultInput;
  }, [requireVerification, hasBusinessAccess]);

  return (
    <Container>
      <Content>
        <Input
          id={question?.blockGroupUuid}
          type="email"
          value={value}
          placeholder={placeholder}
          required={isRequired}
          autoComplete="email"
          aria-label={question?.title ?? undefined}
          aria-required={isRequired ? 'true' : 'false'}
          aria-invalid={error ? 'true' : 'false'}
          aria-describedby={errorId}
          onChange={(e) => onChange?.(e.target.value)}
          formId={formId}
          respondentUuid={respondentUuid}
          sessionUuid={sessionUuid}
          mode={mode}
        />
        {renderRequiredIndicator && renderRequiredIndicator()}
        {error && <ValidationError id={errorId} error={error} />}
      </Content>
    </Container>
  );
};

export default InputEmailBlock;

import { withDirection } from '@tallyforms/ui';
import styled from 'styled-components';

import { InputContent } from '@/components/layout/form';
import {
  getGroupInputBoxShadow,
  getGroupInputBoxShadowActive,
  getGroupInputBoxShadowActiveFocus,
  getGroupInputBoxShadowFocus,
  getGroupInputBoxShadowHover,
} from '@/utils/color';

export const Legend = styled.div`
  position: absolute;
  display: inline-block;
  max-width: 10px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

export const OptionContainer = styled.div<{
  hasError: boolean;
  isLast: boolean;
  isOtherOptionSelected: boolean;
}>`
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing.sm};

  ${({ theme, hasError, isLast, isOtherOptionSelected }) =>
    hasError &&
    isLast &&
    !isOtherOptionSelected &&
    `
    padding-bottom: 0;

    & + .tally-validation-error {
      padding-bottom: ${theme.spacing.sm};
    }
  `}
`;

export const Option = styled.div<{
  isSelected: boolean;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  hasBadge?: boolean;
}>`
  position: relative;
  display: inline-block;
  padding: 9px ${({ theme }) => theme.spacing.sm};
  font-size: 16px;
  line-height: 1.125;
  box-shadow: ${({ theme }) => theme.color.inputBoxShadow};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.inputBackground};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${({ theme, borderColor }) =>
    `
    @media (hover: hover) {
      &:hover {
        box-shadow: ${
          borderColor ? getGroupInputBoxShadowHover(borderColor) : theme.color.inputBoxShadowHover
        };
      }

      &:focus-within {
        box-shadow: ${
          borderColor ? getGroupInputBoxShadowFocus(borderColor) : theme.color.inputBoxShadowFocus
        };
      }
    }
  `}

  ${({ theme, isSelected, borderColor }) =>
    isSelected &&
    `
    box-shadow: ${
      borderColor ? getGroupInputBoxShadowActive(borderColor) : theme.color.inputBoxShadowActive
    } !important;

    > div > div {
      background-color: ${borderColor ? borderColor : theme.color.accent};
    }
  `}

  ${({ theme, isSelected, borderColor }) =>
    isSelected &&
    `
    @media (hover: hover) {
      &:focus-within {
        box-shadow: ${
          borderColor
            ? getGroupInputBoxShadowActiveFocus(borderColor)
            : theme.color.inputBoxShadowActiveFocus
        } !important;
      }
    }
  `}

  label {
    ${({ theme, hasBadge: badge }) =>
      badge && withDirection('padding-left', theme.spacing.sm, theme.direction)}
  }

  > img {
    max-width: 260px;
    width: 100%;
    margin-top: 8px;
  }

  ${({ textColor, backgroundColor }) =>
    textColor &&
    backgroundColor &&
    `
    background-color: ${backgroundColor};
    box-shadow: ${getGroupInputBoxShadow(textColor)};

    label {
      color: ${textColor};
    }
  `}
`;

export const OptionContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-appearance: radio;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const OtherOptionContainer = styled(InputContent)<{
  hasError: boolean;
}>`
  padding-bottom: ${({ theme }) => theme.spacing.sm};

  ${({ theme, hasError }) =>
    hasError &&
    `
    padding-bottom: 0;

     & + .tally-validation-error {
      padding-bottom: ${theme.spacing.sm};
    }
  `}
`;

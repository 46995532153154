import { useRouter } from 'next/router';
import { useEffect, useReducer } from 'react';

import config from '@/config';
import { getUser } from '@/hooks/use-user';
import { trackPageview } from '@/services/stats';
import {
  CommandMenuContext,
  initialState as cmdkInitialState,
  reducer as cmdkStateReducer,
} from '@/store/command-menu/reducer';
import {
  initialState as preferencesInitialState,
  PreferencesContext,
  reducer as preferencesStateReducer,
} from '@/store/preferences/reducer';
import { initialState, reducer, UserContext } from '@/store/user/reducer';

interface Props {
  Component: any;
  pageProps: any;
}

/**
 * We need this component for the user reducer (useReducer hook) and cannot use it directly in _app.tsx,
 * because it's a class and can't be converted to a function component as we need `componentDidCatch`,
 * which doesn't have a hook alternative ATM
 */
const Page = ({ Component, pageProps }: Props) => {
  const router = useRouter();
  const userReducer = useReducer(reducer, initialState);
  const cmdkReducer = useReducer(cmdkStateReducer, cmdkInitialState);
  const preferencesReducer = useReducer(
    preferencesStateReducer,
    // Get the user preferences from the 'preferences' cookie. If this is the
    // first time the page is loaded, use the preferences from the props passed
    // from the server. If those are missing, the default initial state is used.
    pageProps?.preferences || preferencesInitialState,
  );

  const onPageView = () => {
    // Don't track page view if we're in preview mode (trying out different embeds)
    if (router.query.preview === '1') {
      return;
    }

    let url = window.location.href;

    // Custom domain page view
    if (pageProps?.metaData?.isCustomDomain) {
      url = `${config.get('web.base-url')}/custom-domains/${
        window.location.host
      }${window.location.pathname}${window.location.search}`;
    }

    trackPageview(url);
  };

  const [, dispatch] = userReducer;
  useEffect(() => {
    // Track initial page view
    onPageView();

    // For some pages we don't need to fetch the current user
    if (
      ['/', '/pricing', '/login', '/signup', '/r/[id]', '/embed/[id]', '/popup/[id]'].includes(
        router.pathname,
      ) === false
    ) {
      // Initialize the user
      getUser({ dispatch });
    }

    router.events.on('routeChangeComplete', onPageView);

    return () => {
      router.events.off('routeChangeComplete', onPageView);
    };
  }, []);

  return (
    <UserContext.Provider value={userReducer}>
      <CommandMenuContext.Provider value={cmdkReducer}>
        <PreferencesContext.Provider value={preferencesReducer}>
          <Component {...pageProps} />
        </PreferencesContext.Provider>
      </CommandMenuContext.Provider>
    </UserContext.Provider>
  );
};

export default Page;

import { BlockType } from '@tallyforms/lib';

import { useRequiredIndicatorPosition } from '@/hooks/form-builder/use-required-indicator-position';
import { Question } from '@/types/form-builder';

import { Container, Content, ContentWrapper } from './styled';

interface Props {
  html: string;
  groupType: BlockType;
  uuid: string;
  question?: Question;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
}

const TitleBlock = ({ html, groupType, uuid, question, renderRequiredIndicator }: Props) => {
  useRequiredIndicatorPosition(uuid, question?.isRequired);

  return (
    <Container>
      <ContentWrapper>
        <Content groupType={groupType} dangerouslySetInnerHTML={{ __html: html }} />

        {renderRequiredIndicator &&
          renderRequiredIndicator({
            top: 6,
            left: 0,
            right: 0,
            isHiddenWhileCalculatingPosition: true,
          })}
      </ContentWrapper>
    </Container>
  );
};

export default TitleBlock;

import {
  BlockType,
  CalculatedFieldType,
  FieldType,
  formatNumberToFixedDecimals,
  getDateFormatProps,
  Mention,
  SafeSchemaBlock,
} from '@tallyforms/lib';
import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { waitFor } from '@/utils/flow';
import { convertFormDataToText } from '@/utils/form-respond';
import { safeHtmlEntitiesEncode } from '@/utils/safe-html-schema';

type Props = {
  blocks: SafeSchemaBlock[];
  pageBlocks: SafeSchemaBlock[];
  formData: { [key: string]: any };
  formMetaData: { [key: string]: any };
  containerRef?: MutableRefObject<HTMLElement | null>;
};

function useMentions({ blocks, pageBlocks, formData, formMetaData, containerRef }: Props) {
  const { t } = useTranslation();
  const mentions: Mention[] = blocks[0]?.payload?.mentions ?? [];
  const canUpdateHtmlTitle = useRef(false);

  // If we have date mentions, get the date format props
  const dateFormatProps = useMemo(() => {
    if (t && mentions.find((x) => x.field.questionType === BlockType.InputDate)) {
      return getDateFormatProps({ t });
    }

    return undefined;
  }, []);

  const updateMentions = () => {
    if (mentions.length === 0) {
      return;
    }

    for (const { uuid, field, defaultValue } of mentions) {
      const mentionEls = (containerRef?.current ?? document).querySelectorAll(
        `[data-uuid="${uuid}"]`,
      );
      if (mentionEls.length === 0 || !mentionEls.forEach) {
        continue;
      }

      const { uuid: fieldUuid, type, questionType, blockGroupUuid, calculatedFieldType } = field;

      let data: any = '';
      if (type === FieldType.InputField && questionType && blockGroupUuid) {
        data = convertFormDataToText(questionType, formData[blockGroupUuid], blocks, {
          fieldUuid,
          dateFormatProps,
          t,
        });
      } else if (
        [FieldType.CalculatedField, FieldType.HiddenField].includes(type) &&
        fieldUuid &&
        blockGroupUuid
      ) {
        data = formData[blockGroupUuid] ? formData[blockGroupUuid][fieldUuid] : '';
      } else if (type === FieldType.Metadata && fieldUuid) {
        data = formMetaData[fieldUuid] || '';
      }

      // Format number
      if (
        type === FieldType.CalculatedField &&
        calculatedFieldType === CalculatedFieldType.Number &&
        typeof data === 'number'
      ) {
        data = formatNumberToFixedDecimals(data, 2);
      }

      mentionEls.forEach((el) => {
        el.innerHTML = safeHtmlEntitiesEncode(data?.toString() || defaultValue || '');

        // Update the browser title if the mention is in the form title
        if (canUpdateHtmlTitle.current && el.innerHTML && el.closest('h1')) {
          document.title = el.closest('h1')?.textContent?.trim() || '';
        }
      });
    }
  };

  const waitForLazyLoadedMentions = async () => {
    if (mentions.length === 0) {
      return;
    }

    // Do we have page blocks which are lazy loaded and support mentions?
    // Currently only the Matrix block
    if (!pageBlocks.find((x) => x.groupType === BlockType.Matrix)) {
      return;
    }

    // Wait for Matrix to be rendered
    await waitFor(
      () => {
        const numOfChildren = document.querySelector('.tally-block-matrix-row')?.children?.length;

        return typeof numOfChildren === 'number' && numOfChildren > 0;
      },
      100,
      5000,
    );

    updateMentions();
  };

  useEffect(() => {
    canUpdateHtmlTitle.current =
      document.title?.trim() === blocks[0]?.payload?.title?.trim() &&
      document.title?.indexOf('@') !== -1;
  }, []);

  useEffect(() => {
    updateMentions();
    waitForLazyLoadedMentions();
  });
}

export default useMentions;

import { Check, Minus } from 'lucide-react';

import { CheckboxInput, Container } from './styled';

interface Props {
  value: string;
  text?: string;
  isChecked: boolean;
  indeterminate?: boolean;
  isRequired?: boolean;
  name?: string;
  ariaInvalid?: boolean;
  ariaLabel?: string;
  ariaDescribedBy?: string;
  isContainerClickable?: boolean;
  minimalDesign?: boolean;
  align?: 'center' | 'top';
  onToggle?: (value: string) => void;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
}

const Checkbox = ({
  value,
  text,
  isChecked,
  isRequired,
  name,
  ariaInvalid,
  ariaLabel,
  ariaDescribedBy,
  indeterminate = false,
  isContainerClickable = false,
  minimalDesign = false,
  align = 'top',
  onToggle,
  renderRequiredIndicator,
}: Props) => {
  return (
    <Container
      hasText={!!text}
      clickable={isContainerClickable}
      onClick={() => {
        if (isContainerClickable) {
          onToggle?.(value);
        }
      }}
      align={align}>
      <CheckboxInput isChecked={isChecked || indeterminate} minimalDesign={minimalDesign}>
        {isChecked && <Check strokeWidth={3} />}
        {indeterminate && <Minus strokeWidth={3} />}

        <input
          id={`checkbox_${value}`}
          type="checkbox"
          name={name}
          checked={isChecked || indeterminate}
          required={isRequired}
          aria-invalid={ariaInvalid ? 'true' : 'false'}
          aria-label={ariaLabel}
          aria-describedby={ariaDescribedBy}
          onChange={() => onToggle && onToggle(value)}
        />
      </CheckboxInput>
      {text && <label htmlFor={`checkbox_${value}`}>{text}</label>}
      {renderRequiredIndicator?.()}
    </Container>
  );
};

export default Checkbox;

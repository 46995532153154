import { BlockType } from '../types';

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const NO_TITLE_FIELD_BLOCKS = [BlockType.CalculatedFields, BlockType.HiddenFields];

export const NOT_EDITABLE_BLOCKS = [
  BlockType.PageBreak,
  BlockType.Image,
  BlockType.Divider,
  BlockType.Embed,
  BlockType.EmbedVideo,
  BlockType.EmbedAudio,
  BlockType.FileUpload,
  BlockType.LinearScale,
  BlockType.Rating,
  BlockType.Payment,
  BlockType.Signature,
  BlockType.ConditionalLogic,
  BlockType.CalculatedFields,
  BlockType.HiddenFields,
  BlockType.Captcha,
  BlockType.WalletConnect,
  BlockType.Matrix,
  BlockType.RespondentCountry,
];

export const NOT_MENTIONABLE_BLOCKS = [
  BlockType.FileUpload,
  BlockType.Payment,
  BlockType.Signature,
];

export const NOT_ELIGIBLE_BLOCKS_TO_HIDE = [
  BlockType.FormTitle,
  BlockType.PageBreak,
  BlockType.ConditionalLogic,
  BlockType.CalculatedFields,
  BlockType.HiddenFields,
  BlockType.Captcha,
  BlockType.RespondentCountry,
];

export const NO_PREVIEW_BLOCKS = [
  BlockType.ConditionalLogic,
  BlockType.CalculatedFields,
  BlockType.HiddenFields,
  BlockType.RespondentCountry,
];

export const UNSELECTABLE_BLOCKS = [
  BlockType.MultipleChoice,
  BlockType.Dropdown,
  BlockType.LinearScale,
  BlockType.Rating,
];

export const INSERT_MENU_INPUT_FIELD_BLOCKS = [
  BlockType.InputText,
  BlockType.Textarea,
  BlockType.MultipleChoice,
  BlockType.Checkboxes,
  BlockType.Dropdown,
  BlockType.MultiSelect,
  BlockType.InputNumber,
  BlockType.InputEmail,
  BlockType.InputPhoneNumber,
  BlockType.InputLink,
  BlockType.FileUpload,
  BlockType.InputDate,
  BlockType.InputTime,
  BlockType.LinearScale,
  BlockType.Matrix,
  BlockType.Rating,
  BlockType.Payment,
  BlockType.Signature,
  BlockType.Ranking,
  BlockType.WalletConnect,
];

export const INSERT_MENU_LAYOUT_BLOCKS = [
  BlockType.PageBreak,
  BlockType.ThankYouPage,
  BlockType.Text,
  BlockType.Heading1,
  BlockType.Heading2,
  BlockType.Heading3,
  BlockType.Divider,
  BlockType.Title,
  BlockType.Label,
];

export const INSERT_MENU_EMBED_BLOCKS = [
  BlockType.Image,
  BlockType.EmbedVideo,
  BlockType.EmbedAudio,
  BlockType.Embed,
];

export const QUESTIONS_AUTO_JUMP_TO_NEXT_PAGE = [
  BlockType.MultipleChoice,
  BlockType.Dropdown,
  BlockType.Rating,
  BlockType.LinearScale,
];

export const NOT_EDITABLE_QUESTION_TITLES = [
  BlockType.HiddenFields,
  BlockType.CalculatedFields,
  BlockType.Matrix,
];

export const NOT_ELIGIBLE_BLOCKS_TO_MOVE_IN_COLUMN = [
  BlockType.FormTitle,
  BlockType.PageBreak,
  BlockType.ThankYouPage,
  BlockType.ConditionalLogic,
  BlockType.HiddenFields,
  BlockType.CalculatedFields,
  BlockType.Matrix,
  BlockType.MatrixRow,
  BlockType.MatrixColumn,
  BlockType.RespondentCountry,
];

export const MATRIX_BLOCKS = [BlockType.Matrix, BlockType.MatrixRow, BlockType.MatrixColumn];

export const EMPTY_QUESTION_TITLES: { [key: string]: string } = {
  [BlockType.InputText]: 'Untitled short answer field',
  [BlockType.Textarea]: 'Untitled long answer field',
  [BlockType.InputLink]: 'Untitled link field',
  [BlockType.InputEmail]: 'Untitled email field',
  [BlockType.InputNumber]: 'Untitled number field',
  [BlockType.InputPhoneNumber]: 'Untitled phone number field',
  [BlockType.InputDate]: 'Untitled date field',
  [BlockType.InputTime]: 'Untitled time field',
  [BlockType.MultipleChoice]: 'Untitled multiple choice field',
  [BlockType.Checkboxes]: 'Untitled checkboxes field',
  [BlockType.Dropdown]: 'Untitled dropdown field',
  [BlockType.FileUpload]: 'Untitled file upload field',
  [BlockType.LinearScale]: 'Untitled linear scale field',
  [BlockType.Rating]: 'Untitled rating field',
  [BlockType.Ranking]: 'Untitled ranking field',
  [BlockType.MultiSelect]: 'Untitled multi-select field',
  [BlockType.Payment]: 'Untitled payment',
  [BlockType.Signature]: 'Untitled signature',
  [BlockType.Matrix]: 'Untitled matrix field',
  [BlockType.HiddenFields]: 'Hidden fields',
  [BlockType.CalculatedFields]: 'Calculated fields',
  [BlockType.WalletConnect]: 'Untitled Wallet Connect field',
  [BlockType.RespondentCountry]: "Respondent's country",
};

export const NOT_ELIGIBLE_BLOCKS_TO_SELECT = [
  BlockType.FormTitle,
  BlockType.MatrixRow,
  BlockType.MatrixColumn,
];

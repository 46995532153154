import { Frown, LucideIcon } from 'lucide-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Description, Title } from './styled';

interface Props {
  title?: string;
  description?: string;
  icon?: LucideIcon;
  button?: ReactNode;
}

const Error = ({ title, description, icon, button }: Props) => {
  const { t } = useTranslation();

  const Icon = icon ?? Frown;

  return (
    <Container>
      <Icon size={56} />
      <Title>{title ?? t('error.title')}</Title>
      <Description hasButton={!!button}>{description ?? t('error.description')}</Description>
      {button}
    </Container>
  );
};

export default Error;

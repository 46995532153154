import { useEffect, useMemo, useState } from 'react';

import { getFormSession } from '@/utils/form-respond';

function useFormSession(formId: string) {
  const [sessionChange, setSessionChange] = useState(1);
  const sessionUuid = useMemo(() => getFormSession(formId), [sessionChange]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      // If the storage key is related to the form session, update the `sessionChange` state so we can generate a new session UUID
      if (event.key?.includes('FORM_SESSION_')) {
        setSessionChange((prev) => prev + 1);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return sessionUuid;
}

export default useFormSession;

import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type LinearScaleBlock = Block<
  BlockType.LinearScale,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | number;
    start?: number;
    end?: number;
    step?: number;
    hasLeftLabel?: boolean;
    leftLabel?: string;
    hasCenterLabel?: boolean;
    centerLabel?: string;
    hasRightLabel?: boolean;
    rightLabel?: string;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isLinearScaleBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<LinearScaleBlock>;

export function isLinearScaleBlock(block: RespondBlock): block is RespondBlock<LinearScaleBlock>;

export function isLinearScaleBlock(block: Block): block is LinearScaleBlock {
  return block.type === BlockType.LinearScale;
}

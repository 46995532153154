import { LocalStorage } from '@tallyforms/lib';

const PHONE_NUMBER_STORAGE_KEY = 'PHONE_NUMBER_INPUT';

interface PhoneNumberStorage {
  lastCountryCode?: string | null;
  keys?: {
    [key: string]: {
      value: string;
      countryCode: string | null;
    };
  };
}

export const updatePhoneNumberStorage = (
  key: string,
  value: string,
  countryCode: string | null,
) => {
  try {
    let data: PhoneNumberStorage = {};

    // Get existing data
    const payload = LocalStorage.get(PHONE_NUMBER_STORAGE_KEY);
    if (payload) {
      data = JSON.parse(payload);
    }

    // Update data
    if (!data.keys) {
      data.keys = {};
    }

    data.lastCountryCode = countryCode || data.lastCountryCode || null;

    if (value || countryCode) {
      data.keys[key] = { value, countryCode };
    } else {
      delete data.keys[key];
    }

    localStorage.setItem(PHONE_NUMBER_STORAGE_KEY, JSON.stringify(data));
  } catch (_e) {
    // Ignore
  }
};

export const getCountryCodeFromPhoneNumberStorage = (
  key: string,
  value: string | undefined,
  defaultCountryCode: string | undefined,
): string | null => {
  try {
    let data: PhoneNumberStorage = {};

    const payload = LocalStorage.get(PHONE_NUMBER_STORAGE_KEY);
    if (payload) {
      data = JSON.parse(payload);

      if (!value && !defaultCountryCode) {
        if (data.lastCountryCode) {
          return data.lastCountryCode;
        }
      }

      if (
        data.keys &&
        data.keys[key] &&
        value === data.keys[key].value &&
        data.keys[key].countryCode
      ) {
        return data.keys[key].countryCode;
      }
    }
  } catch (_e) {
    // Ignore
  }

  return null;
};

export const cleanupKeysFromPhoneNumberStorage = (keys: string[]) => {
  try {
    const payload = LocalStorage.get(PHONE_NUMBER_STORAGE_KEY);
    if (!payload) {
      return;
    }

    const data: PhoneNumberStorage = JSON.parse(payload);
    if (!data.keys) {
      return;
    }

    let hasChanges = false;
    for (const key of keys) {
      if (data.keys[key]) {
        delete data.keys[key];
        hasChanges = true;
      }
    }

    if (Object.keys(data.keys).length === 0) {
      delete data.keys;
      hasChanges = true;
    }

    if (hasChanges) {
      localStorage.setItem(PHONE_NUMBER_STORAGE_KEY, JSON.stringify(data));
    }
  } catch (_e) {
    // Ignore
  }
};

export const getFormIdOrUrlFromEmail = (
  emailTextContent: string,
): { formIds: string[]; customDomains: string[] } => {
  const formIds: string[] = [];
  const customDomains: string[] = [];

  const words = emailTextContent.split(/\s+/);
  for (const word of words) {
    // Tally URL
    if (word.includes('tally.so')) {
      const matches = word.match(/\/(?:r|embed|popup|forms)\/([a-zA-Z0-9]+)/);
      if (matches === null) {
        continue;
      }

      if (matches[1]) {
        formIds.push(matches[1]);
      }
    } else if (word.startsWith('http')) {
      customDomains.push(word);
    }
  }

  return { formIds, customDomains };
};

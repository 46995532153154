import { withDirection } from '@tallyforms/ui';
import styled from 'styled-components';

export const Container = styled.div<{ hasText: boolean; clickable?: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;

  ${({ hasText }) =>
    hasText &&
    `
    flex: 1;
    width: 100%;
  `}

  label {
    ${({ theme }) => withDirection('padding-left', theme.spacing.sm, theme.direction)}
  }

  ${({ clickable }) => clickable && `cursor: pointer;`}
`;

export const RadioInput = styled.div<{
  isChecked: boolean;
  minimalDesign: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  background-color: ${({ theme }) => theme.color.inputBackground};
  box-shadow: ${({ theme }) => theme.color.inputBoxShadow};
  border-radius: 50%;
  -webkit-tap-highlight-color: transparent;

  input {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    -webkit-appearance: radio;
    opacity: 0;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
  }

  ${({ theme }) =>
    `
    @media (hover: hover) {
      &:hover {
        box-shadow: ${theme.color.inputBoxShadowHover};
      }
    }

    &:focus-within {
      box-shadow: ${theme.color.inputBoxShadowActiveFocus};
    }
  `}

  ${({ theme, isChecked }) =>
    isChecked &&
    `
    box-shadow: ${theme.color.inputBoxShadowActive} !important;
    background-color: ${theme.color.accent};

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${theme.color.background};
    }

  `}

  ${({ theme, isChecked }) =>
    isChecked &&
    `
    @media (hover: hover) {
      &:focus-within {
        box-shadow: ${theme.color.inputBoxShadowActiveFocus} !important;
      }
    }
  `}

  ${({ theme, minimalDesign }) =>
    minimalDesign &&
    `
    box-shadow: none !important;
    border: 1px solid ${theme.color.textGrayscale5};
  `}

  ${({ theme, minimalDesign }) =>
    minimalDesign &&
    `
    @media (hover: hover) {
      &:focus-within {
        box-shadow: ${theme.color.inputBoxShadowFocus} !important;
      }
    }
  `}

  ${({ theme, isChecked, minimalDesign }) =>
    isChecked &&
    minimalDesign &&
    `
    border: 1px solid ${theme.color.accent};
  `}

  ${({ theme, isChecked, minimalDesign }) =>
    isChecked &&
    minimalDesign &&
    `
    @media (hover: hover) {
      &:focus-within {
        box-shadow: ${theme.color.inputBoxShadowActiveFocus} !important;
      }
    }
  `}
`;

import { Currency } from '../../types';

export const getCurrencyPrefix = (currency: string): string | null => {
  switch (currency) {
    case Currency.BRL:
      return 'R$ ';
    case Currency.EUR:
      return '€';
    case Currency.GBP:
      return '£';
    case Currency.HKD:
      return 'HK$ ';
    case Currency.ILS:
      return '₪ ';
    case Currency.INR:
      return '₹ ';
    case Currency.KRW:
      return '₩ ';
    case Currency.MYR:
      return 'RM ';
    case Currency.PHP:
      return '₱ ';
    case Currency.TRY:
      return '₺ ';
    case Currency.TWD:
      return '元 ';
    case Currency.USD:
      return '$';
  }

  return null;
};

import { AllowedFiles, getLanguage, Language, SelectGroup } from '@tallyforms/lib';

import config from '@/config';

const fileMimeTypes: {
  [key: string]: {
    type: string;
    otherMimeTypes?: string[];
  }[];
} = {
  image: [
    { type: '.jpg' },
    { type: '.jpeg' },
    { type: '.png' },
    { type: '.gif' },
    { type: '.svg' },
    { type: '.heic' },
    { type: '.webp' },
    { type: '.bmp' },
    { type: '.psd' },
  ],
  application: [
    { type: '.pdf' },
    { type: '.doc' },
    { type: '.docx' },
    { type: '.xls' },
    { type: '.xlsx' },
    { type: '.ppt' },
    { type: '.pptx' },
    { type: '.zip' },
    { type: '.rar' },
    { type: '.json' },
    { type: '.gzip' },
    { type: '.odt' },
  ],
  text: [
    { type: '.txt' },
    { type: '.csv', otherMimeTypes: ['application'] },
    { type: '.html' },
    { type: '.xml' },
  ],
  video: [{ type: '.mp4' }, { type: '.mov' }, { type: '.webm' }],
  audio: [{ type: '.mp3' }, { type: '.wav' }, { type: '.mp4' }, { type: '.m4a' }],
};

// not by any means complete, only added the extensions that were relevant
export const mimeTypeFromFile = (path: string) => {
  const extension = path.split('.').pop();

  switch (extension) {
    case 'png':
      return 'image/png';
    case 'jpg':
      return 'image/jpeg';
    case 'jpeg':
      return 'image/jpeg';
    case 'gif':
      return 'image/gif';
    case 'svg':
      return 'image/svg+xml';
    case 'webp':
      return 'image/webp';
    case 'bmp':
      return 'image/bmp';
    case 'ico':
      return 'image/x-icon';
  }

  return null;
};

export const getAllowedFilesGroups = (t: any): SelectGroup[] => [
  {
    label: t('label.image'),
    options: [
      { text: t('label.all-image-files'), value: 'image/*' },
      ...fileMimeTypes.image.map(({ type }) => ({
        text: type,
        value: type,
      })),
    ],
  },
  {
    label: t('label.video'),
    options: [
      { text: t('label.all-video-files'), value: 'video/*' },
      ...fileMimeTypes.video.map(({ type }) => ({
        text: type,
        value: type,
      })),
    ],
  },
  {
    label: t('label.audio'),
    options: [
      { text: t('label.all-audio-files'), value: 'audio/*' },
      ...fileMimeTypes.audio.map(({ type }) => ({
        text: type,
        value: type,
      })),
    ],
  },
  {
    label: t('label.text'),
    options: fileMimeTypes.text.map(({ type }) => ({
      text: type,
      value: type,
    })),
  },
  {
    label: t('label.application'),
    options: fileMimeTypes.application.map(({ type }) => ({
      text: type,
      value: type,
    })),
  },
];

export const transformAllowedFilesToSelectValues = (values: AllowedFiles): string[] => {
  if (!values) {
    return [];
  }

  const result: string[] = [];
  Object.keys(values).forEach((key) => {
    const types = values[key];

    if (types.includes('*') && types.length === 1) {
      return result.push(`${key}`);
    }

    const cleanTypes = types.filter((type) => type !== '*');

    cleanTypes.forEach((type) => {
      if (!result.includes(type)) {
        result.push(type);
      }
    });
  });

  return result;
};

export const transformSelectValuesToAllowedFiles = (values: string[]): AllowedFiles => {
  const result: AllowedFiles = {};

  values.forEach((value, index) => {
    // Get keys from FileMimeType
    const fileMimes = Object.keys(fileMimeTypes);
    const keys = fileMimes.filter((key) =>
      fileMimeTypes[key].map(({ type }) => type).includes(value),
    );

    if (value.includes('*')) {
      const [key] = value.split('/');
      const typeKey = `${key}/*`;
      const fileTypes = fileMimeTypes[key].map(({ type }) => type);

      const similarTypes = values.filter((val) => fileTypes.includes(val));

      if (similarTypes.length === 0) {
        result[typeKey] = ['*'];
        return;
      }

      // Checking if it's the last value
      let lastSimilarValueIndex = 0;
      values.forEach((type, i) => {
        if (similarTypes.includes(type) && i > lastSimilarValueIndex) {
          lastSimilarValueIndex = i;
        }
      });

      if (index > lastSimilarValueIndex) {
        result[typeKey] = ['*'];
        return;
      }

      // We have more than one value and it's not the last selected one
      return;
    }

    keys.forEach((key) => {
      const typeKey = `${key}/*`;

      if (result[typeKey] && result[typeKey].includes('*')) {
        return;
      }

      const otherMimeType = fileMimeTypes[key].find(({ type }) => type === value)?.otherMimeTypes;

      if (otherMimeType) {
        otherMimeType.forEach((type) => {
          const similarTypKey = `${type}/*`;

          if (result[similarTypKey]) {
            result[similarTypKey].push(value);
          } else {
            result[similarTypKey] = [value];
          }
        });
      }

      if (result[typeKey]) {
        result[typeKey].push(value);
      } else {
        result[typeKey] = [value];
      }
    });
  });
  return result;
};

export const convertFileSizeToBytes = (size: number, unit: string): number => {
  if (!size || !unit) {
    return 0;
  }

  let sizeNumber = size;
  if (unit === 'KB') {
    sizeNumber = sizeNumber * 1024;
  } else if (unit === 'MB') {
    sizeNumber = sizeNumber * 1024 * 1024;
  } else if (unit === 'GB') {
    sizeNumber = sizeNumber * 1024 * 1024 * 1024;
  }

  return sizeNumber;
};

export const convertBytesToFileSize = (size: number, unit: string): number => {
  if (!size || !unit) {
    return 0;
  }

  let sizeNumber = size;
  if (unit === 'KB') {
    sizeNumber = sizeNumber / 1024;
  } else if (unit === 'MB') {
    sizeNumber = sizeNumber / 1024 / 1024;
  } else if (unit === 'GB') {
    sizeNumber = sizeNumber / 1024 / 1024 / 1024;
  }

  return sizeNumber;
};

export const MAX_FILE_SIZE = convertFileSizeToBytes(
  config.get('file-upload.max-file-size'),
  config.get('file-upload.max-file-size-unit'),
);

export const getAllowedFilesDescription = (values: AllowedFiles, t: any): string => {
  if (typeof values === 'undefined' || Object.keys(values).length === 0) {
    return '';
  }

  const language = getLanguage();
  const description: string[] = [];
  Object.keys(values).forEach((key) => {
    const types = values[key];
    const typeKey = key.replace('/*', '').trim();

    if (types.includes('*') && types.length === 1) {
      // German requires capitals for nouns
      if ([Language.German].includes(language)) {
        return description.push(t(`label.${typeKey}`));
      }

      return description.push(t(`label.${typeKey}`).toLowerCase());
    }

    description.push(...types);
  });

  // Clear similar values from description array
  const filteredValues = description.filter((value, index) => description.indexOf(value) === index);

  if (filteredValues.length === 1) {
    return t('form-builder.file-upload.accepted-file-types', {
      types: filteredValues[0],
    });
  }

  return t('form-builder.file-upload.accepted-file-types', {
    types: filteredValues.join(', '),
  });
};

export const getMaxFileSize = (
  hasMaxFileSize: boolean,
  maxFileSize: number | undefined,
  maxFileSizeUnit: string | undefined,
  hasProAccess: boolean,
): number | undefined => {
  const defaultMaxFileSize = config.get('file-upload.max-file-size');
  const defaultMaxFileSizeUnit = config.get('file-upload.max-file-size-unit');

  if (hasMaxFileSize && maxFileSize) {
    // User can set their own size limit freely
    if (hasProAccess) {
      return maxFileSize;
    }

    // User can only set 10 MB limit if not subscribed
    const fileSizeInBytes = convertFileSizeToBytes(
      maxFileSize,
      maxFileSizeUnit ?? defaultMaxFileSizeUnit,
    );
    if (fileSizeInBytes > MAX_FILE_SIZE) {
      return defaultMaxFileSize;
    }

    // User is within the limit
    return maxFileSize;
  }

  // User didn't set a limit, so if not subscribed we return the limit
  if (!hasProAccess) {
    return defaultMaxFileSize;
  }

  // User is subscribed and didn't set a limit, so no limit is set
  return undefined;
};

export const getMaxFileSizeUnit = (
  hasMaxFileSizeUnit: boolean,
  maxFileSize: number | undefined,
  maxFileSizeUnit: string | undefined,
  hasProAccess: boolean,
): string | undefined => {
  const defaultMaxFileSizeUnit = config.get('file-upload.max-file-size-unit');

  if (hasMaxFileSizeUnit && maxFileSizeUnit && maxFileSize) {
    // User can choose any unit ('KB', 'MB', 'GB')
    if (hasProAccess) {
      return maxFileSizeUnit;
    }

    // User can only choose 'KB' or 'MB' if not subscribed
    if (maxFileSizeUnit === 'GB') {
      return defaultMaxFileSizeUnit;
    }

    // User is within the limit
    return maxFileSizeUnit;
  }

  // User didn't set a unit, so if not subscribed we return the configured unit
  if (!hasProAccess) {
    return defaultMaxFileSizeUnit;
  }

  // User is subscribed and didn't set a unit, so no unit is set
  return undefined;
};

import {
  BlockType,
  Country,
  FormData,
  hasProAccess,
  Language,
  SafeSchemaBlock,
  stripHtml,
  SubscriptionPlan,
  ValidationRule,
} from '@tallyforms/lib';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { ArrowLeft, ArrowRight, EyeOff, ShieldOff } from 'lucide-react';
import dynamic from 'next/dynamic';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Button from '@/components/button/new';
import Error from '@/components/error';
import FormError from '@/components/form/error';
import FormBlocks from '@/components/form-respond/blocks';
import FormCover from '@/components/form-respond/cover';
import FormLogo from '@/components/form-respond/logo';
import ProgressBar from '@/components/form-respond/progress-bar';
import ThankYouPage from '@/components/form-respond/thank-you-page';
import Layout from '@/components/layout/respond';
import config from '@/config';
import useCustomDomainLinks from '@/hooks/form-respond/use-custom-domain-links';
import useFormData from '@/hooks/form-respond/use-form-data';
import useFormSession from '@/hooks/form-respond/use-form-session';
import useFormSubmitValidation from '@/hooks/form-respond/use-form-submit-validation';
import useMentions from '@/hooks/form-respond/use-mentions';
import usePageAutoJump from '@/hooks/form-respond/use-page-auto-jump';
import usePageTransition from '@/hooks/form-respond/use-page-transition';
import useBeforeUnload from '@/hooks/use-before-unload';
import api from '@/services/api';
import {
  dispatchCustomEvent as dispatchCustomEventFacebookPixel,
  isLoaded as isLoadedFacebookPixel,
} from '@/services/fb';
import {
  dispatchCustomEvent as dispatchCustomEventGoogleAnalytics,
  isLoaded as isLoadedGoogleAnalytics,
} from '@/services/ga';
import { captureException, captureMessage } from '@/services/sentry';
import { FormSettings } from '@/types/form-builder';
import { FormStyles } from '@/types/form-design';
import { MetaData } from '@/types/html';
import { Integration } from '@/types/integration';
import { inIframe, isMobile, postMessageToParentWindow } from '@/utils/device';
import { mimeTypeFromFile } from '@/utils/files';
import { sleep, waitFor } from '@/utils/flow';
import {
  focusOnFirstFormInput,
  getCalculatedFieldsFormData,
  getFormSubmittedEventPayload,
  getPageSettings,
  getRespondentUuid,
  removeFormSession,
  removeNotSubmittedFormData,
} from '@/utils/form-respond';
import { transformForRespond, transformToPages } from '@/utils/form-respond/block-transformer';
import { cleanupKeysFromPhoneNumberStorage } from '@/utils/phone-number/storage';
import { getRedirectOnCompletionURL } from '@/utils/safe-html-schema';
import { dispatchTallyEvent, TallyEvent } from '@/utils/tally-event';

import {
  BackButtonContainer,
  ButtonsContainer,
  Content,
  Disclaimer,
  PoweredByContainer,
} from './styled';

const UnlockForm = dynamic(() => import('./unlock-form'), { ssr: true });
const PoweredByBadge = dynamic(() => import('@/components/form-respond/powered-by-badge'), {
  ssr: false,
});

let isCompleted = false;
let isFirstPartialSubmissionRun = true;
let isSavingPartialSubmission = false;
let partialSubmissionFormData: FormData = {};
let lastSavedPartialSubmissionFormData: FormData = {};

interface Props {
  formId: string;
  workspaceId: string;
  name: string;
  blocks: SafeSchemaBlock[];
  isClosed: boolean;
  isPreview: boolean;
  settings: Partial<FormSettings & { isPasswordProtected: boolean }> | null;
  metaData: MetaData;
  integrations: Integration[];
  styles: FormStyles;
  subscriptionPlan: SubscriptionPlan;
  country?: Country | null;
  hasError?: boolean;
  isNotFoundError?: boolean;
}

const FormRespond = ({
  formId,
  workspaceId,
  name,
  blocks,
  isClosed,
  isPreview,
  settings,
  metaData,
  integrations,
  styles,
  subscriptionPlan,
  hasError,
  isNotFoundError,
  country,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isDefaultThankYouPage, setIsDefaultThankYouPage] = useState(false);
  const [errorException, setErrorException] = useState<any>();
  const [page, setPage] = useState(1);
  const [pageHistory, setPageHistory] = useState([1]);
  const [isLoading, setIsLoading] = useState(false);
  const [captchaData, setCaptchaData] = useState<FormData>({});
  const [formMetaData, setFormMetaData] = useState<FormData>({
    formName: name,
  });
  const [hasUniqueSubmissionConflictError, setHasUniqueSubmissionConflictError] = useState(false);

  const pages = useMemo(() => transformToPages(transformForRespond(blocks)), []);
  const sessionUuid = useFormSession(formId);
  const respondentUuid = useMemo(() => getRespondentUuid(workspaceId), []);

  const { formData, onChangeFormData } = useFormData({
    mode: 'respond',
    formId,
    blocks,
    settings,
    page,
    pages,
    pageHistory,
    country,
  });

  const {
    buttonLabel,
    canAutoJumpToNextPage,
    cover,
    coverSettings,
    isCustomThankYouPage,
    isLastInputPage,
    logo,
    nextPage,
    pageBlocks,
    pageFilledIn,
    pageQuestions,
    showBackButton,
    showMaliciousFormWarning,
    showSubmitButton,
  } = getPageSettings(page, pages, blocks, settings, formData, onChangeFormData);

  const { formErrors, isPaying, showPayDisclaimer, onSubmit, setFormErrors } =
    useFormSubmitValidation(
      { ...formData, ...captchaData },
      pageBlocks,
      pageQuestions,
      (paymentFormData) => onPaid(paymentFormData),
      () => onContinue(),
    );

  const startPageTransition = usePageTransition(page);

  // Meta data
  metaData = {
    ...metaData,
    isFormRespondPage: true,
    isFormEmbedded: inIframe(),
    // The title is used for the document <title> tag.
    // We don't want to update it on each render, since it is being kept in sync by the useMentions() hook.
    // So we use the document.title if it is already set.
    title:
      typeof document !== 'undefined' && document.title
        ? document.title
        : metaData.title || name || t('title.form'),
    description: metaData.description || (!styles.removeBranding ? t('description.respond') : ''),
    imageUrl: metaData.imageUrl || cover || '',
    font: settings?.styles?.font || undefined,
    language: (settings?.language ?? Language.English) as Language,
    doNotTrack: isPreview, // Do Not Track form previews
    faviconType: metaData.faviconUrl ? mimeTypeFromFile(metaData.faviconUrl) : null,
  };

  // Styles
  styles.withMedia = !!logo || !!cover;

  const showPoweredByBadge = !isDefaultThankYouPage && !styles.removeBranding;

  const buttonText: string = buttonLabel ?? (isLastInputPage ? t('label.submit') : t('label.next'));

  const isPasswordProtected = !!settings?.isPasswordProtected;
  const [password, setPassword] = useState('');

  const triggerPageViewEvent = () => {
    // Custom event
    dispatchTallyEvent(TallyEvent.TallyFormPageView, { formId, page });

    if (inIframe()) {
      postMessageToParentWindow({
        event: TallyEvent.TallyFormPageView,
        payload: { formId, page },
      });
    }

    // GA
    if (isLoadedGoogleAnalytics()) {
      dispatchCustomEventGoogleAnalytics(TallyEvent.TallyFormPageView, {
        event_category: window.location.pathname,
        event_label: `Page ${page}`,
      });
    }

    // FB pixel
    if (isLoadedFacebookPixel()) {
      dispatchCustomEventFacebookPixel(TallyEvent.TallyFormPageView, {
        content_category: window.location.pathname,
        value: page,
      });
    }
  };

  const triggerSubmitEvent = (submissionId: string, respondentId: string) => {
    const payload = getFormSubmittedEventPayload(
      submissionId,
      respondentId,
      formId,
      name,
      blocks,
      formData,
    );

    // Custom event
    dispatchTallyEvent(TallyEvent.TallyFormSubmitted, payload);

    if (inIframe()) {
      postMessageToParentWindow({
        event: TallyEvent.TallyFormSubmitted,
        payload,
      });
    }

    // GA
    if (isLoadedGoogleAnalytics()) {
      dispatchCustomEventGoogleAnalytics(TallyEvent.TallyFormSubmitted, {
        event_category: window.location.pathname,
        event_label: name,
      });
    }

    // FB pixel
    if (isLoadedFacebookPixel()) {
      dispatchCustomEventFacebookPixel(TallyEvent.TallyFormSubmitted, {
        content_category: window.location.pathname,
        content_name: name,
      });
    }
  };

  const removeCaptchaDataOnPage = (pageNumber: number) => {
    const specificPageBlocks = pages[pageNumber - 1];
    if (!specificPageBlocks) {
      return;
    }

    const groupUuids = specificPageBlocks
      .filter((x) => x.type === BlockType.Captcha)
      .map((x) => x.groupUuid);

    // No captcha on this page
    if (groupUuids.length === 0) {
      return;
    }

    // Remove captcha data for the specific blocks
    let captchaDataChanged = false;
    const newCaptchaData = { ...captchaData };

    for (const groupUuid of groupUuids) {
      if (newCaptchaData[groupUuid]) {
        // Reset the captcha
        dispatchTallyEvent(TallyEvent.CaptchaReset, {
          response: newCaptchaData[groupUuid].response,
        });

        delete newCaptchaData[groupUuid];
        captchaDataChanged = true;
      }
    }

    if (captchaDataChanged) {
      setCaptchaData(newCaptchaData);
    }
  };

  const goToPage = (newPage: number) => {
    startPageTransition();

    setPage(newPage);

    // Scroll to top
    window?.scrollTo(0, 0);
  };

  const { isAutoJumping, setIsAutoJumping, setLastChangedGroupUuid } = usePageAutoJump(
    formData,
    pageBlocks,
    canAutoJumpToNextPage,
    pageFilledIn,
    () => onContinue(),
  );

  const onBack = () => {
    // Stop auto-jump if in process
    if (isAutoJumping) {
      setIsAutoJumping(false);
    }

    if (isDefaultThankYouPage) {
      setIsDefaultThankYouPage(false);
      return;
    }

    const previousPage = pageHistory[pageHistory.length - 2];
    if (previousPage) {
      setPageHistory(pageHistory.slice(0, -1));
      goToPage(previousPage);
    }
  };

  const goToPageWithBlockGroup = (groupUuid: string | undefined): number | null => {
    let pageNumber = 0;
    for (const pageBlocks of pages) {
      pageNumber++;

      if (pageBlocks.find((x) => x.groupUuid === groupUuid)) {
        const pageIndex = pageHistory.indexOf(pageNumber);
        if (pageIndex !== -1) {
          setPageHistory(pageHistory.slice(0, pageIndex + 1));
        }

        goToPage(pageNumber);
        return pageNumber;
      }
    }

    return null;
  };

  const savePartialSubmission = async () => {
    if (isPreview) {
      return;
    }

    if (isSavingPartialSubmission) {
      return;
    }

    if (isEqual(partialSubmissionFormData, lastSavedPartialSubmissionFormData)) {
      return;
    }

    // Make a snapshot of the current partial submission data, so if another call comes in before this one finishes, we don't lose the data
    isSavingPartialSubmission = true;
    const toBeSavedPartialSubmissionFormData = { ...partialSubmissionFormData };

    try {
      await api.post(`/forms/${formId}/respond`, {
        sessionUuid,
        respondentUuid,
        responses: toBeSavedPartialSubmissionFormData,
        isCompleted: false,
        password,
      });
    } catch (_e) {
      // Do nothing
    }

    isFirstPartialSubmissionRun = false;
    isSavingPartialSubmission = false;
    lastSavedPartialSubmissionFormData = {
      ...toBeSavedPartialSubmissionFormData,
    };

    // Remove saved partial submission data
    for (const key in toBeSavedPartialSubmissionFormData) {
      delete partialSubmissionFormData[key];
    }

    // If there is still data left, save it
    if (Object.keys(partialSubmissionFormData).length > 0) {
      savePartialSubmission();
    }
  };
  const debouncedSavePartialSubmission = useRef(debounce(savePartialSubmission, 2000)).current;

  const saveSubmission = async (
    retry = 0,
  ): Promise<{ submissionId?: string; respondentId?: string; error?: any }> => {
    try {
      // Save responses
      const response = await api.post(`/forms/${formId}/respond`, {
        sessionUuid,
        respondentUuid,
        responses: formData,
        captchas: captchaData,
        isCompleted: true,
        password,
      });
      const { submissionId, respondentId } = response.data;

      // Mark the form as completed
      isCompleted = true;

      return { submissionId, respondentId };
    } catch (error) {
      const e = error as any;
      // Captcha error or unique submission conflict error
      if (
        e?.response?.data?.errorType === 'INVALID_CAPTCHA' ||
        e?.response?.data?.errorType === 'FORM_UNIQUE_SUBMISSION_CONFLICT'
      ) {
        return { submissionId: undefined, respondentId: undefined, error };
      }

      // Unknown error, retry
      if (retry >= 3) {
        return { submissionId: undefined, respondentId: undefined, error };
      }

      // Wait a bit before retrying
      await sleep((retry + 1) * 5000);

      return await saveSubmission(retry + 1);
    }
  };

  const onPaid = async (paymentFormData: FormData) => {
    // Bulk change formData so all payments are saved correctly
    onChangeFormData(paymentFormData);

    partialSubmissionFormData = {
      ...partialSubmissionFormData,
      ...paymentFormData,
    };
    savePartialSubmission();
  };

  const onComplete = async () => {
    if (isPreview) {
      isCompleted = true;
      return;
    }

    // If we are already submitting, don't do anything
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setErrorException(undefined);

    // Invoke all pending partial submission requests if any
    if (debouncedSavePartialSubmission) {
      await debouncedSavePartialSubmission.flush();
      await waitFor(() => !isSavingPartialSubmission);
    }

    const { submissionId, respondentId, error } = await saveSubmission();
    setFormMetaData((prev) => ({ ...prev, id: submissionId, respondentId }));

    // Something went wrong
    if (error) {
      // If it's a captcha error, find the captcha block, jump to that page and set an error
      if (error?.response?.data?.errorType === 'INVALID_CAPTCHA') {
        try {
          const captchaBlockGroupUuid = error.response.data.errors[0].field;
          const jumpedToPage = goToPageWithBlockGroup(captchaBlockGroupUuid);
          if (jumpedToPage !== null) {
            setFormErrors({
              [captchaBlockGroupUuid]: [ValidationRule.ShouldSolveCaptcha],
            });
            removeCaptchaDataOnPage(jumpedToPage);
            setIsLoading(false);
            return;
          }
        } catch (e) {
          captureException(e);
        }
      }

      // If it's a unique submission conflict, show the appropriate error screen
      if (error?.response?.data?.errorType === 'FORM_UNIQUE_SUBMISSION_CONFLICT') {
        setHasUniqueSubmissionConflictError(true);
        setIsLoading(false);
        return;
      }

      setErrorException(error);
      captureException(error);
      captureMessage('Form submit: error', {
        extra: {
          formId,
          sessionUuid,
          respondentUuid,
          formData: JSON.stringify(formData ?? {}),
          isFormEmbedded: metaData.isFormEmbedded,
        },
      });

      // If we are on a Thank you page, go back
      onBack();
      setIsLoading(false);
      return;
    }

    if (!submissionId || !respondentId) {
      captureMessage('Form submit: missing submissionId', {
        extra: {
          formId,
          sessionUuid,
          respondentUuid,
          formData: JSON.stringify(formData ?? {}),
          isFormEmbedded: metaData.isFormEmbedded,
        },
      });

      // If we are on a Thank you page, go back
      onBack();
      setIsLoading(false);
      return;
    }

    try {
      // Dispatch form submission event
      triggerSubmitEvent(submissionId, respondentId);

      // Remove the form session
      removeFormSession(formId);

      // Remove not-submitted form data
      removeNotSubmittedFormData(formId);

      // Cleanup potential phone number input storage
      cleanupKeysFromPhoneNumberStorage(Object.keys(formData));
    } catch (e) {
      captureException(e);
      captureMessage('Form submit: post-submit action failed', {
        extra: {
          formId,
          sessionUuid,
          respondentUuid,
          formData: JSON.stringify(formData ?? {}),
          isFormEmbedded: metaData.isFormEmbedded,
        },
      });
    }

    // Do we need to redirect on completing the form?
    if (settings?.redirectOnCompletion) {
      try {
        const href = getRedirectOnCompletionURL(settings.redirectOnCompletion, blocks, formData, {
          id: submissionId,
          respondentId,
          formName: name,
        });

        if (inIframe()) {
          if (window.top) {
            window.top.location.href = href;
          }
        } else {
          window.location.href = href;
        }
        return;
      } catch (_e) {
        // The redirect fails because it's unsafe

        // Go to the default 'Thank you' page
        if (nextPage === null) {
          styles.lastPageHeight = Math.min(document.body.clientHeight, window.innerHeight);

          setIsDefaultThankYouPage(true);

          // Scroll to top
          window?.scrollTo(0, 0);
          return;
        }

        // Go to the custom 'Thank you' page
        setPageHistory([...pageHistory, nextPage]);
        goToPage(nextPage);
      }
    }

    setIsLoading(false);
  };

  const onContinue = async () => {
    if (!isCompleted && !isLoading && isLastInputPage) {
      // Form is completed when we are on the last input page
      await onComplete();

      // If we are redirecting, don't show the 'Thank you' page
      if (settings?.redirectOnCompletion) {
        return;
      }

      // Something went wrong and the form is not completed
      if (!isCompleted) {
        return;
      }
    }

    // Go to the default 'Thank you' page
    if (nextPage === null) {
      styles.lastPageHeight = Math.min(document.body.clientHeight, window.innerHeight);
      setIsDefaultThankYouPage(true);

      // Scroll to top
      window?.scrollTo(0, 0);
      return;
    }

    // Go to the next page
    setPageHistory([...pageHistory, nextPage]);
    goToPage(nextPage);
  };

  useEffect(() => {
    // Custom event
    dispatchTallyEvent(TallyEvent.TallyFormLoaded, { formId });

    if (inIframe()) {
      postMessageToParentWindow({
        event: TallyEvent.TallyFormLoaded,
        payload: { formId },
      });
    }
  }, []);

  useEffect(() => {
    // Page view
    triggerPageViewEvent();

    // If we have captcha data, reset it for the page
    if (Object.keys(captchaData).length > 0) {
      removeCaptchaDataOnPage(page);
    }

    // Focus the first form input on the page
    if (
      // Standalone form not on mobile
      (!isMobile() && !inIframe()) ||
      // Popups
      styles.popup ||
      // Embedded forms on page 2+
      (styles.embed && page !== 1)
    ) {
      focusOnFirstFormInput();
    }
  }, [page]);

  // Update mentions when the user answers
  useMentions({ blocks, pageBlocks, formData, formMetaData });

  // Update custom domain internal links
  useCustomDomainLinks(page, metaData);

  // If the user added data but didn't complete the form yet, check if they are sure they want to close the tab
  useBeforeUnload(
    () =>
      !inIframe() &&
      !isCompleted &&
      !hasUniqueSubmissionConflictError &&
      Object.keys(formData).length > 0,
    t('form-respond.not-submitted-data-warning'),
    [formData, hasUniqueSubmissionConflictError],
  );

  if (hasError) {
    return (
      <Layout metaData={metaData} integrations={integrations} styles={styles}>
        {isNotFoundError ? (
          <Error title={t('error.404.title')} description={t('error.404.description')} />
        ) : (
          <Error />
        )}
      </Layout>
    );
  }

  if (hasUniqueSubmissionConflictError) {
    return (
      <Layout metaData={metaData} integrations={integrations} styles={styles}>
        <Error
          icon={ShieldOff}
          title={t('form-respond.unique-submission-conflict.title')}
          description={t('form-respond.unique-submission-conflict.description')}
        />
      </Layout>
    );
  }

  if (isPasswordProtected && !password) {
    return (
      <Layout metaData={metaData} integrations={integrations} styles={styles}>
        <UnlockForm formId={formId} onUnlock={(password) => setPassword(password)} />

        {!styles.removeBranding && (
          <PoweredByContainer>
            <PoweredByBadge mode="respond" styles={styles} />
          </PoweredByContainer>
        )}
      </Layout>
    );
  }

  if (isClosed) {
    const title = (settings?.closeMessageTitle || t('form-respond.closed.title')) as string;
    const description = (settings?.closeMessageDescription ||
      t('form-respond.closed.description')) as string;
    metaData.title = title;
    metaData.description = description;

    return (
      <Layout metaData={metaData} integrations={integrations} styles={styles}>
        <Error
          icon={EyeOff}
          title={title}
          description={settings?.closeMessageDescription || t('form-respond.closed.description')}
        />
      </Layout>
    );
  }

  return (
    <Layout metaData={metaData} integrations={integrations} styles={styles}>
      {settings?.hasProgressBar && !isDefaultThankYouPage && !isCustomThankYouPage && (
        <ProgressBar
          currentPage={page}
          currentPageBlocks={pageBlocks}
          pages={pages}
          formData={formData}
          blocks={blocks}
          hasCover={typeof cover !== 'undefined'}
          popup={styles.popup}
          embed={styles.embed}
        />
      )}

      {cover && <FormCover cover={cover} settings={coverSettings} styles={styles} />}

      {logo && <FormLogo logo={logo} withCover={!!cover} styles={styles} />}

      <Content
        className={`tally-page${!isDefaultThankYouPage ? ` tally-page-${page}` : ''}${
          isDefaultThankYouPage || isCustomThankYouPage ? ' tally-page-thank-you' : ''
        }`}
        styles={styles}
        page={page}
        isDefaultThankYouPage={isDefaultThankYouPage}
        isCustomThankYouPage={isCustomThankYouPage}
        hasProgressBar={settings?.hasProgressBar}>
        {!isDefaultThankYouPage && showBackButton && (
          <BackButtonContainer className="tally-back-button">
            <Button
              icon={theme.direction === 'rtl' ? ArrowRight : ArrowLeft}
              value={t('label.back')}
              variant="ghost-secondary"
              onClick={onBack}
            />
          </BackButtonContainer>
        )}

        {isDefaultThankYouPage ? (
          <ThankYouPage mode="respond" styles={styles} />
        ) : (
          <form
            onSubmit={(e) => {
              // If the submit button is hidden, we don't want to submit the
              // form when pressing enter inside an input
              if (!showSubmitButton) {
                e.preventDefault();
              }
            }}>
            <progress max={pages.length} value={page}>
              {t('form-respond.progress', {
                page,
                pages: pages.length,
              })}
            </progress>

            {errorException && <FormError exception={errorException} />}

            <FormBlocks
              blocks={blocks}
              pageBlocks={pageBlocks}
              formData={formData}
              errors={formErrors}
              mode="respond"
              formId={formId}
              workspaceId={workspaceId}
              subscriptionPlan={subscriptionPlan}
              hasPartialSubmissions={settings?.hasPartialSubmissions ?? false}
              language={(settings?.language ?? Language.English) as Language}
              styles={styles}
              respondentUuid={respondentUuid}
              sessionUuid={sessionUuid}
              onChange={(groupUuid: string, value: any) => {
                // We are auto jumping to the next page, don't update the form data
                if (isAutoJumping) {
                  return;
                }

                setLastChangedGroupUuid(groupUuid);
                onChangeFormData({ [groupUuid]: value });
              }}
              onSaveResponse={async (response) => {
                // We are auto jumping to the next page, don't update the form data
                if (isAutoJumping) {
                  return;
                }

                if (hasProAccess(subscriptionPlan) && settings?.hasPartialSubmissions) {
                  // Changing form data could trigger conditional logic calculations, so we need to sync calculated fields as well
                  const calculatedFields = getCalculatedFieldsFormData(
                    {
                      ...formData,
                      ...response,
                    },
                    pageHistory,
                    pages,
                    blocks,
                  );

                  // If this is the first time we are saving partial submissions, we need to send the initial form data along
                  if (isFirstPartialSubmissionRun) {
                    // The saved form data should include hidden fields and default answers
                    partialSubmissionFormData = {
                      ...partialSubmissionFormData,
                      ...formData,
                      ...calculatedFields,
                      ...response,
                    };
                  } else {
                    partialSubmissionFormData = {
                      ...partialSubmissionFormData,
                      ...calculatedFields,
                      ...response,
                    };
                  }
                } else {
                  partialSubmissionFormData = {
                    ...partialSubmissionFormData,
                    ...response,
                  };
                }

                debouncedSavePartialSubmission();
              }}
              onSolveCaptcha={(response) => {
                setCaptchaData({ ...captchaData, ...response });
              }}
            />

            {showSubmitButton && (
              <Fragment>
                <ButtonsContainer className="tally-submit-button">
                  <div>
                    <Button
                      type="submit"
                      icon={theme.direction === 'rtl' ? ArrowLeft : ArrowRight}
                      iconPosition="right"
                      value={buttonText}
                      variant="primary"
                      size="big"
                      isLoading={isLoading || isAutoJumping || isPaying}
                      onClick={onSubmit}
                    />
                  </div>

                  {showPoweredByBadge && (
                    <div>
                      <PoweredByBadge mode="respond" styles={styles} />
                    </div>
                  )}
                </ButtonsContainer>

                {showPayDisclaimer && (
                  <Disclaimer
                    dangerouslySetInnerHTML={{
                      __html: t('form-respond.pay-disclaimer', {
                        buttonText: stripHtml(buttonText),
                      }),
                    }}
                  />
                )}

                {showMaliciousFormWarning && (
                  <Disclaimer
                    dangerouslySetInnerHTML={{
                      __html: t('form-respond.password-warning', {
                        reportUrl: config.get('help-links.report-abuse'),
                      }),
                    }}
                  />
                )}
              </Fragment>
            )}
          </form>
        )}

        {showPoweredByBadge && isCustomThankYouPage && (
          <PoweredByContainer>
            <PoweredByBadge
              mode="respond"
              styles={styles}
              isOnThankYouPage={true}
              isOnCustomThankYouPage={true}
            />
          </PoweredByContainer>
        )}
      </Content>
    </Layout>
  );
};

export default FormRespond;

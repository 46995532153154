import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { IS_INTERSECTION_OBSERVER_SUPPORTED } from '@/utils/device';

let observer: IntersectionObserver | undefined;
const blockMap = new Map<
  string,
  {
    isVisible: boolean;
    instanceMap: Map<string, Dispatch<SetStateAction<boolean>>>;
  }
>();

export const useFormBuilderVisibleBlock = (blockUuid: string): boolean => {
  const instanceUuid = useMemo(() => uuid(), []);
  const [isVisible, setIsVisible] = useState(
    // If IntersectionObserver is not supported, we assume the block is visible
    !IS_INTERSECTION_OBSERVER_SUPPORTED ? true : false,
  );

  const connectObserver = () => {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        const id = e.target.id;
        const state = blockMap.get(id);
        if (!state) {
          return;
        }

        // Only update state if it has changed
        if (state.isVisible !== e.isIntersecting) {
          state.isVisible = e.isIntersecting;
          blockMap.set(id, state);
          state.instanceMap.forEach((action) => action(state.isVisible));
        }
      });
    });
  };

  useEffect(() => {
    if (!IS_INTERSECTION_OBSERVER_SUPPORTED) {
      return;
    }

    const blockEl = document.getElementById(blockUuid);
    if (!blockEl) {
      return;
    }

    // Connect the observer if it hasn't been connected yet
    if (!observer) {
      connectObserver();
    }

    // If the block is already in the map, use the existing state
    const existingState = blockMap.get(blockUuid);

    const instanceMap =
      existingState?.instanceMap ?? new Map<string, Dispatch<SetStateAction<boolean>>>();

    // Add the setIsVisible action to the instance map
    instanceMap.set(instanceUuid, setIsVisible);

    // Update the block map with the new state
    blockMap.set(blockUuid, {
      isVisible: existingState?.isVisible ?? isVisible,
      instanceMap: existingState?.instanceMap ?? instanceMap,
    });

    // Observe the block element with a small delay to avoid freezing the UI on load
    if (!existingState) {
      setTimeout(
        () => {
          observer?.observe(blockEl);
        },
        100 + (blockMap.size > 1000 ? 5000 : 0),
      );
    }

    return () => {
      const state = blockMap.get(blockUuid);
      if (!state) {
        return;
      }

      state.instanceMap.delete(instanceUuid);

      // Remove the block from the map if we don't have any more instances
      if (state.instanceMap.size === 0) {
        blockMap.delete(blockUuid);

        // Stop observing the block
        observer?.unobserve(blockEl);
      }

      // Disconnect the observer if there are no more blocks to observe
      if (blockMap.size === 0) {
        observer?.disconnect();
        observer = undefined;
      }
    };
  }, []);

  return blockMap.get(blockUuid)?.isVisible ?? false;
};

export default useFormBuilderVisibleBlock;

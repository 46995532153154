import { breakpoint, withDirection } from '@tallyforms/ui';
import styled from 'styled-components';

const Dropzone = styled.div`
  position: absolute;
  z-index: 1;
  display: none;
`;

export const BlockDropzone = styled(Dropzone)<{
  isInColumn?: boolean;
}>`
  top: 0;
  bottom: 6px;
  left: ${({ theme }) => theme.spacing.xxl};
  right: ${({ theme }) => theme.spacing.xxl};

  @media (max-width: ${breakpoint.sm}) {
    left: 25px;
    right: 25px;
  }

  &.hover {
    border-bottom: 4px solid ${({ theme }) => theme.color.accent}77;
  }

  ${({ isInColumn }) =>
    isInColumn &&
    `
    left: 0;
    right: 0;

    @media (max-width: ${breakpoint.sm}) {
      left: 0;
      right: 0;
    }
  `}
`;

export const ColumnDropzone = styled(Dropzone)<{
  move: 'before' | 'after';
}>`
  top: 0;
  bottom: 0;
  width: calc(${({ theme }) => theme.spacing.xxl} - 5px);

  @media (max-width: ${breakpoint.sm}) {
    width: calc(25px - 5px);
  }

  ${({ theme, move }) =>
    move === 'before' &&
    `
    left: 0;

    &.hover {
      border-right: 4px solid ${theme.color.accent}77;
    }
  `}

  ${({ theme, move }) =>
    move === 'after' &&
    `
    right: 0;

    &.hover {
      border-left: 4px solid ${theme.color.accent}77;
    }
  `}
`;

export const ColumnTopDropzone = styled(Dropzone)`
  top: -3px;
  left: 0;
  right: 0;
  height: 10px;

  &.hover {
    border-top: 4px solid ${({ theme }) => theme.color.accent}77;
  }
`;

export const ColumnListBottomDropzone = styled(Dropzone)`
  bottom: 3px;
  left: ${({ theme }) => theme.spacing.xxl};
  right: ${({ theme }) => theme.spacing.xxl};
  height: 10px;

  @media (max-width: ${breakpoint.sm}) {
    left: 25px;
    right: 25px;
  }

  &.hover {
    border-bottom: 4px solid ${({ theme }) => theme.color.accent}77;
  }
`;

export const ColumnResizerDropzone = styled.div`
  position: absolute;
  top: 0;
  display: block;
  width: ${({ theme }) => theme.spacing.lg};
  height: 100%;
  ${({ theme }) => withDirection('left', `calc(-${theme.spacing.lg} / 2)`, theme.direction)}

  @media (hover: hover) {
    &:hover {
      :after {
        content: '';
        display: block;
        width: 4px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc((${({ theme }) => theme.spacing.lg} - 4px) / 2);
        background: ${({ theme }) => theme.color.border};
      }

      cursor: col-resize !important;
    }
  }

  &.hover,
  &.resizing {
    :after {
      content: '';
      display: block;
      width: 4px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc((${({ theme }) => theme.spacing.lg} - 4px) / 2);
    }
  }

  &.resizing {
    cursor: col-resize !important;

    :after {
      background: ${({ theme }) => theme.color.border};
    }
  }

  &.hover {
    :after {
      background: ${({ theme }) => theme.color.accent}77;
    }
  }
`;

import { Mention } from '../mention';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type FormTitleBlock = Block<
  BlockType.FormTitle,
  {
    title?: string;
    logo?: string;
    cover?: string;
    coverSettings?: { objectPositionYPercent: number };
    mentions?: Mention[];
    button?: {
      label: string;
    };
  }
>;

export function isFormTitleBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<FormTitleBlock>;

export function isFormTitleBlock(block: RespondBlock): block is RespondBlock<FormTitleBlock>;

export function isFormTitleBlock(block: Block): block is FormTitleBlock {
  return block.type === BlockType.FormTitle;
}

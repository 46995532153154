import { Currency } from '../currency';
import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type PaymentBlock = Block<
  BlockType.Payment,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    isConfigured: boolean;
    amount?: Field | number;
    currency: Currency;
    stateUuid?: string;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isPaymentBlock(block: FormBuilderBlock): block is FormBuilderBlock<PaymentBlock>;

export function isPaymentBlock(block: RespondBlock): block is RespondBlock<PaymentBlock>;

export function isPaymentBlock(block: Block): block is PaymentBlock {
  return block.type === BlockType.Payment;
}

export interface StripeConnectPaymentResult {
  stripePaymentId: string;
  name: string;
  email: string;
  amount: number;
  currency: string;
}

export interface StripePaymentIntent {
  id: string;
  secret: string;
}

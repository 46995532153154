import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type CheckboxBlock = Block<
  BlockType.Checkbox,
  {
    index: number;
    isFirst: boolean;
    isLast: boolean;
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    image?: string;
    hasMinChoices?: boolean;
    minChoices?: number;
    hasMaxChoices?: boolean;
    maxChoices?: number;
    hasOtherOption?: boolean;
    isOtherOption?: boolean;
    randomize?: boolean;
    lockInPlace?: string[];
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isCheckboxBlock(block: FormBuilderBlock): block is FormBuilderBlock<CheckboxBlock>;

export function isCheckboxBlock(block: RespondBlock): block is RespondBlock<CheckboxBlock>;

export function isCheckboxBlock(block: Block): block is CheckboxBlock {
  return block.type === BlockType.Checkbox;
}

import { safeUrl } from '@tallyforms/lib';

import { Container, ImageCaption } from './styled';

interface Props {
  images?: Image[];
  link?: string;
  altText?: string;
  caption?: string;
}

interface Image extends Partial<File> {
  url: string;
}

const ImageBlock = ({ images, link, altText, caption }: Props) => {
  return (
    <Container>
      {images &&
        images.map((file: Image) => (
          <div key={file.name}>
            {link ? (
              <a
                className="unstyled"
                href={safeUrl(link)}
                target="_blank"
                rel="noopener noreferrer">
                <img src={file.url} alt={altText ?? caption ?? file.name} />
              </a>
            ) : (
              <img src={file.url} alt={altText ?? caption ?? file.name} />
            )}

            {caption && <ImageCaption className="tally-image-caption">{caption}</ImageCaption>}
          </div>
        ))}
    </Container>
  );
};

export default ImageBlock;

import styled, { keyframes } from 'styled-components';

const spinKeyframes = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

export const Container = styled.div<{ size: number }>`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation: ${spinKeyframes} 1.618s linear infinite;

  svg {
    margin: 0 !important;
  }
`;

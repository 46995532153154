import { padStart } from '@tallyforms/lib';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  formatDistanceStrict,
  isValid,
  parseISO,
} from 'date-fns';
import matchAll from 'string.prototype.matchall';

import { captureException } from '@/services/sentry';

export const parseISODate = (date: Date | string | null | undefined): Date | undefined => {
  if (typeof date === 'undefined') {
    return undefined;
  }

  if (date === null) {
    return undefined;
  }

  if (typeof date === 'string') {
    const parsed = parseISO(date);
    if (isValid(parsed)) {
      return parsed;
    }

    return undefined;
  }

  return date;
};

export const parseDateStringUsingRegExp = (
  date: string,
  payload: {
    regexp: string;
    monthsShort: string[];
    dayIndex?: number;
    monthIndex?: number;
    yearIndex?: number;
    monthNumberIndex?: number;
  },
): any => {
  let parsed;

  const {
    regexp,
    monthsShort,
    dayIndex = -1,
    monthIndex = -1,
    yearIndex = -1,
    monthNumberIndex = -1,
  } = payload;

  try {
    for (const match of matchAll(date, new RegExp(regexp, 'ig'))) {
      if (match[yearIndex] && match[dayIndex] && (match[monthIndex] || match[monthNumberIndex])) {
        const month = match[monthNumberIndex]
          ? parseFloat(match[monthNumberIndex]) - 1
          : monthsShort.indexOf(match[monthIndex]);
        if (month >= 0 && month <= 11) {
          parsed = parseISO(
            `${padStart(match[yearIndex], 4, '0')}-${padStart(
              month + 1,
              2,
              '0',
            )}-${padStart(match[dayIndex], 2, '0')}`,
          );
        }
      }
    }
  } catch (e) {
    captureException(e, { extra: { date, payload } });
  }

  return parsed;
};

export const formatFullDate = (dateStr: string): string => {
  const now = new Date();
  const then = new Date(dateStr);

  if (now.getFullYear() === then.getFullYear()) {
    return format(new Date(dateStr), 'MMM d');
  }

  return format(new Date(dateStr), 'MMM d, yyyy');
};

export const formatFullDateTime = (dateStr: string): string => {
  const now = new Date();
  const then = new Date(dateStr);

  if (now.getFullYear() === then.getFullYear()) {
    return format(new Date(dateStr), 'MMM d, hh:mm a');
  }

  return format(new Date(dateStr), 'MMM d, yyyy hh:mm a');
};

export const formatTimeAgo = (dateStr: string, t: any): string => {
  const now = new Date();
  const then = new Date(dateStr);

  let timeAgoLabel = formatFullDateTime(dateStr);

  if (differenceInMinutes(now, then) < 1) {
    timeAgoLabel = t('label.just-now');
  } else if (differenceInDays(now, then) < 1) {
    timeAgoLabel = t('label.time-ago', {
      period: formatDistanceStrict(now, then, { addSuffix: false }),
    });
  }

  return timeAgoLabel;
};

export const formatApproximateTimeAgo = (dateStr: string, t: any): string => {
  const now = new Date();
  const then = new Date(dateStr);

  let timeAgoLabel = '';

  if (differenceInMinutes(now, then) < 1) {
    timeAgoLabel = t('label.just-now').toLowerCase();
  } else if (differenceInHours(now, then) < 1) {
    timeAgoLabel = t('label.time-ago', {
      period: `${differenceInMinutes(now, then)}m`,
    });
  } else if (differenceInDays(now, then) < 1) {
    timeAgoLabel = t('label.time-ago', {
      period: `${differenceInHours(now, then)}h`,
    });
  } else if (differenceInDays(now, then) < 4) {
    timeAgoLabel = t('label.time-ago', {
      period: `${differenceInDays(now, then)}d`,
    });
  } else {
    timeAgoLabel = formatFullDate(dateStr);
  }

  return timeAgoLabel;
};

import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type RankingOptionBlock = Block<
  BlockType.RankingOption,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    index: number;
    isFirst: boolean;
    isLast: boolean;
    image?: string;
    randomize?: boolean;
    lockInPlace?: string[];
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isRankingOptionBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<RankingOptionBlock>;

export function isRankingOptionBlock(
  block: RespondBlock,
): block is RespondBlock<RankingOptionBlock>;

export function isRankingOptionBlock(block: Block): block is RankingOptionBlock {
  return block.type === BlockType.RankingOption;
}

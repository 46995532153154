export const get = (key: string) => {
  if (typeof window === 'undefined') {
    return null;
  }

  try {
    return window.localStorage?.getItem?.(key);
  } catch {
    return null;
  }
};

import { breakpoint } from '@tallyforms/ui';
import styled from 'styled-components';

import { withAlpha } from '@/utils/color';

export const Container = styled.div<{
  hasCover?: boolean;
  popup?: boolean;
  embed?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  padding: ${({ theme }) => theme.spacing.sm} 0;
  display: flex;
  align-items: center;
  z-index: 999;
  width: 100%;

  ${({ popup, theme }) =>
    popup &&
    `
    padding: ${theme.spacing.sm} 15%;
    top: 0px;
    z-index: 2;
    height: 34px;

    @media (max-width: ${breakpoint.sm}) {
      height: 30px;
    }
    `}

  ${({ hasCover, theme, popup }) =>
    hasCover &&
    `
      background: linear-gradient(180deg, rgba(119, 119, 119, 0.5) 0%, rgba(119, 119, 119, 0.1) 60%, rgba(241, 241, 241, 0) 100%);
      ${!popup && `padding-bottom: ${theme.spacing.lg}`};
    `}

  ${({ embed, hasCover }) =>
    embed &&
    !hasCover &&
    `
    position: relative;
  `}
`;

export const ProgressBarItem = styled.div<{
  width: string;
  completion: number;
  hasCover?: boolean;
  isCombined?: boolean;
}>`
  position: relative;
  margin: 0 ${({ theme }) => theme.spacing.xs};
  height: 4px;
  background-color: ${({ theme, hasCover }) =>
    hasCover ? withAlpha(theme.color.white, 0.5) : withAlpha(theme.color.text, 0.09)};
  width: ${({ width }) => width};
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ completion }) => completion}%;
    background-color: ${({ theme }) => withAlpha(theme.color.text, 0.4)};
    transition: width 0.3s ease-in-out;
    border-radius: 4px;

    ${({ hasCover, theme }) => hasCover && `background-color: ${theme.color.white};`}
  }

  &:first-child {
    margin-left: ${({ theme }) => theme.spacing.sm};
  }

  &:last-child {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }

  ${({ isCombined: combined, theme }) =>
    combined &&
    `
    margin: 0;
    border-radius: 0;

    &::after {
      border-radius: 0;
    }

    &:first-child {
     border-bottom-left-radius: ${theme.spacing.sm};
     border-top-left-radius: ${theme.spacing.sm};

     &::after {
      border-bottom-left-radius: ${theme.spacing.sm};
      border-top-left-radius: ${theme.spacing.sm};
     }
    }

    &:last-child {
      border-bottom-right-radius: ${theme.spacing.sm};
      border-top-right-radius: ${theme.spacing.sm};

      &::after {
        border-bottom-right-radius: ${theme.spacing.sm};
        border-top-right-radius: ${theme.spacing.sm};
       }
    }
  `};
`;

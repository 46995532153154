export const COMMON_MIME_TYPES_PER_EXTENSION: { [key: string]: string[] } = {
  '.jpg': ['image/jpeg'],
  '.jpeg': ['image/jpeg'],
  '.png': ['image/png'],
  '.gif': ['image/gif'],
  '.svg': ['image/svg+xml'],
  '.heic': ['image/heic', 'image/heif'],
  '.webp': ['image/webp'],
  '.bmp': ['image/bmp'],
  '.psd': ['image/vnd.adobe.photoshop', 'application/octet-stream', 'application/psd'],
  '.pdf': ['application/pdf'],
  '.doc': ['application/msword'],
  '.docx': ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  '.xls': [
    'application/vnd.ms-excel',
    'application/excel',
    'application/x-excel',
    'application/x-msexcel',
  ],
  '.xlsx': ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  '.ppt': [
    'application/vnd.ms-powerpoint',
    'application/powerpoint',
    'application/mspowerpoint',
    'application/vnd-mspowerpoint',
  ],
  '.pptx': ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  '.zip': ['application/zip', 'application/x-zip-compressed'],
  '.rar': ['application/vnd.rar', 'application/x-rar-compressed'],
  '.json': ['application/json'],
  '.gzip': ['application/gzip', 'application/x-gzip'],
  '.odt': ['application/vnd.oasis.opendocument.text'],
  '.txt': ['text/plain'],
  '.csv': ['text/csv', 'application/csv'],
  '.html': ['text/html'],
  '.xml': ['application/xml', 'text/xml'],
  '.mp4': ['video/mp4'],
  '.mov': ['video/quicktime'],
  '.webm': ['video/webm'],
  '.mp3': ['audio/mpeg', 'audio/mp3'],
  '.wav': ['audio/wav'],
  '.m4a': ['audio/mp4', 'audio/x-m4a', 'audio/m4a'],
};

import { Currency } from '../../types';

export const getCurrencySuffix = (currency: string): string | null => {
  switch (currency) {
    case Currency.AED:
    case Currency.AUD:
    case Currency.BGN:
    case Currency.CAD:
    case Currency.CHF:
    case Currency.CNY:
    case Currency.DKK:
    case Currency.HRK:
    case Currency.IDR:
    case Currency.ISK:
    case Currency.JPY:
    case Currency.MXN:
    case Currency.NOK:
    case Currency.NZD:
    case Currency.RON:
    case Currency.RSD:
    case Currency.SEK:
    case Currency.SGD:
    case Currency.THB:
    case Currency.UAH:
    case Currency.ZAR:
      return ` ${currency}`;
    case Currency.CZK:
      return ` Kč`;
    case Currency.HUF:
      return ` Ft`;
    case Currency.MAD:
      return ` .د.م.`;
    case Currency.PLN:
      return ` zł`;
    case Currency.RUB:
      return ` p.`;
    case Currency.SAR:
      return ` ﷼`;
    case Currency.VND:
      return ` ₫`;
  }

  return null;
};

import { Block, BlockType, Field } from '../types';

export const GROUP_BLOCKS: { [key: string]: BlockType[] } = {
  [BlockType.Question]: [BlockType.Title],
  [BlockType.MultipleChoice]: [BlockType.MultipleChoiceOption],
  [BlockType.Checkboxes]: [BlockType.Checkbox],
  [BlockType.Dropdown]: [BlockType.DropdownOption],
  [BlockType.Ranking]: [BlockType.RankingOption],
  [BlockType.PageBreak]: [BlockType.PageBreak, BlockType.Text],
  [BlockType.Divider]: [BlockType.Divider, BlockType.Text],
  [BlockType.Matrix]: [
    BlockType.Matrix,
    BlockType.MatrixColumn,
    BlockType.MatrixColumn,
    BlockType.MatrixColumn,
    BlockType.MatrixRow,
    BlockType.MatrixRow,
    BlockType.MatrixRow,
  ],
  [BlockType.MultiSelect]: [BlockType.MultiSelectOption],
};

export const BLOCK_TYPE_TO_GROUP_TYPE: { [key: string]: BlockType } = {
  [BlockType.MultipleChoiceOption]: BlockType.MultipleChoice,
  [BlockType.Checkbox]: BlockType.Checkboxes,
  [BlockType.DropdownOption]: BlockType.Dropdown,
  [BlockType.RankingOption]: BlockType.Ranking,
  [BlockType.MultiSelectOption]: BlockType.MultiSelect,
};

export const TEXT_BLOCKS = [
  BlockType.FormTitle,
  BlockType.Text,
  BlockType.Label,
  BlockType.Title,
  BlockType.Heading1,
  BlockType.Heading2,
  BlockType.Heading3,
  BlockType.MatrixRow,
  BlockType.MatrixColumn,
];

export const INPUT_FIELD_BLOCKS = [
  BlockType.InputText,
  BlockType.Textarea,
  BlockType.InputNumber,
  BlockType.InputEmail,
  BlockType.InputPhoneNumber,
  BlockType.InputLink,
  BlockType.InputDate,
  BlockType.InputTime,
  BlockType.FileUpload,
  BlockType.Payment,
  BlockType.Signature,
  BlockType.Rating,
  BlockType.LinearScale,
  BlockType.Checkbox,
  BlockType.MultipleChoiceOption,
  BlockType.DropdownOption,
  BlockType.RankingOption,
  BlockType.Matrix,
  BlockType.MatrixRow,
  BlockType.MatrixColumn,
  BlockType.MultiSelectOption,
  BlockType.WalletConnect,
];

export const LAYOUT_BLOCKS = [
  BlockType.Title,
  BlockType.Heading1,
  BlockType.Heading2,
  BlockType.Heading3,
  BlockType.Label,
  BlockType.Text,
  BlockType.PageBreak,
  BlockType.ThankYouPage,
  BlockType.Image,
  BlockType.Divider,
  BlockType.Embed,
  BlockType.EmbedAudio,
  BlockType.EmbedVideo,
];

export const ADVANCED_BLOCKS = [
  BlockType.ConditionalLogic,
  BlockType.CalculatedFields,
  BlockType.HiddenFields,
  BlockType.Captcha,
  BlockType.RespondentCountry,
];

export const FIELD_BLOCKS = [
  ...INPUT_FIELD_BLOCKS,
  BlockType.CalculatedFields,
  BlockType.HiddenFields,
  BlockType.RespondentCountry,
];

export const GROUP_OPTIONS_BLOCKS = [
  BlockType.MultipleChoice,
  BlockType.Dropdown,
  BlockType.Checkboxes,
  BlockType.Ranking,
  BlockType.Matrix,
  BlockType.MultiSelect,
];

export const QUESTION_BLOCKS = [...FIELD_BLOCKS, ...GROUP_OPTIONS_BLOCKS];

export const QUESTION_TITLE_BLOCKS = [BlockType.Title, BlockType.Label];

export const NO_TITLE_QUESTION_BLOCKS = [
  BlockType.CalculatedFields,
  BlockType.HiddenFields,
  BlockType.RespondentCountry,
];

export const ELIGIBLE_INPUT_PLACEHOLDER_BLOCKS = [
  BlockType.InputText,
  BlockType.InputNumber,
  BlockType.InputEmail,
  BlockType.InputLink,
  BlockType.InputPhoneNumber,
  BlockType.InputDate,
  BlockType.InputTime,
  BlockType.InputTime,
  BlockType.Textarea,
];

export const ELIGIBLE_DEFAULT_ANSWER_BLOCKS = [
  BlockType.InputText,
  BlockType.Textarea,
  BlockType.InputNumber,
  BlockType.InputEmail,
  BlockType.InputPhoneNumber,
  BlockType.InputLink,
  BlockType.InputDate,
  BlockType.InputTime,
  BlockType.Rating,
  BlockType.LinearScale,
  BlockType.Checkbox,
  BlockType.MultipleChoiceOption,
  BlockType.DropdownOption,
  BlockType.RankingOption,
  BlockType.MultiSelectOption,
];

export const ELIGIBLE_OTHER_ANSWER_GROUP_OPTIONS_BLOCKS = [
  BlockType.MultipleChoice,
  BlockType.Dropdown,
  BlockType.Checkboxes,
  BlockType.MultiSelect,
];

export const DONT_SAVE_PARTIAL_SUBMISSION_BLOCKS = [BlockType.Signature];

export const ELIGIBLE_QUESTION_TITLE_BLOCKS = [
  ...QUESTION_TITLE_BLOCKS,
  ...ELIGIBLE_INPUT_PLACEHOLDER_BLOCKS,
];

/**
 * Split group options without matrix since it's a special case we need to handle
 * Like folding blocks, all groups can be folded except Matrix
 */
export const GROUP_OPTIONS_BLOCKS_WITHOUT_MATRIX: BlockType[] = GROUP_OPTIONS_BLOCKS.filter(
  (block) => block !== BlockType.Matrix,
);

export const isInputBlock = <T extends Block>(
  block: T,
): block is T & { payload: { name?: string } } => {
  return INPUT_FIELD_BLOCKS.includes(block.type);
};

export const isBlockWithPlaceholder = <T extends Block>(
  block: T,
): block is T & { payload: { placeholder?: string } } => {
  return ELIGIBLE_INPUT_PLACEHOLDER_BLOCKS.includes(block.type);
};

export const isBlockWithDefaultAnswer = <T extends Block>(
  block: T,
): block is T & {
  payload: {
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string | number;
  };
} => {
  return ELIGIBLE_DEFAULT_ANSWER_BLOCKS.includes(block.type);
};

export const isBlockWithOtherOption = <T extends Block>(
  block: T,
): block is T & { payload: { hasOtherOption?: boolean; otherOption?: string } } => {
  return ELIGIBLE_OTHER_ANSWER_GROUP_OPTIONS_BLOCKS.includes(block.type);
};

export const isOtherOptionBlock = <T extends Block>(
  block: T,
  group: Block,
): block is T & { payload: { isOtherOption?: boolean } } => {
  return isBlockWithOtherOption(group) && block.groupUuid === group.groupUuid;
};

import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type MatrixCellBlock = Block<
  BlockType.MatrixColumn | BlockType.MatrixRow,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    isFirst: boolean;
    isLast: boolean;
    index: number;
    hasMinChoices?: boolean;
    minChoices?: number;
    hasMaxChoices?: boolean;
    maxChoices?: number;
    name?: string;
  }
>;

export function isMatrixCellBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<MatrixCellBlock>;

export function isMatrixCellBlock(block: RespondBlock): block is RespondBlock<MatrixCellBlock>;

export function isMatrixCellBlock(block: Block): block is MatrixCellBlock {
  return [BlockType.MatrixColumn, BlockType.MatrixRow].includes(block.type);
}

import { ValidationRule, ValidationRuleConstraint } from '@tallyforms/lib';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { parseISODate } from '@/utils/date';
import { getTaxIdPlaceholder } from '@/utils/tax';

import { Container } from './styled';

interface Props {
  id?: string;
  error?: ValidationRuleConstraint;
  errorText?: string;
}

const ValidationError = ({ id, error, errorText }: Props) => {
  const { t } = useTranslation();

  if (error) {
    let constraint: any = error[1];
    if ([ValidationRule.ShouldBeBeforeDate, ValidationRule.ShouldBeAfterDate].includes(error[0])) {
      constraint = format(parseISODate(error[1])!, 'MMMM d, yyyy');
    } else if (error[0] === ValidationRule.ShouldBeBetweenDates) {
      constraint = [
        format(parseISODate(error[1][0])!, 'MMMM d, yyyy'),
        format(parseISODate(error[1][1])!, 'MMMM d, yyyy'),
      ];
    }

    errorText = t(`form.error.${error[0]}`, { constraint });

    // No such error exists
    if (errorText === `form.error.${error[0]}`) {
      errorText = t('error.GENERAL');
    }

    // @ts-ignore
    if (error[0] === 'AIRTABLE_INVALID_FIELDS_MAPPING') {
      errorText = error[2];
    }

    // @ts-ignore
    if (error[0] === 'INVALID_TAX_ID') {
      const format = getTaxIdPlaceholder(constraint);
      if (format) {
        errorText = t('form.error.INVALID_TAX_ID_FORMAT', { format });
      }
    }

    // @ts-ignore
    if (error[0] === 'SHOULD_RANK_ALL_OPTIONS') {
      errorText = t('form.error.RANKING_REQUIRED');
    }

    return (
      <Container id={id} className="tally-validation-error">
        <span>{errorText}</span>
      </Container>
    );
  }

  if (errorText) {
    return (
      <Container id={id} className="tally-validation-error">
        <span>{errorText}</span>
      </Container>
    );
  }

  return null;
};

export default ValidationError;

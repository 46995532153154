import { ALPHABET, Position } from '@tallyforms/lib';
import { deviceType, primaryInput } from 'detect-it';
import type { KeyboardEvent as SyntheticKeyboardEvent } from 'react';

import { getVerticalScrollOffset } from '@/utils/dom';

export const IS_INTERSECTION_OBSERVER_SUPPORTED =
  typeof window !== 'undefined' && 'IntersectionObserver' in window;

export const isMobile = (): boolean => {
  return typeof window !== 'undefined' && window.innerWidth < 576;
};

export const withinBreakpointMd = (): boolean => {
  return typeof window !== 'undefined' && window.innerWidth < 758;
};

export const isTouchDevice = (): boolean => {
  return deviceType === 'touchOnly';
};

export const isMouseDevice = (): boolean => {
  return primaryInput === 'mouse';
};

/**
 * This is not super reliable
 * Souce: https://stackoverflow.com/questions/5899783/detect-safari-using-jquery
 */
export const isSafari = (): boolean => {
  return (
    typeof window !== 'undefined' &&
    typeof navigator !== 'undefined' &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  );
};

export const isMac = (): boolean => {
  return (
    typeof navigator !== 'undefined' &&
    // @ts-ignore
    (navigator.userAgentData?.platform ?? navigator.platform).toUpperCase().indexOf('MAC') >= 0
  );
};

export const getPositionFromEvent = (e: any): Position => {
  return {
    top: e.clientY + getVerticalScrollOffset(),
    bottom: window.innerHeight - e.clientY - getVerticalScrollOffset(),
    left: e.clientX + window.pageXOffset,
    right: window.innerWidth - e.clientX - window.pageXOffset,
  };
};

export const isElementInViewport = (el: HTMLElement): boolean => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const inIframe = (): boolean => {
  try {
    return typeof window !== 'undefined' && window.self !== window.top;
  } catch {
    return true;
  }
};

export const scrollListItemIntoView = (
  listEl: HTMLElement | null | undefined,
  value: string,
  scrollIntoViewOptions?: ScrollIntoViewOptions,
) => {
  if (!listEl) {
    return;
  }
  const optionEl = listEl.querySelector(`[id="${value}"]`);
  if (!optionEl) {
    return;
  }

  const { y: itemY, height: itemHeight } = optionEl.getBoundingClientRect();
  const { y: menuY, height: menuHeight } = listEl.getBoundingClientRect();

  // Item is not visible within the scroll
  if (!(menuY <= itemY && itemY + itemHeight <= menuY + menuHeight)) {
    optionEl.scrollIntoView(scrollIntoViewOptions ?? { behavior: 'smooth', block: 'nearest' });
  }
};

/**
 * Gets the key value from a keyboard event. In Windows, it's possible that
 * pressing a keyboard command that includes the Shift key will capitalize the
 * following key value, resulting in different behavior than expected, ex.
 * ctrl+shift+h resulting in ctrl+shift+H. To avoid this, we turn uppercase
 * single letter keys into lowercase.
 */
export const getKeyboardEventKey = (
  e: KeyboardEvent | SyntheticKeyboardEvent<HTMLElement>,
): string => {
  if (ALPHABET.includes(e.key)) {
    return e.key.toLowerCase();
  }

  return e.key;
};

export const postMessageToParentWindow = (payload: object) => {
  // If the target doesn't exist or doesn't have a postMessage function, return
  if (
    typeof window === 'undefined' ||
    typeof window?.parent === 'undefined' ||
    typeof window?.parent?.postMessage !== 'function'
  ) {
    return;
  }

  window.parent.postMessage(JSON.stringify(payload), '*');
};

export const getCommandKey = (): string => {
  if (isMac()) {
    return '⌘';
  }

  return 'Ctrl';
};

export const getAltKey = (): string => {
  if (isMac()) {
    return '⌥';
  }

  return 'Alt';
};

import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type InputTimeBlock = Block<
  BlockType.InputTime,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    placeholder?: string;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isInputTimeBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<InputTimeBlock>;

export function isInputTimeBlock(block: RespondBlock): block is RespondBlock<InputTimeBlock>;

export function isInputTimeBlock(block: Block): block is InputTimeBlock {
  return block.type === BlockType.InputTime;
}

import { Block, RespondBlock } from '@tallyforms/lib';
import { Color, Font, Theme } from '@tallyforms/ui';

export enum FormLayoutObjectType {
  Block = 'BLOCK',
  ColumnList = 'COLUMN_LIST',
  Column = 'COLUMN',
  Matrix = 'MATRIX',
}

export interface FormLayoutBlock {
  uuid: string;
  type: FormLayoutObjectType.Block;
  block: Block | RespondBlock;
  index: number;
}

export interface FormLayoutColumnList {
  uuid: string;
  type: FormLayoutObjectType.ColumnList;
  columns: FormLayoutColumn[];
  firstColumn?: FormLayoutColumn;
  lastColumn?: FormLayoutColumn;
}

export interface FormLayoutColumn {
  uuid: string;
  type: FormLayoutObjectType.Column;
  blocks: FormLayoutBlock[];
  ratio: number;
  flex?: number;
  firstBlock?: FormLayoutBlock;
  lastBlock?: FormLayoutBlock;
}

export interface FormLayoutMatrix {
  uuid: string;
  type: FormLayoutObjectType.Matrix;
  table: FormLayoutBlock;
  columns: FormLayoutBlock[];
  rows: FormLayoutBlock[];
}

export type FormLayoutObject = FormLayoutBlock | FormLayoutColumnList | FormLayoutMatrix;

export interface FormStyles {
  theme?: Theme;
  color?: Color;
  font?: Font;
  css?: string;
  removeBranding?: boolean;
  embed?: boolean;
  popup?: boolean;
  withMedia?: boolean;
  alignLeft?: boolean;
  transparentBackground?: boolean;
  hideTitle?: boolean;
  dynamicHeight?: boolean;
  lastPageHeight?: number;
  direction?: string;
  showBrandingRef?: string;
}

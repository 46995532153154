import { ActionType } from '@/types/form-builder';

import {
  BlockDropzone,
  ColumnDropzone,
  ColumnListBottomDropzone,
  ColumnResizerDropzone,
  ColumnTopDropzone,
} from './styled';

interface Props {
  style: 'block' | 'column' | 'column-resizer' | 'column-top' | 'column-list-bottom';
  move: 'before' | 'after';
  blockUuid: string | undefined;
  action: ActionType;
  isInColumn?: boolean;
  blockType?: string;
  groupUuid?: string;
}

const MoveDropzone = ({
  style,
  move,
  blockUuid,
  action,
  isInColumn = false,
  blockType,
  groupUuid,
}: Props) => {
  if (!blockUuid) {
    return null;
  }

  const commonProps = {
    className: 'move-dropzone',
    'data-block-uuid': blockUuid,
    'data-in-column': isInColumn ? 1 : 0,
    'data-move': move,
    'data-action': action,
    'data-style': style,
    'data-block-type': blockType,
    'data-block-group-uuid': groupUuid,
  };

  if (style === 'column') {
    return <ColumnDropzone {...commonProps} move={move} />;
  }

  if (style === 'column-resizer') {
    return (
      <ColumnResizerDropzone
        {...commonProps}
        className={`${commonProps.className} column-resize-handle`}
      />
    );
  }

  if (style === 'column-top') {
    return <ColumnTopDropzone {...commonProps} />;
  }

  if (style === 'column-list-bottom') {
    return <ColumnListBottomDropzone {...commonProps} />;
  }

  return <BlockDropzone {...commonProps} isInColumn={isInColumn} />;
};

export default MoveDropzone;

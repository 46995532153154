import { SubscriptionPlan } from '@tallyforms/lib';

export interface State {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  organizationId: string;
  avatarUrl: string | null;
  canAccessBilling: boolean;
  subscriptionPlan: SubscriptionPlan;
  hasAccess: boolean;
  excessUsage: ExcessUsageType | null;
  isOrganizationOwner: boolean;
  organizationOwner: State | null;
  failedPayment?: {
    hostedInvoiceUrl: string | null;
    nextPaymentAttemptAt: string | null;
  };
  isLoggedIn: boolean;
  isLoading: boolean;
  hasError: boolean;
  hasTwoFactorEnabled: boolean;
  hasPasswordSet: boolean;
  ssoIsConnectedWithGoogle: boolean;
  ssoIsConnectedWithApple: boolean;
  authenticationMethodsCount: number;
  updatedAt: string;
  createdAt: string;
}

export interface Action {
  type: ActionType;
  data?: any;
}

export enum ActionType {
  Update = 'User.update',
}

export enum FieldOfWork {
  Student = 'STUDENT',
  Educator = 'EDUCATOR',
  Product = 'PRODUCT',
  Engineering = 'ENGINEERING',
  Design = 'DESIGN',
  Sales = 'SALES',
  Marketing = 'MARKETING',
  CustomerService = 'CUSTOMER_SERVICE',
  Business = 'BUSINESS',
  Operations = 'OPERATIONS',
  HumanResources = 'HUMAN_RESOURCES',
  IT = 'IT',
  Finance = 'FINANCE',
  Entertainment = 'ENTERTAINMENT',
  Health = 'HEALTH',
  Social = 'SOCIAL',
  Law = 'LAW',
  Government = 'GOVERNMENT',
  Other = 'OTHER',
}

export enum OrganizationRole {
  Executive = 'EXECUTIVE',
  DepartmentLead = 'DEPARTMENT_LEAD',
  TeamManager = 'TEAM_MANAGER',
  TeamMember = 'TEAM_MEMBER',
  Freelancer = 'FREELANCER',
  JustMe = 'JUST_ME',
}

export enum DiscoveredVia {
  PoweredByTally = 'POWERED_BY_TALLY',
  WordOfMouth = 'WORD_OF_MOUTH',
  Search = 'SEARCH',
  Newsletter = 'NEWSLETTER',
  Blog = 'BLOG',
  Podcast = 'PODCAST',
  Twitter = 'TWITTER',
  Reddit = 'REDDIT',
  Facebook = 'FACEBOOK',
  LinkedIn = 'LINKEDIN',
  YouTube = 'YOUTUBE',
  IndieHackers = 'INDIE_HACKERS',
  ProductHunt = 'PRODUCT_HUNT',
  Other = 'OTHER',
}

export enum ExcessUsageType {
  Warning = 'WARNING',
  Blocked = 'BLOCKED',
}

import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type MultiSelectBlock = Block<
  BlockType.MultiSelect,
  {
    isHidden?: boolean;
    hasMinChoices?: boolean;
    minChoices?: number;
    hasMaxChoices?: boolean;
    maxChoices?: number;
    hasOtherOption?: boolean;
    isOtherOption?: boolean;
    randomize?: boolean;
    lockInPlace?: string[];
    color?: string;
    index?: number;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
  }
>;

export function isMultiSelectBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<MultiSelectBlock>;

export function isMultiSelectBlock(block: RespondBlock): block is RespondBlock<MultiSelectBlock>;

export function isMultiSelectBlock(block: Block): block is MultiSelectBlock {
  return block.type === BlockType.MultiSelect;
}

const TOTAL_COLUMN_RATIO = 100;
export const MIN_COLUMN_RATIO = 10;
export const COLUMN_RATIO_SNAP_PX = 7; // 7px is based on 700px width of the form builder container divided by 100 (max column ratio)

export const calculateColumnRatios = (columnRatioMap: Map<string, number>): Map<string, number> => {
  const adjustedMap = new Map(columnRatioMap);
  const numOfColumns = adjustedMap.size;

  let total = 0;
  let numOfColumnsWithoutRatio = 0;
  adjustedMap.forEach((ratio) => {
    total += ratio;
    numOfColumnsWithoutRatio += ratio === 0 ? 1 : 0;
  });

  // There are columns without ratio
  if (numOfColumnsWithoutRatio > 0) {
    const ratioToSet = Math.round(TOTAL_COLUMN_RATIO / numOfColumns);
    let totalRatioToSubtract = ratioToSet * numOfColumnsWithoutRatio;
    let numOfColumnsToAdjust = numOfColumns - numOfColumnsWithoutRatio;

    // Sort by ratio ASC
    [...adjustedMap]
      .sort((a, b) => a[1] - b[1])
      .forEach(([columnUuid, ratio]) => {
        if (ratio === 0) {
          adjustedMap.set(columnUuid, ratioToSet);
          total += ratioToSet;
        } else {
          const ratioToSubtract = Math.round(totalRatioToSubtract / numOfColumnsToAdjust);
          const newRatio = Math.round(ratio - ratioToSubtract);

          if (newRatio >= MIN_COLUMN_RATIO) {
            adjustedMap.set(columnUuid, newRatio);
            total -= ratioToSubtract;
            totalRatioToSubtract -= ratioToSubtract;
          } else {
            adjustedMap.set(columnUuid, MIN_COLUMN_RATIO);
            total -= ratio - MIN_COLUMN_RATIO;
            totalRatioToSubtract -= ratio - MIN_COLUMN_RATIO;
          }

          numOfColumnsToAdjust--;
        }
      });
  }

  // There is still available ratio to redistribute (mostly due to removing a column)
  if (total < TOTAL_COLUMN_RATIO) {
    const ratioToAdd = Math.round((TOTAL_COLUMN_RATIO - total) / numOfColumns);

    adjustedMap.forEach((ratio, columnUuid) => {
      adjustedMap.set(columnUuid, ratio + ratioToAdd);
      total += ratioToAdd;
    });
  }

  // There is still small available ratio to redistribute
  if (total !== TOTAL_COLUMN_RATIO) {
    let diff = TOTAL_COLUMN_RATIO - total;

    [...adjustedMap]
      .sort((a, b) => (diff > 0 ? a[1] - b[1] : b[1] - a[1]))
      .forEach(([columnUuid, ratio]) => {
        if (diff === 0) {
          return;
        }

        const adj = diff > 0 ? 1 : -1;
        adjustedMap.set(columnUuid, ratio + adj);
        total += adj;
        diff -= adj;
      });
  }

  return adjustedMap;
};

export const shouldRatiosBeEqual = (ratios: number[]): boolean => {
  const numOfColumns = ratios.length;
  const perfectRatio = TOTAL_COLUMN_RATIO / numOfColumns;

  return ratios.every((ratio) => Math.abs(ratio - perfectRatio) <= 1);
};

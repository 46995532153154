import { Currency } from '../../types';
import { formatNumberToFixedDecimals } from '../number';

export const convertAmountToPriceValue = (amount: number, currency: string): string => {
  let price = 0;

  // Zero-decimal currencies
  if (([Currency.JPY, Currency.KRW, Currency.VND] as string[]).includes(currency)) {
    price = Number(amount);
  } else {
    price = Number(amount / 100);
  }

  return formatNumberToFixedDecimals(price);
};

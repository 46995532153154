import { Block, isPageBreakBlock } from '../../types';

export const hasCustomThankYouPage = <T extends Block = Block>(blocks: T[]) => {
  for (const block of blocks) {
    if (isPageBreakBlock(block) && block.payload.isThankYouPage) {
      return true;
    }
  }

  return false;
};

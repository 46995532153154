import { DisableDays } from '../date';
import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type InputDateBlock = Block<
  BlockType.InputDate,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    placeholder?: string;
    beforeDate?: string;
    afterDate?: string;
    dateRange?: {
      from: string;
      to: string;
    };
    specificDates?: string[];
    startWeekOn?: string;
    disableDays?: DisableDays[];
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isInputDateBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<InputDateBlock>;

export function isInputDateBlock(block: RespondBlock): block is RespondBlock<InputDateBlock>;

export function isInputDateBlock(block: Block): block is InputDateBlock {
  return block.type === BlockType.InputDate;
}

import { FormData, FormErrors, isPaymentBlock, RespondBlock } from '@tallyforms/lib';
import { Dispatch, SetStateAction, useRef, useState } from 'react';

import { usePaymentEventState } from '@/hooks/form-respond/use-payments';
import { Question } from '@/types/form-builder';
import { highlighFirstFormError } from '@/utils/form-respond';
import { getValidationRulesFromBlocks, validate } from '@/utils/form-validation';
import { getDynamicValidatorsFromBlocks } from '@/utils/form-validation/dynamic-validators';

const useFormSubmitValidation = (
  formData: FormData,
  pageBlocks: RespondBlock[],
  pageQuestions: Question[],
  updateFormDataWithPaymentResults: (paymentFormData: FormData) => void,
  onContinue: () => void,
): {
  formErrors: FormErrors;
  isPaying: boolean;
  showPayDisclaimer: boolean;
  onSubmit: (e: any) => Promise<void>;
  setFormErrors: Dispatch<SetStateAction<FormErrors>>;
} => {
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const pageQuestionsSnapshotAtSubmit = useRef<string[]>([]);

  const { isPaying, showPayDisclaimer, completePaymentsIfAny } = usePaymentEventState(
    formData,
    updateFormDataWithPaymentResults,
    () => onPaymentsSuccess(),
    () => onPaymentsError(),
  );

  const getFormErrors = async () => {
    const validationRules = getValidationRulesFromBlocks(pageBlocks);
    const validators = await getDynamicValidatorsFromBlocks(pageBlocks);
    const errors = validate(formData, validationRules, validators, pageBlocks);

    return errors;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    // Save the snapshot of questions on the page at the moment of submit
    pageQuestionsSnapshotAtSubmit.current = pageQuestions.map(
      (question) => question.blockGroupUuid,
    );

    // Reset errors
    setFormErrors({});

    // Check for errors
    const errors = await getFormErrors();

    // Get the errors for payment blocks
    const paymentGroupUuids = pageBlocks.filter(isPaymentBlock).map(({ groupUuid }) => groupUuid);
    const paymentsErrors: FormErrors = {};
    for (const groupUuid of paymentGroupUuids) {
      if (errors[groupUuid]) {
        paymentsErrors[groupUuid] = errors[groupUuid];
      }
    }

    // If we have more errors than just payment errors, we cannot continue
    if (Object.keys(errors).length > Object.keys(paymentsErrors).length) {
      // Set the errors without the payment errors
      const filteredErrors: FormErrors = {};
      for (const errorGroupUuid of Object.keys(errors)) {
        // If this is a payment error, skip it
        if (paymentsErrors[errorGroupUuid]) {
          continue;
        }

        filteredErrors[errorGroupUuid] = errors[errorGroupUuid];
      }

      // Set errors to show
      setFormErrors(filteredErrors);

      // Focus the first error input field if available
      highlighFirstFormError();
      return;
    }

    // Check if we have any payment blocks on the page which need to be completed
    // The method will return:
    // -> true if there are no payments to complete, so we can continue
    // -> false if there are payments to complete, so we need to wait for the results which will trigger onPaymentsSuccess() or onPaymentsError()
    const canContinue = completePaymentsIfAny();
    if (!canContinue) {
      return;
    }

    // We can continue BUT we have payment errors?! How is this possible?
    // The payment blocks are lazy loaded, so there can be a race condition between the respondent clicking on the continue button and the payment blocks being loaded.
    // This additional check is necessary to make sure that the payment errors are shown if the payment blocks are not loaded yet.
    if (canContinue && Object.keys(paymentsErrors).length > 0) {
      setFormErrors(paymentsErrors);
      highlighFirstFormError();
      return;
    }

    onContinue();
  };

  const onPaymentsSuccess = async () => {
    // If we have questions which were shown conditionally after the successful payment(s),
    // we need to stay on the page and can't continue
    const questionsShownAfterSubmit = pageQuestions.filter(
      (question) => pageQuestionsSnapshotAtSubmit.current.indexOf(question.blockGroupUuid) === -1,
    );
    if (questionsShownAfterSubmit.length > 0) {
      return;
    }

    // Otherwise, all payments are completed and we can continue
    onContinue();
  };

  const onPaymentsError = async () => {
    // Check for errors
    const errors = await getFormErrors();
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      // Focus the first error input field if available
      highlighFirstFormError();
    }
  };

  return { formErrors, isPaying, showPayDisclaimer, onSubmit, setFormErrors };
};

export default useFormSubmitValidation;

import { logger } from '@tallyforms/lib';

import defaultConfig from './default.json';
import productionConfig from './production.json';

const env = process.env.NODE_ENV ?? 'development';

let config: any = {};

switch (env) {
  case 'production':
    config = { ...defaultConfig, ...productionConfig };
    break;

  default:
    config = defaultConfig;
    break;
}

const get = (key: string): any => {
  let value;
  const props = key.split('.');

  for (const prop of props) {
    value = (value && value[prop]) || config[prop];
  }

  if (typeof value === 'undefined') {
    logger.error(`[${env}] Config doesn't exist for key: ${key}`);
  }

  return value;
};

const isProductionEnv = (): boolean => {
  return env === 'production';
};

export default {
  get,
  isProductionEnv,
};

import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.color.error};
  font-weight: bold;
  padding: ${({ theme }) => theme.spacing.sm};
  border: 2px solid ${({ theme }) => theme.color.error};
  border-radius: 5px;
  background-color: rgba(217, 57, 0, 0.08);
  margin-bottom: ${({ theme }) => theme.spacing.md};
  line-height: 20px;
`;

import { SubscriptionPlan } from '@tallyforms/lib';
import { createContext, Dispatch } from 'react';

import { Action, ActionType, State } from '@/types/user';

export const initialState: State = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  organizationId: '',
  avatarUrl: '',
  canAccessBilling: false,
  subscriptionPlan: SubscriptionPlan.Free,
  hasTwoFactorEnabled: false,
  hasPasswordSet: false,
  ssoIsConnectedWithGoogle: false,
  ssoIsConnectedWithApple: false,
  hasAccess: true,
  excessUsage: null,
  isOrganizationOwner: false,
  organizationOwner: null,
  isLoggedIn: false,
  authenticationMethodsCount: 0,
  isLoading: true,
  hasError: false,
  createdAt: '',
  updatedAt: '',
};

export const UserContext = createContext<[State, Dispatch<Action>]>([
  initialState,
  () => {
    // Empty dispatch
  },
]);

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.Update: {
      const { payload } = action.data;
      return { ...state, ...payload, isLoading: false };
    }

    default:
      return state;
  }
};

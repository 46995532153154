import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type RespondentCountryBlock = Block<
  BlockType.RespondentCountry,
  {
    isHidden?: true;
  }
>;

export function isRespondentCountryBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<RespondentCountryBlock>;

export function isRespondentCountryBlock(
  block: RespondBlock,
): block is RespondBlock<RespondentCountryBlock>;

export function isRespondentCountryBlock(block: Block): block is RespondentCountryBlock {
  return block.type === BlockType.RespondentCountry;
}

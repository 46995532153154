import {
  Block,
  FormBuilderBlock,
  isFormBuilderBlock,
  RespondBlock,
  stripHtml,
  transformSafeHTMLSchemaToHTML,
} from '@tallyforms/lib';
import he from 'he';

export const convertMatrixUuidToText = (
  uuid: string,
  blocks: FormBuilderBlock[] | RespondBlock[],
) => {
  const block = blocks.find((x) => x.uuid === uuid);
  if (!block) {
    return '';
  }

  if (isFormBuilderBlock(block)) {
    return he.decode(stripHtml(block.value));
  }

  return stripHtml(transformSafeHTMLSchemaToHTML(block.payload.safeHTMLSchema));
};

export const getOptionsToLockInPlace = (groupBlocks: Block[]): string[] => {
  if (!groupBlocks || groupBlocks.length === 0) {
    return [];
  }

  const groupPayload = groupBlocks[0].payload;

  if (!groupPayload.randomize) {
    return [];
  }

  const lockInPlace = groupPayload.lockInPlace ?? [];
  const result: string[] = [];

  groupBlocks.forEach((block) => {
    if (lockInPlace.includes(block.uuid)) {
      result.push(block.uuid);
    } else if (block.payload.isOtherOption) {
      result.push(block.uuid);
    }
  });

  return result;
};

import { createContext, Dispatch } from 'react';

import { getCookie, setCookie } from '@/services/cookie';
import { Action, ActionType, State } from '@/types/preferences';

const COOKIE_KEY = 'preferences';

export const initialState: State = {
  isSidebarOpen: true,
};

const getInitialStateFromCookie = <T>(key: string, fallbackValue: T): T => {
  const value = getCookie(key);

  if (!value) {
    return fallbackValue;
  }

  return JSON.parse(value) as T;
};

export const PreferencesContext = createContext<[State, Dispatch<Action>]>([
  // We try to get the initial state from the cookie, otherwise we fallback to
  // the default initial state.
  getInitialStateFromCookie(COOKIE_KEY, initialState),
  () => {
    // Empty dispatch
  },
]);

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.Update: {
      if (!action.data) {
        return state;
      }

      const { payload } = action.data;

      // Update the cookie so we can persist render correctly on server side
      setCookie(COOKIE_KEY, JSON.stringify({ ...state, ...payload }));

      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

import {
  createGlobalStyle as styledComponentsCreateGlobalStyle,
  DefaultTheme,
} from 'styled-components';

import { getGlobalStyles } from './global';
import { normalizeCss } from './normalize';
import { DefaultTheme as Defaults, ThemeInterface } from './theme';

export const createGlobalStyle = (theme: ThemeInterface, font: string) => {
  return styledComponentsCreateGlobalStyle`
    ${normalizeCss}
    ${getGlobalStyles(theme, font)}
  `;
};

export const withDirection = (style: string, value: string, direction: string) => {
  if (direction === 'ltr') {
    return `${style}: ${value};`;
  }

  const [styleName, styleDirection] = style.split('-');

  const currentStyleDirection = styleDirection ? styleDirection : style;
  const oppositeStyleDirection = currentStyleDirection === 'left' ? 'right' : 'left';

  return styleDirection
    ? `${styleName}-${oppositeStyleDirection}: ${value};`
    : `${oppositeStyleDirection}: ${value};`;
};

export const withFontFamily = (font: string) => {
  // The default font is a CSS variable, so it doesn't need to be wrapped in quotes
  if (!font || font === Defaults.font.family) {
    return withDefaultFontFamily();
  }

  return `"${font}", sans-serif`;
};

export const withDefaultFontFamily = () => {
  return `${Defaults.font.family}, sans-serif`;
};

export const withTextLinkStyle = (
  theme: DefaultTheme,
  variant: 'default' | 'grayscale' = 'default',
) => {
  return `color: ${variant === 'default' ? theme.color.accent : theme.color.textGrayscale2};
    cursor: pointer;
    border-bottom: 1px solid ${variant === 'default' ? theme.color.accent : theme.color.textGrayscale2};
    text-decoration: none;
    font-weight: ${theme.font.weight.medium};

    @media (hover: hover) {
      &:hover {
        color: ${variant === 'default' ? theme.color.accentHover : theme.color.textGrayscale1};
        border-bottom: 1px solid ${variant === 'default' ? theme.color.accentHover : theme.color.textGrayscale1};
      }
    }
  `;
};

import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type DropdownBlock = Block<
  BlockType.Dropdown,
  {
    hasMinChoices?: boolean;
    minChoices?: number;
    hasMaxChoices?: boolean;
    maxChoices?: number;
    isHidden?: boolean;
    hasOtherOption?: boolean;
    isOtherOption?: boolean;
    randomize?: boolean;
    lockInPlace?: string[];
    allowMultiple?: boolean;
    placeholder?: string;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
  }
>;

export function isDropdownBlock(block: FormBuilderBlock): block is FormBuilderBlock<DropdownBlock>;

export function isDropdownBlock(block: RespondBlock): block is RespondBlock<DropdownBlock>;

export function isDropdownBlock(block: Block): block is DropdownBlock {
  return block.type === BlockType.Dropdown;
}

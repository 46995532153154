import styled from 'styled-components';

import { InputContent } from '@/components/layout/form';

export const Container = styled.div`
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const Content = styled(InputContent)``;

export enum Theme {
  Light = 'LIGHT',
  Dark = 'DARK',
  Custom = 'CUSTOM',

  // Deprecated
  Red = 'RED',
  Orange = 'ORANGE',
  Yellow = 'YELLOW',
  Green = 'GREEN',
  Blue = 'BLUE',
  Purple = 'PURPLE',
  Pink = 'PINK',
}

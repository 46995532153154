import { css } from 'styled-components';

import { breakpoint } from './breakpoint';
import { ThemeInterface } from './theme';
import { withFontFamily } from './utils';

export const getGlobalStyles = (theme: ThemeInterface, font: string) => {
  return css`
    html {
      box-sizing: border-box;
      text-rendering: optimizelegibility;
      text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: ${theme?.color.text};
    }

    body {
      --font-family: ${font};
      font-family: ${withFontFamily(theme?.font.family)};
      font-size: ${theme?.font.baseSize};
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
      padding: 0;
      margin: 0;
    }

    [role='button'],
    input[type='submit'],
    input[type='reset'],
    input[type='button'],
    button {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    html,
    body,
    [id='__next'] {
      height: 100%;
      width: 100%;
    }

    [id='__next'] {
      height: auto;
      min-height: 100%;
    }

    input,
    select,
    textarea {
      -webkit-appearance: none;
    }

    .DayPicker {
      min-width: 300px;
      background-color: ${theme?.color.background};

      @media (max-width: ${breakpoint.xs}) {
        min-width: 240px;
      }

      &-wrapper {
        background-color: ${theme?.color.menuBackground};
      }

      &Input-OverlayWrapper {
        background-color: ${theme?.color.background};
      }

      &Input-Overlay {
        z-index: ${theme?.zIndex.overlay};
        background-color: ${theme?.color.menuBackground};
      }

      &-Caption {
        color: ${theme?.color.textGrayscale1};
      }

      &-Month {
        display: flex;
        flex-direction: column;
      }

      &-Weekday {
        color: ${theme?.color.textGrayscale3};
        font-size: 1em;
        width: 36px;
        padding: 0.5em 0;
      }

      &-Day {
        width: 36px;
        padding: 0.5em 0;
        border-radius: 0 !important;

        @media (hover: hover) {
          &:hover {
            background-color: ${theme?.color.accent}20 !important;
            color: ${theme?.color.accent} !important;
          }
        }

        &--start {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
        }

        &--end {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
        }

        &--start,
        &--end {
          background-color: ${theme?.color.accent} !important;
          color: ${theme?.color.accent}20 !important;

          &.DayPicker-Day--today {
            color: white !important;
          }

          @media (hover: hover) {
            &:hover {
              background-color: ${theme?.color.accent} !important;
              color: ${theme?.color.accent}20 !important;
            }
          }
        }

        &--today {
          color: ${theme?.color.accent} !important;
        }

        &--selected {
          background-color: ${theme?.color.accent} !important;

          &.DayPicker-Day--start,
          &.DayPicker-Day--end {
            color: white !important;
          }

          &.DayPicker-Day--today {
            color: white !important;
          }

          &:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            background-color: ${theme?.color.accent}20 !important;
            color: ${theme?.color.accent} !important;

            &.DayPicker-Day--today {
              color: ${theme?.color.accent} !important;
            }
          }

          @media (hover: hover) {
            &:hover {
              background-color: ${theme?.color.accent} !important;
            }
          }
        }

        &--outside {
          background-color: transparent !important;

          @media (hover: hover) {
            &:hover {
              background-color: transparent !important;
            }
          }
        }

        &--disabled {
          color: ${theme?.color.textGrayscale5} !important;

          @media (hover: hover) {
            &:hover {
              color: ${theme?.color.textGrayscale5} !important;
              background-color: transparent !important;
            }
          }
        }
      }
    }

    .selecto-selection {
      border: 0 !important;
      background: ${theme?.color.accent}50 !important;
    }
  `;
};

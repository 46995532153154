import { BlockType } from '@tallyforms/lib';
import last from 'lodash/last';

import { getVerticalScrollOffset } from '@/utils/dom';
import { getCursorPoint, getRangePoint, setCursor } from '@/utils/form-builder/selection';

export const setInnerHTML = (el: HTMLElement, value: string, focus = false) => {
  el.innerHTML = value;

  if (focus) {
    el.focus();
  }
};

export const recursiveSanitizeChildNodes = (
  childNodes: NodeListOf<ChildNode>,
): NodeListOf<ChildNode> => {
  childNodes.forEach((node, index) => {
    if (
      node.nodeType === Node.ELEMENT_NODE &&
      (node.childNodes.length === 0 || node.textContent?.length === 0)
    ) {
      // Remove empty element nodes
      childNodes.item(index).remove();
    }

    if (node.nodeType === Node.ELEMENT_NODE && node.childNodes.length > 0) {
      // Child node with children, go deeper
      recursiveSanitizeChildNodes(node.childNodes);
    }
  });

  return childNodes;
};

export const childNodesToHTML = (childNodes: NodeListOf<ChildNode>): string => {
  let html = '';

  childNodes.forEach((node) => {
    html += node.nodeType === Node.TEXT_NODE ? node.textContent : (node as Element).outerHTML;
  });

  return html;
};

export const filterOutEmptyTextChildNodes = (target: HTMLElement | ChildNode): ChildNode[] => {
  return Array.from(target.childNodes).filter(
    (x: ChildNode) => !(x.nodeType === Node.TEXT_NODE && x.textContent === ''),
  );
};

export const getDeepestFirstChildNode = (el: HTMLElement): ChildNode | null => {
  const getFirstChildNode = (target: HTMLElement | ChildNode): ChildNode | null => {
    return filterOutEmptyTextChildNodes(target)[0] || null;
  };

  let deepestNode = getFirstChildNode(el);

  while (deepestNode && getFirstChildNode(deepestNode)) {
    deepestNode = getFirstChildNode(deepestNode);
  }

  return deepestNode;
};

export const getDeepestLastChildNode = (el: HTMLElement): ChildNode | null => {
  const getLastChildNode = (target: HTMLElement | ChildNode): ChildNode | null => {
    return last(filterOutEmptyTextChildNodes(target)) || null;
  };

  let deepestNode = getLastChildNode(el);

  while (deepestNode && getLastChildNode(deepestNode)) {
    deepestNode = getLastChildNode(deepestNode);
  }

  return deepestNode;
};

export const isNodeEditable = (node: ChildNode): boolean => {
  let contentEditableEl: Element | null | undefined;

  if (node.nodeType === Node.ELEMENT_NODE) {
    contentEditableEl = (node as Element).closest('[contenteditable]');
  } else {
    contentEditableEl = node.parentElement?.closest('[contenteditable]');
  }

  return contentEditableEl?.getAttribute('contenteditable') === 'true';
};

export const closestEditableSibling = (node: ChildNode): ChildNode | null => {
  const el = node.nodeType === Node.ELEMENT_NODE ? (node as Element) : node.parentElement;

  if (!el) {
    return null;
  }

  let nextNode = el.nextSibling;
  let prevNode = el.previousSibling;

  let traverse = true;
  while (traverse) {
    if (nextNode && isNodeEditable(nextNode)) {
      return nextNode;
    } else if (nextNode) {
      nextNode = nextNode.nextSibling;
    }

    if (prevNode && isNodeEditable(prevNode)) {
      return prevNode;
    } else if (prevNode) {
      prevNode = prevNode.previousSibling;
    }

    if (!nextNode && !prevNode) {
      traverse = false;
    }
  }

  return null;
};

export const nodeFromPoint = (x: number, y: number): ChildNode | null => {
  const element = document.elementFromPoint(x, y);
  if (!element) {
    return null;
  }

  // If the element doesn't have children, return itself
  let nodes = element.childNodes;
  if (nodes.length === 0) {
    return element;
  }

  // If the element is a parent of an editable block, we want to get the child nodes of the block instead
  const blockEl = element.querySelector('.content-editable-block');
  if (blockEl) {
    nodes = blockEl.childNodes;
  }

  let node;
  const nodesOnTheSameLine: ChildNode[] = [];
  for (let i = 0; i < nodes.length; i++) {
    const range = document.createRange();
    range.selectNode(nodes[i]);
    const rects = range.getClientRects();

    for (let j = 0; j < rects.length; j++) {
      const rect = rects[j];

      // We're always interested in nodes on the same line
      // because if there isn't a node on that line with the provided x coordinates, we want to return the closest possible node
      if (y === rect.top) {
        nodesOnTheSameLine.push(nodes[i]);
      }

      // Nodes which contain the x and y
      if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
        node = nodes[i];
        break;
      }
    }

    if (node) {
      break;
    }
  }

  // If we didn't find an exact node match, return the last node on the same line
  if (!node && nodesOnTheSameLine.length > 0) {
    return nodesOnTheSameLine.pop()!;
  }

  // No node was found, return the element instead
  if (!node) {
    return element;
  }

  return node;
};

export const nodeOffsetFromPoint = (node: ChildNode, x: number, y: number): number => {
  const rtl = node.parentElement && getComputedStyle(node.parentElement).direction === 'rtl';

  let foundOffset = false;
  let offset = 0;

  try {
    while (!foundOffset) {
      const range = document.createRange();
      range.setStart(node, offset);
      const { x: offsetX, y: offsetY } = getRangePoint(range);

      if (y === offsetY && ((!rtl && x <= offsetX) || (rtl && x >= offsetX))) {
        foundOffset = true;
        continue;
      }

      offset++;
    }
  } catch (_e) {
    offset = offset > 0 ? offset - 1 : 0;
  }

  return offset;
};

export const getAllBlockElements = (): HTMLElement[] => {
  if (typeof document === 'undefined') {
    return [];
  }

  return Array.from(document.querySelectorAll('.content-editable-block')) as HTMLElement[];
};

export const getBlockElement = (uuid: string): HTMLElement | null => {
  if (typeof document === 'undefined') {
    return null;
  }

  return document.getElementById(uuid);
};

export const getBlockElementByIndex = (index: number): HTMLElement | null => {
  const blockElements = getAllBlockElements();

  return blockElements[index] ?? null;
};

export const getMovableBlockIndexAbove = (index: number): number => {
  let moveableIndex = index - 2;
  const blockElement = getBlockElementByIndex(index);
  const dropzones = getBlockDropzonesWithoutMatrixCells();

  // Try to find it with block uuid
  let currentDropzoneIndex = dropzones.findIndex((x) => x.dataset.blockUuid === blockElement?.id);

  // If not found, try to find it with block group uuid (For matrix blocks and other blocks with multiple dropzones)
  if (currentDropzoneIndex === -1) {
    const blockGroupUuid = getBlockGroupUuidByIndex(index);
    currentDropzoneIndex = dropzones.findIndex((x) => x.dataset.blockGroupUuid === blockGroupUuid);

    if (currentDropzoneIndex === -1) {
      return moveableIndex;
    }
  }

  const dropzone = dropzones[currentDropzoneIndex - 2];
  if (!dropzone) {
    return moveableIndex;
  }

  moveableIndex = getBlockIndex(dropzone.dataset.blockUuid!);
  return moveableIndex;
};

export const getMovableBlockIndexBelow = (index: number): number => {
  let moveableIndex = index + 1;
  const blockElement = getBlockElementByIndex(index);
  const dropzones = getBlockDropzonesWithoutMatrixCells();

  // Try to find it with block uuid
  let currentDropzoneIndex = dropzones.findIndex((x) => x.dataset.blockUuid === blockElement?.id);

  // If not found, try to find it with block group uuid (For matrix blocks and other blocks with multiple dropzones)
  if (currentDropzoneIndex === -1) {
    const blockGroupUuid = getBlockGroupUuidByIndex(index);
    currentDropzoneIndex = dropzones.findIndex((x) => x.dataset.blockGroupUuid === blockGroupUuid);

    if (currentDropzoneIndex === -1) {
      return moveableIndex;
    }
  }

  const dropzone = dropzones[currentDropzoneIndex + 1];
  if (!dropzone) {
    return moveableIndex;
  }

  moveableIndex = getBlockIndex(dropzone.dataset.blockUuid!);
  return moveableIndex;
};

export const getBlockValue = (uuid: string): string => {
  return getBlockElement(uuid)?.innerHTML ?? '';
};

export const getBlockIndex = (uuid: string): number => {
  const blockEl = getBlockElement(uuid);
  if (!blockEl) {
    return -1;
  }

  return getAllBlockElements().indexOf(blockEl);
};

export const getBlockTypeByIndex = (index: number): BlockType | null => {
  const blockEl = getBlockElementByIndex(index);
  if (!blockEl) {
    return null;
  }

  const tallyBlockEl = blockEl.closest('.tally-block');
  if (!tallyBlockEl) {
    return null;
  }

  const blockClassName = Array.from(tallyBlockEl.classList).find(
    (x) => x.indexOf('tally-block-') !== -1,
  );
  if (!blockClassName) {
    return null;
  }

  return blockClassNameToType(blockClassName);
};

export const getBlockElementContainingNode = (node: Node): HTMLElement | null => {
  // The node is the block element
  if (
    node.nodeType === Node.ELEMENT_NODE &&
    (node as HTMLElement).className?.indexOf('content-editable-block') !== -1
  ) {
    return node as HTMLElement;
  }

  const parentEl = node.parentElement;
  if (!parentEl) {
    return null;
  }

  // Find the content editable div
  return parentEl.nodeType === Node.ELEMENT_NODE &&
    parentEl.className?.indexOf('content-editable-block') !== -1
    ? parentEl
    : parentEl.closest('.content-editable-block');
};

export const getBlockIndexContainsElement = (el: HTMLElement): number => {
  if (!el) {
    return -1;
  }

  if (
    el.className &&
    el.className.indexOf &&
    el.className.indexOf('content-editable-block') !== -1
  ) {
    return getAllBlockElements().indexOf(el);
  }

  const blockContainerEl = el.closest('.block-container');
  if (!blockContainerEl) {
    return -1;
  }

  const blockEl = blockContainerEl.querySelector('.content-editable-block');
  if (!blockEl) {
    return -1;
  }

  return getAllBlockElements().indexOf(blockEl as HTMLElement);
};

export const getFocusedBlockElement = (): HTMLElement | null => {
  if (typeof document === 'undefined') {
    return null;
  }

  if (!document.activeElement) {
    return null;
  }

  if (document.activeElement.className.indexOf('content-editable-block') !== -1) {
    return document.activeElement as HTMLElement;
  }

  return null;
};

export const getHoveredBlockElement = (): HTMLElement | null => {
  if (typeof document === 'undefined') {
    return null;
  }

  const hoveredBlockEl = document.querySelector('.tally-block:hover');
  if (!hoveredBlockEl) {
    return null;
  }

  const editableBlock = hoveredBlockEl.querySelector('.content-editable-block') as HTMLElement;

  return editableBlock;
};

export const getFocusedBlockUuid = (): string | null => {
  const blockEl = getFocusedBlockElement();
  if (!blockEl) {
    return null;
  }

  return blockEl.getAttribute('id');
};

export const getFocusedBlockValue = (): string => {
  const uuid = getFocusedBlockUuid();
  if (!uuid) {
    return '';
  }

  return getBlockValue(uuid);
};

export const getFocusedBlockIndex = (): number => {
  const focusedBlockEl = getFocusedBlockElement();
  if (!focusedBlockEl) {
    return -1;
  }

  return getAllBlockElements().indexOf(focusedBlockEl);
};

export const getHoveredBlockIndex = (): number => {
  const hoveredBlockEl = getHoveredBlockElement();
  if (!hoveredBlockEl) {
    return -1;
  }

  return getAllBlockElements().indexOf(hoveredBlockEl);
};

export const setFocusedBlockValue = (value: string) => {
  const blockEl = getFocusedBlockElement();
  if (!blockEl) {
    return;
  }

  // Save current cursor point
  const { x, y } = getCursorPoint();

  // Update value
  setInnerHTML(blockEl, value);

  // Restore cursor
  const newFocusNode = nodeFromPoint(x, y);
  if (newFocusNode) {
    const newOffset = nodeOffsetFromPoint(newFocusNode, x, y);
    setCursor(newFocusNode, newOffset);
  }
};

export const getFocusableBlockIndexAbove = (index: number): number => {
  const blocksAbove = getAllBlockElements().splice(0, index).reverse();

  let focusableIndex = -1;
  while (blocksAbove.length > 0 && focusableIndex < 0) {
    const block = blocksAbove.shift()!;
    index--;

    if (block.getAttribute('contenteditable') === 'true' && block.dataset.isFolded === 'false') {
      focusableIndex = index;
    }
  }

  return focusableIndex;
};

export const getFocusableBlockIndexBelow = (index: number): number => {
  const blocksBelow = getAllBlockElements().splice(index + 1);

  let focusableIndex = -1;
  while (blocksBelow.length > 0 && focusableIndex < 0) {
    const block = blocksBelow.shift()!;
    index++;

    if (block.getAttribute('contenteditable') === 'true' && block.dataset.isFolded === 'false') {
      focusableIndex = index;
    }
  }

  return focusableIndex;
};

export const getSelectableBlockIndexAbove = (index: number): number => {
  const blocksAbove = getAllBlockElements().splice(0, index).reverse();

  let selectableIndex = -1;
  while (blocksAbove.length > 0 && selectableIndex < 0) {
    const block = blocksAbove.shift()!;
    index--;

    if (block.dataset.isSelectable === 'true' && block.dataset.isFolded === 'false') {
      selectableIndex = index;
    }
  }

  return selectableIndex;
};

export const getSelectableBlockIndexBelow = (index: number): number => {
  const blocksBelow = getAllBlockElements().splice(index + 1);

  let selectableIndex = -1;
  while (blocksBelow.length > 0 && selectableIndex < 0) {
    const block = blocksBelow.shift()!;
    index++;

    if (block.dataset.isSelectable === 'true' && block.dataset.isFolded === 'false') {
      selectableIndex = index;
    }
  }

  return selectableIndex;
};

export const createBlockRef = (
  value: string,
  options: {
    focus?: boolean;
    cursor?: {
      x: number;
      y: number;
    };
  } = {},
) => {
  return (el: HTMLDivElement) => {
    if (el) {
      // Set value and focus
      setInnerHTML(el, value, options.focus);

      if (options.focus) {
        const blockContainerEl = el.closest('.block-container');
        if (!blockContainerEl) {
          return;
        }

        // Make sure the element is visible within the scroll with some offset
        const { top, bottom, height } = blockContainerEl.getBoundingClientRect();
        if (!(top >= 0 && bottom <= window.innerHeight - 200)) {
          window.scrollTo(0, document.documentElement.scrollTop + height);
        }
      }

      // Set the cursor
      if (options.cursor) {
        const node = nodeFromPoint(options.cursor.x, options.cursor.y);
        if (node) {
          setCursor(node, nodeOffsetFromPoint(node, options.cursor.x, options.cursor.y));
        }
      }
    }
  };
};

export const createEmbedBlockRef = () => {
  return (el: HTMLElement) => {
    if (el) {
      // @ts-ignore
      el.closest('.block-container')?.querySelector('.add-container')?.click();
    }
  };
};

export const getRestoredBlockRef = (value: string) => {
  return (el: HTMLElement) => {
    if (el) {
      // Set value
      setInnerHTML(el, value);
    }
  };
};

export const getPositionForContextMenuByAction = (
  uuid: string | undefined,
  action: string,
): {
  top: number;
  bottom: number;
  left: number;
  right: number;
} => {
  // Otherwise it's triggered by a block (the one we've identified or the focused one)
  const blockEl = uuid ? getBlockElement(uuid) : getFocusedBlockElement();
  if (!blockEl) {
    return {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
  }

  const blockContainerEl = blockEl.closest('.block-container');
  if (!blockContainerEl) {
    return {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
  }

  const actionEl = blockContainerEl.querySelector(`.${action}`) ?? blockContainerEl;

  return {
    top: actionEl.getBoundingClientRect().bottom + 5 + getVerticalScrollOffset(),
    bottom:
      window.innerHeight - actionEl.getBoundingClientRect().top - getVerticalScrollOffset() + 5,
    left: actionEl.getBoundingClientRect().left + window.pageXOffset,
    right: window.innerWidth - actionEl.getBoundingClientRect().right - window.pageXOffset,
  };
};

export const blockClassNameToType = (className: string): BlockType => {
  return className.replace('tally-block-', '').replace(/-/g, '_').toUpperCase() as BlockType;
};

export const getBlockGroupUuidByIndex = (index: number): string | null => {
  const blockEl = getBlockElementByIndex(index);
  if (!blockEl) {
    return null;
  }

  const tallyBlockEl = blockEl.closest('.tally-block');
  if (!tallyBlockEl) {
    return null;
  }

  const blockClassName = Array.from(tallyBlockEl.classList).find(
    (x) => x.indexOf('tally-block-group-') !== -1,
  );
  if (!blockClassName) {
    return null;
  }

  return blockClassName.replace('tally-block-group-', '');
};

export const isContextMenuOpened = (): boolean => {
  const contextMenu = document.querySelector('.tally-context-menu-overlay') as HTMLElement | null;
  if (contextMenu !== null) {
    return true;
  }

  return false;
};

export const getBlockDropzonesWithoutMatrixCells = (): HTMLElement[] => {
  return (
    Array.from(document.querySelectorAll(`.move-dropzone[data-style="block"]`)) as HTMLElement[]
  ).filter(
    (x) =>
      [BlockType.MatrixColumn, BlockType.MatrixRow].includes(x.dataset.blockType! as BlockType) ===
      false,
  );
};

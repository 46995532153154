import useFormBuilderCustomizationActions from '@/hooks/form-builder/use-customization-actions';
import { FormStyles } from '@/types/form-design';

import { BuilderContainer, RespondContainer } from './styled';

interface Props {
  logo: string;
  withCover: boolean;
  inFormBuilder?: boolean;
  styles?: FormStyles;
  onClick?: () => void;
}

const FormBuilderLogo = ({ logo, withCover, inFormBuilder = false, styles, onClick }: Props) => {
  const ref = useFormBuilderCustomizationActions();

  if (inFormBuilder) {
    return (
      <BuilderContainer
        ref={ref}
        className="tally-form-logo"
        onClick={onClick}
        withCover={withCover}>
        <img src={logo} alt="Form logo" />
      </BuilderContainer>
    );
  }

  return (
    <RespondContainer className="tally-form-logo" withCover={withCover} styles={styles}>
      <img src={logo} alt="Form logo" />
    </RespondContainer>
  );
};

export default FormBuilderLogo;

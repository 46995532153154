import styled, { css } from 'styled-components';

const InputStyles = css`
  width: 100%;
  height: 36px;
  padding: 0 ${({ theme }) => theme.spacing.sm};
  box-shadow: ${({ theme }) => theme.color.inputBoxShadow};
  border: 0;
  border-radius: 5px;
  outline: none;
  background-color: ${({ theme }) => theme.color.inputBackground};
  color: ${({ theme }) => theme.color.text};
  caret-color: ${({ theme }) => theme.color.text};

  &:not([disabled]) {
    @media (hover: hover) {
      &:hover {
        box-shadow: ${({ theme }) => theme.color.inputBoxShadowHover};
      }
    }

    &:focus {
      box-shadow: ${({ theme }) => theme.color.inputBoxShadowFocus};
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.inputPlaceholder};
  }
`;

export const Container = styled.input`
  ${InputStyles}
`;

export const InputWithActionsContainer = styled.div`
  ${InputStyles}

  &:focus-within {
    box-shadow: ${({ theme }) => theme.color.inputBoxShadowFocus};
  }

  input::placeholder {
    color: ${({ theme }) => theme.color.inputPlaceholder};
  }
`;

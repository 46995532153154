import { Country, withMinDuration } from '@tallyforms/lib';
import { useEffect, useMemo, useState } from 'react';

import api from '@/services/api';

const fetchCountry = async (options: { delay: number }) => {
  const delay = options?.delay;
  const res = await withMinDuration(async () => api.get('/country'), delay)();

  return res.data.country || null;
};

export const useCountry = (options?: { delay?: number }) => {
  const delay = typeof options?.delay === 'number' ? options.delay : 1000;
  const [state, setState] = useState<'idle' | 'loading' | 'error'>('idle');
  const [country, setCountry] = useState<Country | null>(null);

  useEffect(() => {
    setState('loading');
    fetchCountry({ delay })
      .then((value) => {
        setCountry(value);
        setState('idle');
      })
      .catch(() => setState('error'));
  }, []);

  const isLoading = state === 'loading';

  return useMemo(
    () => ({
      isLoading,
      country,
    }),
    [country, isLoading],
  );
};

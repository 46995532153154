import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type CaptchaBlock = Block<BlockType.Captcha>;

export function isCaptchaBlock(block: FormBuilderBlock): block is FormBuilderBlock<CaptchaBlock>;

export function isCaptchaBlock(block: RespondBlock): block is RespondBlock<CaptchaBlock>;

export function isCaptchaBlock(block: Block): block is CaptchaBlock {
  return block.type === BlockType.Captcha;
}

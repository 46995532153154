import debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';

import {
  REQUIRED_INDICATOR_PADDING,
  REQUIRED_INDICATOR_SIZE,
} from '@/components/form/required-indicator/styled';
import useFormBuilderVisibleBlock from '@/hooks/form-builder/use-visible-block';
import { getLeftPositionFromRange } from '@/utils/dom';

export const useRequiredIndicatorPosition = (
  uuid: string,
  isRequired?: boolean,
  isFormBuilder = false,
) => {
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';
  const areObserversSupported =
    typeof ResizeObserver !== 'undefined' && typeof MutationObserver !== 'undefined';

  // Used for when we are in preview, we would need a small delay
  const isFirstRun = useRef(true);
  const isVisible = useFormBuilderVisibleBlock(uuid);

  // This will either return the body or the preview div
  const getContainer = () => {
    let container = document.body;

    // If we are in preview we need to set the container to the preview div
    const preview = document.getElementById('preview');
    if (preview) {
      container = preview;
    }

    return container;
  };

  /**
   * Set to defaultLeft position to negative (CONTAINER_SIZE + CONTAINER_PADDING to the left)
   * In case of RTL, we set the left to the right position instead
   */
  const setDefaultPosition = (el: HTMLElement) => {
    const defaultLeft = -1 * (REQUIRED_INDICATOR_SIZE + REQUIRED_INDICATOR_PADDING);

    if (isRTL) {
      // unset the right if it's set
      el.style.right = 'unset';
      el.style.left = `${defaultLeft}px`;
    } else {
      // unset the left if it's set
      el.style.left = 'unset';
      el.style.right = `${defaultLeft}px`;
    }

    // If it's hidden show it
    el.classList.remove('hidden');
  };

  const calculatePosition = () => {
    if (!isRequired) {
      return;
    }

    // Use to get the elements we need
    const container = getContainer();
    if (!container) {
      return;
    }

    // get the tally block to make sure we don't go outside of it
    const blockEl = container.querySelector(`.tally-block-${uuid}`) as HTMLElement;
    const requiredIndicatorEl = blockEl?.querySelector('.tally-required-indicator') as HTMLElement;
    if (!requiredIndicatorEl || !blockEl) {
      return;
    }

    // Remove fallback positions
    requiredIndicatorEl.style.top = 'unset';
    requiredIndicatorEl.style.bottom = blockEl.className.includes('tally-block-label')
      ? '10px'
      : '13px';

    // Parent rect that holds the text
    const parentRect = requiredIndicatorEl.parentElement?.getBoundingClientRect();
    const childNodes = requiredIndicatorEl.previousSibling?.childNodes;

    // If no children or parent rect, set to default position
    if (!childNodes || !parentRect || childNodes.length === 0) {
      setDefaultPosition(requiredIndicatorEl);
      return;
    }

    let left = getLeftPositionFromRange(requiredIndicatorEl.previousSibling);
    if (!left) {
      setDefaultPosition(requiredIndicatorEl);
      return;
    }

    // Add the padding
    left += REQUIRED_INDICATOR_PADDING;

    // If the parent width - the indicator padding is less than the left, set left to the parent width - padding * 2 so it doesn't go outside
    if (parentRect.width - REQUIRED_INDICATOR_PADDING < left) {
      // This means we're running out of space, so we need to position it right at the end of the block
      if (blockEl.getBoundingClientRect().width === parentRect.width) {
        left = parentRect.width - REQUIRED_INDICATOR_PADDING * 2;
      } else {
        /**
         * This means we're on the first line of the block, so we need to position it at the end of the parent width
         * This happens because div reports two identical rects for the same line which causes required indicator to be far position
         */
        left = parentRect.width;
      }
    }

    // Set the position based on RTL or not
    if (isRTL) {
      // unset the left if it's set
      requiredIndicatorEl.style.left = 'unset';
      requiredIndicatorEl.style.right = `${left}px`;
    } else {
      // unset the right if it's set
      requiredIndicatorEl.style.right = 'unset';
      requiredIndicatorEl.style.left = `${left}px`;
    }

    // If it's hidden show it
    requiredIndicatorEl.classList.remove('hidden');
  };

  useEffect(() => {
    // If we are in form builder and the block is not visible, don't do anything
    if (isFormBuilder && !isVisible) {
      return;
    }

    // Setup observers to watch over content changes
    const container = getContainer();
    if (!container) {
      return;
    }

    const blockEl = container.querySelector(`.tally-block-${uuid}`);
    if (!blockEl) {
      return;
    }

    if (!areObserversSupported) {
      // Just show all indicators
      document.querySelectorAll('.tally-required-indicator').forEach((el) => {
        el.classList.remove('hidden');
      });

      /**
       * Observers are not supported, add fallback class to position the indicator the old way (At the end of the block)
       * We add this class to the content element
       */
      blockEl
        .querySelector('.title-content')
        ?.classList.add('required-indicator-position-fallback');

      return;
    }

    calculatePosition();

    // If we're in a preview, pass a function to add a small delay for first run
    const calculatePositionFn = document.getElementById('preview')
      ? () => {
          if (isFirstRun.current) {
            setTimeout(calculatePosition, 100);
            isFirstRun.current = false;
            return;
          }

          calculatePosition();
        }
      : calculatePosition;

    const debouncedCalculateFn = debounce(calculatePositionFn, 30);

    const resizeObserver = new ResizeObserver(debouncedCalculateFn);
    const mutationObserver = new MutationObserver(debouncedCalculateFn);

    // Listen for resize
    // Needed for column layouts and resizing the window
    resizeObserver.observe(blockEl);

    blockEl.querySelectorAll('.content-editable-block, h3, label').forEach((el) => {
      // Listen for resize on the title elements
      // Needed for font and style changes
      resizeObserver.observe(el);

      // Listen for mutations in the title elements
      // Needed for when text is updated
      mutationObserver.observe(el, {
        childList: true,
        subtree: true,
        // Needed for content editable so text mutation trigger this one
        characterData: true,
        attributes: true,
      });
    });

    return () => {
      if (!resizeObserver && !mutationObserver) {
        return;
      }

      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [isRequired, isVisible, theme]);
};

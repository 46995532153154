import { Locale } from 'date-fns';
import { t as i18nT, TFunction } from 'i18next';

export interface DateFormatProps {
  f: string;
  months: string[];
  monthsShort: string[];
  weekdays: string[];
  weekdaysShort: string[];
  locale: Locale;
}

export const getDateFormatProps = (options?: { t?: TFunction }): DateFormatProps => {
  const t = options?.t || i18nT;

  const {
    code,
    format: f,
    months,
    monthsShort,
    weekdays,
    weekdaysShort,
  } = JSON.parse(t('json.date-locale'));

  return {
    f,
    months,
    monthsShort,
    weekdays,
    weekdaysShort,
    locale: {
      code,
      formatLong: {
        date: () => f,
      },
      localize: {
        month: (i: number) => monthsShort[i],
      },
    } as unknown as Locale,
  };
};

import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type TitleBlock = Block<
  BlockType.Title,
  {
    isHidden?: boolean;
    isFolded?: boolean;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
  }
>;

export function isTitleBlock(block: FormBuilderBlock): block is FormBuilderBlock<TitleBlock>;

export function isTitleBlock(block: RespondBlock): block is RespondBlock<TitleBlock>;

export function isTitleBlock(block: Block): block is TitleBlock {
  return block.type === BlockType.Title;
}

import { ColorTheme, useColorTheme } from '@tallyforms/ui';
import Head from 'next/head';
import { Fragment } from 'react';

import config from '@/config';
import { formLanguageToCookieConsentLanguage } from '@/services/cookie';
import { MetaData } from '@/types/html';
import {
  Integration,
  IntegrationFacebookPixel,
  IntegrationGoogleAnalytics,
  IntegrationType,
} from '@/types/integration';
import { safeOgImageUrl } from '@/utils/url';

interface Props {
  metaData: MetaData;
  integrations?: Integration[];
  useEmbedWidgetScript?: boolean;
}

const LayoutHead = ({ metaData, integrations, useEmbedWidgetScript = false }: Props) => {
  const colorTheme = useColorTheme();
  const ogImage = metaData.imageUrl || '/images/og.jpg';

  // GA
  const googleAnalytics: IntegrationGoogleAnalytics | undefined = integrations?.find(
    ({ type }) => type === IntegrationType.GoogleAnalytics,
  )?.data;

  // FB pixel
  const fbPixel: IntegrationFacebookPixel | undefined = integrations?.find(
    ({ type }) => type === IntegrationType.FacebookPixel,
  )?.data;

  return (
    <Head>
      <title>{metaData.title}</title>
      {metaData.description && <meta name="description" content={metaData.description} />}
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0"
      />
      {metaData.allowIndexing ? (
        <meta content="index,nofollow" name="robots" />
      ) : (
        <meta content="noindex,nofollow" name="robots" />
      )}

      {metaData.faviconUrl ? (
        <link rel="icon" href={metaData.faviconUrl} type={metaData.faviconType || 'image/x-icon'} />
      ) : (
        <>
          <link
            rel="shortcut icon"
            type="image/png"
            href={new URL('/tally-square.png', config.get('web.base-url')).toString()}
          />
          <link
            rel="alternate icon"
            type="image/png"
            href={new URL(
              colorTheme === ColorTheme.Dark ? '/favicon-light.png' : '/favicon.png',
              config.get('web.base-url'),
            ).toString()}
          />
          <link
            rel="icon"
            type="image/svg+xml"
            href={new URL(
              colorTheme === ColorTheme.Dark ? '/favicon-light.svg' : '/favicon.svg',
              config.get('web.base-url'),
            ).toString()}
          />
          <link
            rel="icon"
            type="image/x-icon"
            href={new URL(
              colorTheme === ColorTheme.Dark ? '/favicon-light.ico' : '/favicon.ico',
              config.get('web.base-url'),
            ).toString()}
          />
        </>
      )}

      {metaData.font && (
        <link
          href={`https://fonts.googleapis.com/css?family=${metaData.font.family.replace(
            /\s/g,
            '+',
          )}:400,500,800,900&display=swap`}
          rel="stylesheet"
        />
      )}

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={metaData.siteName} />
      <meta property="og:url" content={metaData.url} />
      <meta name="title" property="og:title" content={metaData.title} />
      {metaData.description && <meta property="og:description" content={metaData.description} />}
      <meta name="image" property="og:image" content={safeOgImageUrl(ogImage)} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={config.get('community.twitter-username')} />
      <meta name="twitter:title" content={metaData.title} />
      {metaData.description && <meta name="twitter:description" content={metaData.description} />}
      <meta name="twitter:image" content={safeOgImageUrl(ogImage)} />

      {metaData.oembedUrl && !metaData.isCustomDomain && (
        <link
          rel="alternate"
          type="application/json+oembed"
          href={`${config.get('api.base-url')}/oembed?url=${encodeURIComponent(
            metaData.oembedUrl,
          )}&format=json`}
          title={metaData.title}
        />
      )}

      <script
        noModule={true}
        dangerouslySetInnerHTML={{
          __html: `window.location.href = "${config.get(
            'web.base-url',
          )}/browser-not-supported.html";`,
        }}
      />

      {!metaData.doNotTrack && googleAnalytics && (
        <Fragment>
          {googleAnalytics.askForCookieConsent && (
            <Fragment>
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `
             var _iub = _iub || [];
            _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"cookiePolicyInOtherWindow":true,"floatingPreferencesButtonDisplay":"bottom-left","lang":"${formLanguageToCookieConsentLanguage(
              metaData.language,
            )}","perPurposeConsent":true,"purposes":"1,4,5","siteId":3514643,"whitelabel":false,"cookiePolicyId":11696253,"cookiePolicyUrl":"https://tally.so/help/cookie-policy", "banner":{ "acceptButtonColor":"#0070D7","acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"bottom","rejectButtonColor":"#0070D7","rejectButtonDisplay":true,"showPurposesToggles":true,"showTitle":false }};
           `,
                }}
              />
              <script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3514643.js" />
              <script
                type="text/javascript"
                src="//cdn.iubenda.com/cs/iubenda_cs.js"
                async={true}
              />
            </Fragment>
          )}

          <script
            async={true}
            src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics.trackingId}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
             window.dataLayer = window.dataLayer || [];
             function gtag(){dataLayer.push(arguments);}
             gtag('js', new Date());
            ${
              googleAnalytics.askForCookieConsent === false &&
              `
              gtag('consent', 'default', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted'
              });
            `
            }
             gtag('config', '${googleAnalytics.trackingId}', {
               page_path: window.location.pathname,
               cookie_flags: 'secure;samesite=none'
             });
           `,
            }}
          />
        </Fragment>
      )}

      {!metaData.doNotTrack && fbPixel && (
        <Fragment>
          <script
            type={googleAnalytics?.askForCookieConsent ? `text/plain` : `text/javascript`}
            className={googleAnalytics?.askForCookieConsent ? `_iub_cs_activate` : ''}
            dangerouslySetInnerHTML={{
              __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${fbPixel.pixelId}');
              fbq('track', 'PageView');
           `,
            }}
          />
          <noscript
            // @ts-ignore
            type={googleAnalytics?.askForCookieConsent ? `text/plain` : `text/javascript`}
            className={googleAnalytics?.askForCookieConsent ? `_iub_cs_activate` : ''}
            dangerouslySetInnerHTML={{
              __html: `
              <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${fbPixel.pixelId}&ev=PageView&noscript=1" />
            `,
            }}
          />
        </Fragment>
      )}

      {useEmbedWidgetScript && (
        <script async={true} src={`${config.get('web.base-url')}/widgets/embed.js`} />
      )}

      {metaData.isFormEmbedded && (
        <script
          dangerouslySetInnerHTML={{
            __html: `window.iFrameResizer = { heightCalculationMethod: function () { return document.body.clientHeight; } }`,
          }}
        />
      )}
    </Head>
  );
};

export default LayoutHead;

import {
  Block,
  BlockType,
  CalculatedField,
  CalculatedFieldsBlock,
  ConditionalLogicAction,
  ConditionalLogicConditional,
  ConditionalLogicType,
  EMPTY_QUESTION_TITLES,
  Field,
  FieldsOptions,
  FieldType,
  HiddenField,
  isHiddenFieldsBlock,
  RespondBlock,
} from '@tallyforms/lib';
import he from 'he';
import { v4 as uuid } from 'uuid';

import { getBlocksBeforeSpecificBlock } from '@/utils/block';
import { transformBlockToQuestion } from '@/utils/block-transformer';
import { convertMatrixUuidToText } from '@/utils/blocks';

export const blankHiddenFieldFactory = (): HiddenField => {
  return {
    uuid: uuid(),
  };
};

export const blankCalculatedFieldFactory = (): CalculatedField => {
  return {
    uuid: uuid(),
  };
};

export const getFields = (blocks: (Block | RespondBlock)[], options?: FieldsOptions): Field[] => {
  const fields: Field[] = [];
  const processedGroups: string[] = [];
  const allBlocks = [...blocks];

  // Get only blocks before specific block
  if (options?.beforeBlockUuid) {
    blocks = getBlocksBeforeSpecificBlock(
      blocks,
      options.beforeBlockUuid,
      options?.alwaysIncludeBlockTypes,
    );
  }

  // Filter out block types
  if (options?.filterOutBlockTypes && options.filterOutBlockTypes.length > 0) {
    blocks = blocks.filter(({ type }) => options.filterOutBlockTypes!.includes(type) === false);
  }

  // Do we include the metadata fields?
  if (options?.includeMetadata) {
    fields.push({
      uuid: 'id',
      type: FieldType.Metadata,
      questionType: BlockType.FormTitle,
      blockGroupUuid: 'id',
      title: 'id',
    });
    fields.push({
      uuid: 'respondentId',
      type: FieldType.Metadata,
      questionType: BlockType.FormTitle,
      blockGroupUuid: 'respondentId',
      title: 'respondentId',
    });
    fields.push({
      uuid: 'formName',
      type: FieldType.Metadata,
      questionType: BlockType.FormTitle,
      blockGroupUuid: 'formName',
      title: 'formName',
    });
  } else if (options?.includeRespondentMetadata) {
    fields.push({
      uuid: 'ipAddress',
      type: FieldType.Metadata,
      questionType: BlockType.FormTitle,
      blockGroupUuid: 'ipAddress',
      title: 'ipAddress',
    });
    fields.push({
      uuid: 'respondentId',
      type: FieldType.Metadata,
      questionType: BlockType.FormTitle,
      blockGroupUuid: 'respondentId',
      title: 'respondentId',
    });
  }

  blocks.forEach((block) => {
    if (processedGroups.includes(block.groupUuid)) {
      return;
    }

    if (block.type === BlockType.CalculatedFields) {
      (block.payload as CalculatedFieldsBlock['payload']).calculatedFields
        .filter((x) => x.name && x.type)
        .forEach((calculatedField) => {
          fields.push({
            uuid: calculatedField.uuid,
            type: FieldType.CalculatedField,
            questionType: BlockType.CalculatedFields,
            blockGroupUuid: block.groupUuid,
            title: calculatedField.name || '',
            calculatedFieldType: calculatedField.type,
          });
        });
    } else if (isHiddenFieldsBlock(block)) {
      block.payload.hiddenFields.forEach((hiddenField) => {
        fields.push({
          uuid: hiddenField.uuid,
          type: FieldType.HiddenField,
          questionType: BlockType.HiddenFields,
          blockGroupUuid: block.groupUuid,
          title: hiddenField.name || '',
        });
      });
    } else if (block.type === BlockType.Matrix && !options?.combineMatrix) {
      const matrixRows = blocks.filter(
        (x) => x.groupUuid === block.groupUuid && x.type === BlockType.MatrixRow,
      );
      const question = transformBlockToQuestion(block, allBlocks);
      matrixRows.forEach((row) => {
        let title = convertMatrixUuidToText(row.uuid, allBlocks);

        if (
          question &&
          question.title &&
          question.title !== EMPTY_QUESTION_TITLES[BlockType.Matrix]
        ) {
          title = `${question.title} [${title}]`;
        }

        fields.push({
          uuid: row.uuid,
          type: FieldType.InputField,
          questionType: BlockType.Matrix,
          blockGroupUuid: row.groupUuid,
          title,
        });
      });
    } else {
      const question = transformBlockToQuestion(block, allBlocks);
      if (question) {
        fields.push({
          uuid: question.blockGroupUuid,
          type: FieldType.InputField,
          questionType: question.type,
          blockGroupUuid: question.blockGroupUuid,
          title: he.decode(question.title || ''),
        });
      }
    }

    processedGroups.push(block.groupUuid);
  });

  return fields;
};

export const getFieldTitle = (field: Field, t: any): string => {
  let title = field.title;

  if (field.type === FieldType.Metadata) {
    title = t(`form-builder.mention-menu.metadata.${field.uuid}`);
  }

  return title;
};

export const canFindFieldReferencesInLogic = (
  payload: {
    conditionals?: ConditionalLogicConditional[];
    actions?: ConditionalLogicAction[];
  },
  block: Block,
  blockGroupUuids: string[],
): boolean => {
  let hasReferences = false;

  for (const conditional of payload.conditionals ?? []) {
    if (
      (conditional.payload?.field?.blockGroupUuid &&
        blockGroupUuids.includes(conditional.payload.field.blockGroupUuid)) ||
      (conditional.payload?.value?.blockGroupUuid &&
        blockGroupUuids.includes(conditional.payload.value.blockGroupUuid))
    ) {
      return true;
    }

    if (conditional.type === ConditionalLogicType.Group && conditional.payload?.conditionals) {
      hasReferences = canFindFieldReferencesInLogic(conditional.payload, block, blockGroupUuids);

      if (hasReferences) {
        return true;
      }
    }
  }

  for (const action of payload.actions ?? []) {
    if (action.payload?.requireAnswer && blockGroupUuids.includes(action.payload.requireAnswer)) {
      return true;
    } else if (
      action.payload?.calculate?.field?.blockGroupUuid &&
      blockGroupUuids.includes(action.payload.calculate.field.blockGroupUuid)
    ) {
      return true;
    } else if (
      action.payload?.calculate?.value?.blockGroupUuid &&
      blockGroupUuids.includes(action.payload.calculate.value.blockGroupUuid)
    ) {
      return true;
    } else if (
      action.payload?.showBlocks &&
      action.payload.showBlocks.some((x) => x === block.uuid)
    ) {
      return true;
    } else if (
      action.payload?.hideBlocks &&
      action.payload.hideBlocks.some((x) => x === block.uuid)
    ) {
      return true;
    } else if (
      action.payload?.jumpToPage === block.uuid ||
      (action.payload?.jumpToPage && block.type === BlockType.PageBreak)
    ) {
      return true;
    }
  }

  return hasReferences;
};

export const canFindFieldReferencesInCalculatedFields = (
  payload: CalculatedFieldsBlock['payload'],
  blockGroupUuids: string[],
): boolean => {
  for (const calculatedField of payload.calculatedFields) {
    if (
      calculatedField.value?.blockGroupUuid &&
      blockGroupUuids.includes(calculatedField.value.blockGroupUuid)
    ) {
      return true;
    }
  }

  return false;
};

import { defaultColors } from '@tallyforms/ui';
import tinycolor from 'tinycolor2';

const COLORS_CACHE: { [key: string]: string } = {};

export const multiSelectColor: { [key: string]: string } = {
  red: defaultColors.redDark2,
  orange: defaultColors.orangeDark2,
  yellow: defaultColors.yellowDark2,
  green: defaultColors.greenDark2,
  blue: defaultColors.blueDark2,
  purple: defaultColors.purpleDark2,
  pink: defaultColors.pinkDark2,
  gray: defaultColors.text,
};

export const multiSelectBackgroundColor: { [key: string]: string } = {
  gray: defaultColors.grayBackground,
  red: defaultColors.redLight2,
  orange: defaultColors.orangeLight2,
  yellow: defaultColors.yellowLight1,
  green: defaultColors.greenLight2,
  blue: defaultColors.blueLight2,
  purple: defaultColors.purpleLight2,
  pink: defaultColors.pinkLight2,
};

export const multiSelectColorOptions: { [key: string]: string } = {
  gray: defaultColors.grayBackground,
  red: defaultColors.redLight1,
  orange: defaultColors.orangeLight1,
  yellow: defaultColors.yellowLight1,
  green: defaultColors.greenLight1,
  blue: defaultColors.blueLight1,
  purple: defaultColors.purpleLight1,
  pink: defaultColors.pinkLight1,
};

export const multiSelectBorderColor: { [key: string]: string } = {
  gray: defaultColors.gray,
  red: defaultColors.red,
  orange: defaultColors.orange,
  yellow: defaultColors.yellowDark1,
  green: defaultColors.green,
  blue: defaultColors.blue,
  purple: defaultColors.purple,
  pink: defaultColors.pink,
};

export const withAlpha = (color: string, alpha: number): string => {
  const key = `${color}_${alpha}`;
  if (COLORS_CACHE[key]) {
    return COLORS_CACHE[key];
  }

  const colorWithAlpha = tinycolor(color).setAlpha(alpha).toRgbString();
  COLORS_CACHE[key] = colorWithAlpha;

  return colorWithAlpha;
};

export const getGroupInputBoxShadow = (color: string): string => {
  return `${tinycolor(color).setAlpha(0.12).toRgbString()}  0px 1px 1px 0px, ${tinycolor(color)
    .setAlpha(0.16)
    .toRgbString()} 0px 0px 0px 1px, ${tinycolor(color)
    .setAlpha(0.08)
    .toRgbString()} 0px 2px 5px 0px`;
};

export const getGroupInputBoxShadowHover = (color: string): string => {
  return `${tinycolor(color).setAlpha(0.12).toRgbString()} 0px 1px 1px 0px, ${tinycolor(color)
    .setAlpha(0.16)
    .toRgbString()} 0px 0px 0px 1px,${tinycolor(color)
    .setAlpha(0.08)
    .toRgbString()} 0px 3px 9px 0px, ${tinycolor(color)
    .setAlpha(0.08)
    .toRgbString()} 0px 2px 5px 0px`;
};

export const getGroupInputBoxShadowActive = (color: string): string => {
  return `${color} 0px 0px 0px 2px, ${tinycolor(color)
    .setAlpha(0.16)
    .toRgbString()} 0px 0px 0px 1px`;
};

export const getGroupInputBoxShadowFocus = (color: string): string => {
  return `${tinycolor(color).setAlpha(0.36).toRgbString()} 0px 0px 0px 4px, ${tinycolor(color)
    .setAlpha(0.16)
    .toRgbString()} 0px 0px 0px 1px`;
};

export const getGroupInputBoxShadowActiveFocus = (color: string): string => {
  return `${tinycolor(color)
    .setAlpha(0.36)
    .toRgbString()} 0px 0px 0px 4px, ${color} 0px 0px 0px 2px`;
};

export const getMultiSelectColor = (index: number): string => {
  const colors = Object.keys(multiSelectColor);
  return colors[index % colors.length];
};

export const isSameColor = (color1: any, color2: any): boolean => {
  if (!color1 || !color2) {
    return false;
  }

  return tinycolor(color1).toHex() === tinycolor(color2).toHex();
};

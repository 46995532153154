import styled, { keyframes } from 'styled-components';

export const Svg = styled.svg<{ size?: 'default' | 'small' | 'large' }>`
  font-size: 16px;
  stroke: currentColor;
  stroke-width: 3px;
  fill: none;

  ${({ size }) => {
    if (size === 'small') {
      return `
        font-size: 12px;
      `;
    }

    if (size === 'large') {
      return `
        font-size: 24px;
      `;
    }
  }}
`;

const spinnerAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const circleAnimation = keyframes`
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }
  47.5% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -16;
  }
  95%, 100% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -59;
  }
`;

export const Spinner = styled.g`
  transform-origin: center;
  animation: ${spinnerAnimation} 2s linear infinite;
`;

export const Circle = styled.circle`
  fill: none;
  stroke-linecap: round;
  animation: ${circleAnimation} 1.5s ease-in-out infinite;
`;

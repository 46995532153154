export interface State {
  isSidebarOpen: boolean;
}

export interface Action {
  type: ActionType;
  data?: {
    payload: Partial<State>;
  };
}

export enum ActionType {
  Update = 'Preferences.update',
}

export type Preferences = {
  isSidebarOpen?: boolean;
};

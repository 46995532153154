// IE 11 .closest support
import 'element-closest-polyfill';
import 'formdata-polyfill';
import 'url-search-params-polyfill';

// Array.prototype.forEach() polyfill
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

// NodeList.prototype.forEach() polyfill
if (typeof window !== 'undefined' && window.NodeList && !NodeList.prototype.forEach) {
  // @ts-ignore
  NodeList.prototype.forEach = Array.prototype.forEach;
}

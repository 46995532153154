import { Container } from './styled';

interface Props {
  html: string;
}

const TextBlock = ({ html }: Props) => {
  return <Container dangerouslySetInnerHTML={{ __html: html }} className="tally-text" />;
};

export default TextBlock;

import { breakpoint } from '@tallyforms/ui';
import styled from 'styled-components';

export const Container = styled.h1`
  font-size: 40px;
  font-weight: 800;
  line-height: 1.1;
  padding-bottom: ${({ theme }) => theme.spacing.lg};
  margin: 0;

  @media (max-width: ${breakpoint.md}) {
    padding-bottom: ${({ theme }) => theme.spacing.md};
    font-size: 32px;
  }
`;

import { RespondentCountryBlock as RespondentCountryBlockType } from '@tallyforms/lib';
import { useEffect } from 'react';

import { useCountry } from '@/hooks/use-country';

type RespondentCountryBlockProps = RespondentCountryBlockType['payload'] & {
  onChange?: (country: string) => void;
};

const RespondentCountryBlock = ({ onChange }: RespondentCountryBlockProps) => {
  const { country } = useCountry({ delay: 0 });

  useEffect(() => {
    onChange?.(country);
  }, [country]);

  return null;
};

export default RespondentCountryBlock;

export enum DisableDays {
  InThePast = 'IN_THE_PAST',
  InTheFuture = 'IN_THE_FUTURE',
  Mondays = 'MONDAYS',
  Tuesdays = 'TUESDAYS',
  Wednesdays = 'WEDNESDAYS',
  Thursdays = 'THURSDAYS',
  Fridays = 'FRIDAYS',
  Saturdays = 'SATURDAYS',
  Sundays = 'SUNDAYS',
}

export const defaultColors = {
  main: '#F81CE5',
  white: '#FFFFFF',
  black: '#000000',
  darkPurple: '#33125C',

  text: '#37352f',
  darkerGray: '#444444',
  darkGray: '#777777',
  gray: '#888888',
  lightGray: '#BBBBBB',
  lighterGray: '#DFDFDF',
  grayBackground: '#F1F1F1',
  lightGrayBackground: '#F5F5F5',

  error: '#B20110',
  warning: '#ed561b',

  lightBeigeBackground: '#FFFEFC',

  mainBoxShadow: `#F81CE5 0px 0px 0px 2px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px`,
  accentBoxShadow: `#007aff 0px 0px 0px 2px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px`,

  // Used for buttons (currently not generated for custom themes as they are not used yet)
  accentButton: '#0070d7',
  accentButtonHover: '#005cbf',
  accentButtonBoxShadow:
    'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(0, 112, 215, 0.16) 0px 0px 0px 1px, rgba(0, 112, 215, 0.08) 0px 2px 5px 0px',
  accentButtonBoxShadowHover:
    'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(0, 112, 215, 0.16) 0px 0px 0px 1px, rgba(0, 112, 215, 0.08) 0px 3px 9px 0px, rgba(0, 112, 215, 0.08) 0px 2px 5px 0px',

  // Color palettes
  redDark2: '#802222',
  redDark1: '#bf3333',
  red: '#FF4444',
  redRGB: '255, 68, 68',
  redLight1: '#ffa2a2',
  redLight2: '#ffecec',

  orangeDark2: '#803900',
  orangeDark1: '#bf5500',
  orange: '#FF7100',
  orangeRGB: '255, 113, 0',
  orangeLight1: '#ffb880',
  orangeLight2: '#fff1e6',

  yellowDark2: '#807124',
  yellowDark1: '#bfa936',
  yellow: '#ffe148',
  yellowRGB: '255, 225, 72',
  yellowLight1: '#fff0a4',
  yellowLight2: '#fffced',

  greenDark2: '#10651a',
  greenDark1: '#189726',
  green: '#20c933',
  greenRGB: '32, 201, 51',
  greenLight1: '#90e499',
  greenLight2: '#e9faeb',

  blueDark2: '#003d80',
  blueDark1: '#005cbf',
  blue: '#007aff',
  blueRGB: '0, 122, 255',
  blueLight1: '#80bdff',
  blueLight2: '#e6f2ff',

  purpleDark2: '#462380',
  purpleDark1: '#6835bf',
  purple: '#8b46ff',
  purpleRGB: '139, 70, 255',
  purpleLight1: '#c5a3ff',
  purpleLight2: '#f3edff',

  pinkDark2: '#7c0e73',
  pinkDark1: '#ba15ac',
  pink: '#F81CE5',
  pinkRGB: '248, 28, 229',
  pinkLight1: '#fc8ef2',
  pinkLight2: '#fee8fc',
};

export const getDefaultThemeColors = () => {
  const background = defaultColors.white;
  const text = '#37352f';
  const accent = '#007aff';
  const buttonBackground = defaultColors.black;
  const buttonText = defaultColors.white;

  return {
    ...defaultColors,

    background,
    text,
    accent,
    buttonBackground,
    buttonText,

    boxShadow: 'rgba(61, 59, 53, 0.16)',
    boxShadowHalf: 'rgba(61, 59, 53, 0.08)',
    textGrayscale1: '#45433e',
    textGrayscale2: '#777672',
    textGrayscale3: '#898884',
    textGrayscale4: '#bbbab8',
    textGrayscale5: '#dfdfde',
    textGrayscale6: '#f3f3f3',
    textGrayscale7: '#f5f5f5',
    error: '#B20110',
    border: 'rgba(55, 53, 47, 0.09)',
    actionBackgroundHover: 'rgba(55, 53, 47, 0.09)',
    inputPlaceholder: '#bbbab8',
    inputBackground: 'rgba(255, 255, 255, 1)',
    inputBoxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px',
    inputBoxShadowHover:
      'rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px, rgba(61, 59, 53, 0.08) 0px 3px 9px 0px, rgba(61, 59, 53, 0.08) 0px 2px 5px 0px',
    inputBoxShadowFocus:
      'rgba(58, 152, 255, 0.36) 0px 0px 0px 4px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px',
    inputBoxShadowActive: '#007aff 0px 0px 0px 2px, rgba(61, 59, 53, 0.16) 0px 0px 0px 1px',
    inputBoxShadowActiveFocus: 'rgba(58, 152, 255, 0.36) 0px 0px 0px 4px, #007aff 0px 0px 0px 2px',

    accentHover: '#0066d6',
    menuBackground: 'rgba(255, 255, 255, 0.5)',
    menuBoxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
    menuOverlay: 'rgba(15, 15, 15, 0.6)',
    isBackgroundDark: false,
  };
};

import { ALPHABET, BadgeType } from '@tallyforms/lib';

import { Container } from './styled';

interface Props {
  index: number;
  type: BadgeType;
  color?: string;
}

const EnumerationBadge = ({ index, type, color }: Props) => {
  const className =
    'tally-enumeration-badge ' +
    (type === BadgeType.Letters ? 'tally-alphabet-badge' : 'tally-number-badge');

  return (
    <Container
      className={className}
      color={color}
      compactSize={type === BadgeType.Numbers && index + 1 >= 20}>
      {type === BadgeType.Letters ? ALPHABET.charAt(index % ALPHABET.length) : index + 1}
    </Container>
  );
};

export default EnumerationBadge;

import { BlockType, DynamicValidators, RespondBlock } from '@tallyforms/lib';

export const getDynamicValidatorsFromBlocks = async (
  blocks: RespondBlock[],
): Promise<DynamicValidators> => {
  const validators: DynamicValidators = {};

  const blockTypes = blocks.map((x) => x.type);

  // Phone number validation
  if (blockTypes.includes(BlockType.InputPhoneNumber)) {
    const { isPhoneNumberValid } = await import('@/utils/phone-number');
    validators.shouldBePhoneNumber = isPhoneNumberValid;
  }

  return validators;
};

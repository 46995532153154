import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type SignatureBlock = Block<
  BlockType.Signature,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    label?: string;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isSignatureBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<SignatureBlock>;

export function isSignatureBlock(block: RespondBlock): block is RespondBlock<SignatureBlock>;

export function isSignatureBlock(block: Block): block is SignatureBlock {
  return block.type === BlockType.Signature;
}

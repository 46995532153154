import { getDefaultThemeColors } from '../color/default';
import { getFont } from '../font';
import { spacing } from '../spacing';
import { zIndex } from '../z-index';
import { Theme } from './types';

export const DefaultTheme = {
  is: Theme.Light,
  color: getDefaultThemeColors(),
  font: getFont(),
  spacing,
  direction: 'ltr',
  zIndex,
};

export type ThemeInterface = typeof DefaultTheme;

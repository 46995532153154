import { FormData } from '@tallyforms/lib';
import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';

import useTallyEventListener from '@/hooks/use-tally-event';
import { dispatchTallyEvent, TallyEvent } from '@/utils/tally-event';

interface PaymentBlocks {
  [key: string]: { isRequired: boolean; isFilledIn: boolean };
}

interface PaymentResults {
  [key: string]: SuccessfulPaymentResult | null;
}

interface SuccessfulPaymentResult {
  stripePaymentId: string;
  name: string;
  email: string;
  amount: number;
  currency: string;
}

interface Payload {
  groupUuid: string;
  action: Action;
  data?: any;
}

enum Action {
  Mount = 'MOUNT',
  Unmount = 'UNMOUNT',
  FilledIn = 'FILLED_IN',
  Pay = 'PAY',
  PayResult = 'PAY_RESULT',
}

const dispatchAction = (payload: Payload) => {
  dispatchTallyEvent(TallyEvent.PaymentOnChange, payload);
};

export const usePaymentEventState = (
  formData: FormData,
  updateFormDataWithPaymentResults: (formData: FormData) => void,
  onPaymentsSuccess: () => Promise<void>,
  onPaymentsError: () => void,
): {
  isPaying: boolean;
  showPayDisclaimer: boolean;
  completePaymentsIfAny: () => boolean;
} => {
  // isPaying state across all payment blocks
  const [isPaying, setIsPaying] = useState(false);

  // Payment blocks which are mounted on the current page
  const [blocks, setBlocks] = useState<PaymentBlocks>({});

  // Results are kept separate because we want to keep the state even if the payment block is unmounted
  const [results, setResults] = useState<PaymentResults>({});

  // Payments that are waiting for results
  const [waitingForResults, setWaitingForResults] = useState<string[]>([]);

  // We show the pay disclaimer if we have any payment blocks on the page which are filled in
  const showPayDisclaimer = Object.values(blocks).filter((x) => x.isFilledIn).length > 0;

  /**
   * Check if we have any payment blocks on the page which need to be completed
   *
   * @returns true if we can continue, false if we need to wait for payment results
   */
  const completePaymentsIfAny = (): boolean => {
    // If no payment blocks, we can continue
    if (Object.keys(blocks).length === 0) {
      return true;
    }

    // Get all payments that need to be completed
    const paymentsToComplete: string[] = [];
    for (const groupUuid of Object.keys(blocks)) {
      const { isRequired, isFilledIn } = blocks[groupUuid];

      // If payment is required and/or filled in but without a result, we need to complete it
      if ((isRequired || isFilledIn) && !formData[groupUuid]) {
        paymentsToComplete.push(groupUuid);
      }
    }

    // If no payments need to be completed, we can continue
    if (paymentsToComplete.length === 0) {
      return true;
    }

    // Dispatch the pay event to all payment blocks
    dispatchPay(paymentsToComplete);

    // We need to wait for the results, so we cannot continue
    return false;
  };

  // Event reducer
  useTallyEventListener(
    TallyEvent.PaymentOnChange,
    ({ groupUuid, action, data }: Payload) => {
      switch (action) {
        case Action.Mount:
          setBlocks((prev) => ({ ...prev, [groupUuid]: data }));
          break;

        case Action.Unmount:
          setBlocks((prev) => {
            const newBlocks = { ...prev };
            delete newBlocks[groupUuid];
            return newBlocks;
          });
          break;

        case Action.FilledIn:
          setBlocks((prev) => ({
            ...prev,
            [groupUuid]: { ...prev[groupUuid], isFilledIn: data },
          }));

          // If the payment is not filled in anymore and we previously had an error (result is null), we need to clear the result so we can try again
          if (!data && results[groupUuid] === null) {
            setResults((prev) => {
              const newResults = { ...prev };
              delete newResults[groupUuid];
              return newResults;
            });
          }
          break;

        case Action.Pay:
          // Clear previous result if any
          setResults((prev) => {
            const newResults = { ...prev };
            delete newResults[groupUuid];
            return newResults;
          });

          // Set the payment as waiting for results
          setWaitingForResults((prev) => uniq([...prev, groupUuid]));
          break;

        case Action.PayResult:
          setResults((prev) => ({ ...prev, [groupUuid]: data }));
          break;
      }
    },
    [],
  );

  // Check if we can continue after payment(s)
  useEffect(() => {
    if (waitingForResults.length === 0) {
      return;
    }

    setIsPaying(true);

    // Check if all payments have a result
    // Valid results are either null (failed payment) or an object with a stripePaymentId
    const allHaveResults = waitingForResults.every((x) => typeof results[x] !== 'undefined');

    // If not all payments have a result, we need to wait
    if (!allHaveResults) {
      return;
    }

    // All payments have a result
    // Get the successful payments
    const successfulPayments = waitingForResults.filter((x) => results[x]?.stripePaymentId);

    // Get the failed payments
    const failedPayments = waitingForResults.filter((x) => results[x] === null);

    // Check if the formData has all the successful payments
    const isFormDataUpdated = successfulPayments.every((x) => !!formData[x]?.stripePaymentId);

    // If the formData is not updated yet with the results, we need to update it
    if (successfulPayments.length > 0 && !isFormDataUpdated) {
      const resultsFormData: FormData = {};
      for (const groupUuid of successfulPayments) {
        resultsFormData[groupUuid] = results[groupUuid];
      }
      updateFormDataWithPaymentResults(resultsFormData);
      return;
    }

    // All payments have a result and the formData is updated
    setIsPaying(false);
    setWaitingForResults([]);

    // If we have failed payments, we cannot continue
    if (failedPayments.length > 0) {
      onPaymentsError();
      return;
    }

    // All payments are successful
    // We can continue
    onPaymentsSuccess();
  }, [results, waitingForResults, formData]);

  return { isPaying, showPayDisclaimer, completePaymentsIfAny };
};

export const usePaymentEventDispatch = (groupUuid: string, isRequired: boolean) => {
  return {
    dispatchMount: () => {
      dispatchAction({
        groupUuid,
        action: Action.Mount,
        data: { isRequired, isFilledIn: false },
      });
    },
    dispatchUnmount: () => {
      dispatchAction({
        groupUuid,
        action: Action.Unmount,
      });
    },
    dispatchFilledIn: (isFilledIn: boolean) => {
      dispatchAction({
        groupUuid,
        action: Action.FilledIn,
        data: isFilledIn,
      });
    },
    dispatchResult: (result: SuccessfulPaymentResult | null) => {
      dispatchAction({
        groupUuid,
        action: Action.PayResult,
        data: result,
      });
    },
  };
};

export const dispatchPay = (groupUuids: string[]) => {
  // Dispatch the pay event for each payment to update the state
  for (const groupUuid of groupUuids) {
    dispatchAction({
      groupUuid,
      action: Action.Pay,
    });
  }

  // Dispatch the payment pay event which will reach the payment blocks and trigger the pay method
  dispatchTallyEvent(TallyEvent.PaymentPay, groupUuids);
};

export const usePayEvent = (callback?: (arg: any) => void) => {
  useTallyEventListener(TallyEvent.PaymentPay, callback);
};

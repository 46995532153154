import { Color, getCustomThemeColors } from '../color';
import { Font, getFont } from '../font';
import { spacing } from '../spacing';
import { zIndex } from '../z-index';
import { Theme } from './types';

export const getTheme = (styles?: {
  theme?: Theme;
  color?: Color;
  font?: Font;
  direction?: string;
}) => {
  const is = styles?.theme ?? Theme.Light;

  const theme = {
    is,
    color: getCustomThemeColors(is, styles?.color),
    font: getFont(styles?.font),
    spacing,
    direction: styles?.direction ?? 'ltr',
    zIndex,
  };

  if ([Theme.Light, Theme.Dark, Theme.Custom].includes(is) === false) {
    theme.is = Theme.Custom;
  }

  return theme;
};

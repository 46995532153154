import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Tooltip from '@/components/tooltip';

import { Container } from './styled';

interface Props {
  uniqueKey?: string;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  isHiddenWhileCalculatingPosition?: boolean;
  onClick?: () => void;
}

const RequiredIndicator = ({
  top,
  right,
  bottom,
  left,
  isHiddenWhileCalculatingPosition,
  onClick,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isRTL = theme.direction === 'rtl';

  return (
    <Container
      top={top ?? -8}
      right={right ? right : !isRTL ? -8 : 0}
      bottom={bottom}
      left={left ? left : isRTL ? -8 : 0}
      aria-hidden="true"
      className={'tally-required-indicator' + (isHiddenWhileCalculatingPosition ? ' hidden' : '')}
      onClick={onClick}>
      <span>*</span>

      <Tooltip text={t('label.required')} place="top" />
    </Container>
  );
};

export default RequiredIndicator;

export const spacing = {
  xs: '5px',
  sm: '10px',
  md: '20px',
  lg: '40px',
  xl: '50px',
  xxl: '100px',
  xxxl: '150px',
  xxxxl: '200px',

  x: '2px',
  x2: '4px',
  x4: '8px',
  x5: '10px',
  x6: '12px',
  x7: '14px',
  x8: '16px',
  x12: '24px',
  x16: '32px',
  x20: '40px',
  x24: '48px',
  x32: '64px',
  x64: '128px',
  x72: '144px',
  x96: '192px',
  x128: '256px',
};

import { createContext, Dispatch } from 'react';

import { Action, ActionType, State } from '@/types/command-menu';

export const initialState: State = [];

export const CommandMenuContext = createContext<[State, Dispatch<Action>]>([
  initialState,
  () => {
    // Empty dispatch
  },
]);

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SetCommands: {
      return action.data;
    }

    default:
      return state;
  }
};

export enum DecimalSeparator {
  Dot = '.',
  Comma = ',',
}

export enum ThousandsSeparator {
  None = '',
  Dot = '.',
  Comma = ',',
  Space = ' ',
}

export enum NumberFormat {
  Number = 'NUMBER',
  Percent = 'PERCENT',
  USDollar = 'US_DOLLAR',
  Euro = 'EURO',
  Pound = 'POUND',
  Custom = 'CUSTOM',
}

import { SafeHTMLSchema } from '../../types';
import { stripHtml } from './strip-html';

export const transformSafeHTMLSchemaToHTML = (
  schema: SafeHTMLSchema,
  inRespondMode = false,
): string => {
  let html = '';

  schema.forEach(([node, modifiers]) => {
    if (typeof node === 'string' && !modifiers) {
      html += stripHtml(node);
      return;
    }

    if (modifiers && modifiers.length > 0) {
      let tag = 'span';
      const styles: string[] = [];
      const attributes: string[] = [];

      modifiers.forEach(([property, value]) => {
        if (property === 'tag' && value === 'div') {
          tag = 'div';
        } else if (property === 'tag' && value === 'p') {
          tag = 'p';
        } else if (property === 'tag' && value === 'br') {
          tag = 'br';
          node = '';
        } else if (property === 'href') {
          tag = 'a';
          attributes.push(`href="${value}"`);
          attributes.push('target="_blank"');
          attributes.push('rel="noreferrer"');
        } else if (property === 'mention') {
          attributes.push(`class="mention"`);
          attributes.push(`contenteditable="false"`);
          attributes.push(`data-uuid="${value}"`);

          if (inRespondMode) {
            node = '';
          }
        } else {
          styles.push(`${property}: ${value};`);
        }
      });

      if (styles.length > 0) {
        attributes.push(`style="${styles.join(' ')}"`);
      }

      if (tag === 'br') {
        html += '<br>';
      } else {
        html += `<${tag} ${attributes.join(' ')}>${
          typeof node === 'string' ? node : transformSafeHTMLSchemaToHTML(node, inRespondMode)
        }</${tag}>`;
      }
    }
  });

  return html;
};

import {
  DecimalSeparator,
  isValidNumber,
  ThousandsSeparator,
  ValidationRuleConstraint,
} from '@tallyforms/lib';
import { useEffect, useState } from 'react';

import InputNumber from '@/components/form/input/number';
import ValidationError from '@/components/form/validation-error';
import { Question } from '@/types/form-builder';

import { Container, Content } from './styled';

interface Props {
  value?: string;
  placeholder?: string;
  isRequired?: boolean;
  error?: ValidationRuleConstraint;
  question?: Question;
  decimalSeparator?: DecimalSeparator;
  thousandsSeparator?: ThousandsSeparator;
  prefix?: string;
  suffix?: string;
  onChange?: (value: number | undefined) => void;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
}

const InputNumberBlock = ({
  value = '',
  placeholder = '',
  isRequired,
  error,
  question,
  decimalSeparator = DecimalSeparator.Dot,
  thousandsSeparator = ThousandsSeparator.None,
  prefix,
  suffix,
  onChange,
  renderRequiredIndicator,
}: Props) => {
  const [internalValue, setInternalValue] = useState(value ?? '');
  const errorId = error ? `error_${question?.blockGroupUuid}` : undefined;

  // Somehow the decimal and thousands separator are the same, set them to the default
  if (decimalSeparator.toString() === thousandsSeparator.toString()) {
    decimalSeparator = DecimalSeparator.Dot;
    thousandsSeparator = ThousandsSeparator.None;
  }

  // When the value prop changes from outside, update the internal value as well
  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  return (
    <Container>
      <Content>
        <InputNumber
          id={question?.blockGroupUuid}
          value={internalValue}
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandsSeparator}
          prefix={prefix}
          suffix={suffix}
          placeholder={placeholder}
          required={isRequired}
          aria-label={question?.title ?? undefined}
          aria-required={isRequired ? 'true' : 'false'}
          aria-invalid={error ? 'true' : 'false'}
          aria-describedby={errorId}
          maxLength={16}
          onValueChange={({ value, floatValue }, { source }) => {
            if (isValidNumber(value)) {
              setInternalValue(value);
            }

            // Only trigger onChange when the value is changed by the user
            if (source === 'prop') {
              return;
            }

            if (typeof value !== 'undefined' && value !== '') {
              onChange?.(floatValue);
              return;
            }

            onChange?.(undefined);
          }}
          onPaste={(event) => {
            // Prevent the value from being pasted and instead add it to the
            // internal value so its formatted correctly
            event.preventDefault();

            const pastedValue = event.clipboardData.getData('Text');
            let unformattedValue = pastedValue;

            // Remove the thousands separator only if it's set
            if (thousandsSeparator !== ThousandsSeparator.None) {
              unformattedValue = unformattedValue.replace(
                new RegExp(`\\${thousandsSeparator}`, 'g'),
                '',
              );
            }

            unformattedValue = unformattedValue.replace(decimalSeparator, '.');

            if (isValidNumber(unformattedValue)) {
              setInternalValue(unformattedValue);

              // Trigger onChange so the input is considered touched
              onChange?.(parseFloat(unformattedValue));
            }
          }}
        />
        {renderRequiredIndicator && renderRequiredIndicator()}
        {error && <ValidationError id={errorId} error={error} />}
      </Content>
    </Container>
  );
};

export default InputNumberBlock;

import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export interface CalculatedField {
  uuid: string;
  name?: string;
  type?: CalculatedFieldType;
  value?: any;
}

export enum CalculatedFieldType {
  Number = 'NUMBER',
  Text = 'TEXT',
}

export type CalculatedFieldsBlock = Block<
  BlockType.CalculatedFields,
  { calculatedFields: CalculatedField[] }
>;

export type CalculatedFieldsModifyCallback = ({
  payload,
  calculatedField,
  index,
}: {
  payload: CalculatedFieldsBlock['payload'];
  calculatedField: CalculatedField;
  index: number;
}) => void;

export function isCalculatedFieldsBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<CalculatedFieldsBlock>;

export function isCalculatedFieldsBlock(
  block: RespondBlock,
): block is RespondBlock<CalculatedFieldsBlock>;

export function isCalculatedFieldsBlock(block: Block): block is CalculatedFieldsBlock;

export function isCalculatedFieldsBlock(block: Block): block is CalculatedFieldsBlock {
  return block.type === BlockType.CalculatedFields;
}

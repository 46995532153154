import { Language } from '../types';

export const SUPPORTED_RTL_LANGUAGES = [
  Language.Arabic.toString(),
  Language.Hebrew.toString(),
  Language.Yoruba.toString(),
  Language.Farsi.toString(),
];

export const LANGUAGES_WITH_DECIMAL_COMMA: Language[] = [
  Language.Afrikaans, // South Africa
  Language.Dutch, // The Netherlands
  Language.French, // France, Canada (when using French)
  Language.German, // Germany, Austria, Switzerland
  Language.Italian, // Italy
  Language.Spanish, // Spain
  Language.Polish, // Poland
  Language.Czech, // Czech Republic
  Language.Portuguese, // Portugal
  Language.Finnish, // Finland
  Language.Swedish, // Sweden
  Language.Russian, // Russia
  Language.Danish, // Denmark
  Language.Norwegian, // Norway
  Language.Latvian, // Latvia
  Language.Lithuanian, // Lithuania
  Language.Indonesian, // Indonesia
  Language.Romanian, // Romania
  Language.Croatian, // Croatia
  Language.Greek, // Greece
  Language.Bulgarian, // Bulgaria
  Language.Slovenian, // Slovenia
  Language.Slovak, // Slovakia
  Language.Hungarian, // Hungary
  Language.Vietnamese, // Vietnam
  Language.Turkish, // Turkey
  Language.Ukrainian, // Ukraine
  Language.Serbian, // Serbia
];

export const LANGUAGES_WITH_DECIMAL_DOT: Language[] = [
  Language.English, // United States, United Kingdom, Australia, Canada (when using English), Ireland, New Zealand
  Language.Bengali, // Bangladesh
  Language.Korean, // Korea, South
  Language.SpanishMexico, // Mexico
  Language.PortugueseBrazil, // Brazil
  Language.Japanese, // Japan
  Language.ChineseSimplified, // China
  Language.ChineseTraditional, // Taiwan, Hong Kong, Macau (in Chinese and English text)
  Language.Arabic, // Saudi Arabia, United Arab Emirates
  Language.Hebrew, // Israel
  Language.Thai, // Thailand
  Language.Sinhala, // Sri Lanka
  Language.Filipino, // Philippines
  Language.Tamil, // India
  Language.Malay, // Malaysia
];

import styled from 'styled-components';

export const Container = styled.textarea`
  width: 100%;
  max-width: 100%;
  resize: vertical;
  min-height: 96px;
  padding: 12px;
  color: ${({ theme }) => theme.color.text};
  caret-color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.inputBackground};
  box-shadow: ${({ theme }) => theme.color.inputBoxShadow};
  border: 0;
  border-radius: 5px;
  outline: none;

  @media (hover: hover) {
    &:hover {
      box-shadow: ${({ theme }) => theme.color.inputBoxShadowHover};
    }
  }

  &:focus {
    box-shadow: ${({ theme }) => theme.color.inputBoxShadowFocus};
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.inputPlaceholder};
  }
`;

import { withDirection } from '@tallyforms/ui';
import styled from 'styled-components';

import { InputContent } from '@/components/layout/form';

export const Legend = styled.div`
  position: absolute;
  display: inline-block;
  max-width: 10px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

export const ChoiceContainer = styled.div<{
  hasError: boolean;
  isLast: boolean;
  isOtherOptionSelected: boolean;
}>`
  position: relative;

  ${({ theme, hasError, isLast, isOtherOptionSelected }) =>
    hasError &&
    isLast &&
    !isOtherOptionSelected &&
    `
    margin-bottom: -${theme.spacing.sm};

    & + .tally-validation-error {
      padding-bottom: ${theme.spacing.sm};
    }
  `}
`;

export const Choice = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  min-height: 16px;
  padding: 9px 0;
  font-size: 16px;
  line-height: 1.125;

  label {
    cursor: pointer;
  }

  > img {
    max-width: 260px;
    width: 100%;
    margin-top: 8px;
  }

  .tally-required-indicator {
    position: inherit;
    display: inline-flex;
    ${({ theme }) => withDirection('margin-left', theme.spacing.xs, theme.direction)}
  }
`;

export const OtherOptionContainer = styled(InputContent)<{
  hasError: boolean;
}>`
  padding-top: 9px;
  padding-bottom: ${({ theme }) => theme.spacing.sm};

  ${({ theme, hasError }) =>
    hasError &&
    `
    padding-bottom: 0;

     & + .tally-validation-error {
      padding-bottom: ${theme.spacing.sm};
    }
  `}
`;

import { DecimalSeparator } from '@tallyforms/lib';
import { useRef } from 'react';
import { NumberFormatBase, NumericFormatProps, useNumericFormat } from 'react-number-format';

import { Input } from './styled';

type Props = Omit<NumericFormatProps, 'customInput'>;

const InputNumber = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // CR wouterds: this lib could be removed if we add a hook using our internal number utils
  const { removeFormatting, ...rest } = useNumericFormat({
    ...props,
    allowedDecimalSeparators: [DecimalSeparator.Comma, DecimalSeparator.Dot],
    valueIsNumericString: true,
    customInput: Input,
    inputMode: 'decimal',
  });

  // In cases where user is typing in arabic numbers, the cursor gets messed up after formatting it, this makes sure cursor is always correct
  const putCursorAtEnd = () => {
    const input = inputRef.current;
    if (!input) {
      return;
    }

    const index = input.value.length;
    input.setSelectionRange(index, index);
  };

  return (
    <NumberFormatBase
      {...rest}
      removeFormatting={(value, changeMeta) => {
        let val = value;

        // Check if value is an arabic number
        if (/[٠-٩]/.test(value)) {
          val = value.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d).toString());

          // Put the cursor at the end
          setTimeout(putCursorAtEnd, 20);
        }

        return removeFormatting?.(val, changeMeta) ?? value;
      }}
      getInputRef={inputRef}
    />
  );
};

export default InputNumber;

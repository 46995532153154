import { breakpoint, withDirection } from '@tallyforms/ui';
import styled from 'styled-components';

import { BlockDropzone } from '@/components/form-builder/blocks/move-dropzone/styled';

export const TableWrapper = styled.div<{
  isMatrix?: boolean;
  center?: number;
  styleScroll?: boolean;
}>`
  overflow-x: auto;
  background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));

  background-position:
    left center,
    right center,
    left center,
    right center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.color.background};
  background-size:
    30px 100%,
    30px 100%,
    15px 100%,
    15px 100%;

  background-attachment: local, local, scroll, scroll;

  ${({ isMatrix, center }) =>
    isMatrix &&
    `
      & table, .tally-validation-error {
        padding-left: ${center}px;
        padding-right: ${center}px;
      }

      & > .move-dropzone {
        height: 20px;
        top: unset;
        bottom: -20px;
        margin-left: ${center}px;
        margin-right: ${center}px;
      }

      background-image: none;
      background-color: transparent;
      `}

  ${({ styleScroll, theme }) =>
    styleScroll &&
    `
    &::-webkit-scrollbar {
      height: 12px;
      background-color: ${theme.color.lightGrayBackground};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.color.lightGray};
    }
  `}
`;

export const Table = styled.table`
  border: 0;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: auto;
  width: 100%;
  font-size: 14px;

  tr {
    td {
      border-bottom: 1px solid ${({ theme }) => theme.color.border};
    }

    @media (hover: hover) {
      &:hover {
        > td {
          > button {
            position: absolute;
            top: calc(50% - 28px / 2);
            display: inline-flex;

            &:nth-of-type(1) {
              right: 4px;
            }

            &:nth-of-type(2) {
              right: calc(4px + 28px);
            }

            &:nth-of-type(3) {
              right: calc(4px + 2 * 28px);
            }
          }
        }
      }
    }
  }

  th {
    border-bottom: 1px solid ${({ theme }) => theme.color.border};
    white-space: nowrap;
    padding: ${({ theme }) => theme.spacing.sm} 0;
    ${({ theme }) => withDirection('padding-left', theme.spacing.sm, theme.direction)}
    ${({ theme }) => withDirection('padding-right', theme.spacing.xl, theme.direction)}
    text-align: left;
    border-right: 1px solid ${({ theme }) => theme.color.border};
    color: ${({ theme }) => theme.color.gray};
    font-weight: 400;
    position: relative;
    background: ${({ theme }) => theme.color.background};

    &.num-buttons-1 {
      padding-right: calc(15px + 28px);
    }

    &.num-buttons-2 {
      padding-right: calc(15px + 2 * 28px);

      @media (min-width: ${breakpoint.lg}) {
        padding-left: 0;
      }
    }

    &.num-buttons-3 {
      padding-right: calc(15px + 3 * 28px);
    }

    &:last-child {
      border-right: 0;
    }

    > div {
      display: flex;
      align-items: center;

      > svg {
        ${({ theme }) => withDirection('margin-right', theme.spacing.xs, theme.direction)}
      }

      > div {
        ${({ theme }) => withDirection('margin-left', '8px', theme.direction)}
      }
    }
  }

  td {
    position: relative;
    padding: ${({ theme }) => theme.spacing.sm} 0;
    ${({ theme }) => withDirection('padding-left', theme.spacing.sm, theme.direction)}
    ${({ theme }) => withDirection('padding-right', theme.spacing.xl, theme.direction)}
    text-align: left;
    border-right: 1px solid ${({ theme }) => theme.color.border};

    &.no-response {
      color: ${({ theme }) => theme.color.gray};
    }

    &.num-buttons-1 {
      padding-right: calc(15px + 28px);
    }

    &.num-buttons-2 {
      position: relative;
      padding-right: calc(15px + 2 * 28px);

      @media (min-width: ${breakpoint.lg}) {
        padding-left: 0;
      }
    }

    &.num-buttons-3 {
      padding-right: calc(15px + 3 * 28px);
    }

    &.nowrap {
      white-space: nowrap;
    }

    > button {
      display: none;
    }

    &:last-child {
      border-right: 0;
    }

    > div {
      width: 100%;
      min-width: 150px;
    }
  }

  tr > td:first-child {
    white-space: nowrap;
  }

  tr.selected {
    background: ${({ theme }) => theme.color.blueLight2};
  }

  thead,
  tr {
    background: ${({ theme }) => theme.color.background};
  }
`;

export const TableStickyHeader = styled.thead<{ isMatrix?: boolean }>`
  ${({ isMatrix }) => !isMatrix && `z-index: 1000000001;`}
`;

export const MatrixTableWrapper = styled.div<{
  center: number;
  respond?: boolean;
}>`
  position: relative;
  margin-left: -${({ center }) => center}px;
  margin-right: -${({ center }) => center}px;
  padding: 0 100px;

  @media (max-width: ${breakpoint.sm}) {
    padding: 0 25px;
  }

  & ${TableWrapper} {
    padding-top: 10px;
    margin-top: -10px;
  }

  input {
    pointer-events: none;
  }

  ${({ respond, theme }) =>
    respond &&
    `
    padding: 0;

    @media (max-width: ${breakpoint.sm}) {
      padding: 0;
    }


    & .tally-required-indicator {
      ${withDirection('right', '-20px', theme.direction)}
    }

    input {
      pointer-events: auto;
      cursor: pointer;
    }
  `}
`;

export const MatrixTable = styled(Table)`
  padding-bottom: 10px;

  .tally-block {
    padding-left: 0;
    padding-right: 0;
    padding: 0 10px;
  }

  &.selected-block {
    th,
    td,
    table {
      background: ${({ theme }) => theme.color.accent}40;
    }
  }

  th,
  td {
    padding: 0;
    min-width: 100px;
    height: inherit;
  }

  td {
    > div {
      width: 100%;
      min-width: 100%;
    }
  }

  thead {
    position: sticky;
    top: 0;
    &.isSticky {
      background-color: ${({ theme }) => theme.color.background};
    }

    tr {
      height: 1px;
      @supports (-moz-appearance: none) {
        height: 100%;
      }

      & .tally-block {
        height: 100%;

        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        z-index: 3;
      }
    }

    th {
      border-top: 1px solid ${({ theme }) => theme.color.border};
      text-align: center;

      &:first-child {
        border-left: 1px solid ${({ theme }) => theme.color.border};
      }

      &:last-child {
        border-right: 1px solid ${({ theme }) => theme.color.border};
      }

      > div {
        justify-content: center;

        > div {
          margin: 0;
        }
      }

      &:first-child {
        position: sticky;
        left: -1px;
        padding-left: 1px;
        z-index: 2;
        &.isSticky {
          background-color: ${({ theme }) => theme.color.background};
        }

        .tally-block {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          position: absolute;
          ${({ theme }) => withDirection('margin-left', '-60px', theme.direction)}

          & .actions {
            ${({ theme }) => withDirection('left', '-51px', theme.direction)}
          }
        }
      }

      .tally-required-indicator {
        ${({ theme }) => withDirection('right', '-18px', theme.direction)}
      }

      &:not(:first-child) .actions {
        background: ${({ theme }) => theme.color.background};
        width: 74px;
        ${({ theme }) => withDirection('left', '-73px', theme.direction)}
      }
    }

    & ${BlockDropzone}:not(:first-child) {
      ${({ theme }) => withDirection('left', '50%', theme.direction)}
      ${({ theme }) => withDirection('right', '-100px', theme.direction)}
      top: 0;
      bottom: 0;
      z-index: -1;

      &.hover {
        border-bottom: none;
        ::after {
          position: absolute;
          content: '';
          ${({ theme }) => withDirection('right', '97.9px', theme.direction)}
          ${({ theme }) => withDirection('left', '0px', theme.direction)}
          top: 0;
          bottom: 0;
          ${({ theme }) =>
            withDirection('border-right', `4px solid ${theme.color.accent}77`, theme.direction)}
        }
      }
    }

    & ${BlockDropzone}:first-child {
      ${({ theme }) => withDirection('left', '-100px', theme.direction)}
      ${({ theme }) => withDirection('right', '50%', theme.direction)}
      top: 0;
      bottom: 0;
      z-index: -1;

      &.hover {
        border-bottom: none;
        ::after {
          position: absolute;
          content: '';
          ${({ theme }) => withDirection('left', '97.9px', theme.direction)}
          ${({ theme }) => withDirection('right', '0px', theme.direction)}
          top: 0;
          bottom: 0;
          ${({ theme }) =>
            withDirection('border-left', `4px solid ${theme.color.accent}77`, theme.direction)}
        }
      }
    }
  }

  tbody {
    th {
      position: sticky;
      left: -1px;
      padding-left: 1px;
      z-index: 2;
      & .tally-block {
        ${({ theme }) => withDirection('margin-left', '-71px', theme.direction)}
        ${({ theme }) => withDirection('padding-left', '71px', theme.direction)}


        & .actions {
          ${({ theme }) => withDirection('left', '-111px', theme.direction)}
        }
      }

      &.isSticky {
        background-color: ${({ theme }) => theme.color.background};
      }
    }

    tr {
      min-height: 30px;
      height: 30px;

      th:first-child {
        border-left: 1px solid ${({ theme }) => theme.color.border};
      }

      td:last-child {
        border-right: 1px solid ${({ theme }) => theme.color.border};
      }

      td:not(:first-child) {
        > div {
          display: flex;
          justify-content: center;
        }
      }

      @media (hover: hover) {
        &:hover {
          th {
            z-index: 3;
          }
        }
      }

      &.hover {
        th {
          z-index: 4;
        }
      }
    }

    & ${BlockDropzone}:not(:first-child) {
      ${({ theme }) => withDirection('left', '60px', theme.direction)}
      ${({ theme }) => withDirection('right', '0px', theme.direction)}
      top: 15px;
      bottom: -15px;

      &.hover {
        border-bottom: none;
        ::after {
          position: absolute;
          content: '';
          left: 0;
          right: 0px;
          top: 0;
          bottom: 15px;
          border-bottom: 4px solid ${({ theme }) => theme.color.accent}77;
        }
      }
    }

    & ${BlockDropzone}:first-child {
      ${({ theme }) => withDirection('left', '60px', theme.direction)}
      ${({ theme }) => withDirection('right', '0px', theme.direction)}
      top: -15px;
      bottom: 50%;
      &.hover {
        border-bottom: none;
        ::after {
          position: absolute;
          content: '';
          left: 0;
          right: 0px;
          top: 15px;
          border-bottom: 4px solid ${({ theme }) => theme.color.accent}77;
        }
      }
    }
  }
`;

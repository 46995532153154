import { ButtonHTMLAttributes } from 'react';

import { Loader } from '../loader';
import { Container, Content, LoaderContent } from './styled';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  loading?: boolean;
  variant?: 'default' | 'outline';
}

export const Button = ({ children, fullWidth, loading, onClick, ...props }: ButtonProps) => {
  const variant = props.variant ?? 'default';

  return (
    <Container
      variant={variant}
      fullWidth={fullWidth}
      loading={loading}
      onClick={loading ? undefined : onClick}
      {...props}>
      <Content transparent={loading}>{children}</Content>
      <LoaderContent transparent={!loading}>
        <Loader />
      </LoaderContent>
    </Container>
  );
};

import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import { ButtonProps } from './index';

export const Container = styled.button<ButtonProps>`
  position: relative;
  height: 36px;
  font-size: 15px;
  font-weight: 600;
  padding: 0 1.5rem;
  border-radius: 5px;
  background: ${({ theme }) => theme.color.text};
  border: 1px solid ${({ theme }) => theme.color.text};
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  text-align: center;
  transition: all 200ms ease-in-out;

  &:hover {
    background: ${({ theme }) => tinycolor(theme.color.text).darken(20).toString()};
    border-color: ${({ theme }) => tinycolor(theme.color.text).darken(20).toString()};
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(58, 152, 255, 0.36);
  }

  ${({ variant, theme }) =>
    variant === 'outline' &&
    `
      background: transparent;
      color: ${theme.color.textGrayscale2};
      border-color: ${theme.color.textGrayscale5};

      &:hover {
        color: ${theme.color.textGrayscale1};
        border-color: ${theme.color.textGrayscale4};
        background: ${theme.color.lightGrayBackground};
      }
  `};

  ${({ fullWidth }) => fullWidth && `width: 100%;`};
  ${({ loading }) => loading && `cursor: not-allowed;`};
`;

export const Content = styled.span<{ transparent?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: opacity 200ms ease-in-out;

  ${({ transparent }) =>
    transparent &&
    `
      opacity: 0;
  `};
`;

export const LoaderContent = styled(Content)`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
`;

import { forwardRef } from 'react';

import withAutoFocus from '@/components/form/input/with-auto-focus';

import { Container } from './styled';

const Input = forwardRef(
  (props: React.InputHTMLAttributes<HTMLInputElement>, forwardedRef: any) => {
    return <Container ref={forwardedRef} {...props} />;
  },
);

Input.displayName = 'Input';

export default Input;

const InputWithAutoFocus = withAutoFocus(Input);

export { InputWithAutoFocus };

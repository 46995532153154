import i18next from 'i18next';

import { Language } from '../../types';

export const getLanguage = () => {
  try {
    const language = i18next.language as Language;
    if (!language) {
      return Language.English;
    }

    return language;
  } catch {
    return Language.English;
  }
};

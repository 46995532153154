import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

function useBeforeUnload(shouldPreventUnload: () => boolean, prompt: string, deps?: any) {
  const router = useRouter();

  const onUnload = useCallback((e: any) => {
    if (shouldPreventUnload && shouldPreventUnload()) {
      if (e.preventDefault) {
        e.preventDefault();
        e.returnValue = '';
      } else if (!confirm(prompt)) {
        throw 'routeChangeStopped';
      }
    }
  }, deps);

  useEffect(() => {
    window?.addEventListener('beforeunload', onUnload);
    router?.events?.on('routeChangeStart', onUnload);

    return () => {
      window?.removeEventListener('beforeunload', onUnload);
      router?.events?.off('routeChangeStart', onUnload);
    };
  }, [onUnload]);
}

export default useBeforeUnload;

import dynamic from 'next/dynamic';

import { dispatchTallyEvent, TallyEvent } from '@/utils/tally-event';

const Tooltip = dynamic(() => import('@/components/tooltip/component'), {
  ssr: false,
});

interface Props {
  text: string;
  place?: string;
  highlightWords?: boolean;
  textAlign?: 'left' | 'center' | 'right';
}

const TooltipWrapper = (props: Props) => {
  return <Tooltip {...props} />;
};

TooltipWrapper.hide = () => {
  dispatchTallyEvent(TallyEvent.TooltipHide);
};

export default TooltipWrapper;

import {
  BlockType,
  DynamicValidators,
  FormData,
  FormErrors,
  isEmpty,
  REGEX_EMAIL,
  REGEX_URL,
  SafeSchemaBlock,
  ValidationRule,
  ValidationRules,
} from '@tallyforms/lib';

import { isEmailVerified } from '@/hooks/form-respond/use-form-data';
import { FileUpload } from '@/types/form-respond';

export const validate = (
  fields: FormData,
  rules: ValidationRules,
  validators?: DynamicValidators,
  blocks?: SafeSchemaBlock[],
): FormErrors => {
  const errors: FormErrors = {};

  for (const field of Object.keys(rules)) {
    // No value given for field
    if (Object.keys(fields).includes(field) === false) {
      fields[field] = undefined;
    }

    const fieldValue = fields[field];
    const fieldRules = rules[field];

    for (const [rule, constraint] of fieldRules) {
      let hasError = false;

      switch (rule) {
        case ValidationRule.Required:
        case ValidationRule.PaymentRequired:
        case ValidationRule.OptionRequired:
        case ValidationRule.ValueRequired:
        case ValidationRule.SignatureRequired:
        case ValidationRule.FileRequired: {
          if (isEmpty(fieldValue)) {
            hasError = true;
          }
          break;
        }

        case ValidationRule.MatrixRequired: {
          if (isEmpty(fieldValue)) {
            hasError = true;
            break;
          }
          if (blocks) {
            const rows = blocks.filter(
              (x) => x.groupUuid === field && x.type === BlockType.MatrixRow,
            );

            for (const row of rows) {
              if (isEmpty(fieldValue[row.uuid])) {
                hasError = true;
                break;
              }
            }
          }
          break;
        }

        case ValidationRule.RankingRequired:
          if (isEmpty(fieldValue)) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeEmail:
          if (!isEmpty(fieldValue) && fieldValue.match(REGEX_EMAIL) === null) {
            hasError = true;
          }
          break;

        case ValidationRule.EmailVerificationRequired:
          if (!isEmpty(fieldValue) && isEmailVerified(fieldValue) === false) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBePhoneNumber:
          if (
            !isEmpty(fieldValue) &&
            validators?.shouldBePhoneNumber &&
            validators?.shouldBePhoneNumber(fieldValue) === false
          ) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeURL:
          if (!isEmpty(fieldValue) && fieldValue.match(REGEX_URL) === null) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeChecked:
          if (!isEmpty(fieldValue) && fieldValue !== true) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeDomainHost:
          if (!isEmpty(fieldValue) && constraint(fieldValue) === false) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeMinChars:
          if (!isEmpty(fieldValue) && fieldValue.length < constraint) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeMaxChars:
          if (!isEmpty(fieldValue) && fieldValue.length > constraint) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeMinNumber:
          if (!isEmpty(fieldValue) && fieldValue < constraint) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeMaxNumber:
          if (!isEmpty(fieldValue) && fieldValue > constraint) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeBetweenNumbers:
          if (!isEmpty(fieldValue) && (fieldValue < constraint[0] || fieldValue > constraint[1])) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldMatchPassword:
          if (fieldValue !== constraint) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeBeforeDate:
          if (!isEmpty(fieldValue) && fieldValue > constraint) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeAfterDate:
          if (!isEmpty(fieldValue) && fieldValue < constraint) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeBetweenDates:
          if (!isEmpty(fieldValue) && (fieldValue < constraint[0] || fieldValue > constraint[1])) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldBeOneOfSpecificDates:
          if (!isEmpty(fieldValue) && constraint?.includes(fieldValue) === false) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldHaveMinArrayLength:
          if (!isEmpty(fieldValue) && fieldValue.length < constraint) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldHaveMaxArrayLength:
          if (!isEmpty(fieldValue) && fieldValue.length > constraint) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldSolveCaptcha:
          if (
            isEmpty(fieldValue) ||
            typeof fieldValue !== 'object' ||
            isEmpty(fieldValue.sitekey) ||
            isEmpty(fieldValue.response)
          ) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldRankAllOptions:
          if (!isEmpty(fieldValue) && fieldValue.length < constraint) {
            hasError = true;
          }
          break;

        case ValidationRule.ShouldFinishUpload:
          if (
            !isEmpty(fieldValue) &&
            fieldValue.filter((x: FileUpload) => x.isUploading).length > 0
          ) {
            hasError = true;
          }
          break;
      }

      if (hasError) {
        errors[field] = [rule, constraint];
        break;
      }
    }
  }

  return errors;
};

import { useEffect, useLayoutEffect } from 'react';

import { dispatchTallyEvent, TallyEvent } from '@/utils/tally-event';

export { TallyEvent };

function useTallyEventListener(key: string, callback?: (arg: any) => void, deps?: any) {
  function onEvent({ detail }: CustomEvent) {
    callback?.(detail);
  }

  function dispatchEvent(detail: any) {
    dispatchTallyEvent(key, detail);
  }

  // We want to run the event listeners synchronously with useLayoutEffect to avoid a gap between removing and re-adding listeners after component re-render
  // However, during SSR this hook won't work so use useEffect instead to avoid warnings
  const useEffectMethod = typeof window === 'undefined' ? useEffect : useLayoutEffect;

  useEffectMethod(() => {
    if (callback) {
      window.addEventListener(key, onEvent as EventListener);
    }
    return () => {
      if (callback) {
        window.removeEventListener(key, onEvent as EventListener);
      }
    };
  }, deps);

  return dispatchEvent;
}

export default useTallyEventListener;

export const safeUrl = (url: string, safeSearchParams = false): string => {
  let safeUrl = url;
  if (url.toLocaleLowerCase().startsWith('javascript:')) {
    safeUrl = 'http://' + url.substring(11);
  } else if (url.match(/^(https?|[a-zA-Z]+):\/\//i)) {
    safeUrl = url;
  } else {
    safeUrl = 'http://' + url;
  }

  if (safeSearchParams) {
    safeUrl = new URL(safeUrl).toString();
  }

  return safeUrl;
};

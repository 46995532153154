import { Language } from '@tallyforms/lib';
import Cookie from 'js-cookie';

export const setCookie = (
  name: string,
  value: string,
  expires = 2147483647 /* 2038-01-19 04:14:07 */,
) => {
  Cookie.set(name, value, { expires });
};

export const getCookie = (name: string): string | undefined => {
  return Cookie.get(name);
};

export const removeCookie = (name: string, attributes: any = {}) => {
  Cookie.remove(name, attributes);
};

export const removeCookies = (attributes: any = {}) => {
  Object.keys(Cookie.get()).forEach((name: string) => {
    Cookie.remove(name, attributes);
  });
};

export const formLanguageToCookieConsentLanguage = (language: Language | undefined) => {
  switch (language) {
    case Language.SpanishMexico:
      return 'es';
    case Language.PortugueseBrazil:
      return 'pt';
    default:
      return language;
  }
};

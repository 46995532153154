import { useEffect } from 'react';

const CLASS_NAME = 'tally-page-transition';

function usePageTransition(page: number) {
  // Clear the class on unmount
  useEffect(() => {
    return () => {
      document.body.classList.remove(CLASS_NAME);
    };
  }, []);

  // After the page has changed, we remove the class from the body with a small delay to allow for the transition to complete
  useEffect(() => {
    setTimeout(() => {
      document.body.classList.remove(CLASS_NAME);
    }, 50);
  }, [page]);

  // On changing the page, we add a class to the body to indicate that we're transitioning between pages
  return () => {
    document.body.classList.add(CLASS_NAME);
  };
}

export default usePageTransition;

import { Field } from '../field';
import { DecimalSeparator, NumberFormat, ThousandsSeparator } from '../number';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type InputNumberBlock = Block<
  BlockType.InputNumber,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | number;
    placeholder?: string;
    hasMinNumber?: boolean;
    minNumber?: number;
    hasMaxNumber?: boolean;
    maxNumber?: number;
    decimalSeparator?: DecimalSeparator;
    thousandsSeparator?: ThousandsSeparator;
    format?: NumberFormat;
    prefix?: string;
    suffix?: string;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isInputNumberBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<InputNumberBlock>;

export function isInputNumberBlock(block: RespondBlock): block is RespondBlock<InputNumberBlock>;

export function isInputNumberBlock(block: Block): block is InputNumberBlock {
  return block.type === BlockType.InputNumber;
}

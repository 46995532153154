import { SubscriptionPlan } from '../../types';

export const hasProAccess = (
  maybeSubscriptionPlan?: SubscriptionPlan | { subscriptionPlan?: SubscriptionPlan } | null,
) => {
  const subscriptionPlan =
    typeof maybeSubscriptionPlan === 'object'
      ? maybeSubscriptionPlan?.subscriptionPlan
      : maybeSubscriptionPlan;

  return [SubscriptionPlan.Pro, SubscriptionPlan.Business, SubscriptionPlan.Lifetime].includes(
    subscriptionPlan,
  );
};

import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type ImageBlock = Block<
  BlockType.Image,
  {
    isHidden?: boolean;
    caption?: string;
    hasCaption?: boolean;
    link?: string;
    hasLink?: boolean;
    altText?: string;
    hasAltText?: boolean;
    images?: Array<{
      name: string;
      url: string;
    }>;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
  }
>;

export function isImageBlock(block: FormBuilderBlock): block is FormBuilderBlock<ImageBlock>;

export function isImageBlock(block: RespondBlock): block is RespondBlock<ImageBlock>;

export function isImageBlock(block: Block): block is ImageBlock {
  return block.type === BlockType.Image;
}

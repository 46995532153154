import { BlockType } from '@tallyforms/lib';
import { breakpoint, withDirection } from '@tallyforms/ui';
import styled from 'styled-components';

import { REQUIRED_INDICATOR_PADDING } from '@/components/form/required-indicator/styled';

export const Container = styled.div``;

export const ContentWrapper = styled.div`
  position: relative;
  display: inline-flex;
  padding-top: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.sm};

  &.required-indicator-position-fallback {
    .tally-required-indicator {
      position: relative;
      left: auto;
      right: auto;
      ${({ theme }) => withDirection('margin-left', theme.spacing.xs, theme.direction)}
    }

    h3 {
      ${({ theme }) => withDirection('padding-right', '0px', theme.direction)}
    }
  }
`;

export const ContentH1 = styled.h1`
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.25;

  @media (max-width: ${breakpoint.md}) {
    font-size: 24px;
  }
`;

export const ContentH2 = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;

  @media (max-width: ${breakpoint.md}) {
    font-size: 20px;
  }
`;

export const ContentH3 = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25;

  ${({ theme }) =>
    `${withDirection('padding-right', `${REQUIRED_INDICATOR_PADDING}px`, theme.direction)}

    &:empty {
      ${withDirection('padding-right', '0px', theme.direction)}
    }
  `}

  @media (max-width: ${breakpoint.md}) {
    font-size: 18px;
  }
`;

export const Content = styled.h2<{ groupType: BlockType }>`
  font-size: 26px;
  font-weight: 600;
  line-height: 1.1;

  ${({ groupType }) =>
    groupType === BlockType.Question &&
    `
    font-size: 18px;
    font-weight: bold;
    line-height: 1.25;
  `}
`;

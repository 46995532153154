import { ReactNode, useEffect, useState } from 'react';
import { BundledLanguage, BundledTheme, codeToHtml } from 'shiki';

import { Container, Copied, Copy, CopyButton } from './styled';

export interface CodeProps {
  lang?: BundledLanguage;
  theme?: BundledTheme;
  withCopy?: boolean;
  children: ReactNode;
}

export const Code = ({ children: code, lang, theme = 'dracula', withCopy = true }: CodeProps) => {
  const [copied, setCopied] = useState(false);
  const [highlightedCode, setHighlightedCode] = useState<string>();

  useEffect(() => {
    if (!code) {
      return;
    }

    codeToHtml(code.toString(), { lang, theme }).then(setHighlightedCode);
  }, [code, theme, lang]);

  const handleCopy = () => {
    if (copied) {
      return;
    }

    if (code) {
      navigator.clipboard.writeText(code.toString());
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  if (!code) {
    return null;
  }

  return (
    <Container>
      {highlightedCode ? (
        <div dangerouslySetInnerHTML={{ __html: highlightedCode }} />
      ) : (
        <pre>
          <code>{code}</code>
        </pre>
      )}
      {typeof window !== 'undefined' &&
        typeof window.navigator.clipboard !== 'undefined' &&
        withCopy && (
          <CopyButton onClick={handleCopy} copied={copied}>
            {copied ? <Copied /> : <Copy />}
          </CopyButton>
        )}
    </Container>
  );
};

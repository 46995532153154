import axios from 'axios';

import config from '@/config';
import { captureMessage } from '@/services/sentry';

let requestNumber = 0;
let isProxyEnabled = false;
const baseURL = config.get('api.base-url');

const api = (() => {
  const instance = axios.create({ baseURL, withCredentials: true });

  // Wrap the common HTTP methods
  const methods = ['get', 'post', 'put', 'patch', 'delete'] as const;
  methods.forEach((method) => {
    const originalMethod = instance[method].bind(instance);
    instance[method] = async (url: string, ...args: any[]) => {
      requestNumber++;
      return handleRequestWithProxyFallback(() => originalMethod(url, ...args), {
        url,
        method: method.toUpperCase(),
      });
    };
  });

  return instance;
})();

export const getAuthorizationToken = () => {
  return api.defaults.headers.common.authorization;
};

export const setAuthorizationToken = (authorizationToken: string) => {
  api.defaults.headers.common.authorization = `Bearer ${authorizationToken}`;
};

export const enableProxyBaseUrl = () => {
  api.defaults.baseURL = config.get('api.proxy-base-url');
  isProxyEnabled = true;
};

export const fetcher = async (url: string, method = 'GET', payload: any = {}) => {
  requestNumber++;

  // @ts-ignore
  return await api[method.toLowerCase()].apply(api, [url, payload]);
};

const handleRequestWithProxyFallback = async (
  requestFn: () => Promise<any>,
  requestInfo: { url: string; method: string },
) => {
  try {
    return await requestFn();
  } catch (error: any) {
    // Status code 0 means that the request was canceled by the browser, which could be due to a network error or CORS issue.
    // If it's one of the first 3 requests, we can try to use a proxy to make the request.
    if (isProxyEnabled === false && error.response?.status === 0 && requestNumber <= 3) {
      captureMessage('Enable API proxy', {
        extra: { url: requestInfo.url, method: requestInfo.method },
      });

      // Change the base URL to "tally.so/api"
      enableProxyBaseUrl();

      // And retry the request
      return await requestFn();
    }

    // Otherwise, throw the error
    throw error;
  }
};

export default api;

export const isEmpty = <T = any>(fieldValue: T) => {
  if (typeof fieldValue === 'undefined') {
    return true;
  }

  if (fieldValue === null) {
    return true;
  }

  if (typeof fieldValue === 'string') {
    return fieldValue.length === 0;
  }

  if (Array.isArray(fieldValue)) {
    return fieldValue.length === 0;
  }

  return false;
};

import styled from 'styled-components';

export type StatusIndicatorProps = {
  status?: 'success' | 'warning' | 'disabled' | null;
};

export const StatusIndicator = styled.div<StatusIndicatorProps>`
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  transition: background-color 200ms ease-in-out;

  ${({ status }) =>
    !status &&
    `
    display: none;
  `}

  ${({ theme, status }) =>
    status === 'success' &&
    `
    background-color: ${theme.color.green};
  `}

  ${({ theme, status }) =>
    status === 'warning' &&
    `
    background-color: ${theme.color.warning};
  `}

  ${({ theme, status }) =>
    status === 'disabled' &&
    `
    background-color: ${theme.color.lightGray};
  `}
`;

export const StatusIndicatorContainer = styled.div`
  width: 10px;
  height: 10px;
`;

import _accept from 'attr-accept';

/**
 * They added support for ESM in v3;
 * https://github.com/react-dropzone/attr-accept/pull/75
 *
 * Until that is released we can use either v3.0.0-beta.1, or this workaround;
 * https://github.com/react-dropzone/react-dropzone/commit/2471dd9
 */
type Accept = typeof _accept;
const accept = typeof _accept === 'function' ? _accept : (_accept as { default: Accept }).default;

import { AllowedFiles } from '../../types';
import { getAcceptProp } from './get-accept-prop';

export const isAllowedFile = (fileName: string, mimeType: string, allowedFiles: AllowedFiles) => {
  return accept({ name: fileName, type: mimeType }, Object.keys(getAcceptProp(allowedFiles)));
};

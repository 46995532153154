import {
  BlockType,
  Country,
  FormData,
  GROUP_OPTIONS_BLOCKS,
  isField,
  RespondBlock,
  SafeSchemaBlock,
} from '@tallyforms/lib';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';

import { FormSettings } from '@/types/form-builder';
import {
  convertTextToGroupOptionUuid,
  getAnswerForField,
  getInitialFormData,
  getNotSubmittedFormData,
  setNotSubmittedFormData,
} from '@/utils/form-respond';
import { applyCalculatedFieldsOperationsToFormData } from '@/utils/form-respond/calculated-fields';
import {
  countryCallingCodes,
  getCountryCodeFromPhoneNumber,
} from '@/utils/phone-number/country-code';

type FormDataProps = {
  mode: 'respond' | 'preview';
  formId?: string;
  blocks: SafeSchemaBlock[];
  settings: Partial<FormSettings> | null;
  page: number;
  pages: RespondBlock[][];
  pageHistory: number[];
  country?: Country | null;
};

function useFormData({
  mode,
  formId,
  blocks,
  settings,
  page,
  pages,
  pageHistory,
  country,
}: FormDataProps): {
  formData: FormData;
  onChangeFormData: (values: FormData) => void;
} {
  const router = useRouter();
  const isFirstPageRun = useRef(true);

  const [formData, setFormData] = useState<FormData>({
    // we need to initialize some initial state on the server side too
    ...getInitialFormData(router.asPath, blocks, country),
  });
  const [touchedFormData, setTouchedFormData] = useState<{
    [field: string]: boolean;
  }>({});
  const blocksWithDefaultAnswer = useMemo(
    () =>
      blocks.filter(
        (x) => x.payload.hasDefaultAnswer && typeof x.payload.defaultAnswer !== 'undefined',
      ),
    [],
  );
  // Blocks that are not directly inputted by the respondent
  const noDirectInputFields = useMemo(
    () =>
      blocks
        .filter((x) =>
          [
            BlockType.CalculatedFields,
            BlockType.HiddenFields,
            BlockType.RespondentCountry,
          ].includes(x.type),
        )
        .map((x) => x.groupUuid),
    [blocks],
  );

  const applyAutomatedFormDataChanges = (
    changedFormData: FormData,
    changedTouchedFormData: { [field: string]: boolean },
  ): FormData => {
    // Here we update the calculated fields' values & default answers
    //
    // We might need to do this a few times, for example:
    // 1. Calculated fields can update the default answers
    // 2. Those default answers can trigger conditional logic which needs to update the calculated fields, etc.
    // -> Go back to 1. and repeat until there are no state changes between the 2 operations
    //
    // WARNING: This could potentially cause an infinite loop, so we set an upper limit of 50 iterations
    let iterations = 0;
    let shouldUpdate = true;
    while (shouldUpdate) {
      // Apply calculated fields' values + any operations based on logic
      changedFormData = applyCalculatedFieldsOperationsToFormData(
        changedFormData,
        pageHistory,
        pages,
        blocks,
      );
      const formDataA = { ...changedFormData };

      // Apply default answers
      changedFormData = applyFormDataChangesAsDefaultAnswers(
        changedFormData,
        changedTouchedFormData,
      );
      const formDataB = { ...changedFormData };

      // Check if there were updates between the 2 form data states
      // If so, we need to perform another loop (check why in the example above)
      shouldUpdate = JSON.stringify(formDataA) !== JSON.stringify(formDataB) && iterations < 50;
      iterations++;
    }

    return changedFormData;
  };

  const applyFormDataChangesAsDefaultAnswers = (
    changedFormData: FormData,
    changedTouchedFormData: { [field: string]: boolean },
  ): FormData => {
    for (const block of blocksWithDefaultAnswer) {
      // If the field has been changed before, we cannot set a default answer
      if (changedTouchedFormData[block.groupUuid]) {
        continue;
      }

      let defaultAnswer: any;

      if (isField(block.payload.defaultAnswer)) {
        defaultAnswer = getAnswerForField(changedFormData, block.payload.defaultAnswer, blocks);
      } else {
        defaultAnswer = block.payload.defaultAnswer;
      }

      // Phone number input with default country code
      if (defaultAnswer && block.payload.internationalFormat && block.payload.defaultCountryCode) {
        // We found a default calling code for the default country code
        const defaultCallingCode = countryCallingCodes[block.payload.defaultCountryCode];
        if (defaultCallingCode) {
          defaultAnswer = defaultAnswer.trim();

          // Prefix the phone number with the default calling code only if we don't have one already
          const calculatedCountryCode = getCountryCodeFromPhoneNumber(defaultAnswer);
          if (!calculatedCountryCode) {
            defaultAnswer = `${defaultCallingCode}${defaultAnswer}`;
          }
        }
      }

      if (typeof defaultAnswer === 'undefined') {
        changedFormData[block.groupUuid] = undefined;
      } else if (GROUP_OPTIONS_BLOCKS.includes(block.groupType)) {
        // Group option: convert text to option UUID
        changedFormData[block.groupUuid] = convertTextToGroupOptionUuid(
          block.groupUuid,
          block.groupType,
          defaultAnswer,
          blocks,
        );
      } else if (
        [BlockType.InputNumber, BlockType.LinearScale, BlockType.Rating].includes(block.type)
      ) {
        // Number inputs: convert to number
        changedFormData[block.groupUuid] = parseFloat(defaultAnswer);
        if (isNaN(changedFormData[block.groupUuid])) {
          changedFormData[block.groupUuid] = undefined;
        }
      } else {
        changedFormData[block.groupUuid] = defaultAnswer;
      }
    }

    return changedFormData;
  };

  // Used to update formData at once, to prevent state lagging behind
  const onChangeFormData = (values: FormData) => {
    const changedTouchedFormData = { ...touchedFormData };
    for (const groupUuid in values) {
      changedTouchedFormData[groupUuid] = true;
    }

    // Changed field value
    let changedFormData = { ...formData, ...values };

    // Perform calculated fields' operations based on conditional logic & update default answers if needed
    changedFormData = applyAutomatedFormDataChanges(changedFormData, changedTouchedFormData);

    setFormData(changedFormData);

    setTouchedFormData(changedTouchedFormData);

    // Store not-submitted form data if the settings allow it
    if (mode === 'respond' && formId && settings?.saveForLater !== false) {
      const notSubmittedFormData = Object.keys(changedFormData).reduce((acc, key) => {
        // If the field is not directly inputted by the respondent, we save it
        if (noDirectInputFields.includes(key)) {
          acc[key] = changedFormData[key];
        }

        // If the field has been touched, we save it
        if (changedTouchedFormData[key]) {
          acc[key] = changedFormData[key];
        }

        return acc;
      }, {});

      setNotSubmittedFormData(formId, notSubmittedFormData);
    }
  };

  useEffect(() => {
    // Initialize form data
    let initialFormData: FormData = {};
    const changedTouchedFormData: { [field: string]: boolean } = {};

    // Respond mode: init form data and hidden fields
    if (mode === 'respond' && formId) {
      // With non-submitted form data if the settings allow it
      if (settings?.saveForLater !== false) {
        const notSubmittedFormData = getNotSubmittedFormData(formId, blocks);

        initialFormData = {
          ...initialFormData,
          ...notSubmittedFormData,
        };

        for (const key in notSubmittedFormData) {
          changedTouchedFormData[key] = true;
        }
      }

      // With hidden fields
      initialFormData = {
        ...initialFormData,
        ...getInitialFormData(router.asPath, blocks, country),
      };
    }

    // Init form data with default calculated fields' values & default answers
    initialFormData = applyAutomatedFormDataChanges(initialFormData, changedTouchedFormData);

    setFormData(initialFormData);

    if (Object.keys(changedTouchedFormData).length > 0) {
      setTouchedFormData(changedTouchedFormData);
    }
  }, []);

  useEffect(() => {
    // We ignore the first run as this is handled by the initialize useEffect() above
    if (isFirstPageRun.current) {
      isFirstPageRun.current = false;
      return;
    }

    // On page changes: check to calculate fields
    setFormData(applyAutomatedFormDataChanges(formData, touchedFormData));
  }, [page]);

  return { formData, onChangeFormData };
}

// This is used to store the emails that have been verified
// TODO: This should be moved to a form data context
const verifiedEmails: string[] = [];

export const addVerifiedEmail = (email: string) => {
  verifiedEmails.push(email);
};

export const isEmailVerified = (email: string) => {
  return verifiedEmails.includes(email);
};

export default useFormData;

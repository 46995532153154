import { Currency } from './currency';

export enum SubscriptionPlan {
  Free = 'FREE',
  Pro = 'PRO',
  Business = 'BUSINESS',
  Lifetime = 'LIFETIME',
}

export type SubscriptionPricing = {
  currency: Currency;
  plans: {
    [SubscriptionPlan.Pro]: {
      month: number;
      year: number;
    };
    [SubscriptionPlan.Business]: {
      month: number;
      year: number;
    };
  };
};

export type SubscriptionBilling = {
  plan: SubscriptionPlan;
  interval?: 'month' | 'year';
  status?: 'active' | 'canceled' | 'past_due' | 'trialing';
  canceledAt?: string | null;
  expiredAt?: string | null;
  manageSubscriptionUrl?: string;
};

export enum SubscriptionCancelReason {
  PaymentFailed = 'PAYMENT_FAILED',
  Unused = 'UNUSED',
  TooExpensive = 'TOO_EXPENSIVE',
  SwitchedService = 'SWITCHED_SERVICE',
  MissingFeatures = 'MISSING_FEATURES',
  TooComplex = 'TOO_COMPLEX',
  LowQuality = 'LOW_QUALITY',
  CustomerService = 'CUSTOMER_SERVICE',
  Other = 'OTHER',
  Fraud = 'FRAUD',
}

export enum SubscriptionUpgradeTrigger {
  Default = 'DEFAULT',
  Settings = 'SETTINGS',

  // Pro features
  Members = 'MEMBERS',
  Workspaces = 'WORKSPACES',
  RemoveBranding = 'REMOVE_BRANDING',
  NoApplicationFee = 'NO_APPLICATION_FEE',
  UnlimitedUploads = 'UNLIMITED_UPLOADS',
  CustomDomains = 'CUSTOM_DOMAINS',
  CustomizeLinkPreview = 'CUSTOMIZE_LINK_PREVIEW',
  CustomSelfEmailNotifications = 'CUSTOM_SELF_EMAIL_NOTIFICATIONS',
  RespondentEmailNotifications = 'RESPONDENT_EMAIL_NOTIFICATIONS',
  PartialSubmissions = 'PARTIAL_SUBMISSIONS',
  IntegrationGoogleAnalytics = 'INTEGRATION_GOOGLE_ANALYTICS',
  IntegrationFacebookPixel = 'INTEGRATION_FACEBOOK_PIXEL',
  CustomCSS = 'CUSTOM_CSS',

  // Business features
  SubmissionsDataRetention = 'SUBMISSIONS_DATA_RETENTION',
  VerifyEmails = 'VERIFY_EMAILS',
}

import { BlockType, CalculatedFieldType } from './block';

export enum FieldType {
  Metadata = 'METADATA',
  InputField = 'INPUT_FIELD',
  HiddenField = 'HIDDEN_FIELD',
  CalculatedField = 'CALCULATED_FIELD',
}

export interface Field {
  uuid: string;
  type: FieldType;
  questionType: BlockType;
  blockGroupUuid: string;
  title: string;
  calculatedFieldType?: CalculatedFieldType;
  isDeleted?: boolean;
  numberOfResponses?: number;
}

export interface FieldsOptions {
  beforeBlockUuid?: string;
  alwaysIncludeBlockTypes?: BlockType[];
  filterOutBlockTypes?: BlockType[];
  includeMetadata?: boolean;
  combineMatrix?: boolean;
  includeRespondentMetadata?: boolean;
}

export const isField = (field: any): field is Field => {
  return Object.values(FieldType).includes(field?.type);
};

export const set = (key: string, value: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    window.localStorage?.setItem?.(key, value);
  } catch {
    return;
  }
};

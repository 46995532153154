import { getClientIp } from 'request-ip';

type Request = {
  headers?: Record<string, string | string[]>;
  connection?: {
    remoteAddress?: string;
  };
};

export const ipFromRequest = (req: Request) => {
  return (req.headers?.['cf-connecting-ip'] as string) || getClientIp(req) || null;
};

import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type RatingBlock = Block<
  BlockType.Rating,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | number;
    stars?: number;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isRatingBlock(block: FormBuilderBlock): block is FormBuilderBlock<RatingBlock>;

export function isRatingBlock(block: RespondBlock): block is RespondBlock<RatingBlock>;

export function isRatingBlock(block: Block): block is RatingBlock {
  return block.type === BlockType.Rating;
}

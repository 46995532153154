import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type MatrixBlock = Block<
  BlockType.Matrix,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    randomizeRows?: boolean;
    allowMultiple?: boolean;
    hasMinChoices?: boolean;
    minChoices?: number;
    hasMaxChoices?: boolean;
    maxChoices?: number;
    name?: string;
  }
>;

export function isMatrixBlock(block: FormBuilderBlock): block is FormBuilderBlock<MatrixBlock>;

export function isMatrixBlock(block: RespondBlock): block is RespondBlock<MatrixBlock>;

export function isMatrixBlock(block: Block): block is MatrixBlock {
  return block.type === BlockType.Matrix;
}

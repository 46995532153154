import { forwardRef } from 'react';

import { Container } from './styled';

const Textarea = forwardRef(
  (props: React.TextareaHTMLAttributes<HTMLTextAreaElement>, forwardedRef: any) => {
    return <Container ref={forwardedRef} {...props} />;
  },
);

Textarea.displayName = 'Textarea';

export default Textarea;

import { FormData, RespondBlock, SafeSchemaBlock } from '@tallyforms/lib';
import { useEffect, useState } from 'react';

import { getPageCompletion } from '@/utils/form-respond';
import { getPageFlow } from '@/utils/form-respond/logic';

import { Container, ProgressBarItem } from './styled';

interface Props {
  pages: RespondBlock[][];
  currentPage: number;
  currentPageBlocks: RespondBlock[];
  formData: FormData;
  blocks: SafeSchemaBlock[];
  hasCover?: boolean;
  popup?: boolean;
  embed?: boolean;
}

const ProgressBar = ({
  pages,
  currentPage,
  currentPageBlocks,
  formData,
  blocks,
  hasCover,
  popup,
  embed,
}: Props) => {
  const [isCombined, setIsCombined] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const pageFlow = getPageFlow(blocks, pages, formData);

  const pageCompletion = getPageCompletion(pageFlow, formData, currentPage, currentPageBlocks);

  const onWindowResize = () => {
    // If the individual size of each progress bar is approximately less than
    // 40px (taking into account margins), we combine the progress bar into 1
    // section
    if (window.innerWidth / pageFlow.length < 40) {
      setIsCombined(true);
    } else {
      setIsCombined(false);
    }

    // Show the progress bar
    setIsHidden(false);
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    onWindowResize();
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [pages.length]);

  if (isHidden) {
    return null;
  }

  return (
    <Container className="tally-progress-bar" hasCover={hasCover} popup={popup} embed={embed}>
      {pageFlow.map((page) => (
        <ProgressBarItem
          key={page}
          className="tally-progress-bar-item"
          completion={pageCompletion[page]}
          width={`${100 / pageFlow.length}%`}
          hasCover={hasCover}
          isCombined={isCombined}
        />
      ))}
    </Container>
  );
};

export default ProgressBar;

export const getQueryString = (input: Record<string, any>) => {
  const params: Record<string, any> = {};
  for (const [key, value] of Object.entries(input)) {
    if (value === true) {
      params[key] = 1;
    } else if (value === false) {
      params[key] = 0;
    } else if (value !== null && value !== undefined) {
      params[key] = value;
    }
  }

  if (Object.keys(params).length === 0) {
    return '';
  }

  return `?${new URLSearchParams(params)}`;
};

import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type InputPhoneNumberBlock = Block<
  BlockType.InputPhoneNumber,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    placeholder?: string;
    internationalFormat?: boolean;
    defaultCountryCode?: string;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isInputPhoneNumberBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<InputPhoneNumberBlock>;

export function isInputPhoneNumberBlock(
  block: RespondBlock,
): block is RespondBlock<InputPhoneNumberBlock>;

export function isInputPhoneNumberBlock(block: Block): block is InputPhoneNumberBlock {
  return block.type === BlockType.InputPhoneNumber;
}

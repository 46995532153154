import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type TextareaBlock = Block<
  BlockType.Textarea,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    placeholder?: string;
    hasMinCharacters?: boolean;
    minCharacters?: number;
    hasMaxCharacters?: boolean;
    maxCharacters?: number;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isTextareaBlock(block: FormBuilderBlock): block is FormBuilderBlock<TextareaBlock>;

export function isTextareaBlock(block: RespondBlock): block is RespondBlock<TextareaBlock>;

export function isTextareaBlock(block: Block): block is TextareaBlock {
  return block.type === BlockType.Textarea;
}

import { Check } from 'lucide-react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';

import { FormStyles } from '@/types/form-design';

import { Container, Description, IconContainer, Title } from './styled';

const PoweredByBadge = dynamic(() => import('@/components/form-respond/powered-by-badge'), {
  ssr: false,
});

interface Props {
  mode: 'respond' | 'preview';
  styles: FormStyles;
  onPoweredByClick?: (e: any) => void;
}

const ThankYouPage = ({ mode, styles, onPoweredByClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <IconContainer>
        <Check size={32} strokeWidth={3} />
      </IconContainer>

      <Title>{t('form-respond.thank-you-page.title')}</Title>

      {!styles.removeBranding && (
        <Description
          dangerouslySetInnerHTML={{
            __html: t('form-respond.thank-you-page.description'),
          }}
        />
      )}

      {!styles.removeBranding && (
        <PoweredByBadge
          mode={mode}
          styles={styles}
          isOnThankYouPage={true}
          onClick={onPoweredByClick}
        />
      )}
    </Container>
  );
};

export default ThankYouPage;

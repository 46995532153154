import BigNumber from 'bignumber.js';

export const getMinimumAmountFromError = (error: string) => {
  let minimumAmount = 0;
  const matches = error.match(/[0-9]{1,}(\.[0-9]+)?/g);

  if (!matches) {
    return 0;
  }

  // Type 1 error (Ex: Amount must be at least $0.50 usd)
  if (matches.length === 1) {
    minimumAmount = parseFloat(matches[0]);
  }

  // Type 2 error (Ex: Amount must convert to at least 50 cents. €0.20 converts to approximately $0.21.)
  if (matches.length === 3) {
    let amountDivideBy = 100;

    // Zero-decimal settlement currencies (only JPY supported for the moment)
    if (error.includes(`¥${matches[2]}`)) {
      amountDivideBy = 1;
    }

    // Using ceil because `round` results in < 0.5 to be 0 instead of 1
    minimumAmount = BigNumber(matches[1])
      .dividedBy(matches[2])
      .multipliedBy(BigNumber(matches[0]).dividedBy(amountDivideBy))
      .toNumber();
    minimumAmount = Math.round(minimumAmount * 100) / 100;
  }

  return minimumAmount;
};

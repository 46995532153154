import Link from 'next/link';
import { forwardRef } from 'react';

import ActivityIndicator from '@/components/activity-indicator';
import Tooltip from '@/components/tooltip';

import { Container, Content, LinkWrapper, LoadingContainer } from './styled';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  value?: string;
  icon?: (props: any) => JSX.Element;
  iconPosition?: 'left' | 'right';
  variant?: 'primary' | 'outline' | 'ghost' | 'ghost-secondary' | 'destructive';
  color?: 'accent' | 'black' | 'white' | 'main' | any;
  size?: 'regular' | 'big' | 'small';
  href?: string;
  hrefAs?: string;
  target?: string;
  tooltip?: string;
  tooltipTextAlign?: 'left' | 'center' | 'right';
  fullWidth?: boolean;
  isIconOnlyOnMobile?: boolean;
  isLoading?: boolean;
  hasDisabledLook?: boolean;
}

const Button = forwardRef((props: Props, forwardedRef: any) => {
  const {
    value,
    type = 'button',
    icon,
    iconPosition = 'left',
    tooltip,
    tooltipTextAlign,
    isLoading = false,
    disabled = false,
    hasDisabledLook = false,
  } = props;

  const Icon = icon ?? null;

  const renderButton = () => {
    const isBig = props.size === 'big';

    return (
      <Container
        ref={forwardedRef}
        type={type}
        {...props}
        disabled={isLoading || disabled}
        isDisabled={hasDisabledLook || disabled}
        iconOnly={!value && !!Icon}
        aria-label={value ?? tooltip}>
        {isLoading && (
          <LoadingContainer>
            <ActivityIndicator size={16} />
          </LoadingContainer>
        )}

        <Content>
          {Icon && iconPosition === 'left' && <Icon size={16} strokeWidth={isBig ? 3 : 2} />}
          {value && <span>{value}</span>}
          {Icon && iconPosition === 'right' && <Icon size={16} strokeWidth={isBig ? 3 : 2} />}
        </Content>

        {tooltip && (
          <Tooltip
            text={tooltip}
            highlightWords={tooltip.includes('<em>')}
            textAlign={tooltipTextAlign}
            place="bottom"
          />
        )}
      </Container>
    );
  };

  // We have href, then render a link with a button inside
  if (props.href) {
    return (
      <LinkWrapper fullWidth={props.fullWidth}>
        <Link
          href={props.href}
          as={props.hrefAs ?? props.href}
          target={props.target}
          prefetch={false}
          tabIndex={-1}
          aria-label={props.value ?? props.tooltip}>
          {renderButton()}
        </Link>
      </LinkWrapper>
    );
  }

  // Otherwise, we render a regular button
  return renderButton();
});

Button.displayName = 'Button';

export default Button;

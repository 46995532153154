import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type WalletConnectNetwork = {
  id: number;
  name: string;
};

export type WalletConnectBlock = Block<
  BlockType.WalletConnect,
  {
    isHidden?: boolean;
    isRequired?: boolean;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
    hasEmailLogin?: boolean;
    hasSocialLogin?: boolean;
    useCaipFormat?: boolean;
    networks?: [WalletConnectNetwork['id']];
  }
>;

export function isWalletConnectBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<WalletConnectBlock>;

export function isWalletConnectBlock(
  block: RespondBlock,
): block is RespondBlock<WalletConnectBlock>;

export function isWalletConnectBlock(block: Block): block is WalletConnectBlock {
  return block.type === BlockType.WalletConnect;
}

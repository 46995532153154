import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export enum BadgeType {
  Off = 'OFF',
  Numbers = 'NUMBERS',
  Letters = 'LETTERS',
}

export type MultipleChoiceOptionBlock = Block<
  BlockType.MultipleChoiceOption,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    index?: number;
    isFirst?: boolean;
    isLast?: boolean;
    colorCodeOptions?: boolean;
    color?: string;
    hasBadge?: boolean;
    badgeType?: BadgeType;
    hasOtherOption?: boolean;
    isOtherOption?: boolean;
    randomize?: boolean;
    lockInPlace?: string[];
    image?: string;
    hasMinChoices?: boolean;
    minChoices?: number;
    hasMaxChoices?: boolean;
    maxChoices?: number;
    allowMultiple?: boolean;
    name?: string;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
  }
>;

export function isMultipleChoiceOptionBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<MultipleChoiceOptionBlock>;

export function isMultipleChoiceOptionBlock(
  block: RespondBlock,
): block is RespondBlock<MultipleChoiceOptionBlock>;

export function isMultipleChoiceOptionBlock(block: Block): block is MultipleChoiceOptionBlock {
  return block.type === BlockType.MultipleChoiceOption;
}

export const getTaxIdLabel = (countryCode: string, t: any): string => {
  if (
    [
      'BE',
      'BG',
      'CZ',
      'DK',
      'DE',
      'EE',
      'IE',
      'GR',
      'ES',
      'FR',
      'HR',
      'IT',
      'CY',
      'LV',
      'LT',
      'LU',
      'HU',
      'MT',
      'NL',
      'AT',
      'PL',
      'PT',
      'RO',
      'SI',
      'SK',
      'FI',
      'SE',
    ].includes(countryCode)
  ) {
    return t('label.tax-id.vat');
  }

  switch (countryCode) {
    case 'CH':
    case 'GB':
    case 'NO':
    case 'SA':
    case 'TH':
    case 'TW':
    case 'ZA':
      return t('label.tax-id.vat');

    case 'US':
      return t('label.tax-id.ein');
  }

  return t('label.tax-id');
};

export const getTaxIdPlaceholder = (countryCode: string): string => {
  switch (countryCode) {
    case 'AE':
      return '123456789012345';
    case 'AU':
      return '12345678912';
    case 'BR':
      return '01.234.456/5432-10';
    case 'CA':
      return '123456789';
    case 'CH':
      return 'CHE-123.456.789 MWST';
    case 'CL':
      return '12.345.678-K';
    case 'AT':
      return 'ATU12345678';
    case 'BE':
      return 'BE0123456789';
    case 'BG':
      return 'BG0123456789';
    case 'CY':
      return 'CY12345678Z';
    case 'CZ':
      return 'CZ1234567890';
    case 'DE':
      return 'DE123456789';
    case 'DK':
      return 'DK12345678';
    case 'EE':
      return 'EE123456789';
    case 'ES':
      return 'ESA1234567Z';
    case 'FI':
      return 'FI12345678';
    case 'FR':
      return 'FRAB123456789';
    case 'GR':
      return 'EL123456789';
    case 'HR':
      return 'HR12345678912';
    case 'HU':
      return 'HU12345678912';
    case 'IE':
      return 'IE1234567AB';
    case 'IT':
      return 'IT12345678912';
    case 'LT':
      return 'LT123456789123';
    case 'LU':
      return 'LU12345678';
    case 'LV':
      return 'LV12345678912';
    case 'MT':
      return 'MT12345678';
    case 'NL':
      return 'NL123456789B12';
    case 'PL':
      return 'PL1234567890';
    case 'PT':
      return 'PT123456789';
    case 'RO':
      return 'RO1234567891';
    case 'SE':
      return 'SE123456789123';
    case 'SI':
      return 'SI12345678';
    case 'SK':
      return 'SK1234567891';
    case 'GB':
      return 'GB123456789';
    case 'HK':
      return '12345678';
    case 'ID':
      return '12.345.678.9-012.345';
    case 'IN':
      return '12ABCDE3456FGZH';
    case 'JP':
      return '1234567891234';
    case 'KR':
      return '123-45-67890';
    case 'LI':
      return 'CHE123456789';
    case 'MX':
      return 'ABC010203AB9';
    case 'NO':
      return '123456789MVA';
    case 'NZ':
      return '123456789';
    case 'RU':
      return '1234567891';
    case 'SA':
      return '123456789012345';
    case 'SG':
      return 'M12345678X';
    case 'TH':
      return '1234567891234';
    case 'TW':
      return '12345678';
    case 'US':
      return '12-3456789';
    case 'ZA':
      return '4123456789';
  }

  return '';
};

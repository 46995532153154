export const getVerticalScrollOffset = () => {
  if (window.pageYOffset > 0) {
    return window.pageYOffset;
  }

  // It could be that we have locked the scroll (check useLockBodyScroll hook), then we have set the scroll offset as negative body.style.top value
  if (document.body.style.top) {
    return parseFloat(document.body.style.top) * -1;
  }

  return 0;
};

export const getPositionForContextMenuByTrigger = (
  triggerEl: HTMLElement,
): {
  top: number;
  bottom: number;
  left: number;
  right: number;
} => {
  return {
    top: triggerEl.getBoundingClientRect().bottom + 5 + getVerticalScrollOffset(),
    bottom:
      window.innerHeight - triggerEl.getBoundingClientRect().top - getVerticalScrollOffset() + 5,
    left: triggerEl.getBoundingClientRect().left + window.pageXOffset,
    right: window.innerWidth - triggerEl.getBoundingClientRect().right - window.pageXOffset,
  };
};

export const getPositionForContextMenuBySelection = (): {
  top: number;
  bottom: number;
  left: number;
  right: number;
} => {
  const range = window.getSelection()?.getRangeAt(0);
  if (!range) {
    return {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
  }

  // If the range is an element and not text node, use the container
  let clientObj: any = range;
  if (range.startContainer.nodeType === Node.ELEMENT_NODE) {
    clientObj = range.startContainer;
  }

  const { top, bottom, left, right } = clientObj.getBoundingClientRect();

  // Page = window
  return {
    top: bottom + 5 + getVerticalScrollOffset(),
    bottom: window.innerHeight - top - getVerticalScrollOffset() + 5,
    left: left + window.pageXOffset,
    right: window.innerWidth - right - window.pageXOffset,
  };
};

export const getLeftPositionFromRange = (node: Node): number | null => {
  // Create a range and select the previous sibling (The one with the text)
  const range = document.createRange();
  range.selectNode(node);

  // Get all client rects, set default position and return early if none
  const contentRect = range.getClientRects();
  if (contentRect.length === 0) {
    return null;
  }

  /**
   * Assign the yPos to the last one on the rect
   * So later we can find all rects on the same y position
   * And add the width of all
   * On RTL this is needed because having `LTR` and `RTL` text causes to create multiple rects
   */
  const yPos = contentRect[contentRect.length - 1].y;
  let left = 0;
  for (let x = 0; x < contentRect.length; x++) {
    if (contentRect[x].y === yPos) {
      left += contentRect[x].width;
    }
  }

  // We need to check if we have an empty mention to add the space for it
  const childNodesArray = Array.from(node.childNodes);
  const emptyMentions = childNodesArray.filter(
    (node) =>
      node.nodeName === 'SPAN' &&
      (node as HTMLElement).classList.contains('mention') &&
      node.textContent === '',
  );
  if (emptyMentions.length > 0) {
    // For each mention, selected it's rect and add the width
    for (const mention of emptyMentions) {
      range.selectNode(mention);
      const rect = range.getClientRects();

      // We need to check if the rect is on the same y position as the text
      if (rect[0].y === yPos) {
        left += rect[0].width;
      }
    }
  }

  // Detach range since we're done
  range.detach();

  return left;
};

import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type InputEmailBlock = Block<
  BlockType.InputEmail,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    placeholder?: string;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
    requireVerification?: boolean;
  }
>;

export function isInputEmailBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<InputEmailBlock>;

export function isInputEmailBlock(block: RespondBlock): block is RespondBlock<InputEmailBlock>;

export function isInputEmailBlock(block: Block): block is InputEmailBlock {
  return block.type === BlockType.InputEmail;
}

import { Field } from '../field';
import { Position } from '../position';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export enum ConditionalLogicLogicalOperator {
  And = 'AND',
  Or = 'OR',
}

export enum ConditionalLogicCalculateOperator {
  Addition = 'ADDITION',
  Subtraction = 'SUBTRACTION',
  Multiplication = 'MULTIPLICATION',
  Division = 'DIVISION',
  Assignment = 'ASSIGNMENT',
}

export enum ConditionalLogicComparison {
  Is = 'IS',
  IsNot = 'IS_NOT',
  IsAnyOf = 'IS_ANY_OF',
  IsEveryOf = 'IS_EVERY_OF',
  Contains = 'CONTAINS',
  DoesNotContain = 'DOES_NOT_CONTAIN',
  StartsWith = 'STARTS_WITH',
  DoesNotStartWith = 'DOES_NOT_START_WITH',
  EndsWith = 'ENDS_WITH',
  DoesNotEndWith = 'DOES_NOT_END_WITH',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  GreaterOrEqualThan = 'GREATER_OR_EQUAL_THAN',
  LessOrEqualThan = 'LESS_OR_EQUAL_THAN',
  IsBefore = 'IS_BEFORE',
  IsAfter = 'IS_AFTER',
}

export enum ConditionalLogicType {
  Single = 'SINGLE',
  Group = 'GROUP',
}

export interface ConditionalLogicConditional {
  uuid: string;
  type: ConditionalLogicType;
  payload?: ConditionalLogicBlock['payload'];
}

export enum ConditionalLogicActionType {
  JumpToPage = 'JUMP_TO_PAGE',
  Calculate = 'CALCULATE',
  RequireAnswer = 'REQUIRE_ANSWER',
  ShowBlocks = 'SHOW_BLOCKS',
  HideBlocks = 'HIDE_BLOCKS',
  HideButtonToDisableCompletion = 'HIDE_BUTTON_TO_DISABLE_COMPLETION',
}

export interface ConditionalLogicActionPayload {
  // Jump to page (type=number is deprecated, but still supported)
  jumpToPage?: string | number;

  // Show blocks
  showBlocks?: string[];

  // Hide blocks
  hideBlocks?: string[];

  // Require answer
  requireAnswer?: string;

  // Calculate
  calculate?: ConditionalLogicCalculateActionPayload;
}

export interface ConditionalLogicAction {
  uuid: string;
  type?: ConditionalLogicActionType;
  payload?: ConditionalLogicActionPayload;
}

export type ConditionalLogicPayload = {
  updateUuid: string | null;
  logicalOperator: ConditionalLogicLogicalOperator;
  conditionals: ConditionalLogicConditional[];
  actions: ConditionalLogicAction[];
};

export type ConditionalLogicSingleBlockPayload = {
  field?: Field;
  comparison?: ConditionalLogicComparison;
  value?: any;
};

export type ConditionalLogicGroupBlockPayload = {
  logicalOperator?: ConditionalLogicLogicalOperator;
  conditionals?: ConditionalLogicConditional[];
};

export type ConditionalLogicCalculateActionPayload = {
  field?: Field;
  operator?: ConditionalLogicCalculateOperator;
  value?: any;
};

export type ConditionalLogicBlock = Block<
  BlockType.ConditionalLogic,
  ConditionalLogicSingleBlockPayload &
    ConditionalLogicGroupBlockPayload & {
      actions?: ConditionalLogicAction[];
    }
>;

export function isConditionalLogicBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<ConditionalLogicBlock>;

export function isConditionalLogicBlock(
  block: RespondBlock,
): block is RespondBlock<ConditionalLogicBlock>;

export function isConditionalLogicBlock(block: Block): block is ConditionalLogicBlock {
  return block.type === BlockType.ConditionalLogic;
}

export interface ConditionalLogicActionMenu {
  isOpen: boolean;
  position: Position;
  action: ConditionalLogicAction;
  index: number;
}

export type ConditionalLogicConditionalActionsModifyCallback = ({
  blockPayload,
  action,
  index,
}: {
  blockPayload: ConditionalLogicPayload;
  action: ConditionalLogicAction;
  index: number;
}) => void;

export interface ConditionalLogicConditionalMenu {
  isOpen: boolean;
  position: Position;
  conditional: ConditionalLogicConditional;
  isWithinGroup: boolean;
}

export type ConditionalLogicConditionalsModifyCallback = ({
  groupPayload,
  conditional,
  index,
}: {
  groupPayload: ConditionalLogicGroupBlockPayload;
  conditional: ConditionalLogicConditional;
  index: number;
}) => void;

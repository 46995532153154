export enum TallyEvent {
  // Public events
  TallyFormLoaded = 'Tally.FormLoaded',
  TallyFormPageView = 'Tally.FormPageView',
  TallyFormSubmitted = 'Tally.FormSubmitted',
  TallyFormHightlightFirstError = 'Tally.FormHighlightFirstError',
  TallyPopupClosed = 'Tally.PopupClosed',

  // Private events
  TooltipHide = 'Tooltip.hide',
  StylesChange = 'Styles.change',
  TextFormatOnColorChange = 'TextFormat.onColorChange',
  TextFormatAction = 'TextFormat.action',
  SelectableBlocksToggle = 'SelectableBlocks.toggle',
  MovableBlocksMove = 'MoveableBlocks.move',
  CaptchaReset = 'Captcha.reset',
  FormBuilderOnPublish = 'FormBuilder.onPublish',
  PaymentOnChange = 'Payment.onChange',
  PaymentPay = 'Payment.pay',
  FormBuilderAction = 'FormBuilder.action',
}

export const dispatchTallyEvent = (key: string, detail?: any) => {
  if (window.dispatchEvent) {
    let event: any;
    if (typeof CustomEvent === 'function') {
      event = new CustomEvent(key, { detail });
    } else {
      // IE11 support
      event = document.createEvent('Event');
      event.initEvent(key, true, true);
    }

    if (event) {
      window.dispatchEvent(event);
    }
  }
};

import { logger } from '../utils';

interface RequestOptions {
  timeout?: number;
}

interface RequestParams {
  ip?: string;
  email?: string;
}

export class StopForumSpam {
  private static readonly API_URL = 'https://api.stopforumspam.org/api';
  private static readonly TIMEOUT = 500;

  private static async request(params: RequestParams, options?: RequestOptions) {
    const start = Date.now();
    const timeout = options?.timeout || this.TIMEOUT;

    if (!params?.ip && !params?.email) {
      throw new Error('At least one param [ip, email] must be provided');
    }

    const url = new URL(this.API_URL);
    url.searchParams.set('json', 'true');
    if (params.ip) {
      url.searchParams.set('ip', params.ip);
    }
    if (params.email) {
      url.searchParams.set('email', params.email);
    }

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(url, { signal: controller.signal })
      .then(async (response) => {
        const data = await response.json();
        logger.trace(`${url} - ${Date.now() - start}ms`);

        return data as {
          success: 1 | 0;
          email: { value: string; appears: number };
          ip: { value: string; appears: number };
        };
      })
      .catch((error: Error) => {
        logger.trace(`${url} - ${Date.now() - start}ms`);
        logger.error(`${error.name}: ${error.message}`);
      })
      .finally(() => {
        clearTimeout(timeoutId);
      });

    return response || { success: 0, email: { appears: 0 }, ip: { appears: 0 } };
  }

  public static async isCleanIp(ip: string, options?: RequestOptions) {
    logger.wait(`Checking IP ${ip} against StopForumSpam API...`);

    const response = await this.request({ ip }, options);
    if (!response.success) {
      logger.warn('StopForumSpam API call failed, assuming clean IP');
      return true;
    }

    if (response.ip.appears) {
      logger.event(`IP ${ip} found in StopForumSpam database`);
      return false;
    }

    logger.event(`IP ${ip} is clean`);
    return true;
  }

  public static async isCleanEmail(email: string, options?: RequestOptions) {
    logger.wait(`Checking email ${email} against StopForumSpam API...`);

    const response = await this.request({ email }, options);
    if (!response.success) {
      logger.warn('StopForumSpam API call failed, assuming clean email');
      return true;
    }

    if (response.email.appears) {
      logger.event(`Email ${email} found in StopForumSpam database`);
      return false;
    }

    logger.event(`Email ${email} is clean`);
    return true;
  }

  public static async isClean(ip: string, email: string, options?: RequestOptions) {
    logger.wait(`Checking IP ${ip} and email ${email} against StopForumSpam API...`);

    const response = await this.request({ ip, email }, options);
    if (!response.success) {
      logger.warn('StopForumSpam API call failed, assuming clean IP and email');
      return true;
    }

    if (response.ip.appears || response.email.appears) {
      if (response.ip.appears && response.email.appears) {
        logger.event(`IP ${ip} and email ${email} found in StopForumSpam database`);
      } else if (response.ip.appears) {
        logger.event(`IP ${ip} found in StopForumSpam database`);
      } else if (response.email.appears) {
        logger.event(`Email ${email} found in StopForumSpam database`);
      }
      return false;
    }

    logger.event(`IP ${ip} and email ${email} are clean`);
    return true;
  }
}

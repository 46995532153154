import type { CaptureContext } from '@sentry/core';
import * as Sentry from '@sentry/nextjs';

import config from '@/config';

export const sentryWithScope = Sentry.withScope;

export const sentryInit = () => {
  if (!config.isProductionEnv()) {
    return;
  }

  Sentry.init({
    release: process.env.NEXT_PUBLIC_COMMIT_SHA || process.env.COMMIT_SHA,
    dsn: config.get('sentry.dsn'),
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    ignoreErrors: [
      'routeChangeStopped',
      'ResizeObserver loop limit exceeded',
      'Network Error',
      'Request failed with status code 401',
      'getReadMode',
      'play method is not allowed',
      "Failed to set the 'cookie' property",
      '__firefox__',
      'webkitExitFullScreen',
      'Non-Error promise rejection captured',
      'webkit.messageHandlers',
      "Failed to set the 'href' property on 'Location'",
      'instantSearchSDKJSBridgeClearHighlight',
      'webkitPresentationMode',
      'jQuery',
      'onPagePause',
      'onPageResume',
      'isMainframe',
      'Blocked a frame with origin "https://tally.so" from accessing a cross-origin frame',
      'safari-web-extension',
      'get-frame-manager-configuration',
      '_AutofillCallbackHandler',
      'zaloJS',
      `Unexpected token '<'`,
      'privateSpecialRepair',
      `evaluating 'e.getInitialProps'`,
      'wss://relay.walletconnect.org',
      'Failed to disconnect',
      'Request failed with status code 401',
      'HTTP Request failed',
      'Request aborted',
    ],
    allowUrls: [config.get('web.base-url'), 'app://'],
    denyUrls: [
      // Browser extensions
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /^moz-extension:\/\//i,
      /^safari-extension:\/\//i,
      /^safari-web-extension:\/\//i,
      /^opera:\/\//i,
      /^extensions\//i,
      /^resource:\/\//i,

      // Common third-party domains
      /chrome\.google\.com/i,
      /extensions\//i,
      /^file:\/\//i,
      /^adsense/i,
      /^analytics/i,
      /^gtag/i,
    ],
    beforeSend(event) {
      // Skip if error is from a different origin than your app
      const stacktrace = event.exception?.values?.[0]?.stacktrace;
      if (stacktrace?.frames) {
        const isErrorFromApp = stacktrace.frames.some(
          (frame) =>
            frame.filename?.includes(config.get('web.base-url')) ||
            frame.filename?.startsWith('app://'),
        );
        if (!isErrorFromApp) {
          return null;
        }
      }

      return event;
    },
  });
};

export const captureException = (e: any, context?: CaptureContext) => {
  if (!config.isProductionEnv()) {
    console.error(e); // eslint-disable-line
    return;
  }

  Sentry.captureException(e, context);
};

export const captureMessage = (msg: string, context?: CaptureContext) => {
  if (!config.isProductionEnv()) {
    console.error(msg); // eslint-disable-line
    return;
  }

  Sentry.captureMessage(msg, context);
};

import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  height: 36px;
  padding: 0 ${({ theme }) => theme.spacing.sm};
  box-shadow: ${({ theme }) => theme.color.inputBoxShadow};
  border: 0;
  border-radius: 5px;
  outline: none;
  background-color: ${({ theme }) => theme.color.inputBackground};
  color: ${({ theme }) => theme.color.text};
  caret-color: ${({ theme }) => theme.color.text};

  @media (hover: hover) {
    &:hover {
      box-shadow: ${({ theme }) => theme.color.inputBoxShadowHover};
    }
  }

  &:focus {
    box-shadow: ${({ theme }) => theme.color.inputBoxShadowFocus};
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.inputPlaceholder};
  }
`;

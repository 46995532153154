import { useEffect } from 'react';

import { MetaData } from '@/types/html';

function useCustomDomainLinks(page: number, metaData: MetaData) {
  useEffect(() => {
    // Only for custom domains
    if (!metaData.isCustomDomain) {
      return;
    }

    // Missing querySelectorAll?
    if (!document || !document.querySelectorAll) {
      return;
    }

    const linkEls = document.querySelectorAll('form a');
    if (!linkEls || linkEls.length === 0 || !linkEls.forEach) {
      return;
    }

    linkEls.forEach((el) => {
      // Internal link
      if (
        el &&
        el.getAttribute &&
        (el.getAttribute('href') ?? '').indexOf(window.location.origin) === 0 &&
        el.getAttribute('target') === '_blank'
      ) {
        el.removeAttribute('target');
      }
    });
  }, [page]);
}

export default useCustomDomainLinks;

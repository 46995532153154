import {
  BlockType,
  FormBuilderBlock,
  Language,
  Position,
  RichText,
  SubscriptionUpgradeTrigger,
} from '@tallyforms/lib';
import { Dispatch } from 'react';

import { FormStyles } from '@/types/form-design';

export interface State {
  blocks: FormBuilderBlock[];
  settings: FormSettings | null;
  blocksUpdateUuid: string | null;
  updateBlockGroupUuids: string[];
  insertBlockMenu: {
    isOpen: boolean;
    atBlockUuid: string | undefined;
    sections: BlocksSection[] | undefined;
  };
  blockSettingsContextMenu: {
    isOpen: boolean;
    atBlockUuid: string | undefined;
  };
  blockActionsContextMenu: {
    isOpen: boolean;
    atBlockUuid: string | undefined;
  };
  textFormatContextMenu: {
    isOpen: boolean;
  };
  authMenu: {
    isOpen: boolean;
    trigger: AuthTrigger | undefined;
  };
  upgradePlanMenu: {
    isOpen: boolean;
    trigger: SubscriptionUpgradeTrigger | undefined;
  };
  mentionMenu: {
    isOpen: boolean;
    atBlockUuid: string | undefined;
  };
  mentionSettingsMenu: {
    isOpen: boolean;
    uuid: string | undefined;
    position: Position | undefined;
  };
  commandsMenu: {
    isOpen: boolean;
    atBlockUuid: string | undefined;
  };
  bulkInsertOptionsMenu: {
    isOpen: boolean;
    atBlockUuid: string | undefined;
  };
  versionHistory: {
    actionType: ActionType;
    blocks: FormBuilderBlock[];
    updateBlockGroupUuids: string[];
    cursor: { x: number; y: number };
    timestamp: number;
  }[];
  versionHistoryIndex: number;
}

export type Reducer = [State, Dispatch<Action>];

export interface Action {
  type: ActionType;
  data?: any;
}

export enum ActionType {
  Insert = 'FormBuilder.Insert',
  Replace = 'FormBuilder.Replace',
  ReplaceAll = 'FormBuilder.ReplaceAll',
  Remove = 'FormBuilder.Remove',
  Move = 'FormBuilder.Move',
  CreateColumnList = 'FormBuilder.CreateColumnList',
  CreateColumn = 'FormBuilder.CreateColumn',
  AddToColumn = 'FormBuilder.AddToColumn',
  DetachFromColumn = 'FormBuilder.DetachFromColumn',
  UpdateColumnRatio = 'FormBuilder.UpdateColumnRatio',
  UpdateValue = 'FormBuilder.UpdateValue',
  UpdatePayload = 'FormBuilder.UpdatePayload',
  Refresh = 'FormBuilder.Refresh',
  RefreshAll = 'FormBuilder.RefreshAll',
  UpdateFormSettings = 'FormBuilder.UpdateFormSettings',
  OpenInsertBlockMenu = 'FormBuilder.OpenInsertBlockMenu',
  OpenBlockSettingsContextMenu = 'FormBuilder.OpenBlockSettingsContextMenu',
  OpenBlockActionsContextMenu = 'FormBuilder.OpenBlockActionsContextMenu',
  OpenTextFormatContextMenu = 'FormBuilder.OpenTextFormatContextMenu',
  OpenAuthMenu = 'FormBuilder.OpenAuthMenu',
  OpenUpgradePlanMenu = 'FormBuilder.OpenUpgradePlanMenu',
  OpenMentionMenu = 'FormBuilder.OpenMentionMenu',
  OpenMentionSettingsMenu = 'FormBuilder.OpenMentionSettingsMenu',
  InsertMention = 'FormBuilder.InsertMention',
  RemoveMention = 'FormBuilder.RemoveMention',
  UpdateMention = 'FormBuilder.UpdateMention',
  UpdateConditionals = 'FormBuilder.UpdateConditionals',
  UpdateConditionalActions = 'FormBuilder.UpdateConditionalAction',
  UpdateCalculatedFields = 'FormBuilder.UpdateCalculatedFields',
  UpdateHiddenFields = 'FormBuilder.UpdateHiddenFields',
  OpenCommandsMenu = 'FormBuilder.OpenCommandsMenu',
  OpenBulkInsertOptionsMenu = 'FormBuilder.OpenBulkInsertOptionsMenu',
  Undo = 'FormBuilder.Undo',
  Redo = 'FormBuilder.Redo',
  AddTitle = 'FormBuilder.AddTitle',
}

export enum BlocksSection {
  Question = 'QUESTION',
  Field = 'FIELD',
  Layout = 'LAYOUT',
}

export enum ImageSource {
  Upload = 'UPLOAD',
  Link = 'LINK',
  Unsplash = 'UNSPLASH',
  Gallery = 'GALLERY',
}

export enum CursorPosition {
  Start = 'START',
  End = 'END',
  Inherit = 'INHERIT',
}

export enum AuthTrigger {
  SignUp = 'SIGN_UP',
  Publish = 'PUBLISH',
  PaymentBlock = 'PAYMENT_BLOCK',
  UpgradePlan = 'UPGRADE_PLAN',
}

export enum TextFormatAction {
  AddLink = 'ADD_LINK',
  ApplyLastTextColor = 'APPLY_LAST_TEXT_COLOR',
}

export interface Question {
  type: BlockType;
  blockGroupUuid: string;
  title: string | null;
  titleBlockUuid: string | null;
  isRequired: boolean;
  isRequiredBlockUuid: string;
  isRequiredGroupBlockUuid: string;
  isFolded: boolean;
  canBeFolded: boolean;
  startBlockUuid: string;
  endBlockUuid: string;
  numberOfBlocks: number;
  isUntitled?: boolean;
}

export interface FormSettings {
  language: Language | null;
  isClosed: boolean;
  closeMessageTitle: string | null;
  closeMessageDescription: string | null;
  closeTimezone: string | null;
  closeDate: string | null;
  closeTime: string | null;
  submissionsLimit: number | null;
  uniqueSubmissionKey: RichText | null;
  redirectOnCompletion: RichText | null;
  hasSelfEmailNotifications: boolean;
  selfEmailTo: RichText | null;
  selfEmailReplyTo: RichText | null;
  selfEmailSubject: RichText | null;
  selfEmailFromName: RichText | null;
  selfEmailBody: RichText | null;
  hasRespondentEmailNotifications: boolean;
  respondentEmailTo: RichText | null;
  respondentEmailReplyTo: RichText | null;
  respondentEmailSubject: RichText | null;
  respondentEmailFromName: RichText | null;
  respondentEmailBody: RichText | null;
  hasPartialSubmissions: boolean;
  pageAutoJump: boolean;
  saveForLater: boolean;
  styles: FormStyles | null;
  hasProgressBar: boolean;
  password: string | null;
  submissionsDataRetentionDuration: number | null;
  submissionsDataRetentionUnit: string | null;
  metaSiteName: string | null;
  metaSiteFaviconUrl: string | null;
  metaTitle: string | null;
  metaDescription: string | null;
  metaImageUrl: string | null;
  metaAllowIndexing: boolean | null;
}

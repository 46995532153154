import styled from 'styled-components';

export const Container = styled.div<{ color?: string; compactSize?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  background-color: ${({ theme }) => theme.color.textGrayscale2};
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  color: ${({ theme }) => theme.color.white};
  border-radius: 3px;

  ${({ color }) =>
    color &&
    `
    background-color: ${color};
  `}

  ${({ compactSize }) =>
    compactSize &&
    `
    font-size: 11px;
  `}
`;

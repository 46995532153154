import { IconText } from './icon';

export interface Form {
  id: string;
  name: string;
  workspaceId: string;
  status: FormStatus;
  numberOfSubmissions: number;
  isClosed: boolean;
  payments?: { amount: number; currency: string }[];
  index: number;
  createdAt: string;
  updatedAt: string;
}

export enum FormStatus {
  Blank = 'BLANK',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Deleted = 'DELETED',
}

export enum ValidationRule {
  Required = 'REQUIRED',
  PaymentRequired = 'PAYMENT_REQUIRED',
  ShouldBeValidFormat = 'SHOULD_BE_VALID_FORMAT',
  ShouldBeEmail = 'SHOULD_BE_EMAIL',
  ShouldBePhoneNumber = 'SHOULD_BE_PHONE_NUMBER',
  ShouldBeURL = 'SHOULD_BE_URL',
  ShouldBeChecked = 'SHOULD_BE_CHECKED',
  ShouldBeDomainHost = 'SHOULD_BE_DOMAIN_HOST',
  ShouldBeMinChars = 'SHOULD_BE_MIN_CHARS',
  ShouldBeMaxChars = 'SHOULD_BE_MAX_CHARS',
  ShouldBeMinNumber = 'SHOULD_BE_MIN_NUMBER',
  ShouldBeMaxNumber = 'SHOULD_BE_MAX_NUMBER',
  ShouldBeBetweenNumbers = 'SHOULD_BE_BETWEEN_NUMBERS',
  ShouldMatchPassword = 'SHOULD_MATCH_PASSWORD',
  ShouldBeBeforeDate = 'SHOULD_BE_BEFORE_DATE',
  ShouldBeAfterDate = 'SHOULD_BE_AFTER_DATE',
  ShouldBeBetweenDates = 'SHOULD_BE_BETWEEN_DATES',
  ShouldBeOneOfSpecificDates = 'SHOULD_BE_ONE_OF_SPECIFIC_DATES',
  ShouldHaveMinArrayLength = 'SHOULD_HAVE_MIN_ARRAY_LENGTH',
  ShouldHaveMaxArrayLength = 'SHOULD_HAVE_MAX_ARRAY_LENGTH',
  ShouldSolveCaptcha = 'SHOULD_SOLVE_CAPTCHA',
  OptionRequired = 'OPTION_REQUIRED',
  ValueRequired = 'VALUE_REQUIRED',
  SignatureRequired = 'SIGNATURE_REQUIRED',
  FileRequired = 'FILE_REQUIRED',
  RankingRequired = 'RANKING_REQUIRED',
  ShouldRankAllOptions = 'SHOULD_RANK_ALL_OPTIONS',
  ShouldFinishUpload = 'SHOULD_FINISH_UPLOAD',
  MatrixRequired = 'MATRIX_REQUIRED',
  EmailVerificationRequired = 'EMAIL_VERIFICATION_REQUIRED',
}

export type ValidationRuleConstraint = [ValidationRule, any?, string?];

// CR wouterds: dangerous type as it conflicts with the native FormData type
export interface FormData {
  [field: string]: any;
}

export interface ValidationRules {
  [field: string]: ValidationRuleConstraint[];
}

export interface FormErrors {
  [field: string]: ValidationRuleConstraint;
}

export interface DynamicValidators {
  shouldBePhoneNumber?: (phoneNumber: string) => boolean;
}

export enum FormPageTab {
  Summary = 'summary',
  Responses = 'responses',
  Submissions = 'submissions',
  Share = 'share',
  Integrations = 'integrations',
  Settings = 'settings',
}

export interface SelectOption {
  value: string;
  text: string;
  selectedText?: string; // Use if you want to show a different text when the option is selected
  icon?: IconText;
  disabled?: boolean;
  color?: string;
  closeOnSelect?: boolean;
}

export interface SelectGroup {
  label?: string;
  options: SelectOption[];
}

import { COMMON_MIME_TYPES_PER_EXTENSION } from '../../consts/file';
import { AllowedFiles } from '../../types';

export const getAcceptProp = (values: AllowedFiles): AllowedFiles => {
  const result: AllowedFiles = {};

  for (const key of Object.keys(values)) {
    // If it's a wildcard, so we add it to the result
    if (values[key].includes('*')) {
      result[key] = [];
      continue;
    }

    // If it's a specific extension, we add it to the result
    for (const extension of values[key]) {
      COMMON_MIME_TYPES_PER_EXTENSION[extension]?.forEach((mimeType) => {
        if (result[mimeType]) {
          result[mimeType].push(extension);
        } else {
          result[mimeType] = [extension];
        }
      });
    }
  }

  return result;
};

import { BadgeType, ValidationRuleConstraint } from '@tallyforms/lib';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import tinycolor from 'tinycolor2';

import EnumerationBadge from '@/components/badge/enumeration';
import Input from '@/components/form/input';
import ValidationError from '@/components/form/validation-error';
import { isUuid } from '@/services/uuid';
import { Question } from '@/types/form-builder';
import {
  getMultiSelectColor,
  multiSelectBackgroundColor,
  multiSelectBorderColor,
  multiSelectColor,
} from '@/utils/color';

import { Legend, Option, OptionContainer, OptionContent, OtherOptionContainer } from './styled';

interface Props {
  uuid: string;
  values?: string[];
  text: string;
  image?: string;
  isRequired?: boolean;
  index: number;
  isFirst: boolean;
  isLast: boolean;
  error?: ValidationRuleConstraint;
  question?: Question;
  allowMultiple?: boolean;
  colorCodeOptions?: boolean;
  color?: string;
  hasBadge?: boolean;
  badgeType?: BadgeType;
  isOtherOption?: boolean;
  onChange?: (value: string[]) => void;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
}

const MultipleChoiceOptionBlock = ({
  uuid,
  values = [],
  text,
  image,
  isRequired,
  index,
  isFirst,
  isLast,
  error,
  question,
  allowMultiple,
  colorCodeOptions = false,
  color,
  hasBadge = true,
  badgeType = BadgeType.Letters,
  isOtherOption = false,
  onChange,
  renderRequiredIndicator,
}: Props) => {
  const { t } = useTranslation();

  // Color logic for multiple choice options
  const optionColor = colorCodeOptions ? (color ?? getMultiSelectColor(index)) : undefined;
  const backgroundColor = optionColor ? multiSelectBackgroundColor[optionColor] : undefined;
  const textColor = optionColor ? multiSelectColor[optionColor] : undefined;
  const badgeColor = textColor ? tinycolor(textColor).setAlpha(0.3).toRgbString() : undefined;
  const borderColor = optionColor ? multiSelectBorderColor[optionColor] : undefined;

  const legendId = question ? `legend_${question?.blockGroupUuid}` : undefined;
  const errorId = error ? `error_${question?.blockGroupUuid}` : undefined;

  let optionIndex = Array.isArray(values) ? values.findIndex((x) => x === uuid) : -1;
  let isSelected = optionIndex !== -1;
  let otherAnswer = '';
  if (isOtherOption && !isSelected) {
    optionIndex = Array.isArray(values) ? values.findIndex((x) => isUuid(x) === false) : -1;
    if (optionIndex !== -1) {
      isSelected = true;
      otherAnswer = values[optionIndex];
    }
  }

  return (
    <Fragment>
      {isFirst && question && <Legend id={legendId}>{question.title}</Legend>}

      <OptionContainer
        className={`tally-multiple-choice-option ${
          values?.includes(uuid) ? 'tally-multiple-choice-option-selected' : ''
        }`}
        hasError={!!error}
        isLast={isLast}
        isOtherOptionSelected={isOtherOption && isSelected}>
        <Option
          onClick={() => {
            if (!allowMultiple) {
              onChange?.(isSelected ? [] : [uuid]);
              return;
            }

            if (isSelected) {
              onChange?.(values.filter((x) => x !== uuid && x !== otherAnswer));
            } else {
              onChange?.([...values, uuid]);
            }
          }}
          isSelected={isSelected}
          backgroundColor={backgroundColor}
          textColor={textColor}
          borderColor={borderColor}
          hasBadge={hasBadge}>
          <OptionContent>
            {hasBadge && <EnumerationBadge index={index} type={badgeType} color={badgeColor} />}

            <input
              id={`choice_${uuid}`}
              name={`multiple_choice_${question?.blockGroupUuid}`}
              type="radio"
              checked={isSelected}
              required={isRequired}
              aria-describedby={
                legendId && errorId ? `${legendId} ${errorId}` : (errorId ?? legendId)
              }
              onChange={() => {
                if (!allowMultiple) {
                  onChange?.(isSelected ? [] : [uuid]);
                  return;
                }

                if (isSelected) {
                  onChange?.(values.filter((x) => x !== uuid && x !== otherAnswer));
                } else {
                  onChange?.([...values, uuid]);
                }
              }}
            />
            <label htmlFor={`choice_${uuid}`}>{text}</label>
          </OptionContent>

          {image && <img src={image} alt={image} />}

          {renderRequiredIndicator && renderRequiredIndicator()}
        </Option>
      </OptionContainer>

      {isOtherOption && isSelected && (
        <OtherOptionContainer className="tally-other-option-input" hasError={!!error}>
          <Input
            type="text"
            defaultValue={otherAnswer}
            placeholder={t('form-respond.other-option-input')}
            autoFocus={true}
            onChange={(e) => {
              // onChange with updating the values array at index optionIndex
              values[optionIndex] = e.target.value.trim();
              onChange?.([...values]);
            }}
          />
        </OtherOptionContainer>
      )}

      {isLast && error && <ValidationError id={errorId} error={error} />}
    </Fragment>
  );
};

export default MultipleChoiceOptionBlock;

import {
  BlockType,
  CalculatedFieldType,
  ConditionalLogicActionType,
  ConditionalLogicCalculateOperator,
  FormData,
  isCalculatedFieldsBlock,
  isConditionalLogicBlock,
  RespondBlock,
  SafeSchemaBlock,
} from '@tallyforms/lib';
import { BigNumber } from 'bignumber.js';

import { convertValueForCalculatedFieldOperation, getFormDataForField } from '@/utils/form-respond';
import { areConditionsMet } from '@/utils/form-respond/logic';

export const applyInitialCalculatedFieldsToFormData = (
  formData: FormData,
  blocks: SafeSchemaBlock[],
  options?: { onlySpecificBlockUuids?: string[] },
): FormData => {
  const targetedBlocks = options?.onlySpecificBlockUuids
    ? blocks.filter((x) => options.onlySpecificBlockUuids?.includes(x.uuid))
    : blocks;

  targetedBlocks.filter(isCalculatedFieldsBlock).forEach(({ groupUuid, payload }) => {
    payload.calculatedFields.forEach((calculatedField) => {
      if (typeof calculatedField.value !== 'undefined') {
        if (typeof formData[groupUuid] === 'undefined') {
          formData[groupUuid] = {};
        }

        formData[groupUuid][calculatedField.uuid] = convertValueForCalculatedFieldOperation(
          calculatedField.value,
          calculatedField.type,
          formData,
          blocks,
        );
      }
    });
  });

  return formData;
};

export const applyCalculatedFieldsOperationsToFormData = (
  formData: FormData,
  pageHistory: number[],
  pages: RespondBlock[][],
  blocks: SafeSchemaBlock[],
) => {
  const calculatedFieldsBlocks = blocks.filter((x) => x.type === BlockType.CalculatedFields);

  // No calculated fields
  if (calculatedFieldsBlocks.length === 0) {
    return formData;
  }

  // Apply initial calculated fields
  formData = applyInitialCalculatedFieldsToFormData(formData, blocks);

  const conditionalLogicBlocks = blocks.filter((x) => x.type === BlockType.ConditionalLogic);

  // No conditional logic
  if (conditionalLogicBlocks.length === 0) {
    return formData;
  }

  // Go over each page and do the calculations if the conditions are met
  for (const page of pageHistory) {
    const pageBlocks = pages[page - 1] ?? [];

    // We are only interested in CalculatedFields and ConditionalLogic blocks
    const actionBlocks = pageBlocks.filter((x) =>
      [BlockType.CalculatedFields, BlockType.ConditionalLogic].includes(x.type),
    );

    // Go over them and apply the calculations
    for (const block of actionBlocks) {
      // Apply initial values for calculated fields
      if (block.type === BlockType.CalculatedFields) {
        formData = applyInitialCalculatedFieldsToFormData(formData, blocks, {
          onlySpecificBlockUuids: [block.uuid],
        });
      }

      // Apply conditional logic calculations
      if (isConditionalLogicBlock(block)) {
        const { logicalOperator, conditionals, actions } = block.payload;

        if (areConditionsMet(conditionals, logicalOperator, formData, blocks)) {
          for (const action of actions) {
            if (
              action.type === ConditionalLogicActionType.Calculate &&
              action.payload?.calculate?.field &&
              action.payload?.calculate?.operator &&
              typeof action.payload?.calculate?.value !== 'undefined'
            ) {
              let resultValue = getFormDataForField(formData, action.payload.calculate.field);

              let operationValue = convertValueForCalculatedFieldOperation(
                action.payload.calculate.value,
                action.payload.calculate.field.calculatedFieldType,
                formData,
                blocks,
              );

              // For number type use BigNumber calculations
              if (
                action.payload.calculate.field.calculatedFieldType === CalculatedFieldType.Number
              ) {
                resultValue = new BigNumber(resultValue);
                operationValue = new BigNumber(operationValue);
              }

              switch (action.payload.calculate.operator) {
                case ConditionalLogicCalculateOperator.Addition:
                  if (typeof resultValue === 'object') {
                    resultValue = resultValue.plus(operationValue);
                  } else {
                    resultValue += operationValue;
                  }
                  break;

                case ConditionalLogicCalculateOperator.Subtraction:
                  resultValue = resultValue.minus(operationValue);
                  break;

                case ConditionalLogicCalculateOperator.Multiplication:
                  resultValue = resultValue.multipliedBy(operationValue);
                  break;

                case ConditionalLogicCalculateOperator.Division:
                  resultValue = resultValue.dividedBy(operationValue);
                  break;

                case ConditionalLogicCalculateOperator.Assignment:
                  resultValue = operationValue;
                  break;
              }

              // For number type convert BigNumber to number
              if (
                action.payload.calculate.field.calculatedFieldType === CalculatedFieldType.Number
              ) {
                resultValue = resultValue.toNumber();
              }

              formData = {
                ...formData,
                [action.payload.calculate.field.blockGroupUuid]: {
                  ...formData[action.payload.calculate.field.blockGroupUuid],
                  [action.payload.calculate.field.uuid]: resultValue,
                },
              };
            }
          }
        }
      }
    }
  }

  return formData;
};

import { useCallback, useEffect, useState } from 'react';

export enum ColorTheme {
  Light = 'light',
  Dark = 'dark',
}

const mediaQuery = '(prefers-color-scheme: dark)';

const detectColorTheme = () => {
  if (typeof window === 'undefined') {
    return ColorTheme.Light;
  }

  if (window.matchMedia?.(mediaQuery).matches) {
    return ColorTheme.Dark;
  }

  return ColorTheme.Light;
};

export const useColorTheme = () => {
  const [colorTheme, setColorTheme] = useState(detectColorTheme());

  const handleColorThemeChanged = useCallback((e: { matches: boolean }) => {
    if (e.matches) {
      setColorTheme(ColorTheme.Dark);
      return;
    }

    setColorTheme(ColorTheme.Light);
  }, []);

  useEffect(() => {
    window.matchMedia?.(mediaQuery)?.addEventListener?.('change', handleColorThemeChanged);

    return () => {
      window.matchMedia?.(mediaQuery)?.removeEventListener?.('change', handleColorThemeChanged);
    };
  }, [handleColorThemeChanged]);

  return colorTheme;
};

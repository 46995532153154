import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.accent}20;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  svg {
    color: ${({ theme }) => theme.color.accent};
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const Description = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.md};
  line-height: 1.5;
  color: ${({ theme }) => theme.color.textGrayscale3};
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  em {
    font-style: normal;
  }
`;

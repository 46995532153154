import { breakpoint, withDirection } from '@tallyforms/ui';
import styled from 'styled-components';

const customBreakpointXs = '380px';

export const Container = styled.div<{ numberOfItems: number }>`
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing.sm};

  fieldset {
    border: 0;
    padding: 0;
  }

  legend {
    position: absolute;
    display: inline-block;
    max-width: 10px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  .tally-required-indicator {
    top: 10px;
    ${({ theme }) => withDirection('right', '-22px', theme.direction)}
    ${({ theme }) => theme.direction === 'rtl' && 'right: unset'};
  }

  ${({ numberOfItems }) =>
    numberOfItems >= 6 &&
    `
    @media (max-width: ${breakpoint.sm}) {
      .tally-required-indicator {
        top: 8px;
      }
    }
  `}

  ${({ numberOfItems }) =>
    numberOfItems >= 7 &&
    `
    @media (max-width: ${breakpoint.sm}) {
      .tally-required-indicator {
        top: 6px;
      }
    }
  `}

  ${({ numberOfItems }) =>
    numberOfItems >= 8 &&
    `
    @media (max-width: ${breakpoint.sm}) {
      .tally-required-indicator {
        top: 4px;
      }
    }

    @media (max-width: ${customBreakpointXs}) {
      .tally-required-indicator {
        top: 3px;
      }
    }
  `}

  ${({ numberOfItems }) =>
    numberOfItems >= 11 &&
    `
    @media (max-width: ${breakpoint.sm}) {
      min-width: 22px;
      min-height: 22px;
    }

    @media (max-width: ${customBreakpointXs}) {
      min-width: 21px;
      min-height: 21px;
      font-size: 11px;
    }
  `}
`;

export const ItemsContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const Items = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`;

export const Item = styled.div<{ isSelected: boolean; numberOfItems: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  min-height: 36px;
  box-shadow: ${({ theme }) => theme.color.inputBoxShadow};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.inputBackground};
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  font-weight: ${({ theme }) => theme.font.weight.medium};

  &:last-child {
    ${({ theme }) => withDirection('margin-right', '0', theme.direction)}
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: ${({ theme }) => theme.color.inputBoxShadowHover};
    }
  }

  &:focus-within {
    box-shadow: ${({ theme }) => theme.color.inputBoxShadowFocus};
  }

  ${({ theme, isSelected }) =>
    isSelected &&
    `
    box-shadow: ${theme.color.inputBoxShadowActive} !important;
    color: ${theme.color.accent};
    z-index: 1;

    &:focus-within {
      box-shadow: ${theme.color.inputBoxShadowActiveFocus} !important;
    }
  `}

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-appearance: radio;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  ${({ numberOfItems }) =>
    numberOfItems >= 6 &&
    `
    @media (max-width: ${breakpoint.sm}) {
      min-width: 32px;
      min-height: 32px;
      font-size: 12px;
    }
  `}

  ${({ numberOfItems }) =>
    numberOfItems >= 7 &&
    `
    @media (max-width: ${breakpoint.sm}) {
      min-width: 30px;
      min-height: 30px;
    }
  `}

  ${({ numberOfItems, theme }) =>
    numberOfItems >= 8 &&
    `
    @media (max-width: ${breakpoint.sm}) {
      min-width: 24px;
      min-height: 24px;
    }

    @media (max-width: ${customBreakpointXs}) {
      min-width: 24px;
      min-height: 24px;
      font-size: 12px;
      margin-right: 0;
      border-radius: 0;


      ${
        theme.direction === 'rtl' &&
        `
        border-radius: 0;

        &:first-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &:last-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }`
      }

      ${
        theme.direction === 'ltr' &&
        `
        border-radius: 0;

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }`
      }
    }
  `}

  ${({ numberOfItems }) =>
    numberOfItems >= 11 &&
    `
    @media (max-width: ${breakpoint.sm}) {
      min-width: 22px;
      min-height: 22px;
    }

    @media (max-width: ${customBreakpointXs}) {
      min-width: 21px;
      min-height: 21px;
      font-size: 11px;
    }
  `}
`;

export const LabelsContainer = styled.div<{
  numberOfItems: number;
  noCenterLabel: boolean;
}>`
  display: flex;
  justify-content: space-between;

  > span {
    flex: 1;
    font-size: 12px;
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    line-height: 14px;
    color: ${({ theme }) => theme.color.textGrayscale3};

    &:nth-child(2) {
      text-align: center;

      &:empty {
        display: none;
      }
    }

    &:nth-child(3) {
      text-align: right;
    }

    &:nth-child(1),
    &:nth-child(3) {
      max-width: calc(
        ${({ numberOfItems }) => numberOfItems} * 36px /
          ${({ noCenterLabel }) => (noCenterLabel ? 2 : 3)}
      );
    }
  }

  @media (max-width: ${breakpoint.md}) {
    > span {
      font-size: 10px;

      &:nth-child(2) {
        display: none;
      }
    }
  }

  @media (max-width: ${customBreakpointXs}) {
    > span {
      font-size: 9px;
    }
  }
`;

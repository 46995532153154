import { stripHtml } from '@tallyforms/lib';

const CUSTOM_CSS_ID = 'tally-custom-css';

export const loadCustomCss = (css: string) => {
  let style = document.querySelector(`#${CUSTOM_CSS_ID}`);

  // If we have already loaded custom CSS, update the styles
  if (style) {
    style.innerHTML = safeCustomCss(css);
    return;
  }

  // Create a new style element and append it to the head
  style = document.createElement('style');
  style.id = CUSTOM_CSS_ID;
  style.innerHTML = safeCustomCss(css);
  style.setAttribute('type', 'text/css');
  document.head.appendChild(style);
};

export const removeCustomCss = () => {
  document.querySelector(`#${CUSTOM_CSS_ID}`)?.remove();
};

export const safeCustomCss = (css: string): string => {
  if (isCssUnsafe(css)) {
    return '';
  }

  return stripHtml(css);
};

export const isCssUnsafe = (css: string): boolean => {
  // javascript might indicate a malicious intent
  if (css.match(/javascript/i) !== null) {
    return true;
  }

  // expression() might indicate a malicious intent
  if (css.match(/expression/i) !== null) {
    return true;
  }

  // -moz-binding might indicate a malicious intent
  if (css.match(/-moz-binding/i) !== null) {
    return true;
  }

  // script might indicate a malicious intent
  if (css.match(/script/i) !== null) {
    return true;
  }

  // HTML tags are not allowed
  if (css.includes('<') && css.includes('>') && css.includes('</')) {
    return true;
  }

  // XLB script injection
  if (css.match(/behavior\s*:\s*url/i) !== null) {
    return true;
  }

  // Many url(...) calls might indicate a malicious intent
  const urlMatches = css.match(/url\(/gi);
  if (urlMatches && urlMatches.length > 50) {
    return true;
  }

  return false;
};

import { BlockType, ELIGIBLE_INPUT_PLACEHOLDER_BLOCKS } from '@tallyforms/lib';

import { useRequiredIndicatorPosition } from '@/hooks/form-builder/use-required-indicator-position';
import { Question } from '@/types/form-builder';

import { Container, Content, ContentWrapper } from './styled';

interface Props {
  html: string;
  uuid: string;
  question?: Question;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
}

const LabelBlock = ({ html, uuid, question, renderRequiredIndicator }: Props) => {
  useRequiredIndicatorPosition(uuid, question?.isRequired);

  return (
    <Container>
      <ContentWrapper className="title-content">
        <Content
          htmlFor={
            question &&
            [
              ...ELIGIBLE_INPUT_PLACEHOLDER_BLOCKS,
              BlockType.Dropdown,
              BlockType.FileUpload,
            ].includes(question.type)
              ? question.blockGroupUuid
              : undefined
          }
          dangerouslySetInnerHTML={{ __html: html }}
        />

        {renderRequiredIndicator &&
          renderRequiredIndicator({
            top: 4,
            left: 0,
            right: 0,
            isHiddenWhileCalculatingPosition: true,
          })}
      </ContentWrapper>
    </Container>
  );
};

export default LabelBlock;

import { DecimalSeparator, ThousandsSeparator } from '../../types';

export const formatNumber = (
  value: number,
  options: {
    decimalSeparator?: DecimalSeparator;
    thousandsSeparator?: ThousandsSeparator;
    prefix?: string;
    suffix?: string;
  } = {},
) => {
  let formatted = value.toString();

  if (options.decimalSeparator) {
    formatted = formatted.replace('.', options.decimalSeparator);
  }

  if (options.thousandsSeparator) {
    const parts = formatted.split(options.decimalSeparator || '.');

    if (parts[0]) {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandsSeparator);
    }

    formatted = parts.join(options.decimalSeparator || '.');
  }

  if (options.prefix) {
    formatted = options.prefix + formatted;
  }

  if (options.suffix) {
    formatted += options.suffix;
  }

  return formatted;
};

import { breakpoint } from '@tallyforms/ui';
import styled from 'styled-components';

export const FormBuilderContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: ${breakpoint.lg}) {
    max-width: 700px;
  }

  @media (max-width: ${breakpoint.md}) {
    max-width: min(700px, 100vw);
  }
`;

export const FormBuilderSection = styled.section`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: ${breakpoint.lg}) {
    max-width: 700px;
  }

  @media (max-width: ${breakpoint.md}) {
    max-width: min(700px, 100vw);
  }
`;

export const FormRespondContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;

  @media (max-width: ${breakpoint.md}) {
    max-width: 500px;
    max-width: min(700px, calc(100% - 2 * 25px));
  }
`;

export const FormRespondSection = styled.section`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;

  @media (max-width: ${breakpoint.md}) {
    max-width: 500px;
    max-width: min(700px, calc(100% - 2 * 25px));
  }
`;

export const InputContent = styled.div`
  position: relative;
  display: inline-block;
  max-width: 320px;
  width: 100%;

  .content-editable-block,
  input:placeholder-shown {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (max-width: ${breakpoint.md}) {
    min-width: 100%;
  }
`;

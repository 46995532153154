import { Loader } from 'lucide-react';

import { Container } from './styled';

interface Props {
  size?: number;
}

const ActivityIndicator = ({ size = 18 }: Props) => {
  return (
    <Container className="activity-indicator" size={size}>
      <Loader size={size} />
    </Container>
  );
};

export default ActivityIndicator;

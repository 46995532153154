import { Country, SafeSchemaBlock, SubscriptionPlan } from '@tallyforms/lib';

import FormRespond from '@/components/form-respond';
import { FormSettings } from '@/types/form-builder';
import { FormStyles } from '@/types/form-design';
import { MetaData } from '@/types/html';
import { Integration } from '@/types/integration';

interface Props {
  formId: string;
  workspaceId: string;
  name: string;
  blocks: SafeSchemaBlock[];
  isClosed: boolean;
  isPreview: boolean;
  settings: Partial<FormSettings> | null;
  metaData: MetaData;
  integrations: Integration[];
  styles: FormStyles;
  subscriptionPlan: SubscriptionPlan;
  country: Country | null;
  hasError?: boolean;
  isNotFoundError?: boolean;
}

const Respond = (props: Props) => {
  return <FormRespond {...props} />;
};

export default Respond;

import { setUser as setSentryUser } from '@sentry/react';
import { hasBusinessAccess, hasProAccess } from '@tallyforms/lib';
import { AxiosError } from 'axios';
import { Dispatch, useCallback, useContext } from 'react';

import api, { setAuthorizationToken } from '@/services/api';
import { update } from '@/store/user/actions';
import { UserContext } from '@/store/user/reducer';
import { Action } from '@/types/user';

export const getUser = async ({
  dispatch,
  retries = 0,
}: {
  dispatch: Dispatch<Action>;
  retries?: number;
}) => {
  // dispatch(update({ isLoading: true }));

  try {
    const response = await api.get('/me');
    setSentryUser({ id: response?.data?.id });

    const { authorizationToken, ...user } = response.data;
    setAuthorizationToken(authorizationToken);

    dispatch(update({ ...user, isLoggedIn: true, hasError: false, isLoading: false }));
  } catch (e) {
    const error = e as AxiosError;

    if (error?.response?.status === 401 || retries >= 3) {
      dispatch(
        update({
          isLoading: false,
          hasError: error?.response?.status !== 401,
        }),
      );
      return;
    }

    retries++;
    setTimeout(() => getUser({ retries, dispatch }), 500 * retries);
  }
};

const useUser = () => {
  const [state, dispatch] = useContext(UserContext);
  const fetch = useCallback(async () => await getUser({ dispatch }), [dispatch]);

  return {
    user: state,
    fetch,
    dispatch,
    isLoggedIn: state.isLoggedIn,
    organizationOwner: state.organizationOwner,
    isOrganizationOwner: state.isOrganizationOwner,
    canAccessBilling: state.canAccessBilling,
    subscriptionPlan: state.subscriptionPlan,
    hasProAccess: hasProAccess(state.subscriptionPlan),
    hasBusinessAccess: hasBusinessAccess(state.subscriptionPlan),
    hasAccess: state.hasAccess,
    isLoading: state.isLoading,
    hasError: state.hasError,
  };
};

export default useUser;

import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type ThankYouPageBlock = Block<
  BlockType.ThankYouPage,
  {
    isHidden?: boolean;
    isThankYouPage?: boolean;
  }
>;

export function isThankYouPageBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<ThankYouPageBlock>;

export function isThankYouPageBlock(block: RespondBlock): block is RespondBlock<ThankYouPageBlock>;

export function isThankYouPageBlock(block: Block): block is ThankYouPageBlock {
  return block.type === BlockType.ThankYouPage;
}

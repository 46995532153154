import { ValidationRuleConstraint } from '@tallyforms/lib';

import Textarea from '@/components/form/textarea';
import ValidationError from '@/components/form/validation-error';
import { Question } from '@/types/form-builder';

import { Container, Content } from './styled';

interface Props {
  value?: string;
  placeholder?: string;
  isRequired?: boolean;
  error?: ValidationRuleConstraint;
  question?: Question;
  onChange?: (value: string) => void;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
}

const TextareaBlock = ({
  value = '',
  placeholder = '',
  isRequired,
  error,
  question,
  onChange,
  renderRequiredIndicator,
}: Props) => {
  const errorId = error ? `error_${question?.blockGroupUuid}` : undefined;

  return (
    <Container>
      <Content>
        <Textarea
          id={question?.blockGroupUuid}
          value={value}
          placeholder={placeholder}
          required={isRequired}
          aria-label={question?.title ?? undefined}
          aria-required={isRequired ? 'true' : 'false'}
          aria-invalid={error ? 'true' : 'false'}
          aria-describedby={errorId}
          onChange={(e) => onChange?.(e.target.value)}
        />
        {renderRequiredIndicator && renderRequiredIndicator()}
        {error && <ValidationError id={errorId} error={error} />}
      </Content>
    </Container>
  );
};

export default TextareaBlock;

import { FormErrors, stripHtml } from '@tallyforms/lib';
import { Fragment } from 'react';

import Checkbox from '@/components/form/checkbox';
import Radio from '@/components/form/radio';
import MatrixTable from '@/components/table/matrix-table';
import { FormLayoutBlock, FormLayoutMatrix } from '@/types/form-design';

interface Props {
  object: FormLayoutMatrix;
  renderBlock: (object: FormLayoutBlock) => JSX.Element | null;
  formData: any;
  onChange: (id: string, value: any) => void;
  errors: FormErrors;
  hasProAccess: boolean;
  hasPartialSubmissions: boolean;
  onSaveResponse?: (response: any) => Promise<void>;
}

const MatrixLayout = ({
  object,
  renderBlock,
  formData,
  onChange,
  errors,
  hasProAccess,
  hasPartialSubmissions,
  onSaveResponse,
}: Props) => {
  const labelsMap = new Map<string, string>();
  object.rows.map((row) => {
    labelsMap.set(row.uuid, stripHtml(row.block.payload.html));
  });
  object.columns.map((column) => {
    labelsMap.set(column.uuid, stripHtml(column.block.payload.html));
  });

  const onMatrixQuestionChange = (row: FormLayoutBlock, columnId: string) => {
    const isMultiple = row.block.payload.allowMultiple;
    const groupUuid = row.block.groupUuid;
    const checked = isMatrixColumnChecked(row, columnId);
    const answers = formData[groupUuid] || {};
    const previousData = answers[row.uuid];
    let value: any = {};

    if (isMultiple) {
      if (!checked) {
        value = {
          ...answers,
          [row.uuid]: [...(previousData || []), columnId],
        };
      } else {
        value = {
          ...answers,
          [row.uuid]: (previousData || []).filter((x: string) => x !== columnId),
        };
      }
    } else {
      value = {
        ...answers,
        [row.uuid]: !checked ? [columnId] : [],
      };
    }

    onChange(groupUuid, value);

    if (hasProAccess && hasPartialSubmissions && onSaveResponse) {
      onSaveResponse({ [groupUuid]: value });
    }
  };

  const isMatrixColumnChecked = (row: FormLayoutBlock, columnId: string): boolean => {
    const groupUuid = row.block.groupUuid;
    const value = formData[groupUuid] || {};

    let checked = false;
    if (value[row.uuid]) {
      checked = value[row.uuid].includes(columnId);
    }

    return checked;
  };

  return (
    <MatrixTable
      key={object.uuid}
      respond={true}
      headers={[
        <th key={0}>{renderBlock(object.table)}</th>,
        ...object.columns.map((column) => <th key={column.uuid}>{renderBlock(column)}</th>),
      ]}
      body={[
        ...object.rows.map((row) => (
          <tr
            key={row.uuid}
            role="group" // eslint-disable-line
            aria-labelledby={`legend_${row.uuid}`}>
            <th id={`legend_${row.uuid}`}>{renderBlock(row)}</th>

            {object.columns.map((column, i) => (
              <td key={`${row.uuid}_${i}`}>
                <Fragment>
                  {row.block.payload.allowMultiple ? (
                    <Checkbox
                      name={`matrix_row_${row.uuid}`}
                      value={`matrix_checkbox_${i}`}
                      isChecked={isMatrixColumnChecked(row, column.uuid)}
                      isContainerClickable={true}
                      minimalDesign={true}
                      ariaLabel={labelsMap.get(column.uuid)}
                      onToggle={() => onMatrixQuestionChange(row, object.columns[i].uuid)}
                      align="center"
                    />
                  ) : (
                    <Radio
                      name={`matrix_row_${row.uuid}`}
                      value={`matrix_radio_${i}`}
                      isChecked={isMatrixColumnChecked(row, column.uuid)}
                      isContainerClickable={true}
                      minimalDesign={true}
                      ariaLabel={labelsMap.get(column.uuid)}
                      onToggle={() => onMatrixQuestionChange(row, object.columns[i].uuid)}
                    />
                  )}
                </Fragment>
              </td>
            ))}
          </tr>
        )),
      ]}
      error={errors[object.table.block.groupUuid]}
      errorId={`error_${object.table.block.groupUuid}`}
    />
  );
};

export default MatrixLayout;

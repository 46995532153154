import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export enum EmbedSource {
  Link = 'LINK',
  Upload = 'UPLOAD',
}

export type EmbedBlock = Block<
  BlockType.Embed | BlockType.EmbedAudio | BlockType.EmbedVideo,
  {
    isHidden?: boolean;
    type: 'rich' | 'video' | 'photo' | 'link' | 'pdf' | 'gist' | 'image/*' | 'video/*' | 'audio/*';
    provider: string;
    title: string;
    inputUrl: string;
    display: {
      url?: string;
    };
    width?: string | number;
    height?: string | number;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
  }
>;

export function isEmbedBlock(block: FormBuilderBlock): block is FormBuilderBlock<EmbedBlock>;

export function isEmbedBlock(block: RespondBlock): block is RespondBlock<EmbedBlock>;

export function isEmbedBlock(block: Block): block is EmbedBlock {
  return [BlockType.Embed, BlockType.EmbedAudio, BlockType.EmbedVideo].includes(block.type);
}

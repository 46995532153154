import { Language } from '../../types';

export const fetchTranslations = async (lng?: Language | string) => {
  switch (lng) {
    case Language.Afrikaans:
      return import('@tallyforms/lib/assets/translations/af.json').then((res) => res.default);
    case Language.Arabic:
      return import('@tallyforms/lib/assets/translations/ar.json').then((res) => res.default);
    case Language.Bengali:
      return import('@tallyforms/lib/assets/translations/bn.json').then((res) => res.default);
    case Language.Bulgarian:
      return import('@tallyforms/lib/assets/translations/bg.json').then((res) => res.default);
    case Language.Catalan:
      return import('@tallyforms/lib/assets/translations/ca.json').then((res) => res.default);
    case Language.Czech:
      return import('@tallyforms/lib/assets/translations/cs.json').then((res) => res.default);
    case Language.Danish:
      return import('@tallyforms/lib/assets/translations/da.json').then((res) => res.default);
    case Language.German:
      return import('@tallyforms/lib/assets/translations/de.json').then((res) => res.default);
    case Language.English:
      return import('@tallyforms/lib/assets/translations/en.json').then((res) => res.default);
    case Language.Greek:
      return import('@tallyforms/lib/assets/translations/el.json').then((res) => res.default);
    case Language.Spanish:
      return import('@tallyforms/lib/assets/translations/es.json').then((res) => res.default);
    case Language.SpanishMexico:
      return import('@tallyforms/lib/assets/translations/es-MX.json').then((res) => res.default);
    case Language.Estonian:
      return import('@tallyforms/lib/assets/translations/et.json').then((res) => res.default);
    case Language.Euskara:
      return import('@tallyforms/lib/assets/translations/eu.json').then((res) => res.default);
    case Language.Farsi:
      return import('@tallyforms/lib/assets/translations/fa.json').then((res) => res.default);
    case Language.Finnish:
      return import('@tallyforms/lib/assets/translations/fi.json').then((res) => res.default);
    case Language.Filipino:
      return import('@tallyforms/lib/assets/translations/fil.json').then((res) => res.default);
    case Language.French:
      return import('@tallyforms/lib/assets/translations/fr.json').then((res) => res.default);
    case Language.Hebrew:
      return import('@tallyforms/lib/assets/translations/he.json').then((res) => res.default);
    case Language.Hindi:
      return import('@tallyforms/lib/assets/translations/hi.json').then((res) => res.default);
    case Language.Croatian:
      return import('@tallyforms/lib/assets/translations/hr.json').then((res) => res.default);
    case Language.Hungarian:
      return import('@tallyforms/lib/assets/translations/hu.json').then((res) => res.default);
    case Language.Indonesian:
      return import('@tallyforms/lib/assets/translations/id.json').then((res) => res.default);
    case Language.Icelandic:
      return import('@tallyforms/lib/assets/translations/is.json').then((res) => res.default);
    case Language.Italian:
      return import('@tallyforms/lib/assets/translations/it.json').then((res) => res.default);
    case Language.Japanese:
      return import('@tallyforms/lib/assets/translations/ja.json').then((res) => res.default);
    case Language.Georgian:
      return import('@tallyforms/lib/assets/translations/ka.json').then((res) => res.default);
    case Language.Korean:
      return import('@tallyforms/lib/assets/translations/ko.json').then((res) => res.default);
    case Language.Lithuanian:
      return import('@tallyforms/lib/assets/translations/lt.json').then((res) => res.default);
    case Language.Latvian:
      return import('@tallyforms/lib/assets/translations/lv.json').then((res) => res.default);
    case Language.Dutch:
      return import('@tallyforms/lib/assets/translations/nl.json').then((res) => res.default);
    case Language.Norwegian:
      return import('@tallyforms/lib/assets/translations/no.json').then((res) => res.default);
    case Language.Malay:
      return import('@tallyforms/lib/assets/translations/ms.json').then((res) => res.default);
    case Language.Polish:
      return import('@tallyforms/lib/assets/translations/pl.json').then((res) => res.default);
    case Language.Portuguese:
      return import('@tallyforms/lib/assets/translations/pt.json').then((res) => res.default);
    case Language.PortugueseBrazil:
      return import('@tallyforms/lib/assets/translations/pt-BR.json').then((res) => res.default);
    case Language.Romanian:
      return import('@tallyforms/lib/assets/translations/ro.json').then((res) => res.default);
    case Language.Russian:
      return import('@tallyforms/lib/assets/translations/ru.json').then((res) => res.default);
    case Language.Sinhala:
      return import('@tallyforms/lib/assets/translations/si.json').then((res) => res.default);
    case Language.Slovak:
      return import('@tallyforms/lib/assets/translations/sk.json').then((res) => res.default);
    case Language.Slovenian:
      return import('@tallyforms/lib/assets/translations/sl.json').then((res) => res.default);
    case Language.Serbian:
      return import('@tallyforms/lib/assets/translations/sr.json').then((res) => res.default);
    case Language.Swedish:
      return import('@tallyforms/lib/assets/translations/sv.json').then((res) => res.default);
    case Language.Tamil:
      return import('@tallyforms/lib/assets/translations/ta.json').then((res) => res.default);
    case Language.Thai:
      return import('@tallyforms/lib/assets/translations/th.json').then((res) => res.default);
    case Language.Turkish:
      return import('@tallyforms/lib/assets/translations/tr.json').then((res) => res.default);
    case Language.Ukrainian:
      return import('@tallyforms/lib/assets/translations/uk.json').then((res) => res.default);
    case Language.Vietnamese:
      return import('@tallyforms/lib/assets/translations/vi.json').then((res) => res.default);
    case Language.Yoruba:
      return import('@tallyforms/lib/assets/translations/yo.json').then((res) => res.default);
    case Language.ChineseSimplified:
      return import('@tallyforms/lib/assets/translations/zh-CN.json').then((res) => res.default);
    case Language.ChineseTraditional:
      return import('@tallyforms/lib/assets/translations/zh-TW.json').then((res) => res.default);
    default:
      return import('@tallyforms/lib/assets/translations/dev.json').then((res) => res.default);
  }
};

import BigNumber from 'bignumber.js';

import { Currency } from '../../types';

export const convertPriceValueToAmount = (price: string | undefined, currency: string): number => {
  if (!price) {
    return 0;
  }

  // Zero-decimal currencies
  if (([Currency.JPY, Currency.KRW, Currency.VND] as string[]).includes(currency)) {
    return parseFloat(price);
  }

  return new BigNumber(price).times(100).toNumber();
};

import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type DropdownOptionBlock = Block<
  BlockType.DropdownOption,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    placeholder?: string;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    index: number;
    isFirst: boolean;
    isLast: boolean;
    allowMultiple?: boolean;
    hasMinChoices?: boolean;
    minChoices?: number;
    hasMaxChoices?: boolean;
    maxChoices?: number;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isDropdownOptionBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<DropdownOptionBlock>;

export function isDropdownOptionBlock(
  block: RespondBlock,
): block is RespondBlock<DropdownOptionBlock>;

export function isDropdownOptionBlock(block: Block): block is DropdownOptionBlock {
  return block.type === BlockType.DropdownOption;
}

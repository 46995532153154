import styled from 'styled-components';

import { FormStyles } from '@/types/form-design';

export const Container = styled.div`
  position: relative;
  z-index: 1;
`;

export const CoverImg = styled.img<{
  objectPositionYPercent: number;
  styles?: FormStyles;
}>`
  display: block;
  object-fit: cover;
  object-position: center ${({ objectPositionYPercent }) => objectPositionYPercent}%;
  width: 100%;
  height: 25vh;

  ${({ styles }) =>
    (styles?.popup || (styles?.embed && styles?.dynamicHeight)) &&
    `
    height: 200px;
  `}
`;

import {
  Block,
  BlockType,
  GROUP_OPTIONS_BLOCKS,
  isEmpty,
  RespondBlock,
  SafeSchemaBlock,
  TEXT_BLOCKS,
  transformSafeHTMLSchemaToHTML,
} from '@tallyforms/lib';
import he from 'he';
import shuffle from 'lodash/shuffle';
import uniq from 'lodash/uniq';

import { getOptionsToLockInPlace } from '@/utils/blocks';
import { getMultiSelectColor } from '@/utils/color';

export const transformForRespond = (blocks: SafeSchemaBlock[]): RespondBlock[] => {
  const respondBlocks: RespondBlock[] = [];
  const processedGroups: string[] = [];

  blocks.forEach((block) => {
    const { type, groupUuid, groupType } = block;

    if (TEXT_BLOCKS.includes(type)) {
      // Text blocks' node schema to html
      processedGroups.push(groupUuid);
      respondBlocks.push({
        ...block,
        payload: {
          ...block.payload,
          html: transformSafeHTMLSchemaToHTML(block.payload.safeHTMLSchema, true),
        },
      });
    } else if (groupType === BlockType.Dropdown || groupType === BlockType.MultiSelect) {
      // Dropdown option blocks are merged into one block
      if (processedGroups.includes(groupUuid) === false) {
        processedGroups.push(groupUuid);

        respondBlocks.push({
          ...block,
          uuid: block.groupUuid,
          type: block.groupType,
          payload: {
            ...block.payload,
            placeholder: block.payload.placeholder ? he.decode(block.payload.placeholder) : null,
            options: blocks
              .filter((x) => x.groupUuid === groupUuid)
              .map((x) => ({
                value: x.uuid,
                // Decode text
                text: x.payload.text ? he.decode(x.payload.text) : '',
                image: x.payload.image ?? null,
                color: x.payload.color ?? getMultiSelectColor(x.payload.index),
                isOtherOption: x.payload.isOtherOption ?? false,
              })),
          },
        });
      }
    } else if (
      [BlockType.MultipleChoice, BlockType.Checkboxes, BlockType.Ranking].includes(groupType)
    ) {
      // Decode text
      if (block?.payload?.text) {
        block.payload.text = he.decode(block.payload.text);
      }

      processedGroups.push(groupUuid);
      respondBlocks.push(block);
    } else {
      // Decode placeholders
      if (block?.payload?.placeholder) {
        block.payload.placeholder = he.decode(block.payload.placeholder);
      }

      processedGroups.push(groupUuid);
      respondBlocks.push(block);
    }
  });

  return respondBlocks;
};

export const transformToPages = (blocks: RespondBlock[]): RespondBlock[][] => {
  const pages: RespondBlock[][] = [];
  let page: RespondBlock[] = [];

  blocks.forEach((x) => {
    if (x.type !== BlockType.PageBreak) {
      page.push(x);
    } else {
      pages.push(page);
      page = [];
    }
  });

  if (page.length > 0) {
    pages.push(page);
  }

  return pages;
};

export const randomizeGroupOptionsIfAny = <T extends Block = Block>(blocks: T[]) => {
  const blockGroupUuidsToRandomize = uniq(
    blocks
      .filter(
        ({ groupType, payload, type }) =>
          (payload.randomize && GROUP_OPTIONS_BLOCKS.includes(groupType)) ||
          (payload.randomizeRows && type === BlockType.MatrixRow),
      )
      .map((x) => x.groupUuid),
  );

  const updatedBlocks = [...blocks];

  blockGroupUuidsToRandomize.forEach((groupUuid) => {
    // Get all the blocks in this group and their indexes on the page
    let groupBlocks: Block[] = [];
    const pageBlocksIndexes: number[] = [];
    blocks.forEach((block, index) => {
      if (
        block.groupUuid === groupUuid &&
        block.type !== BlockType.Matrix &&
        block.type !== BlockType.MatrixColumn
      ) {
        groupBlocks.push(block);
        pageBlocksIndexes.push(index);
      }
    });

    if (groupBlocks.length === 0) {
      return;
    }

    let randomizedGroupBlocks: Block[] = [];

    // Lock in place options contains a string of block.uuids that should not be randomized
    const lockInPlace = getOptionsToLockInPlace(groupBlocks);
    if (lockInPlace.length > 0) {
      // Remove the lock in place options from the group blocks
      const lockInPlaceBlocks: Block[] = [];
      const lockInPlaceIndexes: number[] = [];
      groupBlocks.forEach((x, index) => {
        if (lockInPlace.includes(x.uuid)) {
          lockInPlaceBlocks.push(x);
          lockInPlaceIndexes.push(index);
        }
      });

      // Remove the lock in place blocks from the group blocks
      groupBlocks = groupBlocks.filter((x) => !lockInPlace.includes(x.uuid));

      // Randomize the group blocks and update the randomized blocks array
      randomizedGroupBlocks = shuffle(groupBlocks);

      // Insert the lock in place blocks back into the randomized group blocks
      lockInPlaceIndexes.forEach((index, i) => {
        randomizedGroupBlocks.splice(index, 0, lockInPlaceBlocks[i]);
      });
    } else {
      // Randomize the group blocks
      randomizedGroupBlocks = shuffle(groupBlocks);
    }

    pageBlocksIndexes.forEach((pageBlockIndex, index) => {
      const { columnUuid, columnListUuid, columnRatio } = updatedBlocks[pageBlockIndex].payload;

      updatedBlocks[pageBlockIndex] = {
        ...randomizedGroupBlocks[index],
        payload: {
          ...randomizedGroupBlocks[index].payload,
          index,
          isFirst: index === 0,
          isLast: index === pageBlocksIndexes.length - 1,
        },
      } as T;

      if (columnUuid) {
        updatedBlocks[pageBlockIndex].payload.columnUuid = columnUuid;
        updatedBlocks[pageBlockIndex].payload.columnListUuid = columnListUuid;
        updatedBlocks[pageBlockIndex].payload.columnRatio = columnRatio;
      }
    });
  });

  return updatedBlocks;
};

export const reorderRankingBlocksBasedOnFormData = (
  blocks: RespondBlock[],
  formData: { [key: string]: any },
): RespondBlock[] => {
  // If we have ranking blocks, we need to reorder the blocks based on the form data
  const rankingBlockGroupUuids = uniq(
    blocks.filter((x) => x.groupType === BlockType.Ranking).map((x) => x.groupUuid),
  );
  if (rankingBlockGroupUuids.length === 0) {
    return blocks;
  }

  const updatedBlocks = [...blocks];

  // For each ranking block group, we need to reorder the blocks based on the form data
  rankingBlockGroupUuids.forEach((groupUuid) => {
    // If there is no form data for this group, we can skip it
    if (isEmpty(formData[groupUuid])) {
      return;
    }

    // Get all the blocks in this group and their indexes on the page
    let groupBlocks: RespondBlock[] = [];
    const pageBlocksIndexes: number[] = [];
    blocks.forEach((block, index) => {
      if (block.groupUuid === groupUuid) {
        groupBlocks.push(block);
        pageBlocksIndexes.push(index);
      }
    });

    // Go over the page indexes
    pageBlocksIndexes.forEach((pageBlockIndex, index) => {
      let blockToMove: RespondBlock | undefined = undefined;

      // Get the ranking option for the current index
      const rankedBlockUuid = formData[groupUuid][index];
      if (typeof rankedBlockUuid !== 'undefined') {
        // If an option exists on the current index, this is the block we need to move
        blockToMove = groupBlocks.find((x) => x.uuid === rankedBlockUuid);
        // Remove the block from the group blocks so we don't move it again
        groupBlocks = groupBlocks.filter((x) => x.uuid !== rankedBlockUuid);
      }

      // No ranking option exists on the current index, we need to move the first block in the group
      if (!blockToMove) {
        blockToMove = groupBlocks.shift();
      }

      // If we have a block to move, we need to update its payload's index and isFirst/isLast to match the new order
      if (blockToMove) {
        // Get the column list uuid, column uuid and column ratio from the original block, so the moved block inherits the same column settings
        const { columnListUuid, columnUuid, columnRatio } = updatedBlocks[pageBlockIndex].payload;

        updatedBlocks[pageBlockIndex] = {
          ...blockToMove,
          payload: {
            ...blockToMove.payload,
            index,
            isFirst: index === 0,
            isLast: index === pageBlocksIndexes.length - 1,
          },
        };

        if (columnUuid) {
          updatedBlocks[pageBlockIndex].payload.columnUuid = columnUuid;
          updatedBlocks[pageBlockIndex].payload.columnListUuid = columnListUuid;
          updatedBlocks[pageBlockIndex].payload.columnRatio = columnRatio;
        }
      }
    });
  });

  return updatedBlocks;
};

import { ValidationRuleConstraint } from '@tallyforms/lib';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '@/components/form/checkbox';
import Input from '@/components/form/input';
import ValidationError from '@/components/form/validation-error';
import { isUuid } from '@/services/uuid';
import { Question } from '@/types/form-builder';

import { Choice, ChoiceContainer, Legend, OtherOptionContainer } from './styled';

interface Props {
  uuid: string;
  values: string[];
  text: string;
  image?: string;
  isRequired?: boolean;
  isFirst: boolean;
  isLast: boolean;
  isOtherOption?: boolean;
  error?: ValidationRuleConstraint;
  question?: Question;
  onChange?: (values: string[]) => void;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
}

const CheckboxBlock = ({
  uuid,
  values,
  text,
  image,
  isRequired,
  isFirst,
  isLast,
  isOtherOption = false,
  error,
  question,
  onChange,
  renderRequiredIndicator,
}: Props) => {
  const { t } = useTranslation();
  const legendId = question ? `legend_${question?.blockGroupUuid}` : undefined;
  const errorId = error ? `error_${question?.blockGroupUuid}` : undefined;

  let optionIndex = Array.isArray(values) ? values.findIndex((x) => x === uuid) : -1;
  let isSelected = optionIndex !== -1;
  let otherAnswer = '';
  if (isOtherOption && !isSelected) {
    optionIndex = Array.isArray(values) ? values.findIndex((x) => isUuid(x) === false) : -1;
    if (optionIndex !== -1) {
      isSelected = true;
      otherAnswer = values[optionIndex];
    }
  }

  const onToggle = () => {
    if (isSelected) {
      onChange?.(values.filter((x) => x !== uuid && x !== otherAnswer));
    } else {
      onChange?.([...values, uuid]);
    }
  };

  return (
    <Fragment>
      {isFirst && question && <Legend id={legendId}>{question.title}</Legend>}

      <ChoiceContainer
        className={`tally-checkbox ${isSelected ? 'tally-checkbox-selected' : ''}`}
        hasError={!!error}
        isLast={isLast}
        isOtherOptionSelected={isOtherOption && isSelected}>
        <Choice>
          <Checkbox
            value={uuid}
            text={text}
            isChecked={isSelected}
            isRequired={isRequired}
            ariaInvalid={!!error}
            aria-describedby={
              legendId && errorId ? `${legendId} ${errorId}` : (errorId ?? legendId)
            }
            onToggle={onToggle}
            renderRequiredIndicator={renderRequiredIndicator}
          />

          {image && <img src={image} alt={image} onClick={() => onToggle()} />}
        </Choice>
      </ChoiceContainer>

      {isOtherOption && isSelected && (
        <OtherOptionContainer className="tally-other-option-input" hasError={!!error}>
          <Input
            type="text"
            defaultValue={otherAnswer}
            placeholder={t('form-respond.other-option-input')}
            autoFocus={true}
            onChange={(e) => {
              // onChange with updating the values array at index optionIndex
              values[optionIndex] = e.target.value.trim();
              onChange?.([...values]);
            }}
          />
        </OtherOptionContainer>
      )}

      {isLast && error && <ValidationError id={errorId} error={error} />}
    </Fragment>
  );
};

export default CheckboxBlock;

import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type HeadingBlock = Block<
  BlockType.Heading1 | BlockType.Heading2 | BlockType.Heading3,
  {
    isHidden?: boolean;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
  }
>;

export function isHeadingBlock(block: FormBuilderBlock): block is FormBuilderBlock<HeadingBlock>;

export function isHeadingBlock(block: RespondBlock): block is RespondBlock<HeadingBlock>;

export function isHeadingBlock(block: Block): block is HeadingBlock {
  return [BlockType.Heading1, BlockType.Heading2, BlockType.Heading3].includes(block.type);
}

import { Container, Divider } from './styled';

const DividerBlock = () => {
  return (
    <Container className="tally-divider">
      <Divider />
    </Container>
  );
};

export default DividerBlock;

export const withMinDuration =
  <T, Args extends any[]>(fn: (...args: Args) => Promise<T>, minDuration: number) =>
  async (...args: Args): Promise<T> => {
    const start = Date.now();
    const result = await fn(...args);
    const elapsed = Date.now() - start;

    const delay = Math.max(0, minDuration - elapsed);
    if (delay > 0) {
      await new Promise((resolve) => setTimeout(resolve, delay));
    }

    return result;
  };

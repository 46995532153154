import { BlockType, RichText } from '@tallyforms/lib';

export enum IntegrationType {
  GoogleSheets = 'GOOGLE_SHEETS',
  Notion = 'NOTION',
  Airtable = 'AIRTABLE',
  Webhooks = 'WEBHOOKS',
  Zapier = 'ZAPIER',
  Integromat = 'INTEGROMAT',
  Integrately = 'INTEGRATELY',
  Pipedream = 'PIPEDREAM',
  Relay = 'RELAY',
  Pluga = 'PLUGA',
  Latenode = 'LATENODE',
  Welcomessage = 'WELCOMEMESSAGE',
  Coda = 'CODA',
  GoogleAnalytics = 'GOOGLE_ANALYTICS',
  FacebookPixel = 'FACEBOOK_PIXEL',
  Mailchimp = 'MAILCHIMP',
  Slack = 'SLACK',
  Gmail = 'GMAIL',
  GoogleCalendar = 'GOOGLE_CALENDAR',
  Hubspot = 'HUBSPOT',
  Salesforce = 'SALESFORCE',
  PDFMonkey = 'PDFMONKEY',
  Trello = 'TRELLO',
  GoogleDrive = 'GOOGLE_DRIVE',
  Dropbox = 'DROPBOX',
  SMS = 'SMS',
  ActiveCampaign = 'ACTIVE_CAMPAIGN',
  Asana = 'ASANA',
  Excel = 'EXCEL',

  // Make OEM clients
  Sellsy = 'SELLSY',
  BoostSpace = 'BOOST_SPACE',
}

export interface Integration {
  type: IntegrationType;
  data: any | any[];
  errors?: any[];
}

export interface FieldMapping {
  outputFieldKey: string;
  integrationFieldKey: string;
}

export interface OutputField {
  key: string;
  label: string;
  type: string;
  questionType?: BlockType;
  labelSupplement?: string;
}

export interface IntegrationOAuthAccount {
  id: string;
  integrationType: IntegrationType;
  name: string;
}

export interface IntegrationGoogleSheet {
  id: string;
  formId: string;
  spreadsheetId: string;
  lastSyncedAt: string;
  isEnabled: boolean;
  connectionName?: string;
  createdAt: string;
}

export interface IntegrationAirtable {
  id: string;
  formId: string;
  apiKey: string;
  oauthAccountId: string | null;
  baseId: string;
  tableId?: string;
  tableName?: string;
  fieldsMapping: FieldMapping[];
  lastSyncedAt: string;
  isEnabled: boolean;
  connectionName?: string;
  createdAt: string;
}

export interface IntegrationNotion {
  id: string;
  formId: string;
  accessToken: string | null;
  oauthAccountId: string | null;
  databaseId: string;
  databaseFields: NotionField[] | null;
  fieldsMapping: FieldMapping[];
  pageContent: RichText | null;
  lastSyncedAt: string;
  isEnabled: boolean;
  connectionName?: string;
  createdAt: string;
}

export interface NotionDatabase {
  id: string;
  name: string;
  fields: NotionField[];
}

export interface NotionField {
  id: string;
  name: string;
  type:
    | 'title'
    | 'text'
    | 'number'
    | 'select'
    | 'multi_select'
    | 'date'
    | 'people'
    | 'file'
    | 'checkbox'
    | 'url'
    | 'email'
    | 'phone_number'
    | 'formula'
    | 'relation'
    | 'rollup'
    | 'created_date'
    | 'created_by'
    | 'last_edited_date'
    | 'last_edited_by';
}

export interface IntegrationGoogleAnalytics {
  id: string;
  formId: string;
  trackingId: string;
  askForCookieConsent: boolean;
}

export interface IntegrationFacebookPixel {
  id: string;
  formId: string;
  pixelId: string;
}

export interface IntegrationSlack {
  id: string;
  formId: string;
  oauthAccountId: string | null;
  conversationId: string;
  message: RichText | null;
  createdByUserId: string;
  isPrivate: boolean;
  lastSyncedAt: string;
  isEnabled: boolean;
  connectionName?: string;
  createdAt: string;
}

export interface SlackConversation {
  id: string;
  name: string;
}

export interface AirtableBase {
  id: string;
  name: string;
  permissionLevel: string;
}

export interface AirtableTable {
  id: string;
  description: string;
  name: string;
  primaryField: string;
  fields: AirtableField[];
}

export interface AirtableField {
  id: string;
  name: string;
  type: string;
}

export enum EventType {
  FormResponse = 'FORM_RESPONSE',
}

export enum DeliveryStatus {
  Queued = 'QUEUED',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  Dropped = 'DROPPED',
}

export interface IntegrationEvent {
  id: string;
  integrationId: string;
  eventType: EventType;
  deliveryStatus: DeliveryStatus;
  submissionId: string;
  statusCode: string;
  response: string;
  createdAt: string;
  updatedAt: string;
}
export interface IntegrationEventsPaginatedResult {
  page: number;
  limit: number;
  hasMore: boolean;
  totalNumberOfEvents: number;
  events: IntegrationEvent[];
}

import {
  BlockType,
  FormErrors,
  hasProAccess,
  Language,
  RespondBlock,
  SafeSchemaBlock,
  SubscriptionPlan,
} from '@tallyforms/lib';

import FormBlock from '@/components/form-respond/block';
import MatrixLayout from '@/components/form-respond/layouts/matrix';
import { FormLayoutBlock, FormLayoutObjectType, FormStyles } from '@/types/form-design';
import {
  transformBlocksToFormLayoutObjects,
  transformBlocksToQuestionsMap,
} from '@/utils/block-transformer';
import { transformForRespond } from '@/utils/form-respond/block-transformer';

import { BlockContainer, Column, ColumnList, Container } from './styled';

interface Props {
  blocks: SafeSchemaBlock[];
  pageBlocks: RespondBlock[];
  formData: any;
  errors: FormErrors;
  mode: 'respond' | 'preview';
  subscriptionPlan: SubscriptionPlan;
  hasPartialSubmissions: boolean;
  language: Language;
  formId?: string;
  workspaceId?: string;
  styles?: FormStyles;
  respondentUuid?: string;
  sessionUuid?: string;
  onChange: (id: string, value: any) => void;
  onSaveResponse?: (response: any) => Promise<void>;
  onSolveCaptcha?: (response: any) => void;
}

const FormBlocks = ({ blocks, pageBlocks, ...restProps }: Props) => {
  const layoutObjects = transformBlocksToFormLayoutObjects(pageBlocks);
  const questions = transformBlocksToQuestionsMap(transformForRespond(pageBlocks));

  const renderBlock = (object: FormLayoutBlock) => {
    // We don't need to render the following blocks in the respond view
    if (
      [BlockType.HiddenFields, BlockType.CalculatedFields, BlockType.ConditionalLogic].includes(
        object.block.type,
      )
    ) {
      return null;
    }

    return (
      <BlockContainer
        key={object.uuid}
        blockType={object.block.type}
        formStyles={restProps.styles}
        className={`tally-block tally-block-${object.block.type
          .toLowerCase()
          .replace(/_/g, '-')} tally-block-${object.block.uuid}`}>
        <FormBlock
          block={object.block}
          blocks={blocks}
          pageBlocks={pageBlocks}
          question={questions.get(object.uuid)}
          {...restProps}
        />
      </BlockContainer>
    );
  };

  return (
    <Container>
      {layoutObjects.map((object) => {
        if (object.type === FormLayoutObjectType.Block) {
          return renderBlock(object);
        }

        if (object.type === FormLayoutObjectType.ColumnList) {
          return (
            <ColumnList key={object.uuid} className="tally-column-list">
              {object.columns.map((column, index) => (
                <Column
                  key={column.uuid}
                  className="tally-column"
                  ratio={column.ratio}
                  flex={column.flex}
                  first={index === 0}
                  last={index === object.columns.length - 1}>
                  {column.blocks.map(renderBlock)}
                </Column>
              ))}
            </ColumnList>
          );
        }

        if (object.type === FormLayoutObjectType.Matrix) {
          return (
            <MatrixLayout
              object={object}
              key={object.uuid}
              renderBlock={renderBlock}
              errors={restProps.errors}
              formData={restProps.formData}
              onChange={restProps.onChange}
              hasProAccess={hasProAccess(restProps.subscriptionPlan)}
              hasPartialSubmissions={restProps.hasPartialSubmissions}
              onSaveResponse={restProps.onSaveResponse}
            />
          );
        }

        return null;
      })}
    </Container>
  );
};

export default FormBlocks;

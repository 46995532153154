import { Field } from '../field';
import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type MultiSelectOptionBlock = Block<
  BlockType.MultiSelectOption,
  {
    isRequired?: boolean;
    isHidden?: boolean;
    placeholder?: string;
    hasDefaultAnswer?: boolean;
    defaultAnswer?: Field | string;
    index: number;
    isFirst: boolean;
    isLast: boolean;
    color?: string;
    layout?: any;
    hasOtherOption?: boolean;
    isOtherOption?: boolean;
    randomize?: boolean;
    lockInPlace?: string[];
    hasMinChoices?: boolean;
    minChoices?: number;
    hasMaxChoices?: boolean;
    maxChoices?: number;
    columnListUuid?: string;
    columnUuid?: string;
    columnRatio?: number;
    name?: string;
  }
>;

export function isMultiSelectOptionBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<MultiSelectOptionBlock>;

export function isMultiSelectOptionBlock(
  block: RespondBlock,
): block is RespondBlock<MultiSelectOptionBlock>;

export function isMultiSelectOptionBlock(block: Block): block is MultiSelectOptionBlock {
  return block.type === BlockType.MultiSelectOption;
}

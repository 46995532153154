import { TFunction } from 'i18next';

import { Country } from '../../types';

export const getCountries = (t: TFunction): Record<Country, string> => {
  try {
    return JSON.parse(t('json.countries'));
  } catch {
    return {} as Record<Country, string>;
  }
};

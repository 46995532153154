import { Block, BlockType } from './block';
import { FormBuilderBlock } from './form-builder';
import { RespondBlock } from './respond-block';

export type PageBreakBlock = Block<
  BlockType.PageBreak,
  {
    isThankYouPage?: boolean;
    isQualifiedForThankYouPage?: boolean;
    index: number;
    name?: string;
    button?: {
      label: string;
    };
  }
>;

export function isPageBreakBlock(
  block: FormBuilderBlock,
): block is FormBuilderBlock<PageBreakBlock>;

export function isPageBreakBlock(block: RespondBlock): block is RespondBlock<PageBreakBlock>;

export function isPageBreakBlock(block: Block): block is PageBreakBlock {
  return block.type === BlockType.PageBreak;
}

export const breakpoint = {
  xs: '320px',
  sm: '576px',
  md: '758px',
  lg: '992px',
  xl: '1200px',
  xxl: '1440px',
  xxxl: '1800px',
  xxxxl: '2400px',
};

import {
  Block,
  BlockType,
  CalculatedFieldType,
  ConditionalLogicActionType,
  ConditionalLogicPayload,
  Country,
  DateFormatProps,
  Field,
  formatNumber,
  FormData,
  getCountries,
  getPageBreakBlock,
  GROUP_OPTIONS_BLOCKS,
  isEmpty,
  isField,
  isHiddenFieldsBlock,
  isRespondentCountryBlock,
  LocalStorage,
  QUESTION_BLOCKS,
  RespondBlock,
  SafeSchemaBlock,
} from '@tallyforms/lib';
import { format, isValid, parseISO } from 'date-fns';
import i18next from 'i18next';
import sampleSize from 'lodash/sampleSize';
import { ParsedUrlQuery } from 'querystring';
import { TFunction } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { captureException } from '@/services/sentry';
import { isUuid } from '@/services/uuid';
import { FormSettings, Question } from '@/types/form-builder';
import { FormStyles } from '@/types/form-design';
import { isSingleChoiceBlock } from '@/utils/block';
import { transformBlocksToQuestionPerBlockGroupMap } from '@/utils/block-transformer';
import { convertMatrixUuidToText } from '@/utils/blocks';
import { isElementInViewport, isSafari, postMessageToParentWindow } from '@/utils/device';
import { getFields } from '@/utils/form-builder/fields';
import {
  reorderRankingBlocksBasedOnFormData,
  transformForRespond,
} from '@/utils/form-respond/block-transformer';
import { applyCalculatedFieldsOperationsToFormData } from '@/utils/form-respond/calculated-fields';
import {
  applyConditionsToPageBlocks,
  areConditionsMet,
  getNextPage,
} from '@/utils/form-respond/logic';
import { TallyEvent } from '@/utils/tally-event';

export const convertFormDataToText = (
  groupType: BlockType,
  data: any,
  blocks: SafeSchemaBlock[],
  options?: {
    fieldUuid?: string;
    dateFormatProps?: DateFormatProps;
    t?: TFunction;
  },
): string => {
  if (typeof data === 'undefined') {
    return '';
  }

  if (GROUP_OPTIONS_BLOCKS.includes(groupType)) {
    return convertGroupOptionUuidToText(groupType, data, blocks, options?.fieldUuid);
  }

  if (groupType === BlockType.InputDate) {
    const parsed = parseISO(data);
    if (isValid(parsed)) {
      // Check if we have a custom date format
      if (options?.dateFormatProps) {
        return format(parsed, options.dateFormatProps.f, {
          locale: options.dateFormatProps.locale,
        });
      }

      // Default date format
      return format(parsed, 'MMMM d, yyyy');
    } else {
      return '';
    }
  }

  if (groupType === BlockType.InputNumber) {
    const block = blocks.find((x) => x.groupUuid === options?.fieldUuid);

    const formatted = formatNumber(data, {
      decimalSeparator: block?.payload?.decimalSeparator,
      thousandsSeparator: block?.payload?.thousandsSeparator,
      prefix: block?.payload?.prefix,
      suffix: block?.payload?.suffix,
    });

    return formatted;
  }

  if (groupType === BlockType.RespondentCountry && options?.t) {
    const countryCode = data as Country;
    const countries = getCountries(options.t);

    return countries[countryCode] || countryCode || '--';
  }

  return data;
};

export const convertGroupOptionUuidToText = (
  groupType: BlockType,
  data: any,
  blocks: SafeSchemaBlock[],
  fieldUuid?: string,
): any => {
  if (!data) {
    return '';
  }

  if (groupType === BlockType.Checkboxes || groupType === BlockType.MultiSelect) {
    const texts = blocks.filter((x) => data.includes(x.uuid)).map((x) => x.payload.text);

    // Check if there is an "Other" answer
    const otherAnswer = data.find((x: string) => isUuid(x) === false);
    if (typeof otherAnswer !== 'undefined') {
      texts.push(otherAnswer);
    }

    return texts.join(', ');
  }

  if (groupType === BlockType.Ranking) {
    return data
      .map((uuid: string) => blocks.find((x) => x.uuid === uuid)?.payload.text ?? '')
      .join(', ');
  }

  if ([BlockType.MultipleChoice, BlockType.Dropdown].includes(groupType)) {
    if (Array.isArray(data)) {
      const texts = blocks.filter((x) => data.includes(x.uuid)).map((x) => x.payload.text);

      // Check if there is an "Other" answer
      const otherAnswer = data.find((x) => isUuid(x) === false);
      if (typeof otherAnswer !== 'undefined') {
        texts.push(otherAnswer);
      }

      return texts.join(', ');
    }

    // This is a "Other" answer, return it
    if (typeof data === 'string' && isUuid(data) === false) {
      return data;
    }

    return blocks.find((x) => x.uuid === data)?.payload.text ?? '';
  }

  if (groupType === BlockType.Matrix) {
    let answer: string[] = [];
    // For normal fields we pass fieldUuid to breakdown the answer
    // But for calculated field, we already have the array of answers of the row
    if (fieldUuid) {
      answer = data[fieldUuid];
    } else {
      answer = data;
    }

    if (answer) {
      return answer
        .map((x: string) => {
          return convertMatrixUuidToText(x, blocks);
        })
        .join(', ');
    }
  }

  return data;
};

export const convertTextToGroupOptionUuid = (
  groupUuid: string,
  groupType: BlockType,
  optionText: any,
  blocks: SafeSchemaBlock[],
): string | string[] | undefined => {
  if (typeof optionText !== 'string') {
    return undefined;
  }

  if (
    groupType === BlockType.Checkboxes ||
    groupType === BlockType.Ranking ||
    groupType === BlockType.MultiSelect
  ) {
    // Check if there is a direct option match
    const optionUuids = blocks
      .filter((x) => x.groupUuid === groupUuid && x.payload.text === optionText)
      .map((x) => x.uuid);
    if (optionUuids.length > 0) {
      return optionUuids;
    }

    // Multiple options could be separated by comma e.g. A,B,C
    if (optionText.includes(',')) {
      optionText.split(/,\s?/).forEach((text) => {
        const block = blocks.find((x) => x.groupUuid === groupUuid && x.payload.text === text);
        if (block) {
          optionUuids.push(block.uuid);
        }
      });

      return optionUuids.length > 0 ? optionUuids : undefined;
    }

    return undefined;
  }

  if ([BlockType.MultipleChoice, BlockType.Dropdown].includes(groupType)) {
    const block = blocks.find((x) => x.groupUuid === groupUuid);

    // If allowMultiple, then the answer is an array of UUIDs
    if (block?.payload.allowMultiple && optionText.includes(',')) {
      const optionUuids: string[] = [];

      optionText.split(/,\s?/).forEach((text) => {
        const block = blocks.find((x) => x.groupUuid === groupUuid && x.payload.text === text);
        if (block) {
          optionUuids.push(block.uuid);
        }
      });

      return optionUuids.length > 0 ? optionUuids : undefined;
    }

    const uuid = blocks.find(
      (x) => x.groupUuid === groupUuid && x.payload.text === optionText,
    )?.uuid;
    return uuid ? [uuid] : undefined;
  }

  return undefined;
};

/**
 * Returns the data found in the formData for a specific field
 */
export const getFormDataForField = (formData: FormData, field: Field): any => {
  let answer: any = undefined;

  if (field.uuid === field.blockGroupUuid) {
    answer = formData[field.uuid];
  } else {
    answer = formData[field.blockGroupUuid] ? formData[field.blockGroupUuid][field.uuid] : '';
  }

  return answer;
};

/**
 * Returns the answer value for a specific field.
 * In the case where the form data is a block UUID, get the text value of the block.
 */
export const getAnswerForField = (
  formData: FormData,
  field: Field,
  blocks: SafeSchemaBlock[],
): any => {
  let data = getFormDataForField(formData, field);

  // Group options have block UUID as answer, turn the UUID to text
  if (GROUP_OPTIONS_BLOCKS.includes(field.questionType)) {
    data = convertGroupOptionUuidToText(field.questionType, data, blocks);
  }

  if (field.questionType === BlockType.RespondentCountry && data) {
    const countries = getCountries(i18next.t);
    return countries[data] || data;
  }

  return data;
};

export const convertValueForCalculatedFieldOperation = (
  value: any,
  calculatedFieldType: CalculatedFieldType | undefined,
  formData: FormData,
  blocks: SafeSchemaBlock[],
): any => {
  // Check if the value is a field, then get the field's answer
  if (isField(value)) {
    value = getAnswerForField(formData, value, blocks);
  }

  // If the field is of number type, cast the value to a number too
  if (calculatedFieldType === CalculatedFieldType.Number && !isNaN(parseFloat(value))) {
    value = parseFloat(value);
  }

  return value;
};

export const getInitialFormData = (
  asPath: string,
  blocks: SafeSchemaBlock[],
  country: Country | null,
): { [key: string]: any } => {
  const query: ParsedUrlQuery = {};
  const searchIndex = asPath.indexOf('?');
  const locationSearch = searchIndex !== -1 ? asPath.slice(searchIndex) : '';

  new URLSearchParams(locationSearch).forEach((value, key) => {
    query[key] = value;
  });

  const formData: { [key: string]: { [key: string]: any } | any } = {};
  for (const block of blocks) {
    if (isRespondentCountryBlock(block)) {
      if (!formData[block.groupUuid] && country) {
        formData[block.groupUuid] = country;
      }
    }

    if (isHiddenFieldsBlock(block)) {
      for (const hiddenField of block.payload.hiddenFields) {
        if (hiddenField?.name && typeof query[hiddenField.name] !== 'undefined') {
          if (!formData[block.groupUuid]) {
            formData[block.groupUuid] = {};
          }

          formData[block.groupUuid][hiddenField.uuid] = query[hiddenField.name];
        }
      }
    }
  }

  return formData;
};

export const getCalculatedFieldsFormData = (
  formData: FormData,
  pageHistory: number[],
  pages: RespondBlock[][],
  blocks: SafeSchemaBlock[],
) => {
  const changedFormData = applyCalculatedFieldsOperationsToFormData(
    formData,
    pageHistory,
    pages,
    blocks,
  );

  // Filter Non calculated fields
  const calculatedFieldBlocks = blocks.filter((block) => block.type === BlockType.CalculatedFields);
  if (calculatedFieldBlocks.length === 0) {
    return {};
  }

  const calculatedFields = calculatedFieldBlocks.reduce((acc, block) => {
    const data: string = changedFormData[block.groupUuid];
    if (!data) {
      return acc;
    }

    return {
      ...acc,
      [block.groupUuid]: data,
    };
  }, {});

  return calculatedFields;
};

export const getNotSubmittedFormData = (
  formId: string,
  blocks: SafeSchemaBlock[],
): { [key: string]: any } => {
  const key = `FORM_DATA_${formId}`;
  const storageItem = LocalStorage.get(key);

  if (!storageItem) {
    return {};
  }

  const notSubmittedData = JSON.parse(storageItem);
  // We need to check that the not-submitted form data is valid and still exists in the form
  const groupUuids = Object.keys(notSubmittedData);
  for (const groupUuid of groupUuids) {
    const block = blocks.find((x) => x.groupUuid === groupUuid);
    // We only care for group blocks since they can be deleted
    if (!block || !GROUP_OPTIONS_BLOCKS.includes(block.groupType)) {
      continue;
    }

    // Check if the selected value exists in the group blocks
    const selectedValue = notSubmittedData[groupUuid];
    if (isEmpty(selectedValue)) {
      continue;
    }

    const groupBlocks = blocks.filter((x) => x.groupUuid === groupUuid);

    // Check for matrix values
    if (block.groupType === BlockType.Matrix) {
      for (const rowUuid in selectedValue) {
        const rowBlock = groupBlocks.find((x) => x.uuid === rowUuid);
        if (!rowBlock) {
          delete notSubmittedData[groupUuid][rowUuid];
          continue;
        }

        // Row exists check for selected columns
        const columnUuids = selectedValue[rowUuid];
        for (const columnUuid of columnUuids) {
          const columnBlock = groupBlocks.find((x) => x.uuid === columnUuid);
          if (!columnBlock) {
            notSubmittedData[groupUuid][rowUuid] = notSubmittedData[groupUuid][rowUuid].filter(
              (x: string) => x !== columnUuid,
            );
          }
        }
      }
      // Check for values for multi options blocks
    } else if (Array.isArray(selectedValue)) {
      for (const blockUuid of selectedValue) {
        let selectedBlock = groupBlocks.find((x) => x.uuid === blockUuid);

        // Check for "Other" option text answer
        if (
          !selectedBlock &&
          isUuid(blockUuid) === false &&
          groupBlocks.length > 0 &&
          groupBlocks[0].payload.hasOtherOption
        ) {
          selectedBlock = groupBlocks[0];
        }

        // No matching block found, remove the value
        if (!selectedBlock) {
          notSubmittedData[groupUuid] = notSubmittedData[groupUuid].filter(
            (x: string) => x !== blockUuid,
          );
        }
      }
    } else {
      // Check for single option blocks
      let selectedBlock = groupBlocks.find((x) => x.uuid === selectedValue);

      // Check for "Other" option text answer
      if (
        !selectedBlock &&
        isUuid(selectedValue) === false &&
        groupBlocks.length > 0 &&
        groupBlocks[0].payload.hasOtherOption
      ) {
        selectedBlock = groupBlocks[0];
      }

      // No matching block found, remove the value
      if (!selectedBlock) {
        delete notSubmittedData[groupUuid];
      }
    }
  }

  return notSubmittedData;
};

export const setNotSubmittedFormData = (formId: string, formData: { [key: string]: any }) => {
  const key = `FORM_DATA_${formId}`;
  LocalStorage.set(key, JSON.stringify(formData));
};

export const removeNotSubmittedFormData = (formId: string) => {
  const key = `FORM_DATA_${formId}`;
  LocalStorage.remove(key);
};

export const getFormStylesFromQuery = (query: ParsedUrlQuery): FormStyles => {
  const styles: FormStyles = {
    removeBranding: false,
  };

  if (query.alignLeft === '1') {
    styles.alignLeft = true;
  }

  if (query.transparentBackground === '1') {
    styles.transparentBackground = true;
  }

  if (query.hideTitle === '1') {
    styles.hideTitle = true;
  }

  if (query.dynamicHeight === '1') {
    styles.dynamicHeight = true;
  }

  if (query.embed === '1') {
    styles.embed = true;
  }

  if (query.popup === '1') {
    styles.popup = true;
  }

  return styles;
};

export const getFormSession = (formId: string): string => {
  const key = `FORM_SESSION_${formId}`;
  const existingSessionUuid = LocalStorage.get(key);

  if (existingSessionUuid) {
    return existingSessionUuid;
  }

  // Create a new session
  const newSessionUuid = uuid();
  LocalStorage.set(key, newSessionUuid);

  return newSessionUuid;
};

export const removeFormSession = (formId: string) => {
  const key = `FORM_SESSION_${formId}`;
  LocalStorage.remove(key);
};

export const getRespondentUuid = (workspaceId: string) => {
  const key = 'RESPONDENT';
  let respondentUuids: { [key: string]: string } = {};

  const storageItem = LocalStorage.get(key);
  if (storageItem) {
    respondentUuids = JSON.parse(storageItem);

    // Re-use if the respondent already exists for the specified workspace
    if (respondentUuids && respondentUuids[workspaceId]) {
      return respondentUuids[workspaceId];
    }
  }

  // Create a new respondent for the workspace
  respondentUuids[workspaceId] = uuid();
  LocalStorage.set(key, JSON.stringify(respondentUuids));

  return respondentUuids[workspaceId];
};

export const getPageBreakBlockByIndex = (
  index: number,
  blocks: (Block | RespondBlock)[],
): Block | RespondBlock => {
  const pageBreakBlockBeforeIndex = blocks
    .slice(0, index)
    .reverse()
    .filter((x) => x.type === BlockType.PageBreak)[0];

  return pageBreakBlockBeforeIndex ?? blocks[0];
};

export const isMaliciousForm = (pages: RespondBlock[][]): boolean => {
  const texts: string[] = [];

  pages.forEach((blocks) => {
    blocks.forEach(({ payload }) => {
      texts.push(payload?.html || payload?.placeholder || '');
    });
  });

  return /password|pa{0,3}s[s|\\*]{0,2}w[0|o|\\*]{0,3}r{0,1}d|pass\sword|pass\*{1,3}w[o]{0,1}rd|jelszó|пароль|kata[\s|-]{0,2}sandi|sandi anda|contrase[ñ|n][y]{0,1}a|senh[a|ä|á]|Passwort|wachtwoord|Passwört|Şifre|m[o|ö]t[s]{0,1} de passe/i.test(
    texts.join(' '),
  );
};

/**
 * Checks conditional logic blocks and return true if an action that hides the
 * button has its conditions met
 */
export const shouldHideButtonDueToConditionalLogic = (
  pageBlocks: RespondBlock[],
  formData: FormData,
  blocks: RespondBlock[],
): boolean => {
  const conditionalLogicBlocks = pageBlocks.filter((x) => x.type === BlockType.ConditionalLogic);

  if (conditionalLogicBlocks.length === 0) {
    return false;
  }

  for (const block of conditionalLogicBlocks) {
    const { logicalOperator, conditionals, actions } =
      // TODO: fix type casting
      block.payload as unknown as ConditionalLogicPayload;

    for (const action of actions) {
      // Check for actions that hide the button
      if (
        action.type === ConditionalLogicActionType.HideButtonToDisableCompletion &&
        areConditionsMet(conditionals, logicalOperator, formData, blocks)
      ) {
        return true;
      }
    }
  }

  return false;
};

export const getPageSettings = (
  page: number,
  pages: RespondBlock[][],
  blocks: SafeSchemaBlock[],
  settings: Partial<FormSettings> | null,
  formData: { [key: string]: any },
  onChangeFormData: (values: FormData) => void,
): {
  buttonLabel: string | undefined;
  canAutoJumpToNextPage: boolean;
  cover: string | undefined;
  coverSettings: { objectPositionYPercent: number } | undefined;
  isCustomThankYouPage: boolean;
  isLastInputPage: boolean;
  logo: string | undefined;
  nextPage: number | null;
  pageBlocks: RespondBlock[];
  pageFilledIn: boolean;
  pageQuestions: Question[];
  showBackButton: boolean;
  showMaliciousFormWarning: boolean;
  showSubmitButton: boolean;
} => {
  const logo = blocks[0]?.payload?.logo;
  const cover = blocks[0]?.payload?.cover;
  const coverSettings = blocks[0]?.payload?.coverSettings;
  const numberOfPages = pages.length;

  let pageBlocks = pages[page - 1] ?? [];

  // Apply conditional logic to page blocks
  pageBlocks = applyConditionsToPageBlocks(pageBlocks, blocks, formData, onChangeFormData);

  // Re-order page blocks based on form data if needed
  pageBlocks = reorderRankingBlocksBasedOnFormData(pageBlocks, formData);

  // Get next page based on conditional logic
  const nextPage = getNextPage(page, pageBlocks, numberOfPages, blocks, formData);

  const pageBreakBlock = getPageBreakBlock(page, blocks);
  const isCustomThankYouPage = !!pageBreakBlock?.payload?.isThankYouPage;
  const nextPageBreakBlock = nextPage ? getPageBreakBlock(nextPage, blocks) : undefined;
  const isLastInputPage = nextPage === null || nextPageBreakBlock?.payload?.isThankYouPage;

  // Check if we should show the submit button: hide if custom thank you page or if conditional logic hides it
  const showSubmitButton =
    !isCustomThankYouPage && !shouldHideButtonDueToConditionalLogic(pageBlocks, formData, blocks);

  // Get page questions to check if we can auto jump to next page and if the page is filled in
  // We use the raw pages because we are interested in hidden questions too
  const pageQuestions = Array.from(
    transformBlocksToQuestionPerBlockGroupMap(pageBlocks).values(),
    // We only care about user facing questions
  ).filter((x) => [BlockType.HiddenFields, BlockType.CalculatedFields].includes(x.type) === false);

  // Determine if we can auto jump to next page
  const canAutoJumpToNextPage =
    !!settings?.pageAutoJump &&
    showSubmitButton && // Only auto jump if we show the submit button
    !isLastInputPage && // Don't auto jump if we are on the last page, then the respondent needs to click the submit button
    pageQuestions.length === 1 && // Only auto jump if there is 1 single choice question on the page
    isSingleChoiceBlock(pageQuestions[0], pageBlocks);

  // Is the page filled in?
  const pageFilledIn = pageQuestions.every(
    (x) =>
      typeof formData[x.blockGroupUuid] !== 'undefined' &&
      isEmpty(formData[x.blockGroupUuid]) === false,
  );

  return {
    logo,
    cover,
    coverSettings,
    pageBlocks,
    pageQuestions,
    nextPage,
    isCustomThankYouPage,
    isLastInputPage,
    canAutoJumpToNextPage,
    pageFilledIn,
    showSubmitButton,
    showBackButton: !isCustomThankYouPage && page !== 1,
    buttonLabel: pageBreakBlock?.payload?.button?.label,
    showMaliciousFormWarning: isMaliciousForm(pages),
  };
};

export const getFormSubmittedEventPayload = (
  submissionId: string,
  respondentId: string,
  formId: string,
  name: string,
  blocks: SafeSchemaBlock[],
  formData: { [key: string]: any },
): any => {
  try {
    const fieldsFormData: any = [];
    const fields = getFields(transformForRespond(blocks));

    for (const field of fields) {
      const { uuid: fieldUuid, title, questionType } = field;

      fieldsFormData.push({
        id: fieldUuid,
        title,
        type: questionType,
        answer: {
          value: getAnswerForField(formData, field, blocks),
          raw: getFormDataForField(formData, field),
        },
      });
    }

    return {
      id: submissionId,
      formId,
      formName: name,
      respondentId,
      createdAt: new Date(),
      fields: fieldsFormData,
    };
  } catch (e) {
    captureException(e);
    return {};
  }
};

export const focusOnFirstFormInput = () => {
  setTimeout(() => {
    // Get the first form element
    const el = document.forms[0]?.elements[0] as HTMLElement | undefined;
    if (
      // Exists
      el &&
      // It's in the viewport
      isElementInViewport(el) &&
      // Is type-able input
      ((el.tagName === 'INPUT' &&
        ['radio', 'checkbox'].includes(el.getAttribute('type') ?? '') === false) ||
        el.tagName === 'TEXTAREA')
    ) {
      el.focus();
    }
  }, 100);
};

export const highlighFirstFormError = () => {
  setTimeout(() => {
    // Get the first validation error
    const validationError = document.querySelector('.tally-validation-error') as HTMLElement | null;
    if (!validationError) {
      return;
    }

    // Get the block element of the validation error
    const block = validationError.closest('.tally-block');

    // If the block has an invalid input, focus on it
    const invalidInput = block?.querySelector('[aria-invalid="true"]') as HTMLElement | null;
    if (invalidInput) {
      invalidInput.focus();

      // Scroll to the input if it's not in the viewport. Focus is not enough for mobile devices
      if (isElementInViewport(invalidInput) === false) {
        invalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      // Focused fields are auto scrolled into view, except on Safari (desktop)
      if (isSafari()) {
        postMessageToParentWindow({
          event: TallyEvent.TallyFormHightlightFirstError,
          payload: { offset: invalidInput.getBoundingClientRect().top },
        });
      }
      return;
    }

    // Otherwise, scroll to the validation error
    if (isElementInViewport(validationError) === false) {
      validationError.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    // Focused fields are auto scrolled into view, except on Safari (desktop)
    if (isSafari()) {
      postMessageToParentWindow({
        event: TallyEvent.TallyFormHightlightFirstError,
        payload: { offset: validationError.getBoundingClientRect().top },
      });
    }
  }, 100);
};

export const getRandomId = () => {
  const chars = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789'.split('');
  return sampleSize(chars, 6).join('');
};

export const getPageCompletion = (
  pageFlow: number[],
  formData: FormData,
  currentPage: number,
  currentPageBlocks: RespondBlock[],
): { [key: string]: number } => {
  const completion: { [key: string]: number } = {};

  for (const page of pageFlow) {
    // If the page is before the current page, mark it as completed
    if (page < currentPage) {
      completion[page] = 100;
      continue;
    }

    // If the page is after the current page, mark it as not started
    if (page > currentPage) {
      completion[page] = 0;
      continue;
    }

    // We are on the current page and need to calculate the completion
    const pageBlockGroupUuids: string[] = [];
    for (const { type, groupUuid, payload } of currentPageBlocks) {
      if (
        QUESTION_BLOCKS.includes(type) === false ||
        [BlockType.HiddenFields, BlockType.CalculatedFields].includes(type)
      ) {
        continue;
      }

      if (!payload.isRequired) {
        continue;
      }

      if (pageBlockGroupUuids.includes(groupUuid)) {
        continue;
      }

      pageBlockGroupUuids.push(groupUuid);
    }

    if (pageBlockGroupUuids.length === 0) {
      completion[page] = currentPage < page ? 0 : 100;
      continue;
    }

    const totalQuestions = pageBlockGroupUuids.length;
    let completedQuestions = 0;

    pageBlockGroupUuids.forEach((blockGroupUuid) => {
      if (!isEmpty(formData[blockGroupUuid])) {
        completedQuestions++;
      }
    });

    completion[page] = currentPage < page ? 0 : (completedQuestions / totalQuestions) * 100;
  }

  return completion;
};

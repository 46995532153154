import { Price } from '../../types';
import { convertAmountToPriceValue } from './convert-amount-to-price-value';
import { getCurrencyPrefix } from './get-currency-prefix';
import { getCurrencySuffix } from './get-currency-suffix';

export const convertAmountInCurrencyToPrice = (amount: number, currency: string): Price => {
  return {
    value: convertAmountToPriceValue(amount, currency),
    currency: {
      value: currency,
      prefix: getCurrencyPrefix(currency),
      suffix: getCurrencySuffix(currency),
    },
  };
};

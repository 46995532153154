import { BlockType } from '@tallyforms/lib';

import { Question } from '@/types/form-builder';

import { Container, ContentH1, ContentH2, ContentH3, ContentWrapper } from './styled';

interface Props {
  html: string;
  groupType: BlockType;
  uuid?: string;
  question?: Question;
}

const HeadingBlock = ({ html, groupType }: Props) => {
  let Content = ContentH3;
  if (groupType === BlockType.Heading1) {
    Content = ContentH1;
  } else if (groupType === BlockType.Heading2) {
    Content = ContentH2;
  }

  return (
    <Container>
      <ContentWrapper className="title-content">
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </ContentWrapper>
    </Container>
  );
};

export default HeadingBlock;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 100%;
  margin: ${({ theme }) => theme.spacing.md};

  svg {
    color: ${({ theme }) => theme.color.textGrayscale4};
    margin-bottom: 32px;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

export const Description = styled.div<{ hasButton: boolean }>`
  max-width: 600px;
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.textGrayscale3};

  ${({ hasButton }) =>
    hasButton &&
    `
    margin-bottom: 16px;
  `}
`;

import Plausible from 'plausible-tracker';

import config from '@/config';

const plausible = Plausible({
  domain: 'tally.so',
  trackLocalhost: false,
});

const canTrack = config.isProductionEnv();

const trackPageview = (url: string) => {
  if (!canTrack) {
    return;
  }

  plausible.trackPageview({ url });
};

const trackEvent = (type: string, options: any) => {
  if (!canTrack) {
    return;
  }

  plausible.trackEvent(type, options);
};

export { trackEvent, trackPageview };

import { Circle, Spinner, Svg } from './styled';

export type LoaderProps = {
  size?: 'default' | 'small' | 'large';
  color?: string;
};

export const Loader = (props: LoaderProps) => {
  const color = props.color || 'currentColor';

  return (
    <Svg
      size={props.size}
      style={{ stroke: color }}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg">
      <Spinner>
        <Circle cx="12" cy="12" r="9.5" />
      </Spinner>
    </Svg>
  );
};

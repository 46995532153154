import { Check as LuCheck, Copy as LuCopy } from 'lucide-react';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  & pre {
    color: #f8f8f1;
    border-radius: 6px;
    background: #282a36;
    overflow-x: auto;
    padding: ${({ theme }) => theme.spacing.md};
    font-size: 0.9em;
  }

  &:hover {
    & button {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const CopyButton = styled.button<{ copied: boolean }>`
  position: absolute;
  top: ${({ theme }) => theme.spacing.sm};
  right: ${({ theme }) => theme.spacing.sm};
  width: 32px;
  height: 32px;
  border-radius: 4px;
  line-height: 0.7;
  background: #313342;
  color: ${({ theme }) => theme.color.lighterGray};
  transition: opacity 100ms ease-in-out;
  border: none;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;

  ${({ copied }) =>
    copied &&
    css`
      cursor: default;
      background: #333544;
      visibility: visible;
      opacity: 1;
    `}

  &:hover {
    color: ${({ theme }) => theme.color.white};
    background: #333544;
  }
`;

export const Copy = styled(LuCopy)`
  width: 16px;
  height: 16px;
`;

export const Copied = styled(LuCheck)`
  color: ${({ theme }) => theme.color.greenLight1};
  width: 16px;
  height: 16px;
`;

export enum BlockType {
  FormTitle = 'FORM_TITLE',

  // Layout
  Text = 'TEXT',
  Label = 'LABEL',
  Title = 'TITLE',
  Heading1 = 'HEADING_1',
  Heading2 = 'HEADING_2',
  Heading3 = 'HEADING_3',
  Divider = 'DIVIDER',
  PageBreak = 'PAGE_BREAK',
  ThankYouPage = 'THANK_YOU_PAGE',

  // Embed
  Image = 'IMAGE',
  Embed = 'EMBED',
  EmbedVideo = 'EMBED_VIDEO',
  EmbedAudio = 'EMBED_AUDIO',

  // Groups
  Question = 'QUESTION',
  MultipleChoice = 'MULTIPLE_CHOICE',
  Checkboxes = 'CHECKBOXES',
  Dropdown = 'DROPDOWN',
  Ranking = 'RANKING',
  Matrix = 'MATRIX',
  MultiSelect = 'MULTI_SELECT',

  // Fields
  InputText = 'INPUT_TEXT',
  InputNumber = 'INPUT_NUMBER',
  InputEmail = 'INPUT_EMAIL',
  InputLink = 'INPUT_LINK',
  InputPhoneNumber = 'INPUT_PHONE_NUMBER',
  InputDate = 'INPUT_DATE',
  InputTime = 'INPUT_TIME',
  Textarea = 'TEXTAREA',
  FileUpload = 'FILE_UPLOAD',
  LinearScale = 'LINEAR_SCALE',
  Rating = 'RATING',
  HiddenFields = 'HIDDEN_FIELDS',
  MultipleChoiceOption = 'MULTIPLE_CHOICE_OPTION',
  Checkbox = 'CHECKBOX',
  DropdownOption = 'DROPDOWN_OPTION',
  RankingOption = 'RANKING_OPTION',
  MultiSelectOption = 'MULTI_SELECT_OPTION',
  Payment = 'PAYMENT',
  Signature = 'SIGNATURE',
  MatrixRow = 'MATRIX_ROW',
  MatrixColumn = 'MATRIX_COLUMN',
  WalletConnect = 'WALLET_CONNECT',

  // Advanced
  ConditionalLogic = 'CONDITIONAL_LOGIC',
  CalculatedFields = 'CALCULATED_FIELDS',
  Captcha = 'CAPTCHA',
  RespondentCountry = 'RESPONDENT_COUNTRY',
}

export type BlockPayload = any;

export type Block<T = BlockType, P = BlockPayload> = {
  uuid: string;
  type: T;
  groupUuid: string;
  groupType: BlockType;
  payload: P;
};

export const isBlock = (block: any): block is Block => {
  if (typeof block !== 'object') {
    return false;
  }

  if (!block?.uuid) {
    return false;
  }

  if (!Object.values(BlockType).includes(block?.type)) {
    return false;
  }

  // uuid & valid block type = assume valid block
  return true;
};

import { ValidationRuleConstraint } from '@tallyforms/lib';

import ValidationError from '@/components/form/validation-error';
import { Question } from '@/types/form-builder';
import { range } from '@/utils/array';

import { Container, Item, Items, ItemsContainer, LabelsContainer } from './styled';

interface Props {
  uuid: string;
  value?: number | undefined;
  start: number;
  end: number;
  step: number;
  leftLabel?: string;
  centerLabel?: string;
  rightLabel?: string;
  isRequired?: boolean;
  error?: ValidationRuleConstraint;
  question?: Question;
  onChange?: (value: number | undefined) => void;
  renderRequiredIndicator?: (props?: any) => JSX.Element | null;
}

const LinearScaleBlock = ({
  uuid,
  value,
  start,
  end,
  step,
  leftLabel,
  centerLabel,
  rightLabel,
  isRequired,
  error,
  question,
  onChange,
  renderRequiredIndicator,
}: Props) => {
  const errorId = error ? `error_${question?.blockGroupUuid}` : undefined;
  const items = range(start, end + 1, step);
  const numberOfItems = items.length;

  return (
    <Container numberOfItems={numberOfItems} className="tally-linear-scale">
      <ItemsContainer>
        <fieldset role="group">
          {question && <legend>{question.title}</legend>}

          <Items>
            {items.map((number) => (
              <Item
                key={number}
                className={`tally-linear-scale-option ${
                  number === value ? 'tally-linear-scale-option-selected' : ''
                }`}
                isSelected={number === value}
                numberOfItems={numberOfItems}
                onClick={() => onChange?.(number === value ? undefined : number)}>
                <input
                  id={`number_${uuid}_${number}`}
                  name={`linear_scale_${uuid}`}
                  type="radio"
                  value={number}
                  checked={value === number}
                  required={isRequired}
                  aria-describedby={errorId}
                  onChange={() => onChange?.(number)}
                />

                <label htmlFor={`number_${uuid}_${number}`}>{number}</label>
              </Item>
            ))}
          </Items>

          {(leftLabel || centerLabel || rightLabel) && (
            <LabelsContainer numberOfItems={numberOfItems} noCenterLabel={!centerLabel}>
              <span className="tally-linear-scale-label">{!!leftLabel && leftLabel}</span>
              <span className="tally-linear-scale-label">{!!centerLabel && centerLabel}</span>
              <span className="tally-linear-scale-label">{!!rightLabel && rightLabel}</span>
            </LabelsContainer>
          )}
        </fieldset>

        {renderRequiredIndicator && renderRequiredIndicator()}

        {error && <ValidationError id={errorId} error={error} />}
      </ItemsContainer>
    </Container>
  );
};

export default LinearScaleBlock;
